import React from "react";
import "./SentenceLayout.scss";

export interface SentenceLayoutProps{
    children?: any
    alignment: "left" | "center" | "right";
    fullWidth?: boolean;
    largeGap?: boolean;
}
export default class SentenceLayout extends React.Component<SentenceLayoutProps, {}>{
    public render() {
        return (
            <div className={`SentenceLayout ${this.props.alignment && this.props.alignment} ${this.props.fullWidth && 'fullWidth'} ${this.props.largeGap && 'largeGap'}`}>
                {this.props.children}
            </div>
        );
    }
}
