import React, {RefObject} from "react";
import "./Checkbox.scss";
import CheckboxProps from "../CheckboxProps";


export default class Checkbox extends React.Component<CheckboxProps, {}>{
    private _divRef: RefObject<HTMLDivElement> = React.createRef();
    private _disabledPerc = 0.2; //Percentuale in disabilitato
    private _enabledPerc = 0.8;  //Percentuale in abilitato
    private _circleCount = 50; //Conteggio dei cerchi

    public componentDidUpdate(prevProps: Readonly<CheckboxProps>, prevState: Readonly<{}>, snapshot?: any) {
        if(prevProps.disabled !== this.props.disabled && this.props.disabled)
            this.props.onChange && this.props.onChange(false);
    }

    public render() {
        return (
            <div
                ref={this._divRef}
                className={`Checkbox ${this.props.disabled && "disabled"}`}
                onClick={() => {
                    if(!this.props.disabled && this.props.onChange)
                        this.props.onChange(!this.props.checked);
                }}>
                {new Array(this._circleCount).fill(0).map((value, index) => {
                    const perc = this.props.checked ? this._enabledPerc : this._disabledPerc;
                    const rotation = (index / this._circleCount) * (360 * perc);
                    return <SmallCircle key={`Circle${index}`} rotation={rotation} color={this.props.checked ? "green" : "red"}/>
                })}
                <span className={`checkmark ${this.props.checked && "checked"}`}/>
            </div>
        );
    }
}

interface SmallCircleProps{
    rotation: number,
    color: "green" | "red";
}
class SmallCircle extends React.Component<SmallCircleProps, {}>{
    private _smallCircleRef: RefObject<HTMLSpanElement> = React.createRef();

    private _setSmallCircleRotation(rotation: number){
        if(this._smallCircleRef.current)
            this._smallCircleRef.current.style.setProperty("--rotation", `${rotation}deg`);
    }

    componentDidMount() {
        this._setSmallCircleRotation(this.props.rotation);
    }

    componentDidUpdate(prevProps: Readonly<SmallCircleProps>, prevState: Readonly<{}>, snapshot?: any) {
        if(prevProps.rotation !== this.props.rotation)
            this._setSmallCircleRotation(this.props.rotation);
    }

    public render() {
        return <span className={`smallCircle ${this.props.color}`} ref={this._smallCircleRef}/>;
    }
}
