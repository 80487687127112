import React from "react";

export interface DADMOverlayProps{
    nomeFoglio: string,
    lavorazione?: string,
    cartaExtra?: string,
    nascondiEliminazione?: boolean,
    onDeleteLavorazione?: (nomeFoglio: string) => void,
    onDeleteCartaExtra?: (nomeFoglio: string) => void,
}

export default class DADMOverlay extends React.Component<DADMOverlayProps, {}>{
    public render() {
        return (
            <div className={"DADMOverlay"}>
                {
                    this.props.cartaExtra &&
                    <span className={"OverlayRow"}>
                        {
                            !this.props.nascondiEliminazione &&
                            <span className={"DeleteButton"} onClick={(ev) => {
                                ev.preventDefault();
                                ev.stopPropagation();
                                this.props.onDeleteCartaExtra && this.props.onDeleteCartaExtra(this.props.nomeFoglio)
                            }}/>
                        }
                        {this.props.cartaExtra}
                    </span>
                }
                {
                    this.props.lavorazione &&
                    <span className={"OverlayRow"}>
                        {
                            !this.props.nascondiEliminazione &&
                            <span className={"DeleteButton"} onClick={(ev) => {
                                ev.preventDefault();
                                ev.stopPropagation();
                                this.props.onDeleteLavorazione && this.props.onDeleteLavorazione(this.props.nomeFoglio)
                            }}/>
                        }
                        {this.props.lavorazione}
                    </span>
                }
            </div>
        );
    }
}
