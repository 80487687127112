import React from "react";
import {NotificaContext} from "../../../Primary/NotificaIcon/NotificaIcon";
import IfContainer from "../../../Layout/IfContainer/IfContainer";
import LocalStorageService from "../../../Servicies/LocalStorageService";
import RedirectService from "../../../Servicies/RedirectService";
import {NotificaChatResponseModel} from "tici_commons";
import SectionLayout from "../../../Layout/SectionLayout/SectionLayout";
import ResponsiveLabel from "../../../Core/ResponsiveLabel/ResponsiveLabel";
import Button from "../../../Core/Buttons/Button";

export interface NotificheProps {
    espandiNotifiche?: boolean,
    onClick?: () => void
}
export default class Notifiche extends React.Component<NotificheProps, {}>{

    /**
     * Va all'ordine partendo dalle notifiche
     * @param contenuto Contenuto della notifica
     * @private
     */
    private _goToOrderFromNotificaChat(contenuto: NotificaChatResponseModel){
        LocalStorageService.RedirectOrdineNotifica = contenuto.idOrdine;
        RedirectService.GoToOrdini();
    }

    public render() {
        return (
            <SectionLayout size={"largeRelative"}>
                <ResponsiveLabel content={"Controlla le tue notifiche"} type={"large"} alignment={"center"} uppercase={true} bold={true}/>
                <SectionLayout size={"mediumRelative"} addPadding={true} showBorder={true} lightBackground={true}>
                    <NotificaContext.Consumer>{
                        notifiche => (
                            <IfContainer
                                condition={notifiche.notifiche.length + notifiche.notificheChat.length > 0}
                                elseComponent={<ResponsiveLabel content={"Nessuna notifica presente"} type={"medium"} alignment={"center"}/>}>
                                {
                                    notifiche.notificheChat.map(notificaChat => (
                                        <SectionLayout size={"largeRelative"} addPadding={true} showBorder={true} lightBackground={true}>
                                            <ResponsiveLabel
                                                content={notificaChat.dataInvio.split('-').reverse().join('/')}
                                                type={"medium"}
                                                alignment={"right"}
                                                bold={true}/>
                                            <Button
                                                content={`${notificaChat.messaggio}. Clicca per raggiungere l'ordine`}
                                                onClick={() => this._goToOrderFromNotificaChat(notificaChat)}
                                                type={"medium"}
                                                buttonType={"simple-text-no-underline"}/>
                                        </SectionLayout>
                                    ))
                                }
                                {
                                    notifiche.notifiche.map(notifica => (
                                        <SectionLayout size={"largeRelative"} addPadding={true} showBorder={true} lightBackground={true}>
                                            <ResponsiveLabel
                                                content={notifica.dataInvio.split('-').reverse().join('/')}
                                                type={"medium"}
                                                alignment={"right"}
                                                bold={true}/>
                                            <ResponsiveLabel
                                                content={notifica.contenuto}
                                                type={"medium"}
                                                alignment={"left"}/>
                                        </SectionLayout>
                                    ))
                                }
                            </IfContainer>

                        )
                    }</NotificaContext.Consumer>
                </SectionLayout>
            </SectionLayout>
        );
    }
}
