import Materiali from "../../../DatabaseData/Materiali";
import ResponsiveLabel from "../../../Core/ResponsiveLabel/ResponsiveLabel";
import SelettoreUv from "../../../Primary/SelettoreUv/SelettoreUv";
import IfContainer from "../../../Layout/IfContainer/IfContainer";
import React, {Fragment} from "react";
import {RenderizzatoreSezioneInterface} from "../../../Primary/Renderizzatore/Renderizzatore";
import {ConfigurazioneSezioneInterface, TipoSezioneConfiguratoreType} from "tici_commons";
import {StorageData} from "../../../Pages/SitoInterno/Configuratore/Storage/StorageManager";

export interface SelettoreUvRenderizzatoreProps {
    sezione: RenderizzatoreSezioneInterface,
    hasSezioneData: (nomeSezione: string) => boolean,
    getSezioneData: (nomeSezione: string) => string,
    getSezioneType: (nomeSezione: string) => TipoSezioneConfiguratoreType,
    isSingolaImmagine: (nomeSezione: string) => boolean,
    isSingoloColore: (nomeSezione: string) => boolean,
    setSezioneData: (nomeSezione: string, tipoSezione: TipoSezioneConfiguratoreType, valoreSezione: string, materialeSezione: string) => void,
    deleteSezioneData: (nomeSezione: string) => void,
    setStorageData: (nomeFile: string, file?: File, base64?: string) => void,
    getStorageData: (nomeFile: string) => StorageData[],
    deleteStorageData: () => void,
    configurazioneSezione: ConfigurazioneSezioneInterface,
}

export const SelettoreUvRenderizzatore = (props: SelettoreUvRenderizzatoreProps) => {
    /**
     * Controlla che la sezione associata abbia un valore accettabile
     * @constructor
     */
    const HasSezioneAssociataValidContent = (): boolean => {
        return Materiali.CanSupportUv(props.getSezioneData(props.configurazioneSezione.sezioneAssociataUv)) ||
            props.getSezioneType(props.configurazioneSezione.sezioneAssociataUv) === 'plex' ||
            props.getSezioneType(props.configurazioneSezione.sezioneAssociataUv) === 'legno'
    }

    /**
     * Controlla che la sezione associata sia sempre valida
     * @constructor
     */
    const IsSezioneAssociataAlwaysValid = (): boolean => {
        return props.isSingolaImmagine(props.configurazioneSezione.sezioneAssociataUv) ||
            props.isSingoloColore(props.configurazioneSezione.sezioneAssociataUv)
    }

    /**
     * Controlla che il selettore sia visibile
     * @constructor
     */
    const IsSelettoreVisible = (): boolean => {
        return ( props.hasSezioneData(props.configurazioneSezione.sezioneAssociataUv) && HasSezioneAssociataValidContent() ) || IsSezioneAssociataAlwaysValid();
    }

    return (
        <Fragment>
            <IfContainer
                condition={IsSelettoreVisible()}
                elseCallback={() => {
                    props.deleteStorageData();
                    props.deleteSezioneData(props.sezione.nomeSezione);
                }}
                elseComponent={
                    <ResponsiveLabel
                        content={`Il codice uv "${props.configurazioneSezione.nomeVisualizzato}" non è applicabile`}
                        type={"small"}
                        alignment={"right"}/>
                }>
                <SelettoreUv
                    addPaddingAndBorder={true}
                    label={`Codice UV ${props.configurazioneSezione.nomeVisualizzato}`}
                    placeHolder={"Seleziona"}
                    currentImageName={props.getSezioneData(props.sezione.nomeSezione)}
                    errorMessage={"Bisogna caricare obbligatoriamente questo uv"}
                    showError={!props.hasSezioneData(props.sezione.nomeSezione) && props.sezione.obbligatoria}
                    currentImage={
                        props.getStorageData(props.getSezioneData(props.sezione.nomeSezione))[0]?? undefined
                    }
                    onChange={(imageName, file, base64) => {
                        if(imageName){
                            props.setSezioneData(props.sezione.nomeSezione, "uv", imageName, base64);
                            if(base64)
                                props.setStorageData(imageName, undefined, base64);
                            else props.setStorageData(imageName, file)
                        }else{
                            props.deleteSezioneData(props.sezione.nomeSezione);
                            props.deleteStorageData();
                        }

                    }}/>
            </IfContainer>
        </Fragment>
    )
}
