import React, {RefObject} from "react";
import "./VerticalSpace.scss";

export interface VerticalSpaceProps{
    gapMobile: number;
    gapTablet: number;
    gapDesktop: number;
}

export default class VerticalSpace extends React.Component<VerticalSpaceProps, any>{
    private _containerRef: RefObject<HTMLDivElement> = React.createRef();

    public componentDidMount() {
        if(this._containerRef.current){
            this._containerRef.current.style.setProperty("--gapMobile", `${this.props.gapMobile}px`);
            this._containerRef.current.style.setProperty("--gapTablet", `${this.props.gapTablet}px`);
            this._containerRef.current.style.setProperty("--gapDesktop", `${this.props.gapDesktop}px`);
        }
    }

    public render() {
        return (
            <div ref={this._containerRef} className={"VerticalSpace"}/>
        );
    }
}
