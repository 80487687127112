import React from "react";
import {AgenteResponseModel, FotografoBaseResponseModel} from "tici_commons";
import AgenteService from "../../../Servicies/AgenteService";
import SectionLayout from "../../../Layout/SectionLayout/SectionLayout";
import IfContainer from "../../../Layout/IfContainer/IfContainer";
import LabelLoader from "../../../Primary/LabelLoader/LabelLoader";
import ResponsiveLabel from "../../../Core/ResponsiveLabel/ResponsiveLabel";
import CardAgente from "../../../Primary/CardAgente/CardAgente";
import TopLevelComponentStorage from "../../../Core/Arch/TopLevelComponentStorage";
import UserService from "../../../Servicies/UserService";

export interface ConfigurazioneAgentiState {
    listaAgenti: AgenteResponseModel[],
    listaFotografi: FotografoBaseResponseModel[]

    isLoading: boolean
}

export default class ConfigurazioneAgenti extends React.Component<{}, ConfigurazioneAgentiState>{
    constructor(props: Readonly<{}> | {}) {
       super(props);
       this.state = {
           listaAgenti: [],
           listaFotografi: [],
           isLoading: true
       }
    }

    public async componentDidMount() {
        this.setState({
            listaAgenti: await AgenteService.RecuperaAgenti(),
            listaFotografi: await AgenteService.ListaFotografi(),
            isLoading: false
        })
    }

    /**
     * Effettua l'eliminazione dell'agente
     * @private
     */
    private async _eliminaAgente(emailAgente: string){
        TopLevelComponentStorage.GetTopLevel('confirmWindow').showConfirmWindow(
            'Eliminazione agente',
            "Sei sicuro di voler eliminare l'agente? Le informazioni associate all'account saranno eliminate e non saranno più recuperabili",
            "DoubleButton",
            async () => {
                TopLevelComponentStorage.GetTopLevel('loadingWindow').showLoadingWindow("Eliminazione", "Eliminazione in corso");
                const response = await UserService.EliminazioneAccount(emailAgente);
                TopLevelComponentStorage.GetTopLevel('loadingWindow').hideLoadingWindow();
                TopLevelComponentStorage.GetTopLevel('confirmWindow').showConfirmWindow(
                    response ? "Eliminazione riuscita" : "Errore nell'eliminazione",
                    response ? "L'account agente é stato eliminato" : "Non é stato possibile eliminare l'agente"
                )
                this.setState({isLoading: true})
                this.setState({listaAgenti: await AgenteService.RecuperaAgenti(), isLoading: false});
            },
            () => TopLevelComponentStorage.GetTopLevel('confirmWindow').hideConfirmWindow
        )
    }

    public render() {
        return (
            <SectionLayout size={"largeRelative"}>
                <IfContainer
                    condition={!this.state.isLoading}
                    elseComponent={<LabelLoader label={"Recupero lista agenti"}/>}>
                    <IfContainer
                        condition={this.state.listaAgenti.length > 0}
                        elseComponent={<ResponsiveLabel content={"Nessun agente presente"} type={"large"} alignment={"center"}/>}>
                        {
                            this.state.listaAgenti.map(agente =>  (
                                <CardAgente
                                    key={`Agente-${agente.idAgente}`}
                                    agente={agente}
                                    listaFotografi={this.state.listaFotografi}
                                    onDelete={() => this._eliminaAgente(agente.email)}/>
                            ))
                        }
                    </IfContainer>
                </IfContainer>
            </SectionLayout>
        )
    }
}
