import "./IfContainer.scss";
import React, {Fragment} from "react";

export interface IfContainerProps{
    children: any,
    condition: boolean,
    elseComponent?: JSX.Element,
    elseCallback?: () => void,
    hiddenMode?: boolean
}

export default class IfContainer extends React.Component<IfContainerProps, {}>{
    componentDidMount() {
        if(!this.props.condition && this.props.elseCallback)
            this.props.elseCallback();
    }

    componentDidUpdate(prevProps: Readonly<IfContainerProps>, prevState: Readonly<{}>, snapshot?: any) {
        if(prevProps.condition !== this.props.condition && this.props.elseCallback)
            this.props.elseCallback();
    }

    public render() {
        let esito = <Fragment/>;
        if(this.props.hiddenMode){
            esito = (
                <Fragment>
                    <div className={`IfContainer ${!this.props.condition && 'hidden'}`}>
                        {this.props.children}
                    </div>
                    {
                        this.props.elseComponent &&
                        <div className={`IfContainer ${this.props.condition && 'hidden'}`}>
                            {this.props.elseComponent}
                        </div>
                    }
                </Fragment>
            )
        }else{
            if(this.props.condition)
                esito = this.props.children;
            else if(this.props.elseComponent) esito = this.props.elseComponent;
        }

        return esito;
    }
}
