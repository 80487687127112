import React, {Fragment} from "react";
import {
    ConfigurazioneBlank,
    ConfigurazioneModelloInterface, ConfigurazioneRenderizzatoreInterface, ConfigurazioneSezioneBlank,
    ConfigurazioneSezioneInterface,
    MaterialiInterface,
} from "tici_commons";
import Responsive2Col from "../../../../../Layout/Responsive2Col/Responsive2Col";
import AutoRefreshComponent from "../../../../../Core/Arch/AutoRefreshComponent";
import MenuModello from "../MenuVistaConfigurazione/MenuModello";
import MenuSezione from "../MenuVistaConfigurazione/MenuSezione";
import DataService from "../../../../../Servicies/DataService";
import SectionLayout from "../../../../../Layout/SectionLayout/SectionLayout";
import OverflowTag from "../../../../../Layout/OverflowTag/OverflowTag";

export interface VistaConfigurazioneProps{
    configurazione?: ConfigurazioneRenderizzatoreInterface,
    onChange?:  (configurazione: ConfigurazioneRenderizzatoreInterface) => void
}

export interface VistaConfigurazioneState{
    materiali: MaterialiInterface[],
    famigliaSelezionata: string,
}

export default class VistaConfigurazione extends AutoRefreshComponent<VistaConfigurazioneProps, VistaConfigurazioneState>{
    constructor(props: Readonly<VistaConfigurazioneProps> | VistaConfigurazioneProps) {
        super(props);
        this.Delay = 10000;
        this.state = {
            materiali: [],
            famigliaSelezionata: "",
        }
    }

    private cycleMateriali = async () => {
        this.setState({materiali: await DataService.ListaMateriali()});
    }

    /**
     * Restituisce la lista dei gruppi delle sezioni
     * @private
     */
    private _listaGruppi(): string[]{
        const esito: string[] = [];

        for(const sezione of this.props.configurazione.configurazioniSezioni){
            if(!esito.map(value => value.toLowerCase()).includes(sezione.nomeGruppo.toLowerCase()) && sezione.nomeGruppo.length !== 0)
                esito.push(sezione.nomeGruppo);
        }

        return esito;
    }

    /**
     * Restituisce la lista delle sezioni
     * @private
     */
    private _listaSezioni(): string[]{
        const esito: string[] = [];

        for(const sezione of this.props.configurazione.configurazioniSezioni){
            if(!esito.includes(sezione.nomeVisualizzato))
                esito.push(sezione.nomeVisualizzato);
        }

        return esito;
    }

    /**
     * Restituisce la lista delle famiglie
     * @private
     */
    private _listaFamiglie(): string[]{
        const esito: string[] = [];

        for(const materiale of this.state.materiali){
            !esito.includes(materiale.famigliaMateriale) &&
            !this.props.configurazione.configurazioneModello.materialiEsclusi.includes(materiale.famigliaMateriale) &&
            esito.push(materiale.famigliaMateriale);
        }

        return esito;
    }

    /**
     * Aggiorna le configurazioni delle sezioni
     * @param nuovaSezione Nuova sezione da inserire
     * @param index Indice di inserimento della sezione
     * @private
     */
    private _updateSezione(nuovaSezione: ConfigurazioneSezioneInterface, index: number){
        const current = {...ConfigurazioneBlank, ...this.props.configurazione};
        current.configurazioniSezioni[index] = {...ConfigurazioneSezioneBlank, ...nuovaSezione};
        this.props.onChange && this.props.onChange(current);
    }

    /**
     * Aggiorna la configurazione del modello
     * @param nuovaConfigurazione Nuova configurazione del modello
     * @private
     */
    private _updateModello(nuovaConfigurazione: ConfigurazioneModelloInterface){
        const current = {...ConfigurazioneBlank, ...this.props.configurazione};
        current.configurazioneModello = {...current.configurazioneModello, ...nuovaConfigurazione};
        this.props.onChange && this.props.onChange(current);
    }

    public render() {
        return (
            this.props.configurazione &&
            <SectionLayout size={"largeRelative"}>
                <OverflowTag/>
                {
                    this.props.configurazione.configurazioneModello &&
                    <MenuModello
                        configurazioneModello={this.props.configurazione.configurazioneModello}
                        onChange={(configurazioneModello) => this._updateModello(configurazioneModello)}
                        listaFamiglie={this._listaFamiglie()}/>
                }
                <Responsive2Col>
                    {
                        this.props.configurazione && this.props.configurazione.configurazioniSezioni &&
                        this.props.configurazione.configurazioniSezioni.map(
                            (configurazione, index) =>
                                <MenuSezione
                                    key={`ConfigurazioneSezione-${index}`}
                                    configurazioneSezione={configurazione}
                                    onChange={configurazioneSezione => this._updateSezione(configurazioneSezione, index)}
                                    listaGruppi={this._listaGruppi()}
                                    listaSezioni={this._listaSezioni()}/>
                        )
                    }
                </Responsive2Col>
            </SectionLayout>
        )
    }
}
