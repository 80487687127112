import "./OrdineLine.scss";
import {DettaglioOrdineModel, UserContextResponseModel} from "tici_commons";
import IfContainer from "../../Layout/IfContainer/IfContainer";
import ResponsiveLabel from "../../Core/ResponsiveLabel/ResponsiveLabel";
import StopLight from "../StopLight/StopLight";
import ChatIcon from "../ChatIcon/ChatIcon";
import SentenceLayout from "../../Layout/SentenceLayout/SentenceLayout";

import React from "react";
import Ordine from "../Ordine/Ordine";
import UserService from "../../Servicies/UserService";
import ImageService from "../../Servicies/ImageService";

export interface OrdineLineProps{
    ordine: DettaglioOrdineModel;
    isOpen: boolean;
    onClick?: (idOrdine: number) => void;
    updateOrdini?: () => void;
}

export interface OrdineLineState {
    userContext: UserContextResponseModel,
}

export default class OrdineLine extends React.Component<OrdineLineProps, OrdineLineState>{
    constructor(props: Readonly<OrdineLineProps> | OrdineLineProps) {
        super(props);
        this.state = {
            userContext: undefined
        }

        UserService.GetUserContext().then((response) => {
            this.setState({userContext: response});
        });
    }

    public render() {
        return (
            <IfContainer condition={!!this.props.ordine}>
                <div className={"OrdineLineContainer"}>
                    <span className={"Header"} onClick={() => {this.props.onClick && this.props.onClick(this.props.ordine.id)}}>
                        <span className={"firstZone"}>
                            <ResponsiveLabel content={`${this.props.ordine.id}`} type={"large"} alignment={"center"} labelType={'whiteLabel'}/>
                            <StopLight lightNumber={this.props.ordine.statoErrore ? 2 : this.props.ordine.statoConferma ? 3 : 1}/>
                            {this.state.userContext && <ChatIcon ordineId={this.props.ordine.id}/>}
                            <IfContainer condition={this.state.userContext && ['amministrazione', 'agente'].includes(this.state.userContext.tipoUtente)}>
                                <span className={"userImage"} style={{backgroundImage: `url(${UserService.UserImageUrl(this.props.ordine.emailFotografo, this.props.ordine.immagineFotografo)})`}}/>
                            </IfContainer>
                        </span>

                        <SentenceLayout alignment={"center"}>
                            <ResponsiveLabel
                                content={`${this.props.ordine.dataCreazione.split('-').reverse().join('/')}`}
                                type={"medium"}
                                alignment={"center"}
                                labelType={"whiteLabel"}/>
                            <ResponsiveLabel
                                content={`"${this.props.ordine.nomeOrdine}"`}
                                type={"large"}
                                alignment={"center"}
                                labelType={"whiteLabel"}
                                italic={true}/>
                            <ResponsiveLabel
                                content={`di`}
                                type={"medium"}
                                alignment={"center"}
                                labelType={"whiteLabel"}/>
                            <ResponsiveLabel
                                content={`${this.props.ordine.nomeFotografo} ${this.props.ordine.cognomeFotografo}`}
                                type={"medium"}
                                alignment={"center"}
                                labelType={"whiteLabel"}/>
                            <ResponsiveLabel
                                content={`${this.props.ordine.emailFotografo}`}
                                type={"medium"}
                                alignment={"center"}
                                labelType={"whiteLabel"}/>
                        </SentenceLayout>

                        <span className={"lastZone"}>
                            <IfContainer condition={this.props.ordine.statoErrore === 1}>
                                <img alt={"AlertIcon"} className={"icon"} src={ImageService.getImage('alertIcon')}/>
                            </IfContainer>
                            <IfContainer
                                condition={this.props.isOpen}
                                elseComponent={<img alt={"EyeIcon"} className={"icon"} src={ImageService.getImage('eyeIcon')}/>}>
                                <img alt={"AzureEyeIcon"} className={"icon"} src={ImageService.getImage('azureEyeIcon')}/>
                            </IfContainer>
                        </span>
                    </span>
                    <IfContainer condition={this.props.isOpen}>
                        <span className={`Body`}>
                            <Ordine
                                key={`Ordine-Line-${this.props.ordine.id}`}
                                ordine={this.props.ordine}
                                vistaAmministrazione={this.state.userContext && this.state.userContext.tipoUtente === 'amministrazione'}
                                updateOrdini={this.props.updateOrdini}/>
                        </span>
                    </IfContainer>
                </div>
            </IfContainer>
        );
    }
}
