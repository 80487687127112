import Config from "../Static/Config";
import {Rest} from "tici_commons";

export default class UV{
    private static _uv: [string, string][] = [];
    static {
        console.warn("Loading uv");
        Rest.Get<[string, string][]>(`${Config.PublicPath}/UV`).then(value => {
            if(value && value.statusCode === 200)
                UV._uv = value.response;
        });
    }

    /**
     * Restituisce la lista degli UV presenti sul database
     */
    public static GetUv(): [string, string][]{
        return UV._uv;
    }
}
