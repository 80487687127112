import React from "react";
import './SearchLayout.scss';

export default class SearchLayout extends React.Component<{children: any}, {}>{
    public render() {
        return (
            <div className={"SearchLayout"}>
                {this.props.children}
            </div>
        );
    }
}
