import React, {RefObject} from "react";
import "./CarouselLayout.scss";
import ResponsiveLabel from "../../Core/ResponsiveLabel/ResponsiveLabel";

export interface CarouselLayoutState{
    shouldScroll: boolean
}
export default class CarouselLayout extends React.Component<{children?: any}, CarouselLayoutState>{
    private _containerRef: RefObject<HTMLDivElement> = React.createRef();

    constructor(props: Readonly<{ children?: any }> | { children?: any }) {
        super(props);
        this.state = {
            shouldScroll: false
        }
    }

    /**
     * Descrive il singolo item del carosello
     * @param props Proprietà dell'item
     * @constructor
     */
    public static CarouselItem(props: {children: any, order?: number}){
        return (
            <span className={"CarouselItem"} style={{order: props.order?? 0}}>
                {props.children}
            </span>
        );
    }

    componentDidMount() {
        if(this._containerRef.current)
            this.setState({shouldScroll: this._shouldScroll()});
    }

    componentDidUpdate(prevProps: Readonly<{ children?: any }>, prevState: Readonly<CarouselLayoutState>, snapshot?: any) {
        if(this.props.children.length !== prevProps.children.length) {
            if (this._containerRef.current)
                this.setState({shouldScroll: this._shouldScroll()});
        }
    }

    /**
     * Controlla se può o meno scrollare e quindi se è il casodi visualizzare i button
     * @private
     */
    private _shouldScroll(): boolean{
        let esito = false;

        if(this._containerRef.current)
            esito = this._containerRef.current.scrollWidth > this._containerRef.current.getBoundingClientRect().width;

        return esito;
    }


    /**
     * Effettua lo spin del carosello
     * @param direction Direzione di spin
     * @private
     */
    private _spin(direction: 1 | -1){
        if(this._containerRef.current){
            const children = this._containerRef.current.children;
            if(children.length > 0){
                const child = children.item(0);
                this._containerRef.current.scrollBy({left: child.getBoundingClientRect().width * direction, behavior: "smooth"});
            }
        }
    }

    render() {
        return (
            <div className={"CarouselLayout"}>
                <div className={"container"}>
                    <span className={`spinButton ${this.state.shouldScroll && 'scroll'}`} onClick={() => this._spin(-1)}>
                        <ResponsiveLabel content={"^"} type={"medium"} alignment={"center"}/>
                    </span>
                    <div ref={this._containerRef} className={"content"}>
                        {this.props.children}
                    </div>
                    <span style={{rotate: "180deg"}} className={`spinButton ${this.state.shouldScroll && 'scroll'}`} onClick={() => this._spin(1)}>
                        <ResponsiveLabel content={"^"} type={"medium"} alignment={"center"}/>
                    </span>
                </div>
            </div>
        )
    }
}
