import {
    ConfigurazioneFotografoModel,
    ConfigurazioneFotografoRouteResponseModel,
    FotolibroModel,
    Rest,
    TipoConfigurazioneEnum
} from "tici_commons";
import Config from "../Static/Config";
import {CoordinatoData} from "../Pages/SitoInterno/Configuratore/Sezioni/Riepilogo/SezioneCoordinato";

export class ConfigurazioneService{
    /**
     * Recupera le configurazioni dal database
     * @param tipoConfigurazione Tipo di configurazione, 0 utente, 1 2 3 per i progetti, 4 per quelle cliente
     * @constructor
     */
    public static async GetConfigurazioni(tipoConfigurazione: TipoConfigurazioneEnum): Promise<ConfigurazioneFotografoRouteResponseModel[]>{
        const esito: ConfigurazioneFotografoRouteResponseModel[] = [];

        try{
            const response = await Rest.Get<ConfigurazioneFotografoModel[]>(
                `${Config.PrivatePath}/ConfigurazioneFotografo/${tipoConfigurazione}`
            );

            if(response && response.statusCode === 200)
                esito.push(...response.response);
        }catch (e){
            console.error("Errore recupero configurazioni", String(e));
        }

        return esito;
    }

    /**
     * Recupera il dettaglio della configurione
     * @param idConfigurazione Identificativo della configurazione
     * @constructor
     */
    public static async GetDettaglioConfigurazione(idConfigurazione: number): Promise<ConfigurazioneFotografoModel | undefined>{
        let esito = undefined;

        try {
            const response = await Rest.Get<ConfigurazioneFotografoModel>(
                `${Config.PrivatePath}/DettaglioConfigurazioneFotografo/${idConfigurazione}`
            )
            if(response && response.statusCode === 200)
                esito = response.response;
        }catch (e) {
            console.error("Errore recupero dettaglio confiugrazione", String(e));
        }

        return esito;
    }

    /**
     * Aggiunge una nuova configurazione sul database
     * @param tipoConfigurazione Tipologia di configurazione da aggiungere
     * @param nomeConfigurazione Nome della configurazione
     * @param data Data della configurazione
     * @param informazioniCoordinato Informazioni del coordinato
     * @constructor
     */
    public static async AddConfigurazione(tipoConfigurazione: TipoConfigurazioneEnum, nomeConfigurazione: string, data: FotolibroModel, informazioniCoordinato: CoordinatoData): Promise<boolean> {
        let esito = false;

        try {
            const response = await Rest.Post(
                `${Config.PrivatePath}/ConfigurazioneFotografo`,
                undefined,
                {
                    tipoConfigurazione: tipoConfigurazione,
                    nomeConfigurazione: nomeConfigurazione,
                    data: data,
                    informazioniCoordinato: informazioniCoordinato
                }
            );

            if(response && response.statusCode === 200)
                esito = true;
        }catch(e){
            console.error("Errore aggiunta configurazione", String(e));
        }

        return esito;
    }

    /**
     * Elimina una configurazione sul database
     * @param idConfigurazione Identificativo della configurazione da eliminare
     * @constructor
     */
    public static async DeleteConfigurazione (idConfigurazione: number): Promise<boolean> {
        let esito = false;

        try{
            const response = await Rest.Delete(
                `${Config.PrivatePath}/ConfigurazioneFotografo/${idConfigurazione}`
            );

            if(response && response.statusCode === 200)
                esito = true;
        }catch (e){
            console.error("Errore eliminazione configurazione", String(e));
        }

        return esito;
    }
}
