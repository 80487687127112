import React, {Fragment} from "react";
import SezioneInformazioniRilegatura from "./RilegaturaSezioni/SezioneInformazioniRilegatura";
import SezioneLogo from "./RilegaturaSezioni/SezioneLogo";
import {FotolibroModel} from "tici_commons";
import {StorageManagerStatus} from "../../Storage/StorageManager";
import {
    GenerateRiepilogoBox,
    RiepilogoBox,
    RiepilogoData,
    RiepilogoInternalBox
} from "../../Commons/RiepilogoDatoModel";
import SezioneGenerica from "../SezioneGenerica";

export interface RilegaturaProps {
    modalitaConfiguratore?: boolean
    stampaFlag?: boolean
}

export default class Rilegatura extends SezioneGenerica<"rilegatura", RilegaturaProps, {}> {
    public generaRiepilogo = (fotolibro: FotolibroModel, storage: StorageManagerStatus, modalitaConfiguratore: boolean): RiepilogoBox | RiepilogoInternalBox | RiepilogoInternalBox[] | RiepilogoData[] => {
        return GenerateRiepilogoBox(
            'Blocco',
            new SezioneInformazioniRilegatura(undefined).generaRiepilogo(fotolibro, storage, modalitaConfiguratore) as RiepilogoInternalBox,
            new SezioneLogo(undefined).generaRiepilogo(fotolibro, storage, modalitaConfiguratore) as RiepilogoInternalBox,
        )
    }

    render() {
        return (
            <Fragment>
                <SezioneInformazioniRilegatura
                    storage={this.props.storage}
                    data={this.props.data}
                    SetParentState={this.props.SetParentState}/>
                <SezioneLogo
                    storage={this.props.storage}
                    data={this.props.data}
                    SetParentState={this.props.SetParentState}/>
            </Fragment>
        );
    }
}
