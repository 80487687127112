import React from "react";
import "./TextArea.scss";
import {DefaultInputProps} from "../DefaultInput/DefaultInput";

export interface TextAreaProps extends Omit<DefaultInputProps, "max" | "min" | "inputRef" | "type" | "onFileUpload" | "placeHolder" | "onChange">{
    maxLenght?: number,
    onChange?: (v: string) => void
}
export default class TextArea extends React.Component<TextAreaProps, {}>{
    public render() {
        return (
            <textarea
                className={"TextArea"}
                onChange={v => {
                    this.props.onChange && !this.props.disabled && this.props.onChange(v.target.value);
                }}
                maxLength={this.props.maxLenght}
                value={this.props.value}
                disabled={this.props.disabled}>
            </textarea>
        );
    }
}
