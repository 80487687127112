import React from "react";
import IfContainer from "../../../../../../Layout/IfContainer/IfContainer";
import {CheckLine, SelectLine} from "../../../../../../Widgets/Configuratore/CComponents";
import {
    ConfigurazioneFotografoModel,
    ConfigurazioneFotografoRouteResponseModel,
    FotolibroModel,
    isCatalogo,
    TipoConfigurazioneEnum
} from "tici_commons";
import {ConfigurazioneService} from "../../../../../../Servicies/ConfigurazioneService";
import LocalStorageService from "../../../../../../Servicies/LocalStorageService";
import RedirectService from "../../../../../../Servicies/RedirectService";
import SezioneGenerica from "../../SezioneGenerica";
import SezioneRequiredProps from "../../../Commons/SezioneRiquiredProps";
import {FotolibroContextInterface} from "../../../Fotolibro/Fotolibro";
import {RiepilogoBox, RiepilogoData, RiepilogoInternalBox} from "../../../Commons/RiepilogoDatoModel";
import {StorageManagerStatus} from "../../../Storage/StorageManager";
import LabelLoader from "../../../../../../Primary/LabelLoader/LabelLoader";
import CategorySectionLayout from "../../../../../../Layout/CategorySectionLayout/CategorySectionLayout";
import SentenceLayout from "../../../../../../Layout/SentenceLayout/SentenceLayout";

export interface SezioneConfigurazioniProps {
    modalitaConfiguratore?: boolean
    loadConfigurazione?: (configurazione: ConfigurazioneFotografoModel) => void
}

export interface SezioneConfigurazioniState {
    isLoading: boolean,
    configurazioni: ConfigurazioneFotografoRouteResponseModel[],
    tipoConfigurazioneUtenteSelezionata: TipoConfigurazioneEnum,
    configurazioneSelezionata: number
}

export default class SezioneConfigurazioni extends SezioneGenerica<"informazioniBase", SezioneConfigurazioniProps, SezioneConfigurazioniState>{
    constructor(props: SezioneConfigurazioniProps & SezioneRequiredProps<"informazioniBase"> & { data: FotolibroContextInterface["informazioniBase"]; storage: StorageManagerStatus }) {
        super(props);
        this.state = {
            isLoading: false,
            configurazioni: [],
            tipoConfigurazioneUtenteSelezionata: TipoConfigurazioneEnum.Utente,
            configurazioneSelezionata: undefined
        }
    }

    public componentDidMount() {
        const configurazioneTiciAttuale = LocalStorageService.ConfigurazioneTiciAttuale;
        if(configurazioneTiciAttuale !== undefined) {
            this._caricaConfigurazioneSalvata(configurazioneTiciAttuale);
        }else this._caricaConfigurazioniSalvate();
    }

    /**
     * Carica tutte le configurazioni salvate
     * @private
     */
    private async _caricaConfigurazioniSalvate() {
        if(this.$.tipoFotolibro !== "accessori"){
            this.setState({isLoading: true});
            let response: ConfigurazioneFotografoRouteResponseModel[] = [];
            let tipoConfigurazioneSelezionata = TipoConfigurazioneEnum.Utente
            if(['libero', 'blocco_book', 'tradizionale'].includes(this.$.tipoFotolibro)){
                response.push(...await ConfigurazioneService.GetConfigurazioni(TipoConfigurazioneEnum.Utente));
                response.push(...await ConfigurazioneService.GetConfigurazioni(TipoConfigurazioneEnum.Cliente));
            }else{
                switch (this.$.tipoFotolibro){
                    case "wedding_project":
                        tipoConfigurazioneSelezionata = TipoConfigurazioneEnum.Wedding
                        break;
                    case "art_project":
                        tipoConfigurazioneSelezionata = TipoConfigurazioneEnum.Art
                        break;
                    case "junior_project":
                        tipoConfigurazioneSelezionata = TipoConfigurazioneEnum.Junior
                        break;
                }
                response.push(...await ConfigurazioneService.GetConfigurazioni(tipoConfigurazioneSelezionata));
                response = response.filter(configurazione => !!configurazione.informazioniCoordinato);
                if(response.length === 0)
                    RedirectService.GoToHomepage();
            }

            this.setState({configurazioni: response, tipoConfigurazioneUtenteSelezionata: tipoConfigurazioneSelezionata, isLoading: false});
        }
    }

    /**
     * Carica una configurazione salvata
     * @param idConfigurazione Id della configurazione da caricare
     * @private
     */
    private async _caricaConfigurazioneSalvata(idConfigurazione: number) {
        this.setState({isLoading: true});
        const dettaglioConfigurazione: ConfigurazioneFotografoModel = await ConfigurazioneService.GetDettaglioConfigurazione(idConfigurazione);
        dettaglioConfigurazione && this.props.loadConfigurazione && this.props.loadConfigurazione(dettaglioConfigurazione);
        this.setState({isLoading: false});
    }

    /**
     * Restituisce il valore da assegnare alla select della configurazione o undefined nel caso in cui non sia stato selezionato nulla
     * @private
     */
    private _valoreConfigurazioneDaId(): string | undefined{
        let esito = undefined;

        if(this.state.configurazioneSelezionata !== undefined){
            const configurazione = this.state.configurazioni.find(configurazione => configurazione.idConfigurazione === this.state.configurazioneSelezionata);
            if(configurazione)
                esito = `${configurazione.idConfigurazione}-${configurazione.nomeConfigurazione}`;
        }

        return esito
    }



    public render() {
        return (
            <IfContainer condition={this.$.tipoFotolibro !== 'accessori' && !this.props.modalitaConfiguratore}>
                <IfContainer condition={this.state.isLoading || this.state.configurazioni.length > 0}>
                    <CategorySectionLayout
                        categoryLabel={"Configurazioni"}
                        size={"largeRelative"}
                        center={true}>
                        <IfContainer
                            condition={!this.state.isLoading}
                            elseComponent={<LabelLoader label={"Caricamento delle configurazioni"}/>}>
                            <IfContainer condition={!isCatalogo(this.$.tipoFotolibro)}>
                                <SentenceLayout alignment={"right"} largeGap={true}>
                                    <CheckLine
                                        label={"Configurazioni personali"}
                                        checkType={"checkbox"}
                                        reduce={true}
                                        checked={this.state.tipoConfigurazioneUtenteSelezionata === TipoConfigurazioneEnum.Utente}
                                        onChange={value => value && this.setState({tipoConfigurazioneUtenteSelezionata: TipoConfigurazioneEnum.Utente})}/>
                                    <CheckLine
                                        label={"Configurazioni clienti"}
                                        checkType={"checkbox"}
                                        reduce={true}
                                        checked={this.state.tipoConfigurazioneUtenteSelezionata === TipoConfigurazioneEnum.Cliente}
                                        onChange={value => value && this.setState({tipoConfigurazioneUtenteSelezionata: TipoConfigurazioneEnum.Cliente})}/>
                                </SentenceLayout>
                            </IfContainer>
                            <SelectLine
                                label={"Configurazioni"}
                                placeHolder={"Seleziona"}
                                elements={
                                    this.state.configurazioni
                                        .filter(configurazione => configurazione.tipoConfigurazione === this.state.tipoConfigurazioneUtenteSelezionata)
                                        .map(configurazione => ({label: `${configurazione.idConfigurazione}-${configurazione.nomeConfigurazione}`}))
                                }
                                value={this._valoreConfigurazioneDaId()?? ""}
                                onChange={v => {
                                    const idConfigurazione =  parseInt(v.split('-')[0].trim());
                                    this.setState({configurazioneSelezionata: idConfigurazione});
                                    this._caricaConfigurazioneSalvata(idConfigurazione);
                                }}/>
                        </IfContainer>
                    </CategorySectionLayout>
                </IfContainer>
            </IfContainer>
        );
    }

    public generaRiepilogo(fotolibro: FotolibroModel, storage: StorageManagerStatus, modalitaConfiguratore: boolean): RiepilogoBox | RiepilogoBox[] | RiepilogoInternalBox | RiepilogoInternalBox[] | RiepilogoData[] {
        return undefined;
    }
}
