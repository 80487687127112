import React, {Fragment} from "react";
import VerticalSpace, {VerticalSpaceProps} from "../../Layout/VerticalSpace/VerticalSpace";
import Loader from "../../Core/Loader/Loader";

export default class VSLoader extends React.Component<VerticalSpaceProps, {}>{
    public render() {
        return (
            <Fragment>
                <VerticalSpace gapMobile={this.props.gapMobile} gapTablet={this.props.gapTablet} gapDesktop={this.props.gapDesktop}/>
                <Loader/>
                <VerticalSpace gapMobile={this.props.gapMobile} gapTablet={this.props.gapTablet} gapDesktop={this.props.gapDesktop}/>
            </Fragment>
        );
    }
}
