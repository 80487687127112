import React from "react";
import './ChatOverlayMessage.scss';
import {ChatModel} from "tici_commons";
import ResponsiveLabel from "../../../../Core/ResponsiveLabel/ResponsiveLabel";
import IfContainer from "../../../../Layout/IfContainer/IfContainer";
import Button from "../../../../Core/Buttons/Button";
import Config from "../../../../Static/Config";

export interface ChatOverlayMessageProps {
    message: ChatModel,
    owner: boolean
}

export default class ChatOverlayMessage extends React.Component<ChatOverlayMessageProps, {}>{
    /**
     * Effettua il download dell'allegato
     * @private
     */
    private _scaricaAllegato(){
        fetch(`${Config.PublicPath}/${this.props.message.allegato}`).then(data => {
            data.blob().then(blob => {
                const a = document.createElement('a');
                a.href = URL.createObjectURL(blob);
                a.download = this.props.message.allegato;
                a.click();

                URL.revokeObjectURL(a.href);
            })
        });
    }

    public render() {
        return (
            <div className={`ChatOverlayMessage ${this.props.owner && 'owner'}`}>
                <span className={"title"}>
                    <ResponsiveLabel
                        content={`${this.props.message.mittente}`}
                        type={"medium"}
                        alignment={"left"}/>
                    <ResponsiveLabel
                        content={`${this.props.message.dataInvio.split(' ')[0].split('-').reverse().join('/')} ${this.props.message.dataInvio.split(' ')[1]}`}
                        type={"medium"}
                        alignment={"right"}/>
                </span>

                <ResponsiveLabel
                    content={`${this.props.message.contenuto}`}
                    type={"medium"}
                    alignment={"left"}
                    labelType={"recapLabel"}
                    selezionabile={true}/>

                <IfContainer condition={!!this.props.message.allegato}>
                    <Button
                        content={"Scarica l'allegato"}
                        type={"small"}
                        alignment={"right"}
                        buttonType={"simple-text"}
                        onClick={() => this._scaricaAllegato()}/>
                </IfContainer>
            </div>
        );
    }
}
