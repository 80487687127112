import {Rest} from "tici_commons";
import Config from "../Static/Config";

export default class RedirectService{
    private static _listenersCounter = 0;
    private static _listeners: Map<number, (path: string) => void> = new Map();

    /**
     * Aggiunge un listener di cambiamento della pagina
     * @param callback Callback da aggiungere
     */
    public static AddListener(callback: (path: string) => void): number{
        const nextIndex = RedirectService._listenersCounter++;
        RedirectService._listeners.set(nextIndex, callback);
        return nextIndex;
    }

    /**
     * Rimuove il listener associato all'indice segnato
     * @param index Indice da cui rimuovere il callback
     */
    public static RemoveListener(index: number){
        RedirectService._listeners.delete(index);
    }

    /**
     * Valida la pagina utente
     * @param nomePagina Nome della pagina da validare
     * @constructor
     */
    public static async ValidaPaginaUtente(nomePagina: string): Promise<boolean>{
        let esito = false;

        try {
            const response = await Rest.Get<boolean>(`${Config.PrivatePath}/ValidaPaginaUtente/${nomePagina}`);
            if(response && response.statusCode === 200)
                esito = response.response;
        }catch (e){
            console.error("Non è stato possibile recuperare le informazioni di validità pagina per l'utente");
        }

        return esito;
    }

    public static GoTo(pageName: string, path: string){
        window.history.replaceState({}, pageName, path);
        RedirectService._listeners.forEach(callback => callback(path));
    }

    public static GoToLogin(){
        RedirectService.GoTo("Accesso", "/accesso");
    }

    public static GoToLogout(){
        RedirectService.GoTo("Logout", "/logout");
    }

    public static GoToNotifiche(){
        RedirectService.GoTo("Notifiche", "/homepage/notifiche");
    }

    public static GoToChat(){
        RedirectService.GoTo("Chat", "/homepage/chat");
    }

    public static GoToRegistrazione(){
        RedirectService.GoTo("Registrazione", "/registrazione");
    }

    public static GoToRecupero(){
        RedirectService.GoTo("Recupero", "/recupero");
    }

    public static GoToHomepage(){
        RedirectService.GoTo("Homepage", "/homepage");
    }

    public static GoToAccount(){
        RedirectService.GoTo("Account", "/homepage/account");
    }

    public static GoToStudiFotografici(){
        RedirectService.GoTo("Studi fotografici", "/homepage/studi_fotografici");
    }

    public static GoToAgenti(){
        RedirectService.GoTo("Account", "/homepage/agenti");
    }

    public static GoToImpersonificazione(){
        RedirectService.GoTo("Account", "/homepage/impersonificazione");
    }

    public static GoToNuovoOrdine(){
        RedirectService.GoTo("Nuovo ordine", "/homepage/nuovo_ordine");
    }

    public static GoToSuperUser(){
        RedirectService.GoTo("Super user", "/homepage/super_user");
    }

    public static GoToOrdini(){
        RedirectService.GoTo("Ordini", "/homepage/ordini");
    }

    public static GoToConfigurazioniSalvate(){
        RedirectService.GoTo("Configurazioni", "/homepage/configurazioni_fotografo");
    }

    public static GoToConfiguratore(){
        RedirectService.GoTo("Configuratore", "/homepage/configuratore_tici");
    }

    public static GoToCaricamentoImpersonificazione(){
        RedirectService.GoTo("CaricamentoImpersonificazione", "/caricamento_impersonificazione");
    }

    public static GoToGestioneModelli3D(){
        RedirectService.GoTo("GestioneModelli3D", "/homepage/gestione_modelli_3d");
    }
}
