import React from "react";
import {SelectLine} from "../CComponents";
import {RenderizzatoreSezioneInterface} from "../../../Primary/Renderizzatore/Renderizzatore";
import {TipoSezioneConfiguratoreType} from "tici_commons";

export interface SelettoreLegnoRenderizzatoreProps {
    sezione: RenderizzatoreSezioneInterface,
    hasSezioneData: (nomeSezione: string) => boolean,
    getSezioneData: (nomeSezione: string) => string,
    setSezioneData: (nomeSezione: string, tiposSezione: TipoSezioneConfiguratoreType, valoreSezione: string, materialeSezione: string) => void
}

export const SelettoreLegnoRenderizzatore = (props: SelettoreLegnoRenderizzatoreProps) => {
    return (
        <SelectLine
            label={props.sezione.nomeSezione}
            placeHolder={"Seleziona"}
            errorMessage={"Bisogna selezionare il colore del legno"}
            showError={!props.hasSezioneData(props.sezione.nomeSezione) && props.sezione.obbligatoria}
            elements={[{label: "Bianco"}, {label: "Limpido"}, {label: "Nero"}, {label: "Noce Chiaro"}, {label: "Rosa Chiaro"}]}
            value={props.getSezioneData(props.sezione.nomeSezione)}
            onChange={v => props.setSezioneData(props.sezione.nomeSezione, "legno", v, v)}/>
    )
}
