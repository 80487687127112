import Materiali from "../../../DatabaseData/Materiali";
import React from "react";
import {SelectLine} from "../CComponents";
import {RenderizzatoreSezioneInterface} from "../../../Primary/Renderizzatore/Renderizzatore";
import {TipoSezioneConfiguratoreType} from "tici_commons";

export interface SelettoreMaterialeRenderizzatoreProps {
    sezione: RenderizzatoreSezioneInterface,
    filtroMateriali: "spesso" | "sottile" | "*",
    famiglieMaterialiEscluse: string[],
    hasSezioneData: (nomeSezione: string) => boolean,
    getSezioneData: (nomeSezione: string) => string,
    setSezioneData: (nomeSezione: string, tiposSezione: TipoSezioneConfiguratoreType, valoreSezione: string, materialeSezione: string) => void
}

export const SelettoreMaterialeRenderizzatore = (props: SelettoreMaterialeRenderizzatoreProps) => {
    return (
        <SelectLine
            autocompleteMode={true}
            label={props.sezione.nomeSezione}
            placeHolder={"Seleziona"}
            errorMessage={"Bisogna selezionare un materiale"}
            showError={!props.hasSezioneData(props.sezione.nomeSezione) && props.sezione.obbligatoria}
            elements={Materiali.GetMateriali(props.filtroMateriali?? '*', ...props.famiglieMaterialiEscluse)}
            value={props.getSezioneData(props.sezione.nomeSezione)}
            onChange={v => props.setSezioneData(props.sezione.nomeSezione, "materiale", v, Materiali.GetMaterialUrl(v))}/>
    )
}
