import React, {Fragment} from 'react';
import ReactDOM from 'react-dom/client';

import './index.scss';
import './Style/Rules.scss';

import LoadingWindow, {LoadingWindowContext} from "./Primary/PopUps/LoadingWindow/LoadingWindow";
import ConfirmWindow, {ConfirmWindowContext} from "./Primary/PopUps/ConfirmWindow/ConfirmWindow";
import SessionBridge from "./Core/Arch/SessionBridge";
import TopLevelComponentStorage from "./Core/Arch/TopLevelComponentStorage";
import SessionAndAccessValidator, {Page} from "./Core/Arch/SessionAndAccessValidator";
import _404 from "./Pages/404";
import LoginPage from "./Pages/Accesso/LoginPage";
import RegistrationPage from "./Pages/Accesso/RegistrationPage";
import LogoutPage from "./Pages/Accesso/LogoutPage";
import CaricamentoImpersonificazione from "./Pages/CaricamentoImpersonificazione";
import ConfermaEmail from "./Pages/Accesso/ConfermaEmail";
import RecuperoPage from "./Pages/Accesso/RecuperoPage";
import PortaleTiciPage from "./Pages/PortaleTici/Page/PortaleTiciPage";
import HomePage from "./Pages/SitoInterno/HomePage/HomePage";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
    <Fragment>
            <SessionBridge>
                <LoadingWindow>
                    <ConfirmWindow>
                        <LoadingWindowContext.Consumer>{loadingWindow => (
                            <ConfirmWindowContext.Consumer>{
                                confirmWindow => (
                                    <TopLevelComponentStorage
                                        loadingWindow={loadingWindow}
                                        confirmWindow={confirmWindow}/>
                                )
                            }</ConfirmWindowContext.Consumer>
                        )}</LoadingWindowContext.Consumer>
                        <SessionAndAccessValidator
                            level={0}
                            notFountPage={<Page pageName={"404"} publicPage={true} pageComponent={<_404/>}/>}>
                            <Page pageName={""} publicPage={true} pageComponent={<LoginPage/>}/>
                            <Page pageName={"accesso"} publicPage={true} pageComponent={<LoginPage/>}/>
                            <Page pageName={"attivazione_email"} publicPage={true} pageComponent={<ConfermaEmail/>}/>
                            <Page pageName={"registrazione"} publicPage={true} pageComponent={<RegistrationPage/>}/>
                            <Page pageName={"recupero"} publicPage={true} pageComponent={<RecuperoPage/>}/>
                            <Page pageName={"logout"} publicPage={true} pageComponent={<LogoutPage/>}/>
                            <Page pageName={"homepage"} publicPage={false} pageComponent={<HomePage/>}/>
                            <Page pageName={"caricamento_impersonificazione"} publicPage={false} pageComponent={<CaricamentoImpersonificazione/>}/>
                            <Page pageName={"totem"} publicPage={false} pageComponent={<PortaleTiciPage/>}/>
                        </SessionAndAccessValidator>
                    </ConfirmWindow>
                </LoadingWindow>
            </SessionBridge>
    </Fragment>
);
