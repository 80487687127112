import React, {Fragment} from "react";
import ContainerLayout from "../../../../../Layout/ContainerLayout/ContainerLayout";
import {SelectLine, TextLine} from "../../../../../Widgets/Configuratore/CComponents";
import IfContainer from "../../../../../Layout/IfContainer/IfContainer";
import SectionLayout from "../../../../../Layout/SectionLayout/SectionLayout";
import VSLoader from "../../../../../Primary/VSLoader/VSLoader";
import Button from "../../../../../Core/Buttons/Button";
import Responsive2Col from "../../../../../Layout/Responsive2Col/Responsive2Col";
import {ConfigurazioneRenderizzatoreInterface} from "tici_commons";
import Config from "../../../../../Static/Config";
import {
    ConfigurazioneGestore3DBridgeContext,
    ConfigurazioneGestore3DBridgeState
} from "../../Bridge/ConfigurazioneGestore3DBridge";
import DataService from "../../../../../Servicies/DataService";
import SuperUserService from "../../../../../Servicies/SuperUserService";
import TopLevelComponentStorage from "../../../../../Core/Arch/TopLevelComponentStorage";
import RedirectService from "../../../../../Servicies/RedirectService";

export interface VistaConfigurazioniProps {
    listaConfigurazioni?: string[],
    caricamentoDaBridge?: boolean,
    azzeraCaricamentoDaBridge?: () => void
}

export interface VistaConfigurazioniState {
    configurazioneSelezionata: string,

    nomeConfigurazione: string,
    nomeModello: string,
    nomeConfigurazioneJSON: string,

    isLoading: boolean
}

export default class VistaConfigurazioni extends React.Component<VistaConfigurazioniProps, VistaConfigurazioniState>{
    private _nuovaConfigurazioneLabel = "Nuova configurazione";
    private _attualmenteCaricataLabel = "Attualmente caricata";

    private _modello3DBase64Encoding: string = undefined;
    private _configurazioneJSON: string = undefined;

    constructor(props: Readonly<VistaConfigurazioniProps> | VistaConfigurazioniProps) {
        super(props);
        this.state = {
            configurazioneSelezionata: "",

            nomeConfigurazione: "",
            nomeModello: "",
            nomeConfigurazioneJSON: "",

            isLoading: false
        }
    }

    /**
     * Carica un modello 3D
     * @param fileName Nome del modello caricato
     * @param fileType Estensione del modello caricato
     * @param file File
     * @param base64Encoding Codifica base 64 del modello caricato
     * @private
     */
    private _caricaModello3D(fileName: string, fileType: string, file: File, base64Encoding: string) {
        if(fileType.toLowerCase() === "obj"){
            this.setState({nomeModello: fileName});
            this._modello3DBase64Encoding = base64Encoding
        }
    }

    /**
     * Carica una configurazione per il modello
     * @param fileName Nome della configurazione caricata
     * @param fileType Estensione della configurazione caricata
     * @param file File
     * @param base64Encoding Codifica base 64 della configurazione caricata
     * @private
     */
    private _caricaConfigurazioneJSON(fileName: string, fileType: string, file: File, base64Encoding: string) {
        if(fileType.toLowerCase() === "json"){
            this.setState({nomeConfigurazioneJSON: fileName});
            this._configurazioneJSON = atob(base64Encoding.split(',')[1]);
        }
    }

    /**
     * Carica la configurazione selezionata dal database
     * @private
     */
    private async _caricaConfigurazioneDaDatabase() {
        this.setState({isLoading: true});
        const esito = await DataService.Configurazione(this.state.configurazioneSelezionata);
        if(esito) {
            this._configurazioneJSON = JSON.stringify(esito.configurazioneModello);
            this._modello3DBase64Encoding = esito.pathModello;
            this.setState({nomeModello: this._attualmenteCaricataLabel, nomeConfigurazioneJSON: this._attualmenteCaricataLabel});
        }else {
            this.setState({
                nomeModello: "",
                nomeConfigurazioneJSON: "",
            })
        }
        this.setState({isLoading: false})
    }

    /**
     * Aggiunge una singola configurazione sul database
     */
    private async _aggiungiConfigurazione(){
        if(this.state.nomeConfigurazione && this.state.nomeModello && this.state.nomeConfigurazioneJSON){
            TopLevelComponentStorage.GetTopLevel('loadingWindow').showLoadingWindow("Caricamento", "Caricamento della configurazione");
            let esitoCaricamento;
            if(!this._updateMode()){
                esitoCaricamento = await SuperUserService.AggiungiConfigurazione(
                    this.state.nomeConfigurazione,
                    this.state.nomeModello,
                    this._modello3DBase64Encoding,
                    this._configurazioneJSON as unknown as ConfigurazioneRenderizzatoreInterface,
                )
            }else{
                esitoCaricamento =await SuperUserService.AggiungiConfigurazione(
                    this.state.configurazioneSelezionata,
                    this.state.nomeModello,
                    this._modello3DBase64Encoding,
                    this._configurazioneJSON as unknown as ConfigurazioneRenderizzatoreInterface,
                    true
                );
            }
            TopLevelComponentStorage.GetTopLevel('loadingWindow').hideLoadingWindow();

            if(esitoCaricamento){
                this.props.azzeraCaricamentoDaBridge && this.props.azzeraCaricamentoDaBridge();
                this.setState({
                    configurazioneSelezionata: "",
                    nomeConfigurazione: "",
                    nomeModello: "",
                    nomeConfigurazioneJSON: ""
                });
                this._configurazioneJSON = undefined;
                this._modello3DBase64Encoding = undefined;
                TopLevelComponentStorage.GetTopLevel('confirmWindow').showConfirmWindow(
                    "Configurazione caricata",
                    "La configurazione è stata caricata correttamente",
                );
            }else{
                TopLevelComponentStorage.GetTopLevel('confirmWindow').showConfirmWindow(
                    "Configurazione non caricata",
                    "La configurazione non è stata caricata",
                );
            }
        }
    }

    /**
     * Elimina una singola configurazione dal database
     * @private
     */
    private _eliminaConfigurazione(){
        TopLevelComponentStorage.GetTopLevel('confirmWindow').showConfirmWindow(
            "Eliminazione configurazione",
            "Sicuro di voler eliminare questa configurazione? La configurazione eliminata non sarà più disponibile e i modelli associati ad " +
            "essa verranno eliminati definitivamente",
            "DoubleButton",
            async () => {
                const esito = await SuperUserService.EliminaConfigurazione(this.state.configurazioneSelezionata);
                TopLevelComponentStorage.GetTopLevel('confirmWindow').showConfirmWindow(
                    esito ? "Eliminazione completata" : "Eliminazione non completata",
                    esito ? "Abbiamo eliminato la configurazione" : "Non é stato possibile eliminare la configurazione"
                );
            },
            () => TopLevelComponentStorage.GetTopLevel('confirmWindow').hideConfirmWindow()
        )
    }

    /**
     * Restituisce true se le configurazioni vanno modificate altrimenti restituisce false
     * @private
     */
    private _updateMode(): boolean{
        let esito = false;

        if(this.state.configurazioneSelezionata !== this._nuovaConfigurazioneLabel)
            esito = this.state.nomeConfigurazioneJSON !== this._attualmenteCaricataLabel || this.state.nomeModello !== this._attualmenteCaricataLabel

        return esito;
    }

    /**
     * Carica i dati dal configuratore
     * @private
     */
    private _fromConfiguratore(){
        const bridge = this.context as ConfigurazioneGestore3DBridgeState;
        if(this.props.caricamentoDaBridge && bridge && bridge.payload && bridge.metaData){
            this.setState({
                configurazioneSelezionata: bridge.metaData.configuratore.configurazioneSelezionata,
                nomeConfigurazione: bridge.metaData.configuratore.nomeConfigurazione,
                nomeModello: bridge.payload.nomeModello,
                nomeConfigurazioneJSON: bridge.payload.nomeConfigurazioneJSON
            });
            this._modello3DBase64Encoding = bridge.metaData.configuratore.modello3DBase64Encoding;
            this._configurazioneJSON = JSON.stringify(bridge.payload.configurazione);
        }
    }

    public componentDidMount() {
        this._fromConfiguratore();
    }

    /**
     * Va al configuratore
     * @private
     */
    private _goToConfiguratore(bridge: ConfigurazioneGestore3DBridgeState){
        bridge.setMetadata({
            configuratore: {
                configurazioneSelezionata: this.state.configurazioneSelezionata,
                nomeConfigurazione: this.state.nomeConfigurazione,
                modello3DBase64Encoding: this._modello3DBase64Encoding,
                configurazioneJSON: this._configurazioneJSON
            }
        });

        bridge.setPayload({
            configurazione: JSON.parse(this._configurazioneJSON),
            nomeConfigurazioneJSON: this.state.nomeConfigurazioneJSON,
            nomeModello: this.state.nomeModello,
            pathModello: `${this._modello3DBase64Encoding.startsWith('data') ? "" : Config.PublicPath}/${this._modello3DBase64Encoding}`
        });

        RedirectService.GoTo("ConfiguratoreModelli3D", "/homepage/configuratore_modelli_3d");
    }

    public render() {
        return (
            <Fragment>
                <SelectLine
                    value={this.state.configurazioneSelezionata}
                    label={"Configurazione"}
                    placeHolder={"Seleziona"}
                    elements={
                        this.props.listaConfigurazioni ?
                            [{label: this._nuovaConfigurazioneLabel}, ...this.props.listaConfigurazioni.map(configurazione => ({label: configurazione}))] : [{label: this._nuovaConfigurazioneLabel}]
                    }
                    onChange={v => {
                        this.setState({configurazioneSelezionata: v, nomeConfigurazione: v === this._nuovaConfigurazioneLabel ? "" : v}, () => {
                            this._caricaConfigurazioneDaDatabase();
                        });
                    }}/>
                <IfContainer condition={!!this.state.configurazioneSelezionata}>
                    <SectionLayout size={"largeRelative"}>
                        <TextLine
                            label={"Nome configurazione"}
                            type={"text"}
                            value={this.state.configurazioneSelezionata === this._nuovaConfigurazioneLabel ? this.state.nomeConfigurazione : this.state.configurazioneSelezionata}
                            disabled={this.state.configurazioneSelezionata !== this._nuovaConfigurazioneLabel}
                            onChange={v => this.setState({nomeConfigurazione: v})}
                            showError={!this.state.nomeConfigurazione && this.state.configurazioneSelezionata === this._nuovaConfigurazioneLabel}
                            errorMessage={"Il nome della configurazione non può essere vuoto"}/>
                        <IfContainer condition={!this.state.isLoading} elseComponent={<VSLoader gapMobile={10} gapTablet={20} gapDesktop={30}/>}>
                            <TextLine
                                type={"file"}
                                label={"Modello 3D"}
                                value={this.state.nomeModello}
                                onFileUpload={this._caricaModello3D.bind(this)}
                                showError={!this.state.nomeModello}
                                errorMessage={"Il Modello 3D non può essere vuoto"}/>
                            <TextLine
                                type={"file"}
                                label={"Configurazione Modello"}
                                value={this.state.nomeConfigurazioneJSON}
                                onFileUpload={this._caricaConfigurazioneJSON.bind(this)}
                                showError={this.state.nomeConfigurazioneJSON.length === 0}
                                errorMessage={"Il JSON non può essere vuoto"}/>
                            <IfContainer condition={!!this.state.nomeConfigurazioneJSON && !!this.state.nomeModello}>
                                <ConfigurazioneGestore3DBridgeContext.Consumer>{
                                    bridge => (
                                        <Button
                                            content={"Modifica configurazione"}
                                            type={"medium"}
                                            alignment={"right"}
                                            buttonType={"simple-text"}
                                            onClick={() => this._goToConfiguratore(bridge)}/>
                                    )
                                }</ConfigurazioneGestore3DBridgeContext.Consumer>
                            </IfContainer>
                        </IfContainer>
                    </SectionLayout>
                    <IfContainer condition={!this.state.isLoading}>
                        <Responsive2Col>
                            <Button content={`${this._updateMode() ? "Aggiorna" : "Aggiungi"}`}
                                    disabled={this._updateMode() ? false : this.state.configurazioneSelezionata !== this._nuovaConfigurazioneLabel}
                                    type={"large"}
                                    buttonType={"full-normal"}
                                    onClick={() => this._aggiungiConfigurazione()}/>
                            <Button
                                content={"Elimina"}
                                disabled={this.state.configurazioneSelezionata === this._nuovaConfigurazioneLabel}
                                type={"large"}
                                buttonType={"full-normal-negative"}
                                onClick={() => this._eliminaConfigurazione()}/>
                        </Responsive2Col>
                    </IfContainer>
                </IfContainer>
            </Fragment>
        );
    }
}

VistaConfigurazioni.contextType = ConfigurazioneGestore3DBridgeContext;
