import './BackgroundOrdineManager.scss';
import React, {Fragment} from "react";
import LocalStorageService from "../../Servicies/LocalStorageService";
import ResponsiveLabel from "../../Core/ResponsiveLabel/ResponsiveLabel";
import Loader from "../../Core/Loader/Loader";
import IfContainer from "../../Layout/IfContainer/IfContainer";
import ImageService from "../../Servicies/ImageService";

export interface BackgroundOrdineManagerContextInterface {
    GeneraOrdineMenu: () => Promise<number>,
    ScriviOrdineMenu: (ordineMenuIndex: number, titolo: string, sottotitolo: string, dettagli?: string) => void,
    CancellaOrdineMenu: (ordineMenuIndex: number) => void
}

export interface BackgroundOrdineManagerProps {
    children: any
}

export interface OrdineMenuEntry{
    ordineMenuIndex: number,
    titolo: string,
    sottotitolo: string,
    dettagli?: string
}

export interface BackgroundOrdineManagerState {
    modalitaEspansa: boolean,
    ordineMenuIndexCounter: number,
    entries: OrdineMenuEntry[]
}

export const BackgroundOrdineManagerContext = React.createContext<BackgroundOrdineManagerContextInterface>(undefined);
export default class BackgroundOrdineManager extends React.Component<BackgroundOrdineManagerProps, BackgroundOrdineManagerState>{
    private _timeoutIndex: number;              //Indice del timeout di chiusura della modale

    constructor(props: Readonly<BackgroundOrdineManagerProps> | BackgroundOrdineManagerProps) {
        super(props);
        this.state = {
            modalitaEspansa: true,
            ordineMenuIndexCounter: 0,
            entries: []
        }
    }

    /**
     * Genera una nuova entry all'interno della lista delle entry
     * @private
     */
    private _generaOrdineMenu(): Promise<number>{
        return new Promise(resolve => {
            let nextIndex: number = -1;
            this.setState(currentState => {
                nextIndex = currentState.ordineMenuIndexCounter + 1;
                return {
                    ordineMenuIndexCounter: nextIndex,
                    entries: [...currentState.entries, {ordineMenuIndex: nextIndex, titolo: '', sottotitolo: ''}]
                }
            }, () => resolve(nextIndex))
        });
    }

    /**
     * Scrive un nuovo contenuto sull'ordine menu
     * @param ordineMenuIndex Indice dell'ordine da modificare
     * @param titolo Titolo da inserire nella entry dell'ordine
     * @param sottotitolo Sottotitolo da inserire nella entry dell'ordine
     * @param dettagli Dettagli da inserire all'interno della entry dell'ordine
     * @private
     */
    private _scriviOrdineMenu(ordineMenuIndex: number, titolo: string, sottotitolo: string, dettagli?: string){
        const targetElement = this.state.entries.find(element => element.ordineMenuIndex === ordineMenuIndex);
        if(targetElement){
            const newArray = this.state.entries.filter(element => element.ordineMenuIndex !== ordineMenuIndex);
            targetElement.titolo = titolo;
            targetElement.sottotitolo = sottotitolo;
            targetElement.dettagli = dettagli;
            newArray.push(targetElement);
            this.setState({entries: newArray});
        }
    }

    /**
     * Cancella una entry dalla lista degli elementi
     * @param ordineMenuIndex Indice della entry da rimuovere
     * @private
     */
    private _cancellaOrdineMenu(ordineMenuIndex: number) {
        this.setState(currentState => ({
            entries: currentState.entries.filter(element => element.ordineMenuIndex !== ordineMenuIndex)
        }))
    }

    componentDidUpdate(prevProps: Readonly<BackgroundOrdineManagerProps>, prevState: Readonly<BackgroundOrdineManagerState>, snapshot?: any) {
        if(prevState.entries.length !== this.state.entries.length) {
            LocalStorageService.OrdiniInCorso = this.state.entries.length;
            this.setState({modalitaEspansa: true});
        }
    }

    /**
     * Cancella la modalità espansa di visualizzazione della lista
     * @private
     */
    private _cancellaModalitaEspansa(){
        this._timeoutIndex = window.setTimeout(() => {
            this.setState({modalitaEspansa: false});
        }, 5000);
    }

    public render() {
        return (
            <div className={"BackgroundOrdineManager"}>
                <IfContainer condition={this.state.entries.length > 0}>
                    <div
                        className={`BackgroundOrdineContainer ${this.state.modalitaEspansa && 'espansa'}`}
                        onMouseLeave={() => this._cancellaModalitaEspansa()}
                        onMouseEnter={() => window.clearTimeout(this._timeoutIndex)}>
                        <IfContainer
                            condition={this.state.modalitaEspansa}
                            elseComponent={
                                <div
                                    className={"ListButton"}
                                    style={{backgroundImage: `url(${ImageService.getImage('listIcon')})`}}
                                    onClick={() => this.setState({modalitaEspansa: true})}/>
                            }>
                            <div className={"ScrollContainer"}>
                                <ResponsiveLabel
                                    content={"Ordini in corso"}
                                    type={"xsmall"}
                                    alignment={"center"}
                                    labelType={"whiteLabel"}/>
                                <Fragment>{
                                    this.state.entries.map(entry => (
                                        <span key={`OrdineBack-${entry.ordineMenuIndex}`} className={"OrdineProgressLine"} style={{order: entry.ordineMenuIndex}}>
                                            <span className={"DataContainer"}>
                                                <span className={"TitoloSottotitoloContainer"}>
                                                    <ResponsiveLabel content={entry.titolo} type={"small"} alignment={"left"} labelType={"whiteLabel"}/>
                                                    <ResponsiveLabel content={entry.sottotitolo} type={"small"} alignment={"left"} labelType={"whiteLabel"}/>
                                                </span>
                                                <ResponsiveLabel content={entry.dettagli} type={"small"} alignment={"left"} labelType={"whiteLabel"}/>
                                            </span>
                                            <Loader/>
                                        </span>
                                    ))
                                }</Fragment>
                            </div>
                        </IfContainer>
                    </div>
                </IfContainer>
                <BackgroundOrdineManagerContext.Provider value={{
                    GeneraOrdineMenu: this._generaOrdineMenu.bind(this),
                    ScriviOrdineMenu: this._scriviOrdineMenu.bind(this),
                    CancellaOrdineMenu: this._cancellaOrdineMenu.bind(this)
                }}>
                    {this.props.children}
                </BackgroundOrdineManagerContext.Provider>
            </div>
        );
    }
}
