import React from "react";
import CategorySwitcherElement
    from "../../../Primary/CategorySwitcher/CategorySwirtcherElement/CategorySwitcherElement";
import ImageService from "../../../Servicies/ImageService";
import IfContainer from "../../../Layout/IfContainer/IfContainer";
import CategorySwitcher from "../../../Primary/CategorySwitcher/CategorySwitcher/CategorySwitcher";
import {SezioneOrdiniType} from "./Ordini";

export interface OrdiniMenuProps{
    sezioneSelezionata: SezioneOrdiniType,
    cambiaSezione: (nuovaSezione: SezioneOrdiniType) => void
}

export default class OrdiniMenu extends React.Component<OrdiniMenuProps, {}>{
    public render() {
        return (
            <CategorySwitcher>
                <CategorySwitcherElement
                    elementIndex={0}
                    icon={ImageService.getImage('timeIcon')}
                    grow={true}
                    label={"Ordini inviati"}
                    selected={this.props.sezioneSelezionata === "Inviato"}
                    onClick={() => this.props.cambiaSezione && this.props.cambiaSezione('Inviato')}/>
                <CategorySwitcherElement
                    elementIndex={1}
                    icon={ImageService.getImage('xIcon')}
                    grow={true}
                    label={"Ordini in errore"}
                    selected={this.props.sezioneSelezionata === "Errore"}
                    onClick={() => this.props.cambiaSezione && this.props.cambiaSezione('Errore')}/>
                <CategorySwitcherElement
                    elementIndex={2}
                    icon={ImageService.getImage('checkIcon')}
                    grow={true}
                    label={"Ordini confermati"}
                    selected={this.props.sezioneSelezionata === "Confermato"}
                    onClick={() => this.props.cambiaSezione && this.props.cambiaSezione('Confermato')}/>
                <CategorySwitcherElement
                    elementIndex={3}
                    icon={ImageService.getImage('listIcon')}
                    grow={true}
                    label={"Lista totale"}
                    selected={this.props.sezioneSelezionata === "Totale"}
                    onClick={() => this.props.cambiaSezione && this.props.cambiaSezione('Totale')}/>
                <CategorySwitcherElement
                    elementIndex={4}
                    icon={ImageService.getImage('searchIcon')}
                    grow={true}
                    label={"Ricerca"}
                    selected={this.props.sezioneSelezionata === "Ricerca"}
                    onClick={() => this.props.cambiaSezione && this.props.cambiaSezione('Ricerca')}/>
                <IfContainer condition={this.props.sezioneSelezionata === 'Dettaglio'}>
                    <CategorySwitcherElement
                        elementIndex={5}
                        icon={ImageService.getImage('detailsIcon')}
                        grow={true}
                        label={"Dettaglio ordine"}
                        selected={this.props.sezioneSelezionata === "Dettaglio"}/>
                </IfContainer>
            </CategorySwitcher>
        );
    }
}
