import {RenderizzatoreSezioneInterface} from "../../../Primary/Renderizzatore/Renderizzatore";
import {TipoSezioneConfiguratoreType} from "tici_commons";
import React from "react";
import {SelectLine} from "../CComponents";

export interface SelettorePlexRenderizzatoreProps {
    sezione: RenderizzatoreSezioneInterface,
    hasSezioneData: (nomeSezione: string) => boolean,
    getSezioneData: (nomeSezione: string) => string,
    setSezioneData: (nomeSezione: string, tiposSezione: TipoSezioneConfiguratoreType, valoreSezione: string, materialeSezione: string) => void
}

export const SelettorePlexRenderizzatore = (props: SelettorePlexRenderizzatoreProps) => {
    return (
        <SelectLine
            label={props.sezione.nomeSezione}
            placeHolder={"Seleziona"}
            errorMessage={"Bisogna selezionare il colore per il plex"}
            showError={!props.hasSezioneData(props.sezione.nomeSezione) && props.sezione.obbligatoria}
            elements={[{label: "Bianco"}, {label: "Nero"}]}
            value={props.getSezioneData(props.sezione.nomeSezione)}
            onChange={v => props.setSezioneData(props.sezione.nomeSezione, "plex", v, v)}/>
    )
}
