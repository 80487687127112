import React, {Fragment, RefObject} from "react";

import './Renderizzatore.scss';
import cameraIcon from '../../Media/Images/Icons/cameraIcon.png';

import {
    Camera,
    Mesh,
    Object3D,
    PerspectiveCamera,
    Scene,
    TextureLoader,
    WebGLRenderer,
    Box3,
    Vector3,
    Euler,
    DirectionalLight,
    PCFSoftShadowMap,
    MeshLambertMaterial,
    AxesHelper,
    SpotLight,
    Light,
    CameraHelper,
    Group,
    OrthographicCamera, Box3Helper, BoxGeometry, Matrix4, Color
} from "three";
import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";
import {OBJLoader} from "three/examples/jsm/loaders/OBJLoader";
import ResponsiveLabel from "../../Core/ResponsiveLabel/ResponsiveLabel";
import {ConfigurazioneRenderizzatoreInterface, ConfigurazioneSezioneInterface, TipoSezione} from "tici_commons";
import IfContainer from "../../Layout/IfContainer/IfContainer";
import VerticalSpace from "../../Layout/VerticalSpace/VerticalSpace";
import Loader from "../../Core/Loader/Loader";
import AutoRefreshComponent from "../../Core/Arch/AutoRefreshComponent";
import {EffectComposer} from "three/examples/jsm/postprocessing/EffectComposer";
import {RenderPass} from "three/examples/jsm/postprocessing/RenderPass";
import {OBB} from "three/examples/jsm/math/OBB";
import {TransformControls} from "three/examples/jsm/controls/TransformControls";

export interface RenderizzatoreSezioneInterface{
    nomeSezione: string,
    tipoSezione: TipoSezione,
    obbligatoria: boolean
}

export interface RenderizzatoreProps{
    currentObjectPath?: string,
    currentConfiguration?: ConfigurazioneRenderizzatoreInterface,
    currentAspectRatio?: number,
    materialsPath?: [string, string][],
    modelChanged?: (sezioni: string[]) => void,
    onSectionChanged?: (sezioni: RenderizzatoreSezioneInterface[]) => void,
    onGenerateImage?: (base64Data: string) => void,
    modalitaGrandeSchermo?: boolean,
    helpersMode?: boolean,
    hideLegenda?: boolean
}

export interface RenderEnvironment{
    scene: Scene,
    renderer: WebGLRenderer,
    camera: Camera,
    orbitControls: OrbitControls,
    currentRenderedObjectContainer: Group,
    currentRenderedObject: Object3D,
    unmodifiedRenderedObject: Object3D,
    currentRenderedObjectBox: Box3,
    light: DirectionalLight,
    composer: EffectComposer,
    renderStatus: boolean
}

export interface RenderizzatoreState{
    isLoading: boolean
}

export default class Renderizzatore extends AutoRefreshComponent<RenderizzatoreProps, RenderizzatoreState>{
    constructor(props: Readonly<RenderizzatoreProps> | RenderizzatoreProps) {
        super(props);
        this.Delay = 200;
        this.state = {
            isLoading: false
        }
    }

    private static _ColoriDefault: number[] = [
        0xffa500,
        0x800080,
        0xffc0cb,
        0x808080,
        0x008000,
        0x800000,
        0x000080,
        0x008080,
        0x800080,
        0x808000,
        0xff00ff,
        0x00ff7f,
        0xff69b4,
        0x00bfff
    ];
    private _canvasRef: RefObject<HTMLCanvasElement> = React.createRef();
    private _environment: RenderEnvironment;

    public cycleGenerazioneImmagineCorrente = () => {
        if(this.props.onGenerateImage){
            if(this._canvasRef.current)
                this.props.onGenerateImage(this._canvasRef.current.toDataURL());
        }
    }

    /**
     * Effettua il setup dell'ambiente di rendering del renderizzatore
     * @private
     */
    private _setupEnvironment(){
        if(this._canvasRef.current){
            const scene = new Scene();
            const renderer = new WebGLRenderer({
                canvas: this._canvasRef.current,
                alpha: true,
                antialias: true,
                depth: true,
                stencil: false,
                preserveDrawingBuffer: true
            });

            renderer.shadowMap.enabled = true;
            renderer.shadowMap.type = PCFSoftShadowMap;

            const camera = new PerspectiveCamera(90, this._canvasRef.current.width / this._canvasRef.current.height);
            camera.position.z = 100;
            camera.lookAt(0, 0, 0);
            const orbitControls = new OrbitControls(camera, renderer.domElement);
            orbitControls.enablePan = false;
            orbitControls.enableDamping = false;

            const directionalLight = new DirectionalLight(0xffffff, 1);
            directionalLight.castShadow = true;
            scene.add(directionalLight, camera);

            const composer = new EffectComposer(renderer);
            const renderPass = new RenderPass( scene, camera );
            composer.addPass( renderPass );

            //const dotscreenPass = new DotScreenPass();
            //composer.addPass(dotscreenPass);

            //const glitchPass = new GlitchPass();
            //composer.addPass( glitchPass );

            const box = new Box3();
            const container = new Group();
            scene.add(container);

            if(this.props.helpersMode) {
                const boxHelper = new Box3Helper(box)
                container.add(boxHelper);
                const axesHelper = new AxesHelper(300000);
                container.add(axesHelper);
                //const cameraHelper = new CameraHelper( directionalLight.shadow.camera );
                //scene.add(cameraHelper);
            }


            this._environment = {
                scene: scene,
                renderer: renderer,
                camera: camera,
                orbitControls: orbitControls,
                currentRenderedObject: null,
                unmodifiedRenderedObject: null,
                currentRenderedObjectContainer: container as any,
                currentRenderedObjectBox: box,
                light: directionalLight,
                renderStatus: true,
                composer: composer
            };
        }
    }

    componentDidUpdate(prevProps: Readonly<RenderizzatoreProps>, prevState: Readonly<{}>, snapshot?: any) {
        if(JSON.stringify(prevProps.materialsPath) !== JSON.stringify(this.props.materialsPath))
            this._renderizzaModello();
        if(this.props.currentObjectPath !== prevProps.currentObjectPath)
            this._loadObject(this.props.currentObjectPath).then(() => {
                this._renderizzaModello();
                this._bindModelPropreties();
            });
        if(JSON.stringify(prevProps.currentConfiguration) !== JSON.stringify(this.props.currentConfiguration)) {
            this._bindConfigurazione();
            this._renderizzaModello();
            this._bindModelPropreties();
        }
        if(prevProps.currentAspectRatio !== this.props.currentAspectRatio)
            this._bindModelPropreties();
    }

    /**
     * Avvia l'esecuzione del loop di animazione
     * @private
     */
    private _startAnimationLoop(){
        const animate = () => {
            if(this._environment){
                if(this._environment.renderStatus)
                    requestAnimationFrame(animate);

                //Cambio posizione della luce
                this._environment.light.position.set(
                    this._environment.camera.position.x,
                    this._environment.camera.position.y,
                    this._environment.camera.position.z
                );
                if(this._environment.currentRenderedObject) {
                    this._environment.light.lookAt(this._environment.currentRenderedObject.position);
                    const box = new Box3().setFromObject(this._environment.currentRenderedObject);
                    const center = new Vector3();
                    box.getCenter(center);
                    this._environment.orbitControls.target = center;
                    this._environment.orbitControls.update();
                }

                this._environment.composer.render();
            }
        }
        animate();
    }

    /**
     * Restituisce una configurazione dato il nome
     * @param name nome della configurazione da cercare
     * @private
     */
    private _getConfigurazioneSezioneByName(name: string): ConfigurazioneSezioneInterface{
        let esito: ConfigurazioneSezioneInterface = undefined
        if(this.props.currentConfiguration && this.props.currentConfiguration.configurazioniSezioni){
            for(const configurazione of this.props.currentConfiguration.configurazioniSezioni){
                if(configurazione.nomeReale === name){
                    esito = configurazione;
                    break;
                }
            }
        }
        return esito;
    }

    /**
     * Restituisce il tipo di sezione dalla configurazione
     * @param configurazione Configurazione da analizzare
     * @private
     */
    private _getTipoSezioneByConfigurazione(configurazione: ConfigurazioneSezioneInterface): TipoSezione{
        let esito: TipoSezione = "materiale";

        if(configurazione.customFlag || configurazione.customTelaFlag || configurazione.customPlexFlag)
            esito = "immagine";
        if(configurazione.legnoFlag)
            esito = "legno";
        if(configurazione.plexFlag)
            esito = "plex";
        if(configurazione.codiceUvFlag)
            esito = "uv";

        return esito;
    }

    /**
     * Filtra le sezioni sulla base della configurazione
     * @param object Gruppo da filtrare
     * @private
     */
    private _filtraSezioniByConfigurazione(object: Object3D): RenderizzatoreSezioneInterface[]{
        const esito: RenderizzatoreSezioneInterface[] = [];

        if(this.props.currentConfiguration){
            object.traverse(part => {
                if(part.name.length !== 0){
                    const configurazione = this._getConfigurazioneSezioneByName(part.name);
                    if(configurazione){
                        if(
                            !configurazione.trasparenteFlag
                            && !configurazione.nonVisibileFlag
                            && !configurazione.singolaImmagineFlag
                            && !configurazione.singoloColoreFlag
                        ) esito.push({
                            nomeSezione: configurazione.nomeVisualizzato,
                            tipoSezione: this._getTipoSezioneByConfigurazione(configurazione) as any,
                            obbligatoria: configurazione.obbligatoriaFlag})
                    }else esito.push({nomeSezione: part.name, tipoSezione: "materiale", obbligatoria: false});
                }
            });
        }else{
            object.traverse(part => esito.push({nomeSezione: part.name, tipoSezione: "materiale", obbligatoria: false}))
        }

        return esito;
    }

    /**
     * Restituisce il dato del materiale
     * @param nomeRealeSezione Nome reale della sezione
     * @private
     */
    private _getMaterialData(nomeRealeSezione: string): string | undefined{
        let esito = undefined;

        const configurazione = this._getConfigurazioneSezioneByName(nomeRealeSezione);
        if(configurazione) {
            const materiali = new Map(this.props.materialsPath);
            if (materiali.has(configurazione.nomeVisualizzato))
                esito = materiali.get(configurazione.nomeVisualizzato);
        }

        return esito;
    }

    /**
     * Avvia la procedura di rendenring del modello
     * @private
     */
    private _renderizzaModello(){
        if(this._environment.currentRenderedObject){
            let currentColorIndex = 0;
            this._environment.currentRenderedObject.traverse(part => {
                const mesh: Mesh = part as Mesh;
                const configurazione = this._getConfigurazioneSezioneByName(part.name);
                if(configurazione){
                    if(configurazione.trasparenteFlag){
                        mesh.material = new MeshLambertMaterial({
                            color: "#e2eeff",
                            transparent: true,
                            opacity: 1 - (configurazione.livelloTrasparenza / 100)
                        });
                    } else if(configurazione.customFlag || configurazione.customPlexFlag || configurazione.customTelaFlag){
                        if(this._getMaterialData(part.name)){
                            const texture = new TextureLoader().load(this._getMaterialData(part.name));
                            mesh.material = new MeshLambertMaterial({map: texture});
                        }else mesh.material = new MeshLambertMaterial({transparent: true, opacity: 0});
                    } else if(configurazione.codiceUvFlag) {
                        if(this._getMaterialData(part.name)){
                            const texture = new TextureLoader().load(this._getMaterialData(part.name));
                            mesh.material = new MeshLambertMaterial({map: texture, transparent: true});
                        }else mesh.material = new MeshLambertMaterial({transparent: true, opacity: 0});
                    } else if(configurazione.legnoFlag && this._getMaterialData(part.name)){
                        const mapper = (nomeMateriale: string): string => {
                            let esito = "#fff";
                            switch(nomeMateriale.replaceAll(' ', '').toLowerCase()){
                                case "bianco":
                                    esito = "#dbdbdb";
                                    break;
                                case "limpido":
                                    esito = "#e4ecff";
                                    break;
                                case "nero":
                                    esito = "#3d3d3d";
                                    break;
                                case "nocechiaro":
                                    esito = "#a55d2f";
                                    break;
                                case "rosachiaro":
                                    esito = "#ffdcce";
                                    break;
                            }
                            return esito;
                        }

                        mesh.material = new MeshLambertMaterial({
                            color: mapper(this._getMaterialData(part.name)),
                        });
                    }else if(configurazione.plexFlag && this._getMaterialData(part.name)){
                        const mapper = (nomeMateriale: string): string => {
                            let esito = "#fff";
                            switch(nomeMateriale.replaceAll(' ', '').toLowerCase()){
                                case "bianco":
                                    esito = "#f0f0f0";
                                    break;
                                case "nero":
                                    esito = "#141414";
                                    break;
                            }
                            return esito;
                        }
                        mesh.material = new MeshLambertMaterial({
                            color: mapper(this._getMaterialData(part.name)),
                        });
                    } else if(configurazione.singoloColoreFlag){
                        mesh.material = new MeshLambertMaterial({color: parseInt(configurazione.singoloColore)});
                    } else if(configurazione.singolaImmagine){
                        const texture = new TextureLoader().load(configurazione.singolaImmagine);
                        mesh.material = new MeshLambertMaterial({map: texture});
                    } else if(this._getMaterialData(part.name)){
                        const texture = new TextureLoader().load(this._getMaterialData(part.name));
                        mesh.material = new MeshLambertMaterial({map: texture});
                    }
                    else{
                        mesh.material = new MeshLambertMaterial({color: parseInt(configurazione.coloreSezione)});
                    }
                }else{
                    mesh.material = new MeshLambertMaterial({color: Renderizzatore._ColoriDefault[currentColorIndex % Renderizzatore._ColoriDefault.length]});
                    currentColorIndex ++;
                }

            });
        }
    }

    /**
     * Effettua il bind tra il modello 3d e la configurazione
     * @private
     */
    private _bindConfigurazione(){
        if(this._environment.currentRenderedObject){
            this._environment.currentRenderedObject.traverse(part => {
                const partName = part.name;
                const configurazione = this._getConfigurazioneSezioneByName(partName);
                part.visible = !(configurazione && configurazione.nonVisibileFlag);
            });

            this.props.onSectionChanged && this.props.onSectionChanged(this._filtraSezioniByConfigurazione(this._environment.currentRenderedObject));
        }
    }

    /**
     * Effettua il binding delle proprietà dell'oggetto
     * @private
     */
    private _bindModelPropreties(){
        const toRadians = (deg: number) => {
            return (3.1415 * deg) / 180;
        }
        if(this._environment.currentRenderedObject && this.props.currentConfiguration && this.props.currentConfiguration.configurazioneModello){
            this._environment.camera.position.z = this.props.currentConfiguration.configurazioneModello.distanzaInizialeCamera;
            this._environment.orbitControls.update();

            this._environment.currentRenderedObject.applyMatrix4(this._environment.currentRenderedObject.matrixWorld.identity());

            this._environment.currentRenderedObject.setRotationFromEuler(new Euler(0, 0, 0)); //Azzeriamo la rotazione attuale
            this._environment.currentRenderedObject.scale.set(1, 1, 1); //Azzeriamo lo scale attuale

            let scale: Map<string, number> = new Map<string, number>([['x', 1], ['y', 1], ['z', 1]]);
            const asseModificaX = this.props.currentConfiguration.configurazioneModello.asseModificaX.toLowerCase();
            const asseModificaY = this.props.currentConfiguration.configurazioneModello.asseModificaY.toLowerCase();

            if(this.props.currentConfiguration.configurazioneModello.partiQuadratoFlag){
                let sizes: Map<string, number> = new Map<string, number>([['x', 1], ['y', 1], ['z', 1]]);

                this._environment.currentRenderedObjectBox.setFromObject(this._environment.unmodifiedRenderedObject, true);
                const crob = this._environment.currentRenderedObjectBox;

                sizes.set('x', crob.max.x - crob.min.x);
                sizes.set('y', crob.max.y - crob.min.y);
                sizes.set('z', crob.max.z - crob.min.z);

                const sizeX = sizes.get(asseModificaX);
                const sizeY = sizes.get(asseModificaY);
                if(sizeX > sizeY){
                    const moltFactor = sizeX / sizeY;
                    scale.set(asseModificaY, moltFactor);
                }else{
                    const moltFactor = sizeY / sizeX;
                    scale.set(asseModificaX, moltFactor);
                }
            }

            if(this.props.currentAspectRatio)
                scale.set(asseModificaX, scale.get(asseModificaX) * this.props.currentAspectRatio);

            this._environment.currentRenderedObject.scale.set(scale.get('x'), scale.get('y'), scale.get('z'));
            this._environment.currentRenderedObjectContainer.setRotationFromEuler(new Euler(
                toRadians(this.props.currentConfiguration.configurazioneModello.rotazioneX),
                toRadians(this.props.currentConfiguration.configurazioneModello.rotazioneY),
                toRadians(this.props.currentConfiguration.configurazioneModello.rotazioneZ),
            ));
        }
    }

    /**
     * Esegue il retrieve delle sezioni base (Senza modifiche) del modello
     * @private
     */
    private _retrieveSezioniBase(): string[]{
        const esito: string[] = [];
        if(this._environment.currentRenderedObject){
            this._environment.currentRenderedObject.traverse(object => {
                if(object.name.length !== 0)
                    esito.push(object.name);
            });
        }
        return esito;
    }


    /**
     * Effettua il caricamento dell'oggetto 3D da renderizzare
     * @param path
     */
    private _loadObject(path: string): Promise<boolean>{
        return new Promise(resolve => {
            if(this._environment){
                const loader = new OBJLoader();
                //Eseguiamo il carimanto dell'object
                this.setState({isLoading: true});
                loader.load(
                    path,
                    (group) => {
                        if(this._environment.currentRenderedObject) {
                            this._environment.currentRenderedObjectContainer.remove(this._environment.currentRenderedObject);
                        }
                        this._environment.currentRenderedObject = group;
                        this._environment.unmodifiedRenderedObject = group.clone(true);
                        this._environment.currentRenderedObject.castShadow = true;
                        this._environment.currentRenderedObject.receiveShadow = true;
                        this._environment.currentRenderedObjectContainer.add(this._environment.currentRenderedObject);

                        //Eseguiamo il bind con la configurazione
                        this._bindConfigurazione();
                        this.props.modelChanged && this.props.modelChanged(this._retrieveSezioniBase());
                        resolve(true);
                        this.setState({isLoading: false});
                    },
                    undefined,
                    () => {
                        resolve(false);
                        this.setState({isLoading: false});
                    }
                );
            }
        });
    }

    /**
     * Recupera le parti del modello caricato tramite path
     * @param path Path del modello da caricare
     * @constructor
     */
    public static GetPartiModello(path: string): Promise<string[]>{
        return new Promise<string[]>(resolve => {
            const esito: string[] = [];
            const loader = new OBJLoader();
            loader.load(
                path,
                (group) => {
                    group.traverse(object => {
                        if(object.name.length !== 0)
                            esito.push(object.name);
                    });
                    resolve(esito);
                },
                undefined,
                () => {
                    resolve(esito);
                }
            );
        });
    }

    componentDidMount() {
        this._setupEnvironment();
        this._startAnimationLoop();
        if(this.props.currentObjectPath) {
            this._loadObject(this.props.currentObjectPath).then((esito) => {
                if(esito) {
                    this._renderizzaModello();
                    this._bindModelPropreties();
                }
            })
        }
    }

    componentWillUnmount() {
        if(this._environment)
            this._environment.renderStatus = false;
    }

    /**
     * Effettua il reset della camera
     * @private
     */
    private _resetCamera(){
        this._environment.orbitControls.reset();
        this._environment.orbitControls.update();
    }

    render() {
        return (
            <Fragment>
                <div
                    style={{width: "100%"}}
                    className={`RenderizzatoreContainer ${(!this.props.currentObjectPath || this.state.isLoading) && 'hidden'}`}>
                    <canvas
                        ref={this._canvasRef}
                        style={{aspectRatio: this.props.modalitaGrandeSchermo ? "3 / 2" : "2 / 1", width: "100%"}}
                        width={1400}
                        height={1400 * (this.props.modalitaGrandeSchermo ? (2 / 3) : (1 / 2))}/>
                    <IfContainer condition={this.props.currentConfiguration !== undefined && !this.state.isLoading && !this.props.hideLegenda}>
                        <div className={"legendaContainer"}>
                            {
                                this.props.currentConfiguration && this.props.currentConfiguration.configurazioniSezioni.map(sezione => (
                                    !sezione.nonVisibileFlag &&
                                    !sezione.singoloColoreFlag &&
                                    !sezione.codiceUvFlag &&
                                    !sezione.legnoFlag &&
                                    !sezione.customFlag &&
                                    !sezione.singolaImmagineFlag &&
                                    !sezione.trasparenteFlag &&
                                    <span key={`legenda-${sezione.nomeReale}`} style={{order: `${sezione.ordine}`}} className={"legendaItem"}>
                                        <span style={{backgroundColor: `${sezione.coloreSezione.replace("0x", "#")}`}} className={"colorBox"}/>
                                        Sezione: {sezione.nomeVisualizzato}
                                    </span>
                                ))
                            }
                            <IfContainer condition={this.props.helpersMode}>
                                <div className={"resetButton"} onClick={this._resetCamera.bind(this)}>
                                    <img alt={"ResetIcon"} className={'resetImage'} src={cameraIcon}/>
                                    <ResponsiveLabel content={"Reset camera"} type={"small"} alignment={"center"}/>
                                </div>
                            </IfContainer>
                        </div>
                    </IfContainer>
                </div>
                {!this.props.currentObjectPath && !this.state.isLoading && <ResponsiveLabel content={"Nessun modello caricato"} alignment={"center"} type={"medium"}/>}
                {
                    this.state.isLoading &&
                    <Fragment>
                        <ResponsiveLabel content={"Caricamento"} type={"medium"} alignment={"center"}/>
                        <VerticalSpace gapMobile={20} gapTablet={40} gapDesktop={60}/>
                        <Loader/>
                    </Fragment>
                }
            </Fragment>
        );
    }
}
