import './PrivacyLayout.scss';
import React from "react";

export default class PrivacyLayout extends React.Component<{children: any}, {}>{
    public render() {
        return (
            <div className={"PrivacylayoutContainer"}>
                {this.props.children}
            </div>
        );
    }
}
