import {ConfigurazioneModelloDBInterface, MaterialiInterface, ModelloInterface, Rest, RestResult} from "tici_commons";
import Config from "../Static/Config";

export default class DataService{
    public static async ListaMateriali(): Promise<MaterialiInterface[]>{
        const esito: MaterialiInterface[] = [];

        try{
            const response = await Rest.Get<MaterialiInterface[]>(`${Config.PublicPath}/Materiali`);
            if(response && response.statusCode === 200)
                esito.push(...response.response)
        }catch (e){
            console.error("Errore nel recupero dei materiali", String(e));
        }

        return esito;
    }

    public static async ListaModelli(): Promise<string[]>{
        const esito: string[] = [];

        try {
            const response = await Rest.Get<ModelloInterface[]>(`${Config.PublicPath}/Modello`);
            if(response && response.statusCode === 200)
                esito.push(...response.response.map(modello => modello.nomeModello));
        }catch (e){
            console.error("Errore nel recupero della lista dei materiali", String(e));
        }

        return esito;
    }

    public static async ListaConfigurazioni(): Promise<string[]>{
        const esito: string[] = [];

        try {
            const response = await Rest.Get<ConfigurazioneModelloDBInterface[]>(`${Config.PublicPath}/Configurazione`);
            if(response && response.statusCode === 200)
                esito.push(...response.response.map(configurazione => configurazione.nomeConfigurazione));
        }catch (e){
            console.error("Errore nel recupero della lista delle configurazioni", String(e));
        }

        return esito;
    }

    public static async Configurazione(nomeConfigurazione: string): Promise<ConfigurazioneModelloDBInterface | undefined>{
        let esito = undefined;

        try{
            const response = await Rest.Get<ConfigurazioneModelloDBInterface[]>(`${Config.PublicPath}/Configurazione/${nomeConfigurazione}`);
            if(response && response.statusCode === 200 && response.response.length > 0)
                esito = response.response[0];
        }catch (e){
            console.error("Non é stato possibile recuperare la configurazione", String(e));
        }

        return esito;
    }
}
