import React from "react";
import "./PreviewStampaOrdine.scss"
import SectionLayout from "../../Layout/SectionLayout/SectionLayout";
import {CheckLine, SelectLine, TextLine} from "../../Widgets/Configuratore/CComponents";
import {OrdineLineTopSeparator} from "../../Widgets/Ordine/OrdineLineComponents";
import Button from "../../Core/Buttons/Button";

export interface PreviewStampaOrdineProps{
    idOrdine: number,
    children?: any
}

export interface PreviewConfigurazioneInterface{
    tipologiaSelezionata: string,
    marginiFoglio: number,
    moltiplicatoreDimensioneTesto: number,
    dueColonneMode: boolean
}

export interface PreviewStampaOrdineState extends PreviewConfigurazioneInterface{
    updateCount: number
}

export default class PreviewStampaOrdine extends React.Component<PreviewStampaOrdineProps, PreviewStampaOrdineState>{
    private _tipologiaFogli = {
        'F1': [7795, 9933],
        'F2': [4961, 7795],
        'F3': [3898, 4961],
        'F4': [2480, 3898],
        'F1 (Orizzontale)': [9933, 7795],
        'F2 (Orizzontale)': [7795, 4961],
        'F3 (Orizzontale)': [4961, 3898],
        'F4 (Orizzontale)': [3898, 2480],
    }

    private _previewContentZone = React.createRef<HTMLDivElement>();
    private _svg = React.createRef<SVGSVGElement>();
    private _updateLoop: number;

    constructor(props: Readonly<PreviewStampaOrdineProps> | PreviewStampaOrdineProps) {
        super(props);
        this.state = {
            updateCount: 0,

            tipologiaSelezionata: 'F4',
            marginiFoglio: 2,
            moltiplicatoreDimensioneTesto: 100,
            dueColonneMode: true
        }
    }

    /**
     * Salva la configurazione attuale della preview all'interno dello storage
     * @private
     */
    private _salvaConfigurazione(){
        localStorage.setItem('ConfigurazionePreviewOrdine', JSON.stringify({
            tipologiaSelezionata: this.state.tipologiaSelezionata,
            dueColonneMode: this.state.dueColonneMode,
            marginiFoglio: this.state.marginiFoglio,
            moltiplicatoreDimensioneTesto: this.state.moltiplicatoreDimensioneTesto
        } as PreviewConfigurazioneInterface))
    }

    /**
     * Effettua il restore della configurazione salvata all'interno dello storage
     * @private
     */
    private _restoreConfigurazione(){
        const data = localStorage.getItem('ConfigurazionePreviewOrdine');
        if(data){
            const configurazione = JSON.parse(data) as PreviewConfigurazioneInterface;
            this.setState(configurazione);
        }
    }

    public componentDidMount() {
        this._restoreConfigurazione();
        this._updateLoop = window.setInterval(() => {
            this.setState(currentState => ({updateCount: currentState.updateCount + 1}));
        }, 300);
    }

    public componentDidUpdate(prevProps: Readonly<PreviewStampaOrdineProps>, prevState: Readonly<PreviewStampaOrdineState>, snapshot?: any) {
        if(
            prevState.moltiplicatoreDimensioneTesto !== this.state.moltiplicatoreDimensioneTesto ||
            prevState.marginiFoglio !== this.state.marginiFoglio ||
            prevState.dueColonneMode !== this.state.dueColonneMode ||
            prevState.tipologiaSelezionata !== this.state.tipologiaSelezionata
        ) this._salvaConfigurazione();
    }

    public componentWillUnmount() {
        window.clearInterval(this._updateLoop);
    }

    /**
     * Calcola una percentuale dell'altezza del container
     * @param perc Percentuale in altezza
     * @private
     */
    private _previewContainerHeight(perc = 1): number{
        let esito = 0;

        if(this._previewContentZone && this._previewContentZone.current){
            esito = this._previewContentZone.current.getBoundingClientRect().height * perc;
        }

        return esito;
    }

    /**
     * Effettua il download del riepilogo
     * @private
     */
    private async _scaricaRiepilogo(){
        if(this._svg && this._svg.current){
            const svgElement = this._svg.current.cloneNode(true) as SVGSVGElement;

            const pageWidth = (this._tipologiaFogli as any)[this.state.tipologiaSelezionata][0];
            const pageHeight = (this._tipologiaFogli as any)[this.state.tipologiaSelezionata][1];

            const styles = document.querySelectorAll('style');
            for(const style of styles){
                svgElement.append(style.cloneNode(true));
            }

            let customStyleText = "";
            for(const sheet of document.styleSheets){
                for(const rule of sheet.cssRules){
                    customStyleText = `${customStyleText} ${rule.cssText}`;
                }
            }
            if(customStyleText.length !== 0){
                const customStyle = document.createElement('style') as HTMLStyleElement;
                customStyle.innerHTML = customStyleText;
                svgElement.append(customStyle);
            }

            const previewContent = svgElement.getElementsByClassName('PreviewContent')[0];
            previewContent.setAttribute('style', `
                --largeSize: ${pageHeight * 0.9 * 0.02 * (this.state.moltiplicatoreDimensioneTesto / 100)}px !important;
                --normalSize: ${pageHeight * 0.9 * 0.01 * (this.state.moltiplicatoreDimensioneTesto / 100)}px !important;
                --internalSpace: ${pageHeight * 0.9 * 0.002}px !important;
                column-count: ${this.state.dueColonneMode ? 2 : 1} !important;
            `);

            const serializer = new XMLSerializer();
            const svgString = serializer.serializeToString(svgElement);
            const encodedSVG = encodeURIComponent(svgString);
            const dataUrl = `data:image/svg+xml;charset=utf-8,${encodedSVG}`;

            const canvas = document.createElement("CANVAS") as HTMLCanvasElement;
            const ctx = canvas.getContext('2d');
            canvas.width = pageWidth;
            canvas.height = pageHeight;

            const image = new Image();
            image.width = canvas.width;
            image.height = canvas.height;
            image.addEventListener("load", () => {
                ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
                const imageData = canvas.toDataURL('image/png');
                const link = document.createElement('a');
                link.download = `${this.props.idOrdine}-riepilogo.png`;
                link.href = imageData;
                link.click();
            })
            image.src = dataUrl;
        }
    }

    private _sezioneConfigurazioneFoglio(){
        return (
            <OrdineLineTopSeparator label={"Configurazione foglio"}>
                <SectionLayout size={"mediumRelative"}>
                    <SelectLine
                        label={"Tipologia foglio"}
                        placeHolder={"Seleziona"}
                        elements={Object.keys(this._tipologiaFogli).map(key => ({label: key}))}
                        value={this.state.tipologiaSelezionata}
                        labelType={'recapLabel'}
                        onChange={v => this.setState({tipologiaSelezionata: v})}/>
                    <TextLine
                        label={"Margini (%)"}
                        labelType={'recapLabel'}
                        type={"number"}
                        max={20}
                        min={0}
                        value={this.state.marginiFoglio.toString()}
                        onChange={v => this.setState({marginiFoglio: isNaN(parseInt(v)) ? 0 : parseInt(v)})}/>
                    <TextLine
                        label={"Moltiplicatore testo (%)"}
                        labelType={'recapLabel'}
                        type={"number"}
                        max={200}
                        min={10}
                        value={this.state.moltiplicatoreDimensioneTesto.toString()}
                        onChange={v => this.setState({moltiplicatoreDimensioneTesto: isNaN(parseInt(v)) ? 0 : parseInt(v)})}/>
                    <CheckLine
                        label={"Su due colonne"}
                        labelType={'recapLabel'}
                        checkType={'switch'}
                        checked={this.state.dueColonneMode}
                        onChange={v => this.setState({dueColonneMode: v})}/>
                </SectionLayout>
            </OrdineLineTopSeparator>
        )
    }

    public render() {
        return (
            <SectionLayout size={"largeRelative"}>
                {this._sezioneConfigurazioneFoglio()}
                <OrdineLineTopSeparator label={"Preview stampa"}>
                    <svg
                        ref={this._svg}
                        style={{
                            width: '100%',
                            height: 'auto',
                            aspectRatio: `${(this._tipologiaFogli as any)[this.state.tipologiaSelezionata][0]} / ${(this._tipologiaFogli as any)[this.state.tipologiaSelezionata][1]}`
                        }}>
                        <foreignObject width="100%" height="100%">
                            <div
                                className={"PreviewContainer"}
                                style={{
                                    aspectRatio: `${(this._tipologiaFogli as any)[this.state.tipologiaSelezionata][0]} / ${(this._tipologiaFogli as any)[this.state.tipologiaSelezionata][1]}`,
                                    padding: `${this.state.marginiFoglio}%`
                                }}>
                                <div
                                    className={"PreviewContent"}
                                    ref={this._previewContentZone}
                                    style={{
                                        '--largeSize': `${this._previewContainerHeight(0.02) * (this.state.moltiplicatoreDimensioneTesto / 100)}px`,
                                        '--normalSize': `${this._previewContainerHeight(0.01) * (this.state.moltiplicatoreDimensioneTesto / 100)}px`,
                                        '--internalSpace': `${this._previewContainerHeight(0.002)}px`,
                                        '--borderSize': `${this._previewContainerHeight(0.001)}px`,
                                        'columnCount': `${this.state.dueColonneMode ? 2 : 1}`
                                    } as any}>
                                    {this.props.children}
                                </div>
                            </div>
                        </foreignObject>
                    </svg>
                </OrdineLineTopSeparator>
                <Button
                    content={"Scarica riepilogo"}
                    buttonType={"full-normal"}
                    type={"large"}
                    onClick={() => this._scaricaRiepilogo()}/>
            </SectionLayout>

        );
    }
}
