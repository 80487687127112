import "./CheckboxButton.scss";
import CheckboxProps from "../CheckboxProps";
import React from "react";
import ResponsiveLabel, {ResponsiveLabelProps} from "../../ResponsiveLabel/ResponsiveLabel";

export default class CheckboxButton extends React.Component<CheckboxProps & ResponsiveLabelProps, {}>{
    public componentDidUpdate(prevProps: Readonly<CheckboxProps>, prevState: Readonly<{}>, snapshot?: any) {
        if(prevProps.disabled !== this.props.disabled && this.props.disabled)
            this.props.onChange && this.props.onChange(false);
    }

    public render() {
        return (
            <div
                className={`CheckboxButton ${this.props.checked && 'checked'}`}
                onClick={() => {
                    if(!this.props.disabled)
                        this.props.onChange && this.props.onChange(!this.props.checked);
                }}>
                <ResponsiveLabel
                    content={this.props.content}
                    type={this.props.type}
                    alignment={this.props.alignment}
                    labelType={this.props.labelType}
                    italic={this.props.italic}
                    uppercase={this.props.uppercase}
                    bold={this.props.bold}/>
            </div>
        );
    }
}
