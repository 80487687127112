import React from "react";
import "./SelettoreQuadrato.scss";
import xIcon from "../../Media/Images/Icons/xIcon.png";

export interface SelettoreQuadratoProps{
    value: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9
    onChange?: (v : number) => void
}

export default class SelettoreQuadrato extends React.Component<SelettoreQuadratoProps, {}>{
    /**
     * Disegna la singola cella del selettore
     * @param position Posizione di identificazione del selettore
     * @private
     */
    private _drawCell(position: number){
        return (
            <div
                className={`cell`}
                onClick={() => {
                    this.props.onChange && this.props.onChange(this.props.value === position ? 0 : position);
                }}>
                {this.props.value === position && <img src={xIcon} alt={"xIcon"} />}
            </div>
        );
    }

    render() {
        return (
            <div className={`SelettoreQuadrato`}>{
                new Array<number>(9).fill(0).map((value, index) => {
                    return this._drawCell(index + 1);
                })
            }</div>
        );
    }
}
