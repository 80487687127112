import React from "react";
import ModalLayout from "../../Layout/ModalLayout/ModalLayout";
import Config from "../../Static/Config";
import IfContainer from "../../Layout/IfContainer/IfContainer";
import {UserContextResponseModel} from "tici_commons";
import UserService from "../../Servicies/UserService";
import CarouselLayout from "../../Layout/CarouselLayout/CarouselLayout";

export interface ModalePubblicitaState{
    userContext: UserContextResponseModel
    elementiPubblicita: string[]
}

export default class ModalePubblicita extends React.Component<{}, ModalePubblicitaState>{
    constructor(props: Readonly<{}> | {}) {
        super(props);
        this.state = {
            userContext: undefined,
            elementiPubblicita: []
        }
    }

    public componentDidMount() {
        this._init();
    }

    private async _init(){
        const userContext = await UserService.GetUserContext();
        if(userContext)
            this.setState({userContext});
        await this._fetchDatiPubblicita();
    }

    private async _fetchDatiPubblicita(){
        //TODO: Aggiungere parte redazionale
        const data = await fetch(`${Config.PublicPath}/NoMapping/Pubblicita/elementiPubblicita.json`);
        let json: {documentiPubblicita: string[]} = await data.json();
        this.setState({elementiPubblicita: json.documentiPubblicita});
    }

    public render() {
        return (
            <IfContainer condition={this.state.userContext && this.state.userContext.tipoUtente === "fotografo"}>
                <ModalLayout
                    showXClose={true}
                    isOpen={true}
                    transparentMode={true}
                    body={
                        <CarouselLayout>
                            {this.state.elementiPubblicita.map(elementoPubblicita =>
                                elementoPubblicita.endsWith('.mp4') ?
                                    <video
                                        controlsList={"nodownload"}
                                        controls={true}
                                        style={{width: "100%"}}
                                        src={`${Config.PublicPath}/NoMapping/Pubblicita/${elementoPubblicita}`}
                                        onBlur={ev => ev.target.pause()}/>
                                    :
                                    <img alt={"immaginePubblicita"} style={{width: "100%"}} src={`${Config.PublicPath}/NoMapping/Pubblicita/${elementoPubblicita}`}/>
                            )}
                        </CarouselLayout>
                    }/>
            </IfContainer>
        );
    }
}
