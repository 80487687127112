import React, {RefObject} from "react";
import "./ContainerLayout.scss";

export interface ContainerLayoutProps{
    children?: any;
    size: "largeRelative" | "mediumRelative" | "smallRelative" | "large" | "medium" | "small",
    gap?: boolean,
    fullHeight?: boolean,
    grow?: boolean,
    center?: boolean,
    backgroundBlur?: boolean,
    scroll?: boolean,
    order?: number
}
export default class ContainerLayout extends React.Component<ContainerLayoutProps, {}>{
    private _containerRef: RefObject<HTMLDivElement> = React.createRef();
    private _idAggiornamentoPaddingScroll: number;

    /**
     * Aggiorna l'ordinamento del container
     * @private
     */
    private _aggiornaOrdinamento(){
        if(this._containerRef.current && this.props.order)
            this._containerRef.current.style.order = this.props.order.toString();
    }

    componentDidMount() {
        this._aggiornaOrdinamento();
    }

    componentDidUpdate(prevProps: Readonly<ContainerLayoutProps>, prevState: Readonly<{}>, snapshot?: any) {
        this._aggiornaOrdinamento();
        window.clearInterval(this._idAggiornamentoPaddingScroll);
    }

    public render() {
        return (
            <div ref={this._containerRef} className={`ContainerLayout ${this.props.scroll && "scroll"} ${this.props.backgroundBlur && "blur"} ${this.props.gap === undefined ? "gap" : (this.props.gap && "gap")} ${this.props.size} ${this.props.fullHeight && "fullHeight"} ${this.props.grow && "grow"} ${this.props.center && "center"}`}>
                {this.props.children}
            </div>
        );
    }
}
