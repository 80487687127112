import React from "react";
import {TextLine} from "../../../../../Widgets/Configuratore/CComponents";
import Formati from "../../../../../DatabaseData/Formati";
import SectionLayout from "../../../../../Layout/SectionLayout/SectionLayout";

export interface VistaTestProps {
    onChange?: (aspectRatio: number) => void
}

export interface VistaTestState {
    formato: string
}

export default class VistaTest extends React.Component<VistaTestProps, VistaTestState>{
    constructor(props: Readonly<VistaTestProps> | VistaTestProps) {
        super(props);
        this.state = {
            formato: ""
        }
    }

    public render() {
        return (
            <SectionLayout size={"smallRelative"} center={true}>
                <TextLine
                    label={"Formato"}
                    placeHolder={"NxM"}
                    value={this.state.formato}
                    onChange={v => {
                        this.props.onChange && this.props.onChange(Formati.GetAspectRatioFromFormato(v));
                        this.setState({
                            formato: v
                        })
                    }}/>
            </SectionLayout>
        );
    }
}
