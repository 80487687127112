import React from "react";
import ResponsiveLabel from "../../Core/ResponsiveLabel/ResponsiveLabel";
import Config from "../../Static/Config";

export interface SubitemInterface{
    icona: string,
    titolo: string,
    onClick?: () => void
}

export interface SubmenuItemProps{
    iconaItem: string,
    titoloItem: string,
    sempreVisibile?: boolean,
    visibileSoloInCollaborazione?: boolean
    subItems?: SubitemInterface[]
    selected?: boolean,
    onClick?: () => void,
}
export default class SubmenuItem extends React.Component<SubmenuItemProps, {}>{
    public render() {
        return (
            <div
                className={`SubmenuItem ${this.props.selected && 'selected'} ${this.props.sempreVisibile && 'sempreVisibile'} ${this.props.visibileSoloInCollaborazione && 'visibileSoloInCollaborazione'}`}
                onClick={() => {
                    if(this.props.onClick){
                        if(this.props.subItems && this.props.subItems.length > 0){
                            if(window.innerWidth < Config.TabletBkp)
                                this.props.onClick();
                        }else this.props.onClick();
                    }
                }}>
                <img
                    alt={`SubmenuItem${this.props.titoloItem}`}
                    className={"SubmenuItemIcon"}
                    src={this.props.iconaItem}/>
                <ResponsiveLabel
                    content={this.props.titoloItem}
                    type={"xsmall"}
                    alignment={"center"}
                    labelType={"whiteLabel"}
                    uppercase={true}/>
                {
                    this.props.subItems && this.props.subItems.length > 0 &&
                    <div className={"SubitemContainer"}>{
                        this.props.subItems.map((subItem, index) => (
                            <span key={`Subitem-${index}`} className={"Subitem"} onClick={(ev) => subItem.onClick && subItem.onClick()}>
                                {subItem.icona && <img className={"SubitemIcon"} src={subItem.icona}/>}
                                <ResponsiveLabel content={subItem.titolo} type={"xsmall"} alignment={"left"} labelType={"whiteLabel"}/>
                            </span>
                        ))
                    }</div>
                }
            </div>
        );
    }
}
