import React from "react";
import {Rest} from "tici_commons";
import RedirectService from "../../Servicies/RedirectService";
import UserService from "../../Servicies/UserService";

export default class LogoutPage extends React.Component<{}, {}>{
    componentDidMount() {
        if(Rest.PERSONIFICATION){
            localStorage.clear();
            sessionStorage.clear();
            Rest.PERSONIFICATION = undefined;
            UserService.UID = Rest.UID;
            RedirectService.GoToCaricamentoImpersonificazione();
        }else{
            localStorage.clear();
            sessionStorage.clear();
            Rest.UID = undefined;
            Rest.PERSONIFICATION = undefined;
            RedirectService.GoToLogin();
        }
    }

    public render() {
        return <></>;
    }
}
