import "./ChatOverlayHead.scss";
import maximizeIcon from "../../../../Media/Images/Icons/maximizeIcon.png";
import minimizeIcon from "../../../../Media/Images/Icons/minimizeIcon.png";
import closewindowIcon from "../../../../Media/Images/Icons/closewindowIcon.png";
import React from "react";
import ResponsiveLabel from "../../../../Core/ResponsiveLabel/ResponsiveLabel";
import IfContainer from "../../../../Layout/IfContainer/IfContainer";

export interface ChatOverlayHeadProps {
    chatTitle: string,
    isOpen?: boolean,
    onChangeOpenStatus?: (newStatus: boolean) => void,
    onClose?: () => void,
    onMousePressChange?: (pressed: boolean) => void
}

export default class ChatOverlayHead extends React.Component<ChatOverlayHeadProps, any>{
    public render() {
        return (
            <span
                className={"ChatOverlayHead"}
                onMouseDown={() => this.props.onMousePressChange && this.props.onMousePressChange(true)}
                onMouseUp={() => this.props.onMousePressChange && this.props.onMousePressChange(false)}>
                <ResponsiveLabel
                    content={this.props.chatTitle}
                    type={"medium"}
                    alignment={"center"}
                    labelType={"whiteLabel"}/>
                <span className={"buttonZone"}>
                    <IfContainer
                        condition={this.props.isOpen}
                        elseComponent={
                            <img
                                src={maximizeIcon}
                                alt={"maximizeIcon"}
                                className={"button"}
                                onClick={() => this.props.onChangeOpenStatus && this.props.onChangeOpenStatus(true)}/>
                        }>
                        <img
                            src={minimizeIcon}
                            alt={"minimizeIcon"}
                            className={"button"}
                            onClick={() => this.props.onChangeOpenStatus && this.props.onChangeOpenStatus(false)}/>
                    </IfContainer>
                    <img
                        src={closewindowIcon}
                        alt={"closeIcon"}
                        className={"button"}
                        onClick={() => this.props.onClose && this.props.onClose()}/>
                </span>
            </span>
        );
    }
}
