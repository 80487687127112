import React, {RefObject} from "react";
import "./PortaleTiciPage.scss";
import {ButtonTotem, ListaItems, OnlyDesktopLayout} from "../Widgets/Wigets";
import Modello from "../../../DatabaseData/Modello";
import ListaMaterialiConfiguratore from "../../../Primary/ListaMaterialiConfiguratore/ListaMaterialiConfiguratore";
import {SezioneGenericaModel} from "tici_commons";
import StorageManager from "../../SitoInterno/Configuratore/Storage/StorageManager";
import ImageLoader from "../Tools/ImageLoader";
import ResponsiveLabel from "../../../Core/ResponsiveLabel/ResponsiveLabel";

export interface PortaleTiciPageState{
    categorySelected: "menu" | "copertina" | "custodia",

    selectedItem: string
    sezioniConfiguratore: [string, SezioneGenericaModel][]
    showEditor: boolean

    backgroundBase: string,
    backgroundConfigurazione: string,
    logo: string
}

export default class PortaleTiciPage extends React.Component<{}, PortaleTiciPageState>{
    private _contentZoneRef: RefObject<HTMLDivElement> = React.createRef();

    constructor(props: Readonly<{}> | {}) {
        super(props);
        this.state = {
            categorySelected: "menu",
            selectedItem: "",
            sezioniConfiguratore: [],
            showEditor: false,

            backgroundBase: "",
            backgroundConfigurazione: "",
            logo: ""
        }
    }

    public async componentDidMount() {
        this.setState({
            backgroundBase: await ImageLoader.backgroundBase(),
            backgroundConfigurazione: await ImageLoader.backgroundConfigurazione(),
            logo: await ImageLoader.logo()
        })
    }

    private _cambiaCategoria(nuovaCategoria: 'menu' | 'copertina' | 'custodia'){
        this.setState({categorySelected: nuovaCategoria, selectedItem: "", sezioniConfiguratore: [], showEditor: false});
    }

    public render() {
        return (
            <OnlyDesktopLayout>
                <div className={"TotemContainer"} style={{backgroundImage: `url(${this.state.categorySelected === 'menu' ? this.state.backgroundBase : this.state.backgroundConfigurazione})`}}>
                    {
                        this.state.categorySelected === 'menu' &&
                        <div className={"MenuContainer"}>
                            <ResponsiveLabel
                                content={"Libera la tua creatività"}
                                type={"xxlarge"}
                                alignment={"center"}
                                italic={true}/>
                            <span className={"ButtonContainer"}>
                                <ButtonTotem
                                    content={"Copertine"}
                                    onClick={() => this._cambiaCategoria('copertina')}/>
                                <ButtonTotem
                                    content={"Custodie"}
                                    onClick={() => this._cambiaCategoria('custodia')}/>
                            </span>
                            <img alt={"logo"} className={"Logo"} src={this.state.logo}/>
                            <ResponsiveLabel
                                content={"Configura il tuo album 3D"}
                                type={"xxlarge"}
                                alignment={"center"}
                                bold={true}
                                uppercase={true}/>
                        </div>
                    }
                    {
                        this.state.categorySelected !== 'menu' &&
                        <div className={"ConfiguratoreContainer"}>
                            <ListaItems
                                items={Modello.GetModelli(this.state.categorySelected === "custodia" ? 'custodia' : 'copertina')}
                                selectedItem={this.state.selectedItem}
                                click={(item) => this.setState({selectedItem: item, showEditor: false})}/>
                            <StorageManager>
                                <ListaMaterialiConfiguratore
                                    idManager={"copertina"}
                                    nomeConfigurazioneModello={Modello.GetNomeConfigurazione(this.state.selectedItem)}
                                    sezioniData={this.state.sezioniConfiguratore}
                                    onSectionChanges={value => this.setState({sezioniConfiguratore: value})}
                                    aspectRatioModello={1}
                                    modalitaGrandeSchermo={true}
                                    editorDestination={this._contentZoneRef.current}/>
                            </StorageManager>
                            <div className={"ButtonZone"}>
                                <img alt={"logo"} className={"Logo"} src={this.state.logo}/>
                                <ButtonTotem content={"Indietro"} onClick={() => this._cambiaCategoria('menu')}/>
                            </div>
                            <div
                                className={`EditorZone ${this.state.showEditor && 'open'}`}
                                onClick={() => this.setState(currentState => ({showEditor: !currentState.showEditor}))}>
                                <div ref={this._contentZoneRef} className={"ContentZone"}/>
                            </div>
                        </div>
                    }
                </div>
            </OnlyDesktopLayout>
        );
    }
}
