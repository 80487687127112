import React from "react";
import "./ModalLayout.scss";

export interface ModalLayoutProps{
    children?: any,

    header?: JSX.Element,
    body: JSX.Element,
    footer?: JSX.Element,

    showXClose?: boolean,
    transparentMode?: boolean,
    isOpen?: boolean,
    openStateChange?: (openState: boolean) => void
}

export interface ModalLayoutState{
    isOpen: boolean
}

export interface ModalLayoutContextState{
    openModal: () => void,
    closeModal: () => void
}

export const ModalLayoutContext = React.createContext<ModalLayoutContextState>(undefined);

export default class ModalLayout extends React.Component<ModalLayoutProps, ModalLayoutState>{
    constructor(props: Readonly<ModalLayoutProps> | ModalLayoutProps) {
        super(props);
        this.state = {
            isOpen: false
        }
    }

    public componentDidMount() {
        this.setState({isOpen: this.props.isOpen})
    }

    public componentDidUpdate(prevProps: Readonly<ModalLayoutProps>, prevState: Readonly<ModalLayoutState>, snapshot?: any) {
        if(prevProps.isOpen !== this.props.isOpen && this.props.isOpen !== this.state.isOpen)
            this.setState({isOpen: this.props.isOpen});
        if(prevState.isOpen !== this.state.isOpen && this.props.openStateChange)
            this.props.openStateChange(this.state.isOpen);
    }

    /**
     * Apre la modale
     * @private
     */
    private _openModal() {
        this.setState({isOpen: true});
    }

    /**
     * Chiude la modale
     * @private
     */
    private _closeModal() {
        this.setState({isOpen: false});
    }

    public render() {
        return (
            <ModalLayoutContext.Provider value={{openModal: this._openModal.bind(this), closeModal: this._closeModal.bind(this)}}>
                <div className={`ModalLayout ${this.state.isOpen && "open"}`}>
                    <span className={`modal ${this.state.isOpen && "open"} ${this.props.transparentMode && 'transparentMode'}`}>
                    {this.props.showXClose &&
                        <span className={`xZone ${this.props.transparentMode && 'transparentMode'}`}>
                            <span onClick={this._closeModal.bind(this)} className={`xIcon ${this.props.transparentMode && 'transparentMode'}`}/>
                        </span>}
                    {this.props.header && this.props.header}
                    {this.props.body}
                    {this.props.footer && this.props.footer}
                    </span>
                </div>
                {this.props.children}
            </ModalLayoutContext.Provider>
        );
    }
}
