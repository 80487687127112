import React from "react";
import "./DatePicker.scss";

export interface DatePickerData{
    day: number,
    month: number,
    year: number
}

export interface DatePickerDataStatus extends DatePickerData{
    status: boolean
}

export interface DatePickerProps{
    selectedDate: DatePickerData,
    status: DatePickerDataStatus[],
    center?: boolean,
    fullWidthMobile?: boolean,
    onChange: (newDate: DatePickerData) => void
}

export default class DatePicker extends React.Component<DatePickerProps, {}>{
    /**
     * Genera una data corrente
     * @constructor
     */
    public static GenerateCurrentData(): DatePickerData{
        const data = new Date();
        return {day: data.getDate(), month: data.getMonth() + 1, year: data.getFullYear()};
    }

    /**
     * Esegue la mappatura tra numero del mese e la label da visualizzare
     * @param numberMonth Identificativo numerico del mese
     * @private
     */
    private _monthLabel(numberMonth: number): string{
        const monthArray: string[] = [
            "Gennaio",
            "Febbraio",
            "Marzo",
            "Aprile",
            "Maggio",
            "Giugno",
            "Luglio",
            "Agosto",
            "Settembre",
            "Ottobre",
            "Novembre",
            "Dicembre"
        ]
        return monthArray[numberMonth >= 1 && numberMonth <= 12 ? numberMonth - 1 : 0];
    }

    /**
     * Incrementa o decrementa di una unità il mese correntemente tenuto all'interno delle props
     * @param dir Direzione di incremento
     * @private
     */
    private _monthIncrement(dir: 1 | -1): DatePickerData{
        let newMonth = this.props.selectedDate.month + dir;
        let newYear = this.props.selectedDate.year
        if(newMonth > 12){
            newMonth = 1;
            newYear++;
        }else if(newMonth < 1){
            newMonth = 12;
            newYear--;
        }

        return {day: this.props.selectedDate.day, month: newMonth, year: newYear};
    }

    /**
     * Restituisce lo stato di errore per la data specificata
     * @param day Giorno specificato da controllare
     * @param month Mese specificato da controllare
     * @param year Anno specificato da controllare
     * @return Restituisce -1 in caso di errori, 0 neutro, 1 positivo
     * @private
     */
    private _getErrorStatus(day: number, month: number, year: number): -1 | 0 | 1{
        let esito = 0;
        if(this.props.status){
            for(let status of this.props.status) {
                if (status.day === day && status.month === month && status.year === year) {
                    esito = status.status ? 1 : -1;
                    break;
                }
            }

        }
        return esito as -1 | 0 | 1;
    }

    /**
     * Invoca l'evento di cambiamento per il mese
     * @param increment Incremento del mese
     * @private
     */
    private _invokeMonthChange(increment = 0){
        this.props.onChange && this.props.onChange(this._monthIncrement(increment as -1 | 1));
    }

    /**
     * Invoca l'evento di cambiamento per il giorno
     * @param newDay Nuovo giorno
     * @private
     */
    private _invokeDayChange(newDay: number){
        this.props.onChange && this.props.onChange({...this.props.selectedDate ,day: newDay});
    }

    /**
     * Restituisce la lista dei giorni
     * @private
     */
    private _dayLabels(){
        return ['L', 'M', 'M', 'G', 'V', 'S', 'D'].map((value, index) => (<span key={`label-${index}`}>{value}</span>))
    }

    /**
     * Restituisce il numero di giorni in un mese
     * @param year Anno di riferimento
     * @param month Mese di riferimento
     * @private
     */
    private _daysInMonth(year: number, month: number): number{
        return new Date(year, month, 0).getDate();
    }

    /**
     * Restituisce il numero del giorno
     * @param year Anno di riferimento
     * @param month Mese di riferimento
     * @private
     */
    private _dayNumber(year: number, month: number): number{
        let date = new Date(year, month - 1, 1).getDay();
        if(date === 0)
            date = 7;
        return date;
    }

    /**
     * Restituisce la lista dei giorni vuoti per la data selezionata
     * @private
     */
    private _emptyDays(){
        const date = this._dayNumber(this.props.selectedDate.year, this.props.selectedDate.month) - 1;
        return new Array(date).fill(0).map((value, index) => (<span className={"empty"} key={`empty-${index}`}/>))
    }

    /**
     * Restituisce la lista dei giorni pieni
     * @private
     */
    private _fullDays(){
        const date = this._daysInMonth(this.props.selectedDate.year, this.props.selectedDate.month);
        return new Array(date).fill(0).map((value, index) => {
            const errorStatus = this._getErrorStatus(index + 1, this.props.selectedDate.month, this.props.selectedDate.year);
            return (
                <span
                    key={index}
                    className={`dayLabel ${index + 1 === this.props.selectedDate.day && 'selected'} ${errorStatus === 1 ? 'green' : (errorStatus === -1 ? 'red' : '')}`}
                    onClick={() => {this._invokeDayChange(index + 1)}}>
                    {index + 1}
                </span>
            )
        })
    }

    public render() {
        return (
            <div className={`DatePickerContainer ${this.props.center && 'center'} ${this.props.fullWidthMobile && 'fullWidthMobile'}`}>
                <div className={"RecapContainer"}>
                    <span onClick={() =>  this._invokeMonthChange(-1)} className={"leftArrow"}/>
                    <div className={"dataContainer"}>
                        <span>{this.props.selectedDate.day}</span>
                        <span>{this.props.selectedDate.year}</span>
                        <span>{this._monthLabel(this.props.selectedDate.month)}</span>
                    </div>
                    <span onClick={() => this._invokeMonthChange(1)} className={"rightArrow"}/>
                </div>
                <div className={"CalendarContainer"}>
                    <div className={"dayLabelContainer"}>{
                        this._dayLabels()
                    }</div>
                    <div className={"daysContainer"}>
                        {this._emptyDays()}
                        {this._fullDays()}
                    </div>
                </div>
            </div>
        );
    }
}
