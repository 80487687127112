import {FotolibroModel, isNormalView, TipoFotolibroMapper} from "tici_commons";
import React from "react";
import {CheckLine, TextLine} from "../../../../../../Widgets/Configuratore/CComponents";
import {
    GenerateGroup,
    GenerateRiepilogoData,
    GenerateRiepilogoInternalBox,
    RiepilogoData,
    RiepilogoInternalBox
} from "../../../Commons/RiepilogoDatoModel";
import {StorageManagerStatus} from "../../../Storage/StorageManager";
import SezioneGenerica from "../../SezioneGenerica";
import CategorySectionLayout from "../../../../../../Layout/CategorySectionLayout/CategorySectionLayout";
import IfContainer from "../../../../../../Layout/IfContainer/IfContainer";
import SentenceLayout from "../../../../../../Layout/SentenceLayout/SentenceLayout";
import Button from "../../../../../../Core/Buttons/Button";
import TwoSpaceLine from "../../../../../../Layout/TwoSpaceLine/TwoSpaceLine";

export interface SezioneBaseProps{
    modalitaConfiguratore?: boolean,
    fileDatCaricato?: boolean,
    caricaFileDat?: (datBase64: string) => void
}

export default class SezioneBase extends SezioneGenerica<"informazioniBase", SezioneBaseProps, {}> {
    public static ErrorMessages = {
        "NomeProgettoAssenteError": "Il nome del progetto non può essere vuoto"
    }

    public generaRiepilogo = (fotolibro: FotolibroModel, storage: StorageManagerStatus, modalitaConfiguratore: boolean): RiepilogoInternalBox | RiepilogoData[] => {
        return GenerateRiepilogoInternalBox(
            "Informazioni base",
            GenerateRiepilogoData(
                `${modalitaConfiguratore ? 'Nome della configurazione' : 'Nome dell\'ordine'}: ${fotolibro.informazioniBase.nomeProgetto}`,
                SezioneBase.ErrorMessages.NomeProgettoAssenteError,
                fotolibro.informazioniBase.nomeProgetto.length === 0
            ),
            ...GenerateGroup(
                isNormalView(fotolibro.informazioniBase.tipoFotolibro) && !modalitaConfiguratore,
                GenerateRiepilogoData('Stampa prevista', undefined, undefined, fotolibro.informazioniBase.stampaFlag),
                GenerateRiepilogoData('Rilegatura prevista', undefined, undefined, fotolibro.informazioniBase.rilegaturaFlag),
            ),
            GenerateRiepilogoData('Custodia prevista', undefined, undefined, fotolibro.informazioniBase.custodiaFlag),
            ...GenerateGroup(
                fotolibro.informazioniBase.tipoFotolibro !== 'accessori',
                GenerateRiepilogoData('Album ridotti previsti', undefined, undefined, fotolibro.informazioniBase.albumRidottoFlag),
                GenerateRiepilogoData('Album pocket previsti', undefined, undefined, isNormalView(fotolibro.informazioniBase.tipoFotolibro) && fotolibro.informazioniBase.albumPocketFlag)
            ),
            GenerateRiepilogoData(`Tipo fotolibro: ${TipoFotolibroMapper(fotolibro.informazioniBase.tipoFotolibro)}`)
        )
    }

    /**
     * Carica un file dat
     * @param fileType Tipo del file che si sta cercando di caricare
     * @param base64Encoding Codifica base64 del file dat che si vuole caricare
     * @private
     */
    private _caricaFileDat(fileType: string, base64Encoding: string){
        if(fileType === 'dat')
            this.props.caricaFileDat && this.props.caricaFileDat(base64Encoding);
    }

    /**
     * Rimuove il file dat presente
     * @private
     */
    private _rimuoviFileDat(){
        this.props.caricaFileDat && this.props.caricaFileDat(undefined);
    }

    public render() {
        return (
            <CategorySectionLayout
                categoryLabel={"Informazioni base"}
                size={"largeRelative"}
                center={true}>
                <TextLine
                    label={this.props.modalitaConfiguratore ? "Nome della configurazione" : "Nome dell'ordine"}
                    showError={!this.$.nomeProgetto.length}
                    errorMessage={SezioneBase.ErrorMessages.NomeProgettoAssenteError}
                    onChange={v => this.$.nomeProgetto = v}
                    value={this.$.nomeProgetto}/>
                <SentenceLayout alignment={"right"} largeGap={true}>
                    <IfContainer
                        condition={isNormalView(this.$.tipoFotolibro) && !this.props.modalitaConfiguratore}>
                        <CheckLine
                            label={"Stampa / Rilegatura"}
                            checkType={"checkbox"}
                            onChange={v => {
                                this.$.stampaFlag = v;
                                if(v)
                                    this.$.rilegaturaFlag = !v
                            }}
                            checked={this.$.stampaFlag}
                            reduce={true}/>
                        <CheckLine
                            label={"Rilegatura"}
                            checkType={"checkbox"}
                            onChange={v => {
                                this.$.rilegaturaFlag = v;
                                if(v)
                                    this.$.stampaFlag = !v;
                            }}
                            checked={this.$.rilegaturaFlag}
                            reduce={true}/>
                    </IfContainer>
                    <CheckLine
                        label={"Custodia"}
                        checkType={"checkbox"}
                        onChange={v => this.$.custodiaFlag = v}
                        checked={this.$.custodiaFlag}
                        reduce={true}/>
                    <IfContainer condition={this.$.tipoFotolibro !== 'accessori'}>
                        <CheckLine
                            label={"Album ridotti"}
                            checkType={"checkbox"}
                            onChange={v => this.$.albumRidottoFlag = v}
                            checked={this.$.albumRidottoFlag}
                            reduce={true}/>
                        <IfContainer condition={isNormalView(this.$.tipoFotolibro)}>
                            <CheckLine
                                label={"Album pocket"}
                                checkType={"checkbox"}
                                onChange={v => this.$.albumPocketFlag = v}
                                checked={this.$.albumPocketFlag}
                                reduce={true}/>
                        </IfContainer>
                    </IfContainer>
                </SentenceLayout>
                <IfContainer condition={this.$.rilegaturaFlag}>
                    <IfContainer
                        condition={!this.props.fileDatCaricato}
                        elseComponent={
                            <TwoSpaceLine label={""}>
                                <Button
                                    content={"Rimuovi file DAT"}
                                    type={"medium"}
                                    buttonType={"full-normal"}
                                    onClick={() => this._rimuoviFileDat()}/>
                            </TwoSpaceLine>
                        }>
                        <TextLine
                            label={"File dat progetto"}
                            type={"file"}
                            value={""}
                            onFileUpload={(fileName, fileType, file, base64Encoding) => this._caricaFileDat(fileType, base64Encoding)}/>
                    </IfContainer>
                </IfContainer>
            </CategorySectionLayout>
        );
    }
}
