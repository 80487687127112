import React from "react";
import "./Loader.scss";

export default class Loader extends React.Component<{}, {}>{
    public render() {
        return (
            <div className={"Loader"}>
                <span className={"ball"}/>
                <span className={"ball"}/>
                <span className={"ball"}/>
                <span className={"ball"}/>
                <span className={"ball"}/>
                <span className={"ball"}/>
                <span className={"ball"}/>
                <span className={"ball"}/>
                <span className={"ball"}/>
            </div>
        );
    }
}
