import {NotificaChatResponseModel, Rest} from "tici_commons";
import Config from "../Static/Config";

export default class NotificheService{
    public static async NotificheChat(): Promise<NotificaChatResponseModel[]> {
        let esito: NotificaChatResponseModel[] = [];

        try {
            const response = await Rest.Get<NotificaChatResponseModel[]>(`${Config.PrivatePath}/NotificheChat`);
            if(response && response.statusCode === 200)
                esito.push(...response.response)
        }catch (e){
            console.error("Non é stato possibile recuperare le notifiche della chat", String(e));
        }

        return esito;
    }
}
