import "./PsdVisualizer.scss";
import React from "react";
import Psd from '@webtoon/psd';
import {pLog} from "tici_commons";

export interface PsdVisualizerProps{
    psdUrl?: string
}

export interface PsdVisualizerState{
    imageData: string
}

export default class PsdVisualizer extends React.Component<PsdVisualizerProps, PsdVisualizerState>{
    private _currentPsd: Psd;                                   //File psd attualmente caricato

    constructor(props: Readonly<PsdVisualizerProps> | PsdVisualizerProps) {
        super(props);
        this.state = {
            imageData: ""
        }
    }

    /**
     * Genera l'immagine dal psd corrente
     * @private
     */
    private async _generateImageFromPsd(){
        if(this._currentPsd){
            const canvas = document.createElement('canvas');
            canvas.width = this._currentPsd.width;
            canvas.height = this._currentPsd.height;
            const ctx = canvas.getContext('2d');

            for(const layer of this._currentPsd.layers){
                try{
                    if(layer.name.toLowerCase().trim() !== 'sfondo'){
                        const imageData = new ImageData(
                            await layer.composite(),
                            layer.width,
                            layer.height
                        );
                        const image = document.createElement('canvas');
                        image.width = layer.width;
                        image.height = layer.height;
                        image.getContext('2d').putImageData(imageData, 0, 0);
                        ctx.drawImage(image, layer.left, layer.top);
                    }
                }catch (ex){
                    pLog("PsdVisualizer", "e", "Errore nella stampa di un layer: ", layer.name);
                }
            }


            this.setState({imageData: canvas.toDataURL()});
        }
    }

    /**
     * Effettua il caricamento del file psd
     * @private
     */
    private async _loadPsd(){
        if(this.props.psdUrl){
            let error = false
            try{
                const response = await fetch(this.props.psdUrl);
                if(response.ok){
                    const buffer = await response.arrayBuffer();
                    this._currentPsd = Psd.parse(buffer);
                    this._generateImageFromPsd();
                }else error = true;
            }catch(ex){
                error = true;
            }finally {
                if(error) {
                    this.setState({imageData: ""});
                    this._currentPsd = undefined;
                    pLog("PsdVisualizer", "e", "Errore nel caricamento di un file psd");
                }
            }
        }
    }

    /**
     * Restituisce l'immagine psd convertita in base64
     * @param psdUrl Url del psd da caricare
     * @constructor
     */
    public static async GetPsdImage(psdUrl: string): Promise<string>{
        let esito = "";

        if(psdUrl){
            try{
                const response = await fetch(psdUrl);
                if(response.ok){
                    const buffer = await response.arrayBuffer();
                    const currentPsd = Psd.parse(buffer);

                    const canvas = document.createElement('canvas');
                    canvas.width = currentPsd.width;
                    canvas.height = currentPsd.height;
                    const ctx = canvas.getContext('2d');

                    for(const layer of currentPsd.layers){
                        try{
                            if(layer.name.toLowerCase().trim() !== 'sfondo'){
                                const imageData = new ImageData(
                                    await layer.composite(),
                                    layer.width,
                                    layer.height
                                );
                                const image = document.createElement('canvas');
                                image.width = layer.width;
                                image.height = layer.height;
                                image.getContext('2d').putImageData(imageData, 0, 0);
                                ctx.drawImage(image, layer.left, layer.top);
                            }
                        }catch (ex){
                            pLog("PsdVisualizer", "e", "Errore nella stampa di un layer: ", layer.name);
                        }
                    }

                    esito = canvas.toDataURL();
                }
            }catch(ex){
                pLog("PsdVisualizer", 'e', "Errore nella generazione di un immagine psd")
            }
        }

        return esito;
    }

    componentDidMount() {
        this._loadPsd();
    }

    public render() {
        return (
            this.state.imageData &&
                <img alt={"psdImage"} className={"PsdVisualizerContainer"} src={this.state.imageData}/>
        );
    }
}
