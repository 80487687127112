import "./Wigets.scss";
import {ItemElementInterface} from "../../../Primary/Selects/ElementSelect/ElementSelect";
import ResponsiveLabel from "../../../Core/ResponsiveLabel/ResponsiveLabel";
import {Fragment} from "react";
import PaddingLayout from "../../../Layout/PaddingLayout/PaddingLayout";


export const OnlyDesktopLayout = (props: {children: any}) => {
    return (
        <Fragment>
            <div className={"OnlyDesktopLayout"}>
                {props.children}
            </div>
            <div className={"TabletMobileLayout"}>
                <PaddingLayout paddingMobile={"16px"} paddingTablet={"16px"} paddingDesktop={"16px"}>
                    <ResponsiveLabel
                        content={"Il contenuto é disponibile esclusivamente su schermi superiori ai 1200px"}
                        type={"large"}
                        alignment={"center"}/>
                </PaddingLayout>
            </div>
        </Fragment>
    )
}

export const ButtonTotem = (props: {content: string, onClick: () => void}) => {
    return (
        <div
            className={"ButtonTotem"}
            onClick={() => props.onClick && props.onClick()}>
            {props.content}
        </div>
    )
}

export const ListaItems = (
    props: {
        items: ItemElementInterface[],
        selectedItem?: string
        click?: (item: string) => void
    }
) => {
    return (
        <div className={"ListItems"}>{
            props.items && props.items.map(item => (
                <span
                    className={`Item ${props.selectedItem === item.label && 'selected'}`}
                    onClick={() => props.click && props.click(item.label)}>
                    <ResponsiveLabel
                        content={item.label}
                        type={"large"}
                        alignment={"center"}
                        italic={true}
                        bold={item.label === props.selectedItem}/>
                    <div
                        style={{backgroundImage: `url(${item.image})`}}
                        className={"ItemImage"}/>
                </span>
            ))
        }</div>
    )
}
