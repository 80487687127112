import {Rest, RouteDataGetType} from "tici_commons";
import Config from "../Static/Config";

export default class AnalyticsRotteService {
    public static async GetRotte(): Promise<string[]>{
        const esito: string[] = [];

        try {
            const response = await Rest.Get<string[]>(`${Config.PrivatePath}/Routes`);
            if(response && response.statusCode === 200)
                esito.push(...response.response)
        }catch (e){
            console.error("Non é stato possibile recuperare le rotte", String(e));
        }

        return esito;
    }

    public static async GetData(nomeRotta: string, tipoDato: RouteDataGetType): Promise<{[key: string]: any}[]>{
        const esito: {[key: string]: any}[] = [];

        try {
            const response = await Rest.Get<{[key: string]: any}[]>(`${Config.PrivatePath}/RouteData/${nomeRotta}/${tipoDato}`);
            if(response && response.statusCode === 200)
                esito.push(...response.response);
        }catch (e){
            console.error("Non é stato possibile recuperare i dati della rotta", String(e));
        }

        return esito;
    }
}
