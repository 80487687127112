import React, { Fragment } from "react";
import ResponsiveLabel from '../ResponsiveLabel/ResponsiveLabel';
import "./ProgressBar.scss";

export interface ProgessBarProps{
    progress: number
}

export default class ProgressBar extends React.Component<ProgessBarProps, {}>{
    /**
     * Calcola il progresso della barra
     * @private
     */
    private _calcolaProgresso(): number{
        return Math.round(this.props.progress * 100)
    }

    render() {
        return (
            <Fragment>
                <div className={"ProgressBar"}>
                    <ResponsiveLabel alignment={"center"} content={"0%"} type={"small"}/>
                    <span className={"ProgressBarLine"}>
                        <span style={{width:`${this._calcolaProgresso()}%`}}>
                            <ResponsiveLabel alignment={"right"} content={`${this._calcolaProgresso()}%`} type={"small"}/>
                        </span>
                    </span>
                    <ResponsiveLabel alignment={"center"} content={"100%"} type={"small"}/>
                </div>
            </Fragment>
        );
    }
}
