import "./SUComponents.scss";
import ResponsiveLabel from "../../Core/ResponsiveLabel/ResponsiveLabel";
import Config from "../../Static/Config";
import ImageService from "../../Servicies/ImageService";

export interface MaterialeLineProps{
    nomeMateriale: string,
    famigliaMateriale: string,
    lussuriaMateriale: string,
    spessoreMateriale: string,
    nomeMaterialeImage: string,
    ammetteUv: number,
    onDelete?: () =>  void
}
export function MaterialeLine(props: MaterialeLineProps){
    return (
        <span className={"MaterialeLine"}>
            <img className={"materialImage"} src={`${Config.PublicPath}/${props.nomeMaterialeImage}`} alt={"Materiale"}/>
            <ResponsiveLabel content={`${props.lussuriaMateriale}: ${props.famigliaMateriale}-${props.nomeMateriale}-${props.spessoreMateriale} ${props.ammetteUv === 1 ? "Ammette UV" : "Non ammette UV"}`} type={"medium"} alignment={"center"}/>
            <img className={"deleteButton"} src={ImageService.getImage('xIcon')} alt={"xIcon"} onClick={() => {
                props.onDelete && props.onDelete();
            }}/>
        </span>
    )
}
