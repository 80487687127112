import React from "react";
import {SezioneGenericaModel, SpessoreMateriale, TipoSezioneConfiguratoreType} from "tici_commons";
import Materiali from "../../DatabaseData/Materiali";
import {SessionBridgeContext, SessionBridgeInterface} from "./SessionBridge";

export interface MaterialManagerComponentProps{
    idManager: string,
    storeBridgeData?: boolean
    sezioniData: [string, SezioneGenericaModel][],
    onSectionChanges: (sezioniData: [string, SezioneGenericaModel][]) => void,
    materialiFissi?: [string, string][]
}

export interface MaterialManagerComponentState{
    materiali: [string, string][],
    vincoliGruppoSpessore: [string, {vincolante: string, spessore: SpessoreMateriale}][]    //Vincoli dello spessore. Il vincolante è la sezione che crea il vincolo
}

export default abstract class MaterialManagerComponent<P extends MaterialManagerComponentProps, S extends MaterialManagerComponentState> extends React.Component<P, S> {
    constructor(props: Readonly<P> | P) {
        super(props);
    }

    /**
     * Interpreta i dati delle props per determinare se è necessario o meno salvare i dati nel bridge di sessione
     * @private
     */
    private _storageBridgeData(): boolean{
        let esito = true;
        if(this.props.storeBridgeData !== undefined)
            esito = this.props.storeBridgeData;
        return esito;
    }

    componentDidMount() {
        const context = this.context as SessionBridgeInterface;
        const materiali = context.getData(`${this.props.idManager}Materiali`);
        //Separiamo l'inserimento dei materiali fissi in due statement a causa del delay di aggiornamento dello state
        if(materiali) {
            this.setState({materiali: JSON.parse(materiali)}, () => this._inserisciMaterialiFissi());
            context.deleteData(`${this.props.idManager}Materiali`);
        }else{
            this._inserisciMaterialiFissi();
        }
    }

    componentWillUnmount() {
        if(this._storageBridgeData()){
            const context = this.context as SessionBridgeInterface;
            context.setData(`${this.props.idManager}Materiali`, JSON.stringify(this.state.materiali));
        }
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot?: any) {
        //alert("Props:"+ JSON.stringify(this.props));
        if(JSON.stringify(prevProps.materialiFissi) !== JSON.stringify(this.props.materialiFissi)) {
            this._inserisciMaterialiFissi();
        }
    }

    /**
     * Effettua l'inserimento dei materiali fissi nel renderizzatore
     * @private
     */
    private _inserisciMaterialiFissi(){
        if(this.props.materialiFissi){
            //Inizializziamo i materiali
            const mappaStato = new Map(this.state.materiali);
            for(const fisso of this.props.materialiFissi){
                mappaStato.set(fisso[0], fisso[1]);
            }

            this.setState({materiali: Array.from(mappaStato)});
        }
    }

    /**
     * Pulisce tutte le sezioni
     * @constructor
     * @protected
     */
    protected ClearSezioni(){
        this.setState({materiali: [], vincoliGruppoSpessore: []}, () => this._inserisciMaterialiFissi());
        if(this.props.onSectionChanges)
            this.props.onSectionChanges([]);
    }

    /**
     * Restituisce l'esito del controllo di esistenza dei dati di sezione
     * @param nomeSezione Nome della sezione da controllare
     * @constructor
     * @protected
     */
    protected HasSezioneData(nomeSezione: string): boolean{
        let esito = false;
        if(this.props.sezioniData)
            esito = new Map(this.props.sezioniData).has(nomeSezione);
        return esito;
    }

    /**
     * Restituisce i dati di una sezione, intesi come le informazioni peculiari della singola sezione (Nome immagine, pathImmagine, ecc)
     * @param nomeSezione NOme della sezione da controllare
     * @constructor
     * @protected
     */
    protected GetSezioneData(nomeSezione: string): string{
        let esito = "";
        if(this.HasSezioneData(nomeSezione))
            esito = new Map(this.props.sezioniData).get(nomeSezione).informazione;
        return esito;
    };

    /**
     * Restituisce il tipo di una sezione se la sezione esiste
     * @param nomeSezione Nome della sezione da controllare
     * @constructor
     * @protected
     */
    protected GetSezioneType(nomeSezione: string): TipoSezioneConfiguratoreType{
        let esito: TipoSezioneConfiguratoreType = "materiale";
        if(this.HasSezioneData(nomeSezione))
            esito = new Map(this.props.sezioniData).get(nomeSezione).tipoSezione;
        return esito;
    }

    /**
     * Elimina i dati di una particolare sezione
     * @param nomeSezione Sezione di cui eliminare i dati
     * @constructor
     * @protected
     */
    protected DeleteSezioneData(nomeSezione: string): void {
        if(this.HasSezioneData(nomeSezione)) {
            const mapSezioni = new Map(this.props.sezioniData);
            mapSezioni.delete(nomeSezione);
            if(this.props.onSectionChanges)
                this.props.onSectionChanges(Array.from(mapSezioni));
            const mapMateriali = new Map(this.state.materiali);
            mapMateriali.delete(nomeSezione);
            this.setState({materiali: Array.from(mapMateriali)}, () => this._inserisciMaterialiFissi());
        }
    }

    /**
     * Imposta i dati per una particolare sezione
     * @param nomeSezione Nome della sezione da inserire
     * @param tipoSezione Tipologia della sezione
     * @param dataSezione Dati da associare alla sezione
     * @param materialeSezione Materiale della sezione
     * @constructor
     * @protected
     */
    protected SetSezioneData(nomeSezione: string, tipoSezione: TipoSezioneConfiguratoreType, dataSezione: string, materialeSezione: string){
        if(dataSezione){
            const mapSezioni = new Map(this.props.sezioniData);
            mapSezioni.set(nomeSezione, {tipoSezione: tipoSezione, informazione: dataSezione});
            if(this.props.onSectionChanges)
                this.props.onSectionChanges(Array.from(mapSezioni));
            const mapMateriali = new Map(this.state.materiali);
            mapMateriali.set(nomeSezione, materialeSezione);
            this.setState({materiali: Array.from(mapMateriali)}, () => this._inserisciMaterialiFissi());

            //Gestione dei vincoli
            this.SetSpessoreGruppo(this.GetGruppoSezione(nomeSezione), nomeSezione, Materiali.GetSpessore(dataSezione));
        }else this.DeleteSezioneData(nomeSezione);
    }

    /**
     * Setta lo spessore per il singolo gruppo
     * @param nomeGruppo Nome del gruppo da modificare
     * @param vincolante Colui che crea il vincolo per il gruppo. Se questo valore è stato assengato non viene modificato
     * @param spessoreGruppo Spessore del gruppo da inserire
     * @constructor
     * @protected
     */
    protected SetSpessoreGruppo(nomeGruppo: string, vincolante: string, spessoreGruppo: SpessoreMateriale){
        if(spessoreGruppo){
            const mappaSpessore = new Map(this.state.vincoliGruppoSpessore);
            if(mappaSpessore.has(nomeGruppo))
                vincolante = mappaSpessore.get(nomeGruppo).vincolante;

            mappaSpessore.set(nomeGruppo, {vincolante: vincolante, spessore: spessoreGruppo});
            this.setState({vincoliGruppoSpessore: Array.from(mappaSpessore)});
        }
    }

    /**
     * Restituisce lo spessore del gruppo
     * @param nomeGruppo Nome del gruppo da cui cercare lo spessore
     * @param nomeSezione Nome della sezione che richiede lo spessore del gruppo per determinare se si tratta della sezione vincolante
     * @constructor
     * @protected
     */
    protected GetSpessoreGruppo(nomeGruppo: string, nomeSezione: string): SpessoreMateriale | "*"{
        let esito: SpessoreMateriale | '*' = "*";

        if(nomeGruppo){
            const mappaSpessore = new Map(this.state.vincoliGruppoSpessore);
            if(mappaSpessore.has(nomeGruppo) && mappaSpessore.get(nomeGruppo).vincolante !== nomeSezione)
                esito = mappaSpessore.get(nomeGruppo).spessore;
        }

        return esito;
    }

    /**
     * Restituisce il nome del gruppo per una singola sezione
     * @param nomeSezione Nome della sezione di cui recuperare il gruppo
     * @constructor
     * @protected
     */
    protected abstract GetGruppoSezione(nomeSezione: string): string;
}

MaterialManagerComponent.contextType = SessionBridgeContext;
