import React from "react";
import ResponsiveLabel from "../../Core/ResponsiveLabel/ResponsiveLabel";

export type AzioneRapidaSubmenuProps = {
    onClickEvent?: () => void,
    icona: string,
    componenteIcona?: any,
    titoloAzioneRapida?: string
} | {
    onClickEvent?: () => void,
    icona?: string,
    componenteIcona: any,
    titoloAzioneRapida?: string
}

export default class AzioneRapidaSubmenu extends React.Component<AzioneRapidaSubmenuProps, any>{
    public render() {
        return (
            <div
                onClick={() => this.props.onClickEvent && this.props.onClickEvent()}
                className={"AzioneRapida"}>
                {this.props.icona && <img className={"AzioneRapidaIcon"} src={this.props.icona} alt={`Icona${this.props.titoloAzioneRapida}`}/>}
                {this.props.componenteIcona && this.props.componenteIcona}
                {
                    this.props.titoloAzioneRapida &&
                    <ResponsiveLabel
                        content={this.props.titoloAzioneRapida}
                        type={"xsmall"}
                        labelType={"whiteLabel"}
                        alignment={"center"}
                        uppercase={true}/>
                }
            </div>
        );
    }
}
