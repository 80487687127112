import React from "react";
import LabelLoader from "../Primary/LabelLoader/LabelLoader";
import RedirectService from "../Servicies/RedirectService";

export default class CaricamentoImpersonificazione extends React.Component<{}, {}>{
    public componentDidMount() {
        window.setTimeout(() => {
            RedirectService.GoToHomepage();
        }, 3000);
    }

    public render() {
        return (
            <div style={{display: "flex", justifyContent: "flex-start", alignItems: "center", width: "100%", height: "100%"}}>
                <LabelLoader label={"Caricamento"}/>
            </div>
        )
    }
}
