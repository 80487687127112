import React, {Fragment} from "react";
import {StorageData, StorageDataMapper} from "../../../Pages/SitoInterno/Configuratore/Storage/StorageManager";
import MultiSelettore from "../../MultiSelettore/MultiSelettore";
import SectionLayout from "../../../Layout/SectionLayout/SectionLayout";
import ResponsiveLabel from "../../../Core/ResponsiveLabel/ResponsiveLabel";
import IfContainer from "../../../Layout/IfContainer/IfContainer";
import {SelectLine} from "../../../Widgets/Configuratore/CComponents";
import {CartaAlternativa, Lavorazioni, NomeSezioneInformazioneType} from "tici_commons";
import Responsive2Col from "../../../Layout/Responsive2Col/Responsive2Col";
import Button from "../../../Core/Buttons/Button";
import ImagePreviewOverlay from "../../ImagePreviewOverlay/ImagePreviewOverlay";
import Modello from "../../../DatabaseData/Modello";
import ListaMaterialiConfiguratore from "../../ListaMaterialiConfiguratore/ListaMaterialiConfiguratore";

export interface DADMIndexAzioneProps{
    immagini: StorageData[],
    onDelete?: (nomeImmagine: string) => void,
    modificheCartaExtra?: boolean,
    aggiungiCartaExtraSelezionati?: (cartaExtraSelezionata: string) => void,
    modificheLavorazioni?: boolean,
    aggiungiLavorazione?: (nomeImmagine: string, nomeLavorazione: string, lavorazioneSezioni?: NomeSezioneInformazioneType[]) => boolean
}

export interface DADMIndexAzioneState{
    cartaExtraSelezionata: string,
    lavorazioneSelezionata: string,
    lavorazioneSezioni: NomeSezioneInformazioneType[]
    nomeImmagineOverlay: string
}

export default class DADMIndexAzione extends React.Component<DADMIndexAzioneProps, DADMIndexAzioneState>{
    constructor(props: Readonly<DADMIndexAzioneProps> | DADMIndexAzioneProps) {
        super(props);
        this.state = {
            cartaExtraSelezionata: "",
            lavorazioneSelezionata: "",
            lavorazioneSezioni: [],
            nomeImmagineOverlay: ""
        }
    }

    /**
     * Gestisce l'aggiunta di carta extra
     * @private
     */
    private _aggiungiCartaExtraHandle(){
        this.props.aggiungiCartaExtraSelezionati && this.props.aggiungiCartaExtraSelezionati(this.state.cartaExtraSelezionata);
        this.setState({cartaExtraSelezionata: ""});
    }

    /**
     * Gestisce la rimozione dei fogli
     * @private
     */
    private _rimuoviFogliHandle(){
        if(this.props.onDelete){
            for(const immagine of this.props.immagini){
                this.props.onDelete(immagine.name);
            }
        }
        this.setState({cartaExtraSelezionata: ""});
    }

    /**
     * Controlla che il modello per la lavorazione selezionata esista oppure no
     * @private
     */
    private _modelloEsiste(): boolean{
        return Modello.ModelExist(this.state.lavorazioneSelezionata);
    }

    /**
     * Genera le sezioni per il singolo foglio su cui deve essere applicata la lavorazione con aggiunte
     * @private
     */
    private _generaSezioniPerFoglio(): NomeSezioneInformazioneType[]{
        const esito: NomeSezioneInformazioneType[] = [];

        //Aggiunta dell'elemento di base
        esito.push(['A', {tipoSezione: "immagineTela", informazione: this.props.immagini[0].name}]);
        for(const aggiunta of this.state.lavorazioneSezioni){
            if(aggiunta[0] !== 'A')
                esito.push(aggiunta);
        }

        return esito;
    }

    /**
     * Genera i materiali fissi per la lavorazione con aggiunte del foglio corrente
     * @private
     */
    private _generaMaterialiFissi(): [string, string][]{
        return [['A', this.props.immagini[0].url()]];
    }

    /**
     * Aggiunge le lavorazioni
     * @private
     */
    private _aggiungiLavorazioneHandle(){
        if(
            this.props.aggiungiLavorazione &&
            this.props.aggiungiLavorazione(
                this.props.immagini[0].name,
                this.state.lavorazioneSelezionata,
                this._modelloEsiste() ? this._generaSezioniPerFoglio() : undefined
            )
        ){
            this.setState({lavorazioneSezioni: [], lavorazioneSelezionata: ""});
        }
    }

    public render() {
        return (
            <SectionLayout
                size={"largeRelative"}
                addPadding={true}
                showBorder={true}>
                <ResponsiveLabel content={"Selezione corrente"} type={"medium"} alignment={"center"}/>
                <MultiSelettore
                    immagini={this.props.immagini}
                    posizioniSelezionate={[]}
                    onChange={selezione => this.setState({nomeImmagineOverlay: selezione[0]})}/>
                <IfContainer condition={this.props.modificheCartaExtra}>
                    <SelectLine
                        label={"Seleziona carta extra"}
                        placeHolder={"Seleziona"}
                        elements={CartaAlternativa.filter(carta => !carta.cartoncinoFlag).map(carta => ({label: carta.nomeCarta}))}
                        value={this.state.cartaExtraSelezionata}
                        onChange={v => this.setState({cartaExtraSelezionata: v, lavorazioneSelezionata: "", lavorazioneSezioni: []})}/>
                </IfContainer>
                {this.props.modificheLavorazioni && this.props.immagini.length === 1 && <Fragment>
                    <SelectLine
                        label={"Seleziona lavorazione"}
                        placeHolder={"Seleziona"}
                        elements={Lavorazioni.map(v => ({label: v}))}
                        value={this.state.lavorazioneSelezionata}
                        onChange={v => this.setState({
                            lavorazioneSelezionata: v,
                            cartaExtraSelezionata: "",
                            lavorazioneSezioni: []
                        })}/>
                    <IfContainer condition={this._modelloEsiste()}>
                        <ListaMaterialiConfiguratore
                            idManager={`lavorazione-${this.props.immagini[0].name}`}
                            nomeConfigurazioneModello={Modello.GetNomeConfigurazione(this.state.lavorazioneSelezionata)}
                            sezioniData={this._generaSezioniPerFoglio()}
                            materialiFissi={this._generaMaterialiFissi()}
                            onSectionChanges={value => this.setState({lavorazioneSezioni: value})}
                            storeBridgeData={false}
                            nascondiLegenda={true}/>
                    </IfContainer>
                </Fragment>}
                <IfContainer
                    condition={!!this.state.cartaExtraSelezionata || !!this.state.lavorazioneSelezionata}
                    elseComponent={
                        <Button
                            content={"Elimina immagine"}
                            type={"medium"}
                            buttonType={"full-normal-negative"}
                            onClick={() => this._rimuoviFogliHandle()}/>
                    }>
                    <Responsive2Col>
                        <Button
                            content={!!this.state.cartaExtraSelezionata ? "Aggiungi carta extra" : "Aggiungi lavorazione"}
                            type={"medium"}
                            buttonType={"full-normal"}
                            onClick={() => !!this.state.cartaExtraSelezionata ? this._aggiungiCartaExtraHandle() : this._aggiungiLavorazioneHandle()}/>
                        <Button
                            content={"Elimina immagine"}
                            type={"medium"}
                            buttonType={"full-normal-negative"}
                            onClick={() => this._rimuoviFogliHandle()}/>
                    </Responsive2Col>
                </IfContainer>
                <IfContainer condition={!!this.state.nomeImmagineOverlay}>
                    <ImagePreviewOverlay
                        nomeImmagine={this.state.nomeImmagineOverlay}
                        sorgenteImmagine={this.props.immagini.find(immagine => immagine.name === this.state.nomeImmagineOverlay)?.url()}
                        onCloseClick={() => this.setState({nomeImmagineOverlay: ""})}/>
                </IfContainer>
            </SectionLayout>
        );
    }
}
