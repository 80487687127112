import {FotolibroModel} from "../../../tici_commons";

export function UnCamelCase(string: string): string{
    let esito = "";

    for(let i = 0; i < string.length; i++){
        if(i !== 0 && /[A-Z0-9]/.test(string.charAt(i)))
            esito += " ";
        esito += i === 0 ? string.charAt(i) : string.charAt(i).toLowerCase();
    }

    return esito;
}

/**
 * Controlla se l'immagine al link passato esiste oppure no
 * @param imageUrl Url dell'immagine da controllare
 */
export function imageExists(imageUrl: string): boolean{
    const http = new XMLHttpRequest();
    http.open('HEAD', imageUrl, false);
    http.send();

    return http.status !== 404;
}

/**
 * Converte un file in un formato base64
 * @param file File da convertire
 * @private
 */
export function toBase64(file: File): Promise<{name: string, base64: string}>{
    return new Promise<{name: string, base64: string}>((resolve, reject) => {
        if(file){
            const fileReader = new FileReader();
            fileReader.addEventListener("loadend", () => {
                resolve({name: file.name, base64: fileReader.result as string});
            });
            fileReader.readAsDataURL(file);
        }else reject();
    });
}

/**
 * Effettua il caricamento di un immagine
 * @return Restituisce il nome del file, il formato e la conversione base64
 */
export function loadImage(supportedTypesCSV: string): Promise<[string, string, string] | false>{
    return new Promise<[string, string, string] | false>(resolve => {
        const input = document.createElement("input");
        input.type = "file";
        input.addEventListener("change", () => {
            if(input.files.length > 0){
                const target = input.files.item(0);
                const type = target.name.split(".")[1];
                if(supportedTypesCSV.toLowerCase().includes(type.toLowerCase())){
                    toBase64(target).then(data => {
                        resolve([data.name, type, data.base64]);
                    }).catch(() => {
                        console.error("Errore nella conversione base64 del file");
                        resolve(false);
                    });
                }else resolve(false);
            }
        });
        input.click();
    });
}

/**
 * Converte i millisecondi passati come parametro in un formato minuti:secondi
 * @param ms Millisecondi da convertire
 */
export function fromMsToTime(ms: number): string{
    let secondi = Math.round(ms / 1000);
    let minuti = Math.floor(secondi / 60);

    return `${minuti > 0 ? `${minuti} minuti e ` : ''}${secondi - (minuti * 60)} secondi`;
}

/**
 * Restituisce un ordinamento partendo dal nome dell'immagine passata
 * @param name Nome dell'immagine
 */
export function orderFromImageName(name: string): number{
    return isNaN(parseInt(name)) ? stringToValue(name) : parseInt(name);
}

/**
 * Converte una stringa in un valore univoco
 * @param string Stringa da convertire
 * @param startValue Valore di partenza
 */
export function stringToValue(string: string, startValue = 10000000000): number{
    let esito = -1;

    if(string && startValue){
        let sum = startValue;
        for(let i = 1; i <= string.length; i++){
            sum += string.charCodeAt(i - 1) * i;
        }
        esito = sum;
    }

    return esito;
}

/**
 * Effettua la bonifica del formato
 * @param formato Formato da bonificare
 */
export function bonificaFormato(formato: string): string{
    let esito = "1x1";

    if(formato){
        const parti = formato.split('x');
        if(parti.length === 2){
            const x = parti[0];
            const y = parti[1];
            if(!isNaN(parseFloat(x)) && !isNaN(parseFloat(y))){
                const nX = Math.round(parseFloat(x));
                const nY = Math.round(parseFloat(y));
                esito = `${nX}x${nY}`;
            }
        }
    }

    return esito;
}

/**
 * Trasforma il formato in aspect ratio
 * @param formato Formato da convertire
 */
export function formatoToAspectRatio(formato: string): string{
    const formatoBonificato = bonificaFormato(formato);
    const x = formatoBonificato.split('x')[0].trim();
    const y = formatoBonificato.split('x')[1].trim()
    return `${x} / ${y}`;
}

/**
 * Filtra in modalità configruazione alcune informazioni da non inviare al database o da slavare(ES. Le immagini dei cofanetti non devono essere inviate)
 * @param fotolibro Fotolibro da filtrare
 */
export function filtraFotolibro(fotolibro: FotolibroModel): FotolibroModel{
    const newFotolibro = {...fotolibro};

    //Lavoriazioni con aggiunte
    newFotolibro.informazioniBase.lavorazioniConAggiunte = [];

    //File di copertina
    newFotolibro.copertina.sezioniCopertina = newFotolibro.copertina.sezioniCopertina.filter(
        sezione => sezione[1].tipoSezione === 'materiale' ||
            sezione[1].tipoSezione === 'legno' ||
            sezione[1].tipoSezione === 'plex'
    );
    newFotolibro.copertina.copertinaAlbumRidottoFotoCopertina = undefined;
    newFotolibro.copertina.copertinaAlbumRidottoStampaCodiceUvFlag = false;

    //File di cofanetto
    newFotolibro.custodia.sezioniCustodia = newFotolibro.custodia.sezioniCustodia.filter(
        sezione => sezione[1].tipoSezione === 'materiale' ||
            sezione[1].tipoSezione === 'legno' ||
            sezione[1].tipoSezione === 'plex'
    );
    newFotolibro.custodia.fileAssociatoCofanettoPiccolo = "";
    newFotolibro.custodia.uvAssociatoCofanettoPiccolo = "";

    //Accessori
    newFotolibro.accessori.accessori.forEach(accessorio => {
        if(accessorio[0] === 'Chiavetta')
            accessorio[1].chiavettaUsbUv = '';
    })

    return newFotolibro;
}
