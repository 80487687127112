import {
    AgenteContextResponseModel,
    AgentePostBodyInterface, AgentePutBodyInterface, AgenteResponseModel, FotografoBaseResponseModel,
    Rest,
    UtenteModel
} from "tici_commons";
import Config from "../Static/Config";

export default class AgenteService{
    public static async RegistraAgente(utente: UtenteModel, immagineProfiloBase64: string): Promise<boolean>{
        let esito = false;

        try{
            const body: AgentePostBodyInterface = {
                utente,
                immagineProfiloBase64
            }
            const response = await Rest.Post(
                `${Config.PrivatePath}/Agente`,
                undefined,
                body
            )

            if(response && response.statusCode === 200)
                esito = true;
        }catch (e){
            console.error("Non é stato possibile registrare l'agente", String(e));
        }

        return esito;
    }

    public static async RecuperaAgenti(): Promise<AgenteResponseModel[]>{
        const esito: AgenteResponseModel[] = [];

        try {
            const response = await Rest.Get<AgenteResponseModel[]>(`${Config.PrivatePath}/Agente`);
            if(response && response.statusCode === 200)
                esito.push(...response.response);
        }catch (e){
            console.error("Non é stato possibile recuperare la lista degli agenti", String(e));
        }

        return esito;
    }

    public static async ListaFotografi(): Promise<FotografoBaseResponseModel[]>{
        const esito: FotografoBaseResponseModel[] = [];

        try {
            const response = await Rest.Get<FotografoBaseResponseModel[]>(`${Config.PrivatePath}/ListaFotografi`);
            if(response && response.statusCode === 200)
                esito.push(...response.response);
        }catch (e){
            console.error("Non é stato possibile recuperare i fotografi");
        }

        return esito;
    }

    public static async AssociaFotografi(idAgente: number, listaFotografi: number[]): Promise<boolean> {
        let esito = false;

        try {
            const body: AgentePutBodyInterface = {
                idAgente,
                listaFotografi
            }
            const response = await Rest.Put(
                `${Config.PrivatePath}/Agente`,
                undefined,
                body
            )
            if(response && response.statusCode === 200)
                esito = true;
        }catch (e){
            console.error("Non é stato possibile associare la lista dei fotografi all'agente", String(e));
        }

        return esito;
    }

    public static async AgenteContext(idAgente: number): Promise<AgenteContextResponseModel | undefined>{
        let esito = undefined;

        try{
            const response = await Rest.Get<AgenteContextResponseModel>(`${Config.PrivatePath}/AgenteContext/${idAgente}`);
            if(response && response.statusCode === 200)
                esito = response.response;
        }catch (e){
            console.error("Non é stato possibile recuperare il contesto dell'agente", String(e));
        }

        return esito;
    }
}
