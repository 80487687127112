import {ChiavettaUsbModel, FotolibroModel, ModelliChiavettaData} from "tici_commons";
import React from "react";
import {CheckLine, ImageVisualizer, SelectLine, TextLine} from "../../../../../../Widgets/Configuratore/CComponents";
import IfContainer from "../../../../../../Layout/IfContainer/IfContainer";
import {StorageDataMapper, StorageManagerStatus} from "../../../Storage/StorageManager";
import {
    GenerateGroup,
    GenerateRiepilogoBox, GenerateRiepilogoData, GenerateRiepilogoInternalBox,
    RiepilogoBox,
    RiepilogoData,
    RiepilogoInternalBox
} from "../../../Commons/RiepilogoDatoModel";
import SelettoreUv from "../../../../../../Primary/SelettoreUv/SelettoreUv";
import CategorySectionLayout from "../../../../../../Layout/CategorySectionLayout/CategorySectionLayout";
import SezioneAccessoriGenerica from "../SezioneAccessoriGenerica";

export default class SezioneChiavettaUsb extends SezioneAccessoriGenerica<'Chiavetta', {}, {}> {
    public static ErrorMessages = {
        ModelloAssenteError: "Il modello non è stato selezionato",
        UvAssenteError: "L'uv non è stato selezionato",
        FotoFronteError: "L'immagine frontale non è stata selezionata",
        FotoRetroError: "L'immagine retro non è stata selezionata"
    }

    public generaRiepilogo = (accessorio: ChiavettaUsbModel, indexAccessorio: number, fotolibro: FotolibroModel, storage: StorageManagerStatus, modalitaConfiguratore: boolean): RiepilogoBox | RiepilogoInternalBox | RiepilogoInternalBox[] | RiepilogoData[] | RiepilogoBox[] => {
        return GenerateRiepilogoBox(
            `Chiavetta USB ${indexAccessorio + 1}`,
            GenerateRiepilogoInternalBox(
                `Chiavetta USB ${indexAccessorio + 1}`,
                GenerateRiepilogoData(
                    `Modello: ${accessorio.modelloChiavettaUsb}`,
                    SezioneChiavettaUsb.ErrorMessages.ModelloAssenteError,
                    accessorio.modelloChiavettaUsb.length === 0
                ),
                ...GenerateGroup(
                    storage && accessorio.modelloChiavettaUsb.toLowerCase().includes("card"),
                    storage && GenerateRiepilogoData(
                        `Foto fronte: ${storage.GetStorageData(`Accessorio-${indexAccessorio}-${StorageDataMapper.FotoUvFronte}`)[0] ? 
                            storage.GetStorageData(`Accessorio-${indexAccessorio}-${StorageDataMapper.FotoUvFronte}`)[0].name : ''}`,
                        SezioneChiavettaUsb.ErrorMessages.FotoFronteError,
                        storage.GetStorageData(`Accessorio-${indexAccessorio}-${StorageDataMapper.FotoUvFronte}`).length === 0
                    ),
                    storage && GenerateRiepilogoData(
                        `Foto retro: ${storage.GetStorageData(`Accessorio-${indexAccessorio}-${StorageDataMapper.FotoUvRetro}`)[0] ? 
                            storage.GetStorageData(`Accessorio-${indexAccessorio}-${StorageDataMapper.FotoUvRetro}`)[0].name : ''}`,
                        SezioneChiavettaUsb.ErrorMessages.FotoRetroError,
                        storage.GetStorageData(`Accessorio-${indexAccessorio}-${StorageDataMapper.FotoUvRetro}`).length === 0
                    )
                ),
                ...GenerateGroup(
                    accessorio.chiavettaUsbBoxFlag,
                    GenerateRiepilogoData("Box chiavetta previsto"),
                    GenerateRiepilogoData(
                        `Uv: ${accessorio.chiavettaUsbUv}`,
                        SezioneChiavettaUsb.ErrorMessages.UvAssenteError,
                        accessorio.chiavettaUsbUv.length === 0
                    ),
                    GenerateRiepilogoData(
                        `Testo uv box: ${accessorio.testoUvBoxChiavetta}`,
                        undefined, undefined, accessorio.testoUvBoxChiavetta.trim().length !== 0
                    )
                )
            )
        )
    }

    public render() {
        return (
            <CategorySectionLayout categoryLabel={`Chiavetta usb ${this.props.indexAccessorio + 1}`} size={"largeRelative"} center={true}>
                <SelectLine
                    label={"Modello chiavetta"}
                    placeHolder={"Seleziona"}
                    elements={ModelliChiavettaData.map(value => ({label: value}))}
                    value={this.$.modelloChiavettaUsb}
                    onChange={v => this.$.modelloChiavettaUsb = v}
                    errorMessage={SezioneChiavettaUsb.ErrorMessages.ModelloAssenteError}
                    showError={this.$.modelloChiavettaUsb.length === 0}/>
                <IfContainer condition={this.$.modelloChiavettaUsb.toLowerCase().includes("card")}>
                    <IfContainer condition={this.CountImmagini(StorageDataMapper.FotoUvFronte) === 1}>
                        <ImageVisualizer
                            storageData={this.ImmaginiDaCategoria(StorageDataMapper.FotoUvFronte)[0]?? undefined}
                            onDelete={() => this.DeleteImmagine(StorageDataMapper.FotoUvFronte)}/>
                    </IfContainer>
                    <TextLine
                        label={"Foto uv fronte"}
                        type={"file"}
                        errorMessage={SezioneChiavettaUsb.ErrorMessages.FotoFronteError}
                        showError={this.CountImmagini(StorageDataMapper.FotoUvFronte) === 0}
                        value={this.ImmaginiDaCategoria(StorageDataMapper.FotoUvFronte)[0]?.name?? ""}
                        onFileUpload={(fileName, fileType, file) => {
                            if(["png", "jpg"].includes(fileType)){
                                this.DeleteImmagine(StorageDataMapper.FotoUvFronte);
                                this.UplodaImmagine(StorageDataMapper.FotoUvFronte, fileName, file);
                            }
                        }}/>
                    <IfContainer condition={this.CountImmagini(StorageDataMapper.FotoUvRetro) === 1}>
                        <ImageVisualizer
                            storageData={this.ImmaginiDaCategoria(StorageDataMapper.FotoUvRetro)[0]?? undefined}
                            onDelete={() => this.DeleteImmagine(StorageDataMapper.FotoUvRetro)}/>
                    </IfContainer>
                    <TextLine
                        label={"Foto uv retro"}
                        type={"file"}
                        errorMessage={SezioneChiavettaUsb.ErrorMessages.FotoRetroError}
                        showError={this.CountImmagini(StorageDataMapper.FotoUvRetro) === 0}
                        value={this.ImmaginiDaCategoria(StorageDataMapper.FotoUvRetro)[0]?.name?? ""}
                        onFileUpload={(fileName, fileType, file) => {
                            if(["png", "jpg"].includes(fileType)){
                                this.DeleteImmagine(StorageDataMapper.FotoUvRetro);
                                this.UplodaImmagine(StorageDataMapper.FotoUvRetro, fileName, file);
                            }
                        }}/>
                </IfContainer>
                <CheckLine
                    label={"Box"}
                    checkType={"switch"}
                    checked={this.$.chiavettaUsbBoxFlag}
                    onChange={v => this.$.chiavettaUsbBoxFlag = v}
                    reduce={true}/>
                <IfContainer condition={this.$.chiavettaUsbBoxFlag}>
                    <SelettoreUv
                        label={"Uv"}
                        placeHolder={"Seleziona"}
                        showError={this.$.chiavettaUsbUv.length === 0}
                        errorMessage={SezioneChiavettaUsb.ErrorMessages.UvAssenteError}
                        currentImageName={this.$.chiavettaUsbUv}
                        currentImage={this.ImmaginiDaCategoria(StorageDataMapper.UvChiavettaUsb)[0]?? undefined}
                        onChange={(imageName, file, base64) => {
                            if(imageName){
                                this.$.chiavettaUsbUv = imageName;
                                this.DeleteImmagine(StorageDataMapper.UvChiavettaUsb);
                                if(base64)
                                    this.UplodaImmagine(StorageDataMapper.UvChiavettaUsb, imageName, undefined, base64);
                                else this.UplodaImmagine(StorageDataMapper.UvChiavettaUsb, imageName, file);
                            }else{
                                this.$.chiavettaUsbUv = "";
                                this.DeleteImmagine(StorageDataMapper.UvChiavettaUsb);
                            }
                        }}/>
                    <TextLine
                        label={"Testo uv box"}
                        value={this.$.testoUvBoxChiavetta}
                        onChange={v => this.$.testoUvBoxChiavetta = v}/>
                </IfContainer>
            </CategorySectionLayout>
        );
    }
}
