import React from "react";

export interface SessionBridgeInterface{
    hasData: (key: string) => boolean,
    setData: (key: string, data: string) => void,
    getData: (key: string) => string | undefined,
    deleteData: (key: string) => void
}

export const SessionBridgeContext = React.createContext<SessionBridgeInterface>(undefined);

export default class SessionBridge extends React.Component<{children: any}, SessionBridgeInterface>{
    private _data: {[key: string]: string} = {};                //Dati memorizzati nel bridge

    constructor(props: Readonly<{ children: any }> | { children: any }) {
        super(props);
        this.state = {
            setData: this._setData.bind(this),
            getData: this._getData.bind(this),
            deleteData: this._deleteData.bind(this),
            hasData: this._hasData.bind(this)
        }
    }

    /**
     * Controlla se il dato è presente nel SessionBridge
     * @param key Chiave di ricerca
     * @private
     */
    private _hasData(key: string): boolean{
        return !!this._data[key];
    }

    /**
     * Setta un nuovo dato all'interno del SessionBridge
     * @param key Chiave di ricerca
     * @param value Valore da inserire associato alla chiave
     * @private
     */
    private _setData(key: string, value: string){
        this._data[key] = value;
    }

    /**
     * Restituisce se presente il dato associato alla chiave di ricerca inserita
     * @param key Chiave di ricerca
     * @private
     */
    private _getData(key: string): string | undefined{
        return this._data[key];
    }

    /**
     * Elimina un dato dal SessionBridge
     * @param key Chiave di eliminazione
     * @private
     */
    private _deleteData(key: string){
        this._data[key] = undefined;
    }

    public render() {
        return (
            <SessionBridgeContext.Provider value={this.state}>
                {this.props.children}
            </SessionBridgeContext.Provider>
        )
    }
}
