import React from "react";
import "./ResponsiveLabel.scss";

export type ResponsiveLabelType =
    "separatorLabel" |
    "recapLabel" |
    "errorLabel" |
    "warningLabel" |
    "whiteLabel" |
    "leftOnMobile" |
    "cardAgenteInfo" |
    "_404" |
    "footer" |
    "footerHp"

export interface ResponsiveLabelProps{
    content: string,
    type: "xxlarge" | "xlarge" | "large" | "xmedium" | "medium" | "xsmall" | "small",
    alignment: "left" | "center" | "right",
    labelType?: ResponsiveLabelType,
    italic?: boolean,
    bold?: boolean,
    uppercase?: boolean,
    selezionabile?: boolean
}
export default class ResponsiveLabel extends React.Component<ResponsiveLabelProps, {}>{
    public render() {
        return (
            <span className={`ResponsiveLabel ${this.props.uppercase && 'uppercase'} ${this.props.bold && 'bold'} ${this.props.italic && 'italic'} ${this.props.labelType} ${this.props.type} ${this.props.alignment} ${this.props.selezionabile && 'testoSelezionabile'}`}>{this.props.content}</span>
        );
    }
}
