import "./CardFotografo.scss";

import React from "react";
import {RecapElement} from "../../Widgets/Configuratore/CComponents";
import {FotografoContextResponseModel, RegioniEnum} from "tici_commons";
import UserService from "../../Servicies/UserService";
import ImageService from "../../Servicies/ImageService";
import ResponsiveLabel from "../../Core/ResponsiveLabel/ResponsiveLabel";
import ContainerLayout from "../../Layout/ContainerLayout/ContainerLayout";
import SentenceLayout from "../../Layout/SentenceLayout/SentenceLayout";
import VerticalSpace from "../../Layout/VerticalSpace/VerticalSpace";
import ElementSelect from "../Selects/ElementSelect/ElementSelect";
import TopLevelComponentStorage from "../../Core/Arch/TopLevelComponentStorage";
import IfContainer from "../../Layout/IfContainer/IfContainer";

export interface CardFotografoProps {
    fotografo: FotografoContextResponseModel,
    modalitaRidotta?: boolean,
    updateFotografi?: () => void,
    onConfirm?: () => void,
    onReject?: () => void,
    onDelete?: () => void,
}
export default class CardFotografo extends React.Component<CardFotografoProps, {}>{
    private _Line(label: string, value: string){
        return (
            <RecapElement
                value={`${label}: ${value}`}
                messageType={"recap"}/>
        )
    }

    /**
     * Disgna un button della card
     * @param img Immagine da visualizzare sulla card
     * @param onClick Evento click
     * @private
     */
    private _Button(img: string, onClick?: () => void){
        return (
            <img
                onClick={() => {onClick && onClick()}}
                className={"HeaderButton"}
                alt={"ButtonIcon"}
                src={img}/>
        )
    }

    /**
     * Aggiorna la locazione del fotografo
     * @param locazione Locazione da impostare
     * @private
     */
    private async _aggiornaLocazione(locazione: string){
        TopLevelComponentStorage.GetTopLevel('loadingWindow').showLoadingWindow("Aggiornamento locazione", "Stiamo aggiornando la locazione del fotografo");
        const esito = await UserService.ModificaLocazioneFotografo(this.props.fotografo.identificativo, locazione);
        TopLevelComponentStorage.GetTopLevel('loadingWindow').hideLoadingWindow();

        if(esito && this.props.updateFotografi)
            this.props.updateFotografi();

        TopLevelComponentStorage.GetTopLevel('confirmWindow').showConfirmWindow(
            esito ? 'Aggiornamento concluso' : 'Aggiornamento non concluso',
            esito ? 'La locazione del fotografo é stata aggiornata' : 'La locazione del fotografo non é stata aggiornata'
        );
    }

    public render() {
        return (
            <div className={"CardFotografoContainer"}>
                <span className={`CardFotografoHeader ${this.props.modalitaRidotta && 'modalitaRidotta'}`}>
                    <div
                        className={"ProfileImage"}
                        style={{backgroundImage: `url("${UserService.UserImageUrl(this.props.fotografo.email, this.props.fotografo.immagineProfilo)}")`}}/>
                    <ContainerLayout
                        gap={false}
                        size={"largeRelative"}>
                        <ResponsiveLabel
                            content={this.props.fotografo.nomeStudio}
                            type={"xmedium"}
                            alignment={"left"}
                            labelType={"whiteLabel"}/>
                        <SentenceLayout alignment={"left"}>
                            <ResponsiveLabel
                                content={this.props.fotografo.nome}
                                type={"medium"}
                                alignment={"left"}
                                labelType={"whiteLabel"}/>
                            <ResponsiveLabel
                                content={this.props.fotografo.cognome}
                                type={"medium"}
                                alignment={"left"}
                                labelType={"whiteLabel"}/>
                        </SentenceLayout>
                        <IfContainer condition={this.props.fotografo.nuovoCliente}>
                            <ResponsiveLabel
                                content={`*${this.props.fotografo.nome} ${this.props.fotografo.cognome} é un nuovo cliente TICI*`}
                                type={"medium"}
                                alignment={"left"}
                                labelType={"whiteLabel"}
                                bold={true}/>
                        </IfContainer>
                        <IfContainer condition={this.props.modalitaRidotta}>
                            <ResponsiveLabel
                                content={this.props.fotografo.regioneAppartenenza}
                                type={"xmedium"}
                                uppercase={true}
                                alignment={"right"}
                                labelType={"whiteLabel"}/>
                        </IfContainer>
                    </ContainerLayout>
                    <IfContainer condition={!this.props.modalitaRidotta}>
                        <ContainerLayout
                            gap={false}
                            size={"largeRelative"}>
                            <ResponsiveLabel
                                content={"Fotografo"}
                                type={"medium"}
                                alignment={"right"}
                                labelType={"whiteLabel"}/>
                            <VerticalSpace gapMobile={4} gapTablet={6} gapDesktop={6}/>
                            <SentenceLayout alignment={"right"}>
                                {this._Button(ImageService.getImage(this.props.fotografo.statoAttivazione === 0 ? 'approveIcon' : 'disapproveIcon'), () => {
                                    this.props.fotografo.statoAttivazione === 1 ? (this.props.onReject && this.props.onReject()) : (this.props.onConfirm && this.props.onConfirm())
                                })}
                                {this._Button(ImageService.getImage('trashIcon'), () => {this.props.onDelete && this.props.onDelete()})}
                            </SentenceLayout>
                        </ContainerLayout>
                    </IfContainer>

                </span>
                <IfContainer condition={!this.props.modalitaRidotta}>
                    <span className={"CardFotografoBody"}>
                        {this._Line("Email", this.props.fotografo.email)}
                        {this._Line("Cellulare", this.props.fotografo.numeroTelefono)}
                        {this._Line("CF", this.props.fotografo.codiceFiscale)}
                        {this._Line("P. IVA", this.props.fotografo.partitaIva)}
                        {this._Line("Indirizzo", this.props.fotografo.indirizzoStudio)}
                        {this._Line("Codice fatture", this.props.fotografo.codiceUnivocoFatture)}
                        <ElementSelect
                            label={"Locazione"}
                            value={this.props.fotografo.regioneAppartenenza?? ''}
                            onChange={v => this._aggiornaLocazione(v)}
                            elements={Object.values(RegioniEnum).map(regione => ({label: regione}))}/>
                    </span>
                </IfContainer>
            </div>
        );
    }
}
