import React, {Fragment} from "react";
import {ConfigurazioneModelloInterface} from "tici_commons";
import Responsive3Col from "../../../../../Layout/Responsive3Col/Responsive3Col";
import {
    CheckLine,
    ScrollZone,
    ScrollZoneElement,
    SelectLine,
    TextLine
} from "../../../../../Widgets/Configuratore/CComponents";
import IfContainer from "../../../../../Layout/IfContainer/IfContainer";
import Button from "../../../../../Core/Buttons/Button";
import ResponsiveLabel from "../../../../../Core/ResponsiveLabel/ResponsiveLabel";
import SectionLayout from "../../../../../Layout/SectionLayout/SectionLayout";
import Formati from "../../../../../DatabaseData/Formati";

export interface MenuModelloState{
    famigliaSelezionata: string,
    formatoSelezionato: string
}

export interface MenuModelloProps {
    configurazioneModello: ConfigurazioneModelloInterface,
    onChange: (configurazioneModello: ConfigurazioneModelloInterface) => void,
    listaFamiglie: string[]
}

export default class MenuModello extends React.Component<MenuModelloProps, MenuModelloState> {
    constructor(props: Readonly<MenuModelloProps> | MenuModelloProps) {
        super(props);
        this.state = {
            famigliaSelezionata: "",
            formatoSelezionato: ""
        }
    }

    /**
     * Aggiorna la configurazione del modello
     * @param key Chiave del valore da modificare
     * @param value Valore da inserire al posto della chiave
     * @private
     */
    private _updateConfigurazioneModello<T extends keyof ConfigurazioneModelloInterface>(key: T, value: ConfigurazioneModelloInterface[T]){
        if(this.props.configurazioneModello){
            const newConfigurazione = {...this.props.configurazioneModello, [key]: value};
            this.props.onChange && this.props.onChange(newConfigurazione);
        }
    }

    /**
     * Aggiunge una nuova famiglia da escludere
     * @private
     */
    private _aggiungiFamigliaEsclusa(){
        if(this.state.famigliaSelezionata && !this.props.configurazioneModello.materialiEsclusi.includes(this.state.famigliaSelezionata)){
            const materialiEsclusi = [...this.props.configurazioneModello.materialiEsclusi, this.state.famigliaSelezionata];
            this.setState({famigliaSelezionata: ""});
            this._updateConfigurazioneModello("materialiEsclusi", materialiEsclusi);
        }
    }

    /**
     * Aggiunge una nuovo vincolo di formato
     * @private
     */
    private _aggiungiVincoloFormato(){
        if(this.state.formatoSelezionato && !this.props.configurazioneModello.vincoliFormati.includes(Formati.GetIdFromFormato(this.state.formatoSelezionato))){
            const vincoliFormato = [...this.props.configurazioneModello.vincoliFormati, Formati.GetIdFromFormato(this.state.formatoSelezionato)];
            this.setState({formatoSelezionato: ""});
            this._updateConfigurazioneModello("vincoliFormati", vincoliFormato);
        }
    }

    /**
     * Rimuove la famiglia dalla lista di quelle escluse
     * @param famiglia Famiglia da escludere dalla lista
     * @private
     */
    private _rimuoviFamigliaEsclusa(famiglia: string){
        const materialiEsclusi = this.props.configurazioneModello.materialiEsclusi.filter(v => v !== famiglia);
        this._updateConfigurazioneModello("materialiEsclusi", materialiEsclusi);
    }

    /**
     * Rimuove un vincolo di formato
     * @param vincoloFormato Vincolo del formato da rimuovere
     * @private
     */
    private _rimuoviVincoloFormato(vincoloFormato: number){
        const vincoliFormato = this.props.configurazioneModello.vincoliFormati.filter(v => v !== vincoloFormato);
        this._updateConfigurazioneModello("vincoliFormati", vincoliFormato);
    }

    private _SezioneGestioneFamiglie(){
        return (
            <Fragment>
                <IfContainer condition={this.props.listaFamiglie && this.props.listaFamiglie.length > 0}>
                    <SelectLine
                        label={"Lista famiglie"}
                        placeHolder={"Seleziona"}
                        value={this.state.famigliaSelezionata}
                        elements={this.props.listaFamiglie && this.props.listaFamiglie.map(value => ({label: value}))}
                        onChange={value => this.setState({famigliaSelezionata: value})}/>
                </IfContainer>
                <IfContainer condition={!!this.state.famigliaSelezionata}>
                    <Button
                        content={"Escludi famiglia"}
                        type={"medium"}
                        buttonType={"full-normal"}
                        onClick={this._aggiungiFamigliaEsclusa.bind(this)}/>
                </IfContainer>
                <IfContainer condition={this.props.configurazioneModello.materialiEsclusi.length > 0}>
                    <ResponsiveLabel content={"Materiali esclusi:"} type={"medium"} alignment={"center"}/>
                    <ScrollZone>{
                        this.props.configurazioneModello.materialiEsclusi.map(
                            famiglia => (
                                <ScrollZoneElement key={famiglia} content={famiglia} onDeleteClick={() => this._rimuoviFamigliaEsclusa(famiglia)}/>
                            )
                        )
                    }</ScrollZone>
                </IfContainer>
            </Fragment>
        )
    }

    private _SezioneGestioneFormati(){
        return (
            <Fragment>
                <SelectLine
                    label={"Lista formati"}
                    placeHolder={"Seleziona"}
                    value={this.state.formatoSelezionato}
                    elements={Formati.GetFormati("*")}
                    onChange={value => this.setState({formatoSelezionato: value})}/>
                <IfContainer condition={!!this.state.formatoSelezionato}>
                    <Button
                        content={"Vincola a questo formato"}
                        type={"medium"}
                        buttonType={"full-normal"}
                        onClick={this._aggiungiVincoloFormato.bind(this)}/>
                </IfContainer>
                <IfContainer condition={this.props.configurazioneModello.vincoliFormati.length > 0}>
                    <ResponsiveLabel content={"Vincoli sui formati:"} type={"medium"} alignment={"center"}/>
                    <ScrollZone>{
                        this.props.configurazioneModello.vincoliFormati.map(
                            formato => (
                                <ScrollZoneElement key={formato} content={Formati.GetFromatoFromId(formato)} onDeleteClick={() => this._rimuoviVincoloFormato(formato)}/>
                            )
                        )
                    }</ScrollZone>
                </IfContainer>
            </Fragment>
        )
    }

    public render() {
        return (
            this.props.configurazioneModello &&
            <SectionLayout size={"largeRelative"} addPadding={true} showBorder={true} lightBackground={true}>
                <Responsive3Col>
                    <TextLine
                        label={"RotazioneX"}
                        type={"number"}
                        min={0}
                        max={360}
                        value={this.props.configurazioneModello.rotazioneX.toString()}
                        onChange={value => this._updateConfigurazioneModello("rotazioneX", parseInt(value))}/>
                    <TextLine
                        label={"RotazioneY"}
                        type={"number"}
                        min={0}
                        max={360}
                        value={this.props.configurazioneModello.rotazioneY.toString()}
                        onChange={value => this._updateConfigurazioneModello("rotazioneY", parseInt(value))}/>
                    <TextLine
                        label={"RotazioneZ"}
                        type={"number"}
                        min={0}
                        max={360}
                        value={this.props.configurazioneModello.rotazioneZ.toString()}
                        onChange={value => this._updateConfigurazioneModello("rotazioneZ", parseInt(value))}/>
                </Responsive3Col>
                <CheckLine
                    label={"Rendi quadrato"}
                    checkType={"switch"}
                    checked={this.props.configurazioneModello.partiQuadratoFlag}
                    onChange={value => this._updateConfigurazioneModello("partiQuadratoFlag", value)}/>
                <TextLine
                    label={"Distanza camera"}
                    value={this.props.configurazioneModello.distanzaInizialeCamera.toString()}
                    type={"number"}
                    onChange={value => isNaN(parseInt(value)) ? this._updateConfigurazioneModello("distanzaInizialeCamera", 0) : this._updateConfigurazioneModello('distanzaInizialeCamera', parseInt(value))}/>
                <SelectLine
                    label={"Asse X"}
                    placeHolder={"Seleziona"}
                    elements={[{label: "X"}, {label: "Y"}, {label: "Z"}]}
                    value={this.props.configurazioneModello.asseModificaX}
                    onChange={value => this._updateConfigurazioneModello("asseModificaX", value as "X" | "Y" | "Z")}/>
                <SelectLine
                    label={"Asse Y"}
                    placeHolder={"Seleziona"}
                    elements={[{label: "X"}, {label: "Y"}, {label: "Z"}]}
                    value={this.props.configurazioneModello.asseModificaY}
                    onChange={value => this._updateConfigurazioneModello("asseModificaY", value as "X" | "Y" | "Z")}/>
                {this._SezioneGestioneFamiglie()}
                {this._SezioneGestioneFormati()}
            </SectionLayout>
        )
    }
}
