import React from "react";
import './ChatLine.scss';
import {ChatPersonaleResponseModel} from "tici_commons";
import ResponsiveLabel from "../../Core/ResponsiveLabel/ResponsiveLabel";
import ChatIcon from "../ChatIcon/ChatIcon";
import {ChatContext} from "../ChatOverlay/ChatContainer/ChatContainer";
import Button from "../../Core/Buttons/Button";
import SentenceLayout from "../../Layout/SentenceLayout/SentenceLayout";
import LocalStorageService from "../../Servicies/LocalStorageService";
import RedirectService from "../../Servicies/RedirectService";

export interface ChatLineProps{
    chat: ChatPersonaleResponseModel
}

export default class ChatLine extends React.Component<ChatLineProps, {}>{
    /**
     * Va all'ordine partendo dalla chat
     * @param idOrdine Id dell'ordine
     * @private
     */
    private _goToOrderFromIdOrdine(idOrdine: number){
        LocalStorageService.RedirectOrdineNotifica = idOrdine;
        RedirectService.GoToOrdini();
    }

    public render() {
        return (
            <ChatContext.Consumer>{
                chatContext => (
                    <span className={"ChatLine"} onClick={() => chatContext.openChat(this.props.chat.idOrdine, this.props.chat.nomeOrdine)}>
                        <SentenceLayout alignment={"left"}>
                            <ResponsiveLabel
                                content={`${this.props.chat.idOrdine}) ${this.props.chat.nomeOrdine} - ${this.props.chat.nomeStudio} (${this.props.chat.numeroMessaggi} messaggi)`}
                                type={"medium"}
                                alignment={"left"}
                                labelType={"whiteLabel"}/>
                            <Button
                                content={"Vai all'ordine"}
                                type={"medium"}
                                buttonType={"simple-light"}
                                onClick={() => this._goToOrderFromIdOrdine(this.props.chat.idOrdine)}/>
                        </SentenceLayout>
                        <ChatIcon ordineId={this.props.chat.idOrdine}/>
                    </span>
                )
            }</ChatContext.Consumer>
        );
    }
}
