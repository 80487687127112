import React, {Fragment} from "react";
import ResponsiveLabel from "../../../Core/ResponsiveLabel/ResponsiveLabel";
import SectionLayout from '../../../Layout/SectionLayout/SectionLayout';
import IfContainer from "../../../Layout/IfContainer/IfContainer";
import Button from "../../../Core/Buttons/Button";
import {TextLine, ImageVisualizer} from "../../../Widgets/Configuratore/CComponents";
import {
    FotografoContextResponseModel, ModificaUtenteRoutePutBodyInterface,
    PasswordRegex, PVRegex,
    TelefonoRegex,
    UserContextResponseModel
} from "tici_commons";
import TopLevelComponentStorage from "../../../Core/Arch/TopLevelComponentStorage";
import RedirectService from "../../../Servicies/RedirectService";
import UserService from "../../../Servicies/UserService";
import FotografoService from "../../../Servicies/FotografoService";
import LabelLoader from "../../../Primary/LabelLoader/LabelLoader";
import SentenceLayout from "../../../Layout/SentenceLayout/SentenceLayout";
import TwoSpaceLine from "../../../Layout/TwoSpaceLine/TwoSpaceLine";
import Checkbox from "../../../Core/Checkbox/Checkbox/Checkbox";

export interface ModificaUtenteStatoForm extends ModificaUtenteRoutePutBodyInterface{
    rPassword: string,
}

export interface ModificaUtenteState extends ModificaUtenteStatoForm{
    isLoading: boolean,
    hasError: boolean,
    contestoUtente: UserContextResponseModel,
    contestoFotografo: FotografoContextResponseModel
} 

export default class ModificaUtente extends React.Component<{}, ModificaUtenteState> {
    private _immagineProfiloBase64: string;

    public constructor(props: Readonly<{}> | {}) {
        super(props);
        this.state = {
            isLoading: true,
            hasError: false,
            contestoUtente: undefined,
            contestoFotografo: undefined,
            immagineProfilo: "",
            password: "",
            rPassword: "",
            numeroTelefono: "",
            indirizzoStudio: "",
            codiceUnivocoFatture: "",
            nomeStudio: "",
            partitaIva: "",
            privacyAccettata: false,
        }

        this._init();
    }

    /**
     * Inizializza le informazioni dell'account
     * @private
     */
    private async _init(){
        try{
            let hasError = true;
            const userContext = await UserService.GetUserContext();
            let fotografoContext = undefined;
            if(userContext){
                if(userContext.tipoUtente === "fotografo"){
                    fotografoContext = await FotografoService.GetFotografoContext();
                    hasError = !fotografoContext;
                }else hasError = false;
            }

            if(!hasError){
                this.setState({
                    isLoading: false,
                    hasError: false,
                    contestoUtente: userContext,
                    contestoFotografo: fotografoContext as FotografoContextResponseModel,
                    privacyAccettata: userContext.privacyAccettata
                })
            }else{
                this.setState({isLoading: false, hasError: true});
                console.error("Errore recupero contesto");
            }
        }catch (e){
            this.setState({isLoading: false, hasError: true});
            console.error("Errore recupero contesto", String(e));
        }
    }

    /**
     * Controlla la validità dei campi di input
     * @private
     */
    private _isValid(): boolean{
        let esito = false;
        if(
            this.state.password ||
            this.state.indirizzoStudio ||
            this.state.numeroTelefono ||
            this.state.immagineProfilo ||
            this.state.codiceUnivocoFatture ||
            this.state.nomeStudio ||
            this.state.partitaIva ||
            this.state.privacyAccettata !== this.state?.contestoUtente?.privacyAccettata
        ){
            esito = true;
            if(this.state.password){
                esito &&= this.state.password === this.state.rPassword;
                esito &&= PasswordRegex.test(this.state.password);
            }
            if(this.state.numeroTelefono){
                esito &&= TelefonoRegex.test(this.state.numeroTelefono);
            }
            if(this.state.partitaIva){
                esito &&= PVRegex.test(this.state.partitaIva);
            }
        }

        return esito;
    }

    /**
     * Modifichiamo le informazioni dell'account
     * @private
     */
    private async _modificaAccount(){
        TopLevelComponentStorage.GetTopLevel('loadingWindow').showLoadingWindow("Modifica account", "Stiamo modificando le informazioni del tuo account");
        if(this._isValid()){
            const esito = await UserService.ModificaAccount({
                indirizzoStudio: this.state.indirizzoStudio.length > 0 ? this.state.indirizzoStudio : undefined,
                password: this.state.password.length > 0 ? this.state.password : undefined,
                immagineProfilo: this.state.immagineProfilo.length > 0 ? this.state.immagineProfilo : undefined,
                immagineProfiloBase64: this.state.immagineProfilo.length > 0 ? this._immagineProfiloBase64 : undefined,
                numeroTelefono: this.state.numeroTelefono.length > 0 ? this.state.numeroTelefono : undefined,
                partitaIva: this.state.partitaIva.length > 0 ? this.state.partitaIva : undefined,
                nomeStudio: this.state.nomeStudio.length > 0 ? this.state.nomeStudio : undefined,
                codiceUnivocoFatture: this.state.codiceUnivocoFatture.length > 0 ? this.state.codiceUnivocoFatture : undefined,
                privacyAccettata: this.state.privacyAccettata
            })
            TopLevelComponentStorage.GetTopLevel('loadingWindow').hideLoadingWindow();
            TopLevelComponentStorage.GetTopLevel('confirmWindow')
                .showConfirmWindow(
                    `Modifica ${esito ? 'avvenuta' : 'non avvenuta'}`,
                    esito ?
                        "La modifica delle informazioni dell'account è avvenuta correttamente, verrai fatto uscire dall'account per permetterci di aggiornare le informazioni" :
                        "Non è stato possibile salvare le nuove informazioni dell'account",
                    "SingleButton",
                    () => {
                        if(esito)
                            RedirectService.GoToLogout();
                    }
                )
        }else{
            TopLevelComponentStorage.GetTopLevel('loadingWindow').hideLoadingWindow();
            TopLevelComponentStorage.GetTopLevel('confirmWindow').showConfirmWindow("Errore dati", "I dati inseriti non sono validi");
        }
    }

    private _SezioneCaricamentoErrore(){
        return (
            <Fragment>
                <IfContainer condition={this.state.isLoading}>
                    <LabelLoader label={"Stiamo caricando le informazioni del profilo"}/>
                </IfContainer>
                <IfContainer condition={this.state.hasError}>
                    <ResponsiveLabel content={"Errore nel recupero dei dati dal server"} type={"xmedium"} alignment={"center"}/>
                </IfContainer>
            </Fragment>
        )
    }

    private _SezioneAccountBase() {
        return (
            <Fragment>
                <ImageVisualizer
                    imageData={this.state.immagineProfilo ? this._immagineProfiloBase64 : UserService.UserImageUrl()}
                    onDelete={() => {
                        this._immagineProfiloBase64 = undefined;
                        this.setState({immagineProfilo: ""});
                    }}/>
                <TextLine
                    label={"Immagine profilo"}
                    type={"file"}
                    value={this.state.immagineProfilo.length === 0 ? this.state.contestoUtente.immagineProfilo : this.state.immagineProfilo}
                    onFileUpload={(fileName, fileType, file, base64Encoding) => {
                        if(['png', 'jpeg', 'jpg'].includes(fileType.toLowerCase())){
                            this._immagineProfiloBase64 = base64Encoding;
                            this.setState({immagineProfilo: fileName});
                        }
                    }}/>
                <TextLine
                    label={"Nome"}
                    value={this.state.contestoUtente.nome}
                    readonly={true}/>
                <TextLine
                    label={"Cognome"}
                    value={this.state.contestoUtente.cognome}
                    readonly={true}/>
                <TextLine
                    label={"Email"}
                    value={this.state.contestoUtente.email}
                    readonly={true}/>
                <TextLine
                    label={"Password"}
                    type={"password"}
                    placeHolder={"Mantieni la stessa"}
                    value={this.state.password}
                    onChange={v => this.setState({password: v})}
                    errorMessage={"La password non soddisfa i requisiti"}
                    showError={this.state.password && !PasswordRegex.test(this.state.password)}/>
                <IfContainer condition={!!this.state.password}>
                    <TextLine
                        label={"Ripeti password"}
                        type={"password"}
                        value={this.state.rPassword}
                        onChange={v => this.setState({rPassword: v})}
                        errorMessage={"Le password non coincidono"}
                        showError={this.state.password !== this.state.rPassword}/>
                </IfContainer>
                <TextLine
                    label={"Codice Fiscale"}
                    value={this.state.contestoUtente.codiceFiscale}
                    readonly={true}/>
                <TextLine
                    label={"Numero di telefono"}
                    value={this.state.numeroTelefono}
                    placeHolder={this.state.contestoUtente.numeroTelefono}
                    onChange={v => this.setState({numeroTelefono: v})}
                    errorMessage={"Il numero di telefono non soddisfa i requisiti"}
                    showError={this.state.numeroTelefono && !TelefonoRegex.test(this.state.numeroTelefono)}/>
            </Fragment>
        )
    }

    private _SezioneFotografo() {
        return (
            <Fragment>
                <TextLine
                    label={"Studio"}
                    value={this.state.nomeStudio}
                    placeHolder={this.state.contestoFotografo.nomeStudio}
                    onChange={v => this.setState({nomeStudio: v})}/>
                <TextLine
                    label={"Indirizzo Studio"}
                    value={this.state.indirizzoStudio}
                    placeHolder={this.state.contestoFotografo.indirizzoStudio}
                    onChange={v => this.setState({indirizzoStudio: v})}/>
                <TextLine
                    label={"Partita Iva"}
                    value={this.state.partitaIva}
                    placeHolder={this.state.contestoFotografo.partitaIva}
                    onChange={v => this.setState({partitaIva: v})}
                    showError={this.state.partitaIva.length !== 0 && !PVRegex.test(this.state.partitaIva)}
                    errorMessage={"La partita iva non é formalmente valida"}/>
                <TextLine
                    label={"Codice fatture"}
                    value={this.state.codiceUnivocoFatture}
                    placeHolder={this.state.contestoFotografo.codiceUnivocoFatture}
                    onChange={v => this.setState({codiceUnivocoFatture: v})}/>
            </Fragment>
        )
    }

    public render() {
        return (
            <SectionLayout size={"largeRelative"}>
                <ResponsiveLabel alignment={"center"} content={"Modifica i dati del tuo account"} type={"large"} uppercase={true} bold={true}/>
                <SectionLayout size={"mediumRelative"} showBorder={true} addPadding={true} lightBackground={true}>
                    <IfContainer
                        condition={!this.state.isLoading && !this.state.hasError}
                        elseComponent={this._SezioneCaricamentoErrore()}>
                        {!this.state.isLoading && !this.state.hasError && this._SezioneAccountBase()}
                        {this.state.contestoFotografo && this._SezioneFotografo()}
                        {
                            !this.state.isLoading && !this.state.hasError &&
                            <SentenceLayout alignment={"right"}>
                                <ResponsiveLabel content={"Accetto le politiche di"} type={"medium"} alignment={"right"}/>
                                <Button
                                    content={"privacy"}
                                    type={"medium"}
                                    buttonType={"simple-text"}
                                    onClick={() => window.open('https://www.ticialbum.com/privacy-policy', '_blank')}/>
                                <Checkbox
                                    checked={this.state.privacyAccettata}
                                    onChange={v => {
                                        this.setState({privacyAccettata: v});
                                    }}/>
                            </SentenceLayout>
                        }
                    </IfContainer>
                    <Button
                        content={"Salva"}
                        type={"large"}
                        buttonType={"full-normal"}
                        disabled={!this._isValid()}
                        alignment={"center"}
                        onClick={() => this._modificaAccount()}/>
                </SectionLayout>
            </SectionLayout>
        );
    }
}
