import React, {Fragment} from "react";
import ResponsiveLabel from "../../../../../../Core/ResponsiveLabel/ResponsiveLabel";
import Responsive2Col from "../../../../../../Layout/Responsive2Col/Responsive2Col";
import SectionLayout from "../../../../../../Layout/SectionLayout/SectionLayout";
import {CheckLine, ImageVisualizer, TextLine} from "../../../../../../Widgets/Configuratore/CComponents";
import IfContainer from "../../../../../../Layout/IfContainer/IfContainer";
import SelettoreQuadrato from "../../../../../../Primary/SelettoreQuadrato/SelettoreQuadrato";
import {StorageDataMapper, StorageManagerStatus} from "../../../Storage/StorageManager";
import {
    GenerateGroup, GenerateRiepilogoData,
    GenerateRiepilogoInternalBox,
    RiepilogoBox,
    RiepilogoData,
    RiepilogoInternalBox
} from "../../../Commons/RiepilogoDatoModel";
import SezioneGenerica from "../../SezioneGenerica";
import {FotolibroModel} from "tici_commons";
import CategorySectionLayout from "../../../../../../Layout/CategorySectionLayout/CategorySectionLayout";

export default class SezioneLogo extends SezioneGenerica<"rilegatura", {}, {}> {
    public static ErrorMessages = {
        PosizioneLogoError: "Bisogna selezionare almeno una posizione a destra o a sinistra del logo",
        CaricamentoLogoError: "Il logo non è stato caricato"
    }

    public generaRiepilogo = (fotolibro: FotolibroModel, storage: StorageManagerStatus, modalitaConfiguratore: boolean): RiepilogoInternalBox | RiepilogoData[] | RiepilogoBox | RiepilogoInternalBox[] => {
        return GenerateRiepilogoInternalBox(
            'Logo su fodera',
            ...GenerateGroup(
                fotolibro.rilegatura.logoFoderaInizioFlag,
                GenerateRiepilogoData(
                    undefined,
                    SezioneLogo.ErrorMessages.PosizioneLogoError,
                    true,
                    !!(fotolibro.rilegatura.logoFoderaInizioFacciataSinistra === 0 &&
                    fotolibro.rilegatura.logoFoderaInizioFacciataDestra === 0 &&
                    fotolibro.rilegatura.logoFoderaInizioFlag)
                ),
                ...GenerateGroup(
                    !(fotolibro.rilegatura.logoFoderaInizioFacciataSinistra === 0 &&
                    fotolibro.rilegatura.logoFoderaInizioFacciataDestra === 0 &&
                    fotolibro.rilegatura.logoFoderaInizioFlag),
                    GenerateRiepilogoData("Logo all'inizio"),
                    GenerateRiepilogoData(`Posizione sinistra: ${fotolibro.rilegatura.logoFoderaInizioFacciataSinistra}, posizione destra: ${fotolibro.rilegatura.logoFoderaInizioFacciataDestra}`)
                ),
                GenerateRiepilogoData(
                    `Specifiche di testo: ${fotolibro.rilegatura.logoFoderaInizioSpecificheTesto}`,
                    undefined,
                    undefined,
                    fotolibro.rilegatura.logoFoderaInizioSpecificheTesto.trim().length > 0
                ),
                GenerateRiepilogoData(
                    `Specifiche di posizione: ${fotolibro.rilegatura.logoFoderaInizioSpecifichePosizione}`,
                    undefined,
                    undefined,
                    fotolibro.rilegatura.logoFoderaInizioSpecifichePosizione.trim().length > 0
                )
            ),
            ...GenerateGroup(
                fotolibro.rilegatura.logoFoderaFineFlag,
                GenerateRiepilogoData(
                    undefined,
                    SezioneLogo.ErrorMessages.PosizioneLogoError,
                    true,
                    !!(fotolibro.rilegatura.logoFoderaFineFacciataSinistra === 0 &&
                        fotolibro.rilegatura.logoFoderaFineFacciataDestra === 0 &&
                        fotolibro.rilegatura.logoFoderaFineFlag)
                ),
                ...GenerateGroup(
                    !(fotolibro.rilegatura.logoFoderaFineFacciataSinistra === 0 &&
                        fotolibro.rilegatura.logoFoderaFineFacciataDestra === 0 &&
                        fotolibro.rilegatura.logoFoderaFineFlag),
                    GenerateRiepilogoData("Logo alla fine"),
                    GenerateRiepilogoData(`Posizione sinistra: ${fotolibro.rilegatura.logoFoderaFineFacciataSinistra}, posizione destra: ${fotolibro.rilegatura.logoFoderaFineFacciataDestra}`)
                ),
                GenerateRiepilogoData(
                    `Specifiche di testo: ${fotolibro.rilegatura.logoFoderaFineSpecificheTesto}`,
                    undefined,
                    undefined,
                    fotolibro.rilegatura.logoFoderaFineSpecificheTesto.trim().length > 0
                ),
                GenerateRiepilogoData(
                    `Specifiche di posizione: ${fotolibro.rilegatura.logoFoderaInizioSpecifichePosizione}`,
                    undefined,
                    undefined,
                    fotolibro.rilegatura.logoFoderaFineSpecifichePosizione.trim().length > 0
                )
            ),
            ...GenerateGroup(
                fotolibro.rilegatura.logoFoderaInizioFlag || fotolibro.rilegatura.logoFoderaFineFlag,
                GenerateRiepilogoData(
                    undefined,
                    SezioneLogo.ErrorMessages.CaricamentoLogoError,
                    true,
                    storage && storage.GetStorageData(StorageDataMapper.LogoFodera).length === 0
                )
            )
        )
    }

    private _SezioneLogo(inizio: boolean){
        return (
            <SectionLayout size={"largeRelative"}>
                <CheckLine
                    label={inizio ? "Logo all'inizio" : "Logo alla fine"}
                    checkType={"switch"}
                    checked={inizio ? this.$.logoFoderaInizioFlag : this.$.logoFoderaFineFlag}
                    onChange={v => this.$[`logoFodera${inizio ? 'Inizio' : 'Fine'}Flag`] = v}
                    showError={
                        inizio ?
                            this.$.logoFoderaInizioFacciataSinistra === 0 &&
                            this.$.logoFoderaInizioFacciataDestra === 0 &&
                            this.$.logoFoderaInizioFlag :
                            this.$.logoFoderaFineFacciataSinistra === 0 &&
                            this.$.logoFoderaFineFacciataDestra === 0 &&
                            this.$.logoFoderaFineFlag
                    }
                    errorMessage={SezioneLogo.ErrorMessages.PosizioneLogoError}/>
                <IfContainer condition={inizio ? this.$.logoFoderaInizioFlag : this.$.logoFoderaFineFlag}>
                    <Responsive2Col>
                        <SectionLayout size={"largeRelative"}>
                            <ResponsiveLabel
                                content={`Posizione del logo pagina sinistra: ${inizio ? this.$.logoFoderaInizioFacciataSinistra : this.$.logoFoderaFineFacciataSinistra}`}
                                type={"medium"}
                                alignment={"center"}/>
                            <SelettoreQuadrato
                                onChange={v => this.$[`logoFodera${inizio ? 'Inizio' : 'Fine'}FacciataSinistra`] = v}
                                value={(inizio ? this.$.logoFoderaInizioFacciataSinistra : this.$.logoFoderaFineFacciataSinistra) as any}/>
                        </SectionLayout>
                        <SectionLayout size={"largeRelative"}>
                            <ResponsiveLabel
                                content={`Posizione del logo pagina destra: ${inizio ? this.$.logoFoderaInizioFacciataDestra : this.$.logoFoderaFineFacciataDestra}`}
                                type={"medium"}
                                alignment={"center"}/>
                            <SelettoreQuadrato
                                onChange={v => this.$[`logoFodera${inizio ? 'Inizio' : 'Fine'}FacciataDestra`] = v}
                                value={(inizio ? this.$.logoFoderaInizioFacciataDestra : this.$.logoFoderaFineFacciataDestra) as any}/>
                        </SectionLayout>
                    </Responsive2Col>
                    <TextLine
                        label={"Specifiche di testo"}
                        value={inizio ? this.$.logoFoderaInizioSpecificheTesto : this.$.logoFoderaFineSpecificheTesto}
                        onChange={v => this.$[`logoFodera${inizio ? 'Inizio' : 'Fine'}SpecificheTesto`] = v}/>
                    <TextLine
                        label={"Specifiche di posizione"}
                        value={inizio ? this.$.logoFoderaInizioSpecifichePosizione : this.$.logoFoderaFineSpecifichePosizione}
                        onChange={v => this.$[`logoFodera${inizio ? 'Inizio' : 'Fine'}SpecifichePosizione`] = v}/>
                </IfContainer>
            </SectionLayout>
        )
    }

    private _SezioneCaricamentoLogo(){
        return (
            <Fragment>
                <IfContainer condition={this.CountImmagini(StorageDataMapper.LogoFodera) === 1}>
                    <ImageVisualizer
                        storageData={this.ImmaginiDaCategoria(StorageDataMapper.LogoFodera)[0]?? undefined}
                        onDelete={() => this.DeleteImmagine(StorageDataMapper.LogoFodera)}/>
                </IfContainer>
                <TextLine
                    label={"Immagine del logo"}
                    type={"file"}
                    value={this.CountImmagini(StorageDataMapper.LogoFodera) === 1 ? this.ImmaginiDaCategoria(StorageDataMapper.LogoFodera)[0].name : ""}
                    onFileUpload={(fileName, fileType, file) => {
                        if(['png', 'jpg', 'jpeg'].includes(fileType)){
                            this.DeleteImmagine(StorageDataMapper.LogoFodera);
                            this.UplodaImmagine(StorageDataMapper.LogoFodera, fileName, file);
                        }
                    }}
                    showError={this.CountImmagini(StorageDataMapper.LogoFodera) === 0}
                    errorMessage={SezioneLogo.ErrorMessages.CaricamentoLogoError}/>
            </Fragment>
        )
    }

    public render() {
        return (
            <CategorySectionLayout categoryLabel={"Logo"} size={"largeRelative"} center={true}>
                <Responsive2Col>
                    {this._SezioneLogo(true)}
                    {this._SezioneLogo(false)}
                </Responsive2Col>
                <IfContainer condition={this.$.logoFoderaInizioFlag || this.$.logoFoderaFineFlag}>
                    {this._SezioneCaricamentoLogo()}
                </IfContainer>
            </CategorySectionLayout>
        );
    }
}
