import {ItemElementInterface} from "../Primary/Selects/ElementSelect/ElementSelect";
import Config from "../Static/Config";
import {MaterialiInterface, Rest, SpessoreMateriale} from "tici_commons";

export default class Materiali{
    private static _materiali: MaterialiInterface[] = [];
    static{
        console.warn("Loading materials");
        Rest.Get<MaterialiInterface[]>(`${Config.PublicPath}/Materiali`).then(v => {
            if(v && v.statusCode === 200)
                Materiali._materiali = v.response;
        });
    }

    /**
     * Restituisce il path dell'immagine dal nome del materiale
     * @param labelMateriale Materiale da caricare
     * @constructor
     */
    public static GetMaterialUrl(labelMateriale: string): string{
        let esito = "";
        if(labelMateriale){
            const material = Materiali._materiali.filter(value => {
                return labelMateriale === Materiali._GenerateLabel(value);
            });
            if(material.length > 0)
                esito = `${Config.PublicPath}/${material[0].pathImmagine}`;
        }
        return esito;
    }

    /**
     * Restituisce lo spessore di un materiale se presente, altrimenti restituisce undefined
     * @param labelMateriale Label del materiale di cui si vuole ottenere lo spessore
     * @constructor
     */
    public static GetSpessore(labelMateriale: string): SpessoreMateriale | undefined{
        let esito: SpessoreMateriale = undefined;
        if(labelMateriale){
            const material = Materiali._materiali.filter(value => {
                return labelMateriale === Materiali._GenerateLabel(value);
            });
            if(material.length > 0)
                esito = material[0].spessoreMateriale;
        }
        return esito;
    }

    /**
     * Restituisce il risultato del controllo sul materiale. Se il materiale supporta l'uv restituisce true, altrimenti restituisce false
     * @param labelMateriale Etichetta del materiale
     * @constructor
     */
    public static CanSupportUv(labelMateriale: string): boolean{
        let esito = false;

        if(labelMateriale){
            const material = Materiali._materiali.filter(value => {
                return labelMateriale === Materiali._GenerateLabel(value);
            });
            if(material.length > 0)
                esito = material[0].ammetteUv === 1;
        }

        return esito
    }

    /**
     * Restituisce la lista dei materiali filtrate
     * @param spessoreMateriale spessore del materiale
     * @param famiglieEscluse Famiglie da escludere
     * @constructor
     */
    public static GetMateriali(spessoreMateriale: "spesso" | "sottile" | "*" = "*", ...famiglieEscluse: string[]): ItemElementInterface[]{
        return Materiali._materiali
            .filter(material => spessoreMateriale === '*' ? true : material.spessoreMateriale === spessoreMateriale)
            .filter(material => !famiglieEscluse.includes(material.famigliaMateriale))
            .map(
                material => ({
                    label: Materiali._GenerateLabel(material),
                    image: `${Config.PublicPath}/${material.pathImmagine.replace('\\', "/").replace("//", "/")}`
                })
            )
            .sort((a, b) => a.label === b.label ? 0 : a.label.length - b.label.length);
    }

    /**
     * Genera la label del materiale
     * @param material Materiale di cui generare la label
     * @private
     */
    private static _GenerateLabel(material: MaterialiInterface): string{
        let esito = "";

        if(material){
            esito = `${material.lussuriaMateriale.toUpperCase()}: ${material.famigliaMateriale} ${material.nomeMateriale} - ${material.spessoreMateriale.toUpperCase()}${material.ammetteUv ? ' UV' : ''}`
        }

        return esito;
    }
}
