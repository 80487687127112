import React, {Fragment} from "react";
import './Submenu.scss';
import AzioneRapidaSubmenu from "./AzioneRapidaSubmenu";
import ImageService from "../../Servicies/ImageService";
import NotificaProvider from "../NotificaIcon/NotificaIcon";
import SubmenuItem from "./SubmenuItem";
import {Rest, UserContextResponseModel} from "tici_commons";
import RedirectService from "../../Servicies/RedirectService";
import TopLevelComponentStorage from "../../Core/Arch/TopLevelComponentStorage";
import Menu from "../../Pages/SitoInterno/Menu/Menu";
import LocalStorageService from "../../Servicies/LocalStorageService";

export type VistaCorrenteType =
    "Home" |
    "NuovoOrdine" |
    "ListaOrdini" |
    "Configurazioni" |
    "Configuratore" |
    "SuperUser" |
    "StudiFotografici" |
    "Agenti" |
    "Personificazione"

export interface SubmenuProps{
    utente: UserContextResponseModel,
    superUser: boolean,
    children: any
}

export interface SubmenuState{
    vistaCorrente: VistaCorrenteType,
}

export const SubmenuContext = React.createContext<{aviable: true}>({aviable: true});

export default class Submenu extends React.Component<SubmenuProps, SubmenuState>{
    constructor(props: Readonly<SubmenuProps> | SubmenuProps) {
        super(props);
        this.state = {
            vistaCorrente: 'Home'
        }
    }

    public componentDidMount() {
        LocalStorageService.OrdiniInCorso = 0;
        this.setState({
            vistaCorrente: this.vistaCorrente
        });
    }

    public componentDidUpdate(prevProps: Readonly<SubmenuProps>, prevState: Readonly<SubmenuState>, snapshot?: any) {
        if(prevState.vistaCorrente !== this.state.vistaCorrente)
            this.vistaCorrente = this.state.vistaCorrente;
    }

    private _MenuFotografo(){
        return (
            <Fragment>
                <SubmenuItem
                    iconaItem={ImageService.getImage('ordineIcon')}
                    titoloItem={"Nuovo ordine"}
                    selected={this.state.vistaCorrente === 'NuovoOrdine'}
                    onClick={() => {RedirectService.GoToNuovoOrdine(); this.setState({vistaCorrente: 'NuovoOrdine'})}}
                    subItems={[
                        {icona: ImageService.getImage('plusIcon'), titolo: "Wedding Project", onClick: () => RedirectService.GoTo("Wedding Project", "/homepage/coordinato_wedding")},
                        {icona: ImageService.getImage('plusIcon'), titolo: "Junior Project", onClick: () => RedirectService.GoTo("Junior Project", "/homepage/coordinato_junior")},
                        {icona: ImageService.getImage('plusIcon'), titolo: "Art Project", onClick: () => RedirectService.GoTo("Art Project", "/homepage/coordinato_art")},
                        {icona: ImageService.getImage('plusIcon'), titolo: "Album tradizionale", onClick: () => RedirectService.GoTo("Album tradizionale", "/homepage/album_tradizionale")},
                        {icona: ImageService.getImage('plusIcon'), titolo: "Blocco book", onClick: () => RedirectService.GoTo("Blocco book", "/homepage/album_blocco_book")},
                        {icona: ImageService.getImage('plusIcon'), titolo: "Fotolibro configurazione libera", onClick: () => RedirectService.GoTo("Configurazione libera", "/homepage/fotolibro_configurazione_libera")},
                        {icona: ImageService.getImage('plusIcon'), titolo: "Accessori", onClick: () => RedirectService.GoTo("Accessori", "/homepage/accessori")}
                    ]}/>
                <SubmenuItem
                    iconaItem={ImageService.getImage('databaseIcon')}
                    titoloItem={"Le mie configurazioni"}
                    selected={this.state.vistaCorrente === 'Configurazioni'}
                    onClick={() => {RedirectService.GoToConfigurazioniSalvate(); this.setState({vistaCorrente: 'Configurazioni'})}}/>
                <SubmenuItem
                    iconaItem={ImageService.getImage('penIcon')}
                    titoloItem={"Configuratore TICI"}
                    selected={this.state.vistaCorrente === 'Configuratore'}
                    onClick={() => {RedirectService.GoToConfiguratore(); this.setState({vistaCorrente: 'Configuratore'})}}/>
            </Fragment>
        )
    }

    private _MenuAmministrazione(){
        return (
            <Fragment>
                <SubmenuItem
                    iconaItem={ImageService.getImage('cameraIcon')}
                    titoloItem={"Studi fotografici"}
                    selected={this.state.vistaCorrente === 'StudiFotografici'}
                    onClick={() => {RedirectService.GoToStudiFotografici(); this.setState({vistaCorrente: 'StudiFotografici'})}}/>
                <SubmenuItem
                    iconaItem={ImageService.getImage('buisnessmanIcon')}
                    titoloItem={"Agenti"}
                    selected={this.state.vistaCorrente === 'Agenti'}
                    onClick={() => {RedirectService.GoToAgenti(); this.setState({vistaCorrente: 'Agenti'})}}/>
                <SubmenuItem
                    iconaItem={ImageService.getImage('personificationIcon')}
                    titoloItem={"Impersonificazione"}
                    selected={this.state.vistaCorrente === 'Personificazione'}
                    onClick={() => {RedirectService.GoToImpersonificazione(); this.setState({vistaCorrente: 'Personificazione'})}}/>
            </Fragment>
        )
    }

    private _MenuAgente(){
        return (
            <Fragment>
                <SubmenuItem
                    iconaItem={ImageService.getImage('personificationIcon')}
                    titoloItem={"Impersonificazione"}
                    selected={this.state.vistaCorrente === 'Personificazione'}
                    onClick={() => {RedirectService.GoToImpersonificazione(); this.setState({vistaCorrente: 'Personificazione'})}}/>
            </Fragment>
        )
    }

    private _MenuSuperUser(){
        return (
            <Fragment>
                <SubmenuItem
                    iconaItem={ImageService.getImage('crownIcon')}
                    titoloItem={"Super user"}
                    selected={this.state.vistaCorrente === 'SuperUser'}
                    onClick={() => {RedirectService.GoToSuperUser(); this.setState({vistaCorrente: 'SuperUser'})}}/>
            </Fragment>
        )
    }

    /**
     * Mostra l'informativa di supporto al numero
     * @private
     */
    private _mostraInformativaNumero(){
        TopLevelComponentStorage
            .GetTopLevel('confirmWindow')
            .showConfirmWindow("Supporto", "Per maggiore supporto contatta tramite Whatsapp il numero +39 376 091 6987");
    }

    /**
     * Effettua la procedura di logout
     * @private
     */
    private _logout(){
        if(LocalStorageService.OrdiniInCorso > 0){
            TopLevelComponentStorage.GetTopLevel('confirmWindow').showConfirmWindow(
                "Ordini in corso",
                `Attualmente ci sono ${LocalStorageService.OrdiniInCorso} ordini in corso, uscendo dall'account questi ordini falliranno. Uscire comunque?`,
                "DoubleButton",
                () => {
                    TopLevelComponentStorage.GetTopLevel('confirmWindow').hideConfirmWindow();
                    RedirectService.GoToLogout();
                }
            )
        }else{
            RedirectService.GoToLogout();
        }
    }

    public render(){
        return (
            <Fragment>
                <div className={"SubmenuContainer"}>
                    <div className={"AzioniRapideContainer"}>
                        <AzioneRapidaSubmenu
                            titoloAzioneRapida={"Esci"}
                            icona={Rest.PERSONIFICATION ? ImageService.getImage('personificationIcon') : ImageService.getImage('undoIcon')}
                            onClickEvent={() => this._logout()}/>
                        <AzioneRapidaSubmenu
                            componenteIcona={<NotificaProvider.NotificaIcon/>}
                            onClickEvent={() => RedirectService.GoToNotifiche()}/>
                        <AzioneRapidaSubmenu
                            icona={ImageService.getImage('messageIcon')}
                            onClickEvent={() => RedirectService.GoToChat()}/>
                        <AzioneRapidaSubmenu
                            icona={ImageService.getImage('whatsappIcon')}
                            onClickEvent={() => this._mostraInformativaNumero()}/>
                    </div>
                </div>
                <div
                    id={"SubmenuItemsContainer"}
                    className={`SubmenuItemsContainer`}>
                    <SubmenuItem
                        iconaItem={ImageService.getImage('homeIcon')}
                        titoloItem={"Home"}
                        selected={this.state.vistaCorrente === 'Home'}
                        sempreVisibile={true}
                        onClick={() => {RedirectService.GoToHomepage(); this.setState({vistaCorrente: 'Home'})}}/>
                    <SubmenuItem
                        iconaItem={ImageService.getImage('listIcon')}
                        titoloItem={"Ordini"}
                        selected={this.state.vistaCorrente === 'ListaOrdini'}
                        onClick={() => {RedirectService.GoToOrdini(); this.setState({vistaCorrente: 'ListaOrdini'})}}/>
                    {this.props?.utente?.tipoUtente === 'fotografo' && this._MenuFotografo()}
                    {this.props?.utente?.tipoUtente === 'amministrazione' && this._MenuAmministrazione()}
                    {this.props?.utente?.tipoUtente === 'agente' && this._MenuAgente()}
                    {this.props?.superUser && this._MenuSuperUser()}
                </div>
                <SubmenuContext.Provider value={{aviable: true}}>
                    {this.props.children}
                </SubmenuContext.Provider>
            </Fragment>
        )
    }

    //beam

    public set vistaCorrente(nuovaVista: string){
        sessionStorage.setItem('VistaCorrenteSubmenu', nuovaVista);
    }

    public get vistaCorrente(): VistaCorrenteType{
        let esito: VistaCorrenteType = 'Home';
        const data = sessionStorage.getItem('VistaCorrenteSubmentu');
        if(data)
            esito = data as VistaCorrenteType;
        return esito;
    }
}
