import React from "react";
import {ConfirmWindowContextState} from "../../Primary/PopUps/ConfirmWindow/ConfirmWindow";
import {LoadingWindowContextState} from "../../Primary/PopUps/LoadingWindow/LoadingWindow";

export interface TopLevelComponentStorageProps {
    confirmWindow?: ConfirmWindowContextState,
    loadingWindow?: LoadingWindowContextState,

}

export default class TopLevelComponentStorage extends React.Component<TopLevelComponentStorageProps, {}>{
    private static _TopLevelComponent: TopLevelComponentStorageProps = {
        confirmWindow: undefined,
        loadingWindow: undefined
    }

    constructor(props: Readonly<TopLevelComponentStorageProps> | TopLevelComponentStorageProps) {
        super(props);
        TopLevelComponentStorage._TopLevelComponent = this.props;
    }

    componentDidMount() {
        TopLevelComponentStorage._TopLevelComponent = this.props;
    }

    componentDidUpdate(prevProps: Readonly<TopLevelComponentStorageProps>, prevState: Readonly<{}>, snapshot?: any) {
        TopLevelComponentStorage._TopLevelComponent = this.props;
    }

    /**
     * Restituisce il topLevelComponent memorizzato nel componente
     * @param topLevel
     * @constructor
     */
    public static GetTopLevel<T extends keyof typeof TopLevelComponentStorage._TopLevelComponent>(topLevel: T): (typeof TopLevelComponentStorage._TopLevelComponent)[T] | undefined{
        let esito: (typeof TopLevelComponentStorage._TopLevelComponent)[T] | undefined = undefined;

        if(TopLevelComponentStorage._TopLevelComponent[topLevel]){
            esito = TopLevelComponentStorage._TopLevelComponent[topLevel];
        }

        return esito;
    }

    public render() {
        return <span style={{display: 'unset'}} className={"TopLevelComponentStorage"}/>;
    }
}
