import React, {Fragment} from "react";
import {StorageDataMapper, StorageManagerStatus} from "../../../Storage/StorageManager";
import IfContainer from "../../../../../../Layout/IfContainer/IfContainer";
import {CheckLine, SelectLine, TextLine} from "../../../../../../Widgets/Configuratore/CComponents";
import Formati from "../../../../../../DatabaseData/Formati";
import {CartaPrincipale, ColoreFoglioType, FotolibroModel, isCatalogo} from "tici_commons";
import {
    GenerateGroup,
    GenerateRiepilogoData, GenerateRiepilogoInternalBox,
    RiepilogoBox,
    RiepilogoData,
    RiepilogoInternalBox
} from "../../../Commons/RiepilogoDatoModel";
import {CoordinatoContext} from "../../../Fotolibro/Fotolibro";
import SezioneUploaderMultifunzionale from "./SezioneUploaderMultifunzionale";
import SezioneGenerica from "../../SezioneGenerica";
import CategorySectionLayout from "../../../../../../Layout/CategorySectionLayout/CategorySectionLayout";
import {SupportaDorsoDritto} from "../../../Commons/Utils";

export interface SezioneAlbumPrincipaleProps{
    resetInformazioniCopertinaCustodia: () => void,
    resetInformazioniDorsoCopertina: () => void,
    modalitaConfiguratore?: boolean
}

export default class SezioneAlbumPrincipale extends SezioneGenerica<"informazioniBase", SezioneAlbumPrincipaleProps, {}> {
    public static ErrorMessages = {
        'NumeroFogliError': "Il numero di stampe caricate non coincide con il numero di fogli",
        'OrientamentoAssenteError': "L'orientamento dell'album non è stato selezionato",
        'FormatoAsssenteError': "Il formato dell'album non è stato selezionato",
        'CartaPrincipaleError': "La carta principale non è stata selezionata"
    }

    public generaRiepilogo = (fotolibro: FotolibroModel, storage: StorageManagerStatus, modalitaConfiguratore: boolean): RiepilogoInternalBox | RiepilogoData[] | RiepilogoBox => {
        const erroreNumeroFogli =
            storage &&
            (fotolibro.informazioniBase.tipoFotolibro === 'libero' || isCatalogo(fotolibro.informazioniBase.tipoFotolibro)) &&
            !modalitaConfiguratore &&
            !fotolibro.informazioniBase.rilegaturaFlag ?
                storage.GetStorageData(StorageDataMapper.StampeFogli).length + fotolibro.informazioniBase.lavorazioniCartoncini.length !== fotolibro.informazioniBase.numeroFogli :
                false;

        const visibilitaNumeroFogli = fotolibro.informazioniBase.tipoFotolibro === 'libero' || isCatalogo(fotolibro.informazioniBase.tipoFotolibro) ?
            (fotolibro.informazioniBase.stampaFlag || fotolibro.informazioniBase.rilegaturaFlag) : true

        return GenerateRiepilogoInternalBox(
            "Album principale",
            GenerateRiepilogoData(
                `Numero di fogli: ${fotolibro.informazioniBase.numeroFogli}`,
                SezioneAlbumPrincipale.ErrorMessages['NumeroFogliError'],
                erroreNumeroFogli,
                visibilitaNumeroFogli
            ),
            ...GenerateGroup(
                fotolibro.informazioniBase.tipoFotolibro === 'tradizionale' || fotolibro.informazioniBase.tipoFotolibro === 'blocco_book',
                GenerateRiepilogoData(
                    `Veline: ${fotolibro.informazioniBase.tipoFotolibro === 'tradizionale' ? 'classiche' : 'GSK'}`,
                    undefined, undefined, fotolibro.informazioniBase.velineFlag
                ),
                GenerateRiepilogoData(
                    `Colore foglio: ${fotolibro.informazioniBase.coloreFoglio}`
                )
            ),
            GenerateRiepilogoData(
                `Orientamento: ${fotolibro.informazioniBase.orientamentoAlbum}`,
                SezioneAlbumPrincipale.ErrorMessages['OrientamentoAssenteError'],
                fotolibro.informazioniBase.orientamentoAlbum.length === 0
            ),
            GenerateRiepilogoData(
                `Formato chiuso: ${fotolibro.informazioniBase.formatoAlbum}`,
                SezioneAlbumPrincipale.ErrorMessages['FormatoAsssenteError'],
                fotolibro.informazioniBase.formatoAlbum.length === 0,
                fotolibro.informazioniBase.orientamentoAlbum.length !== 0
            ),
            GenerateRiepilogoData(
                `Carta principale: ${fotolibro.informazioniBase && fotolibro.informazioniBase.cartaPrincipale}`,
                SezioneAlbumPrincipale.ErrorMessages.CartaPrincipaleError,
                fotolibro.informazioniBase && fotolibro.informazioniBase.cartaPrincipale.length === 0,
                fotolibro.informazioniBase.stampaFlag || fotolibro.informazioniBase.rilegaturaFlag
            )
        )
    }

    /**
     * Aggiorna le informazioni della copertina e del cofanetto quando viene cambiato formato o orientamento
     * @param formatoAlbum Formato dell'album principale
     * @private
     */
    private _aggiornaInformazioniCopertinaCustodia(formatoAlbum: string){
        if(!isCatalogo(this.$.tipoFotolibro))
            this.props.resetInformazioniCopertinaCustodia && this.props.resetInformazioniCopertinaCustodia();
        if(!SupportaDorsoDritto(formatoAlbum))
            this.props.resetInformazioniDorsoCopertina && this.props.resetInformazioniDorsoCopertina();
    }

    private _SezioneOrientamento(){
        return (
            <Fragment>
                <SelectLine
                    label={"Orientamento"}
                    placeHolder={"Seleziona"}
                    errorMessage={SezioneAlbumPrincipale.ErrorMessages.OrientamentoAssenteError}
                    showError={!this.$.orientamentoAlbum.length}
                    elements={[
                        {label: `Quadrato`},
                        {label: `Orizzontale`},
                        {label: `Verticale`},
                        {label: `Panoramico`}
                    ]}
                    value={this.$.orientamentoAlbum}
                    onChange={v => {
                        this.$.orientamentoAlbum = v;
                        this._aggiornaInformazioniCopertinaCustodia("");
                    }}/>
                <IfContainer condition={!!this.$.orientamentoAlbum.length}>
                    <CoordinatoContext.Consumer>{
                        coordinato => (
                            <SelectLine
                                label={"Formato chiuso"}
                                placeHolder={"Seleziona"}
                                errorMessage={SezioneAlbumPrincipale.ErrorMessages.FormatoAsssenteError}
                                showError={!this.$.formatoAlbum}
                                elements={
                                    isCatalogo(this.$.tipoFotolibro) && coordinato ?
                                        Formati.GetFormatiVincolatiCoordinato(Formati.GetFormati(this.$.orientamentoAlbum), coordinato) :
                                        Formati.GetFormati(this.$.orientamentoAlbum)
                                }
                                value={this.$.formatoAlbum}
                                onChange={v => {
                                    this.$.formatoAlbum = v;
                                    this._aggiornaInformazioniCopertinaCustodia(v);
                                }}/>
                        )
                    }</CoordinatoContext.Consumer>
                </IfContainer>
            </Fragment>
        )
    }

    private _SezioneStampeAbilitate(){
        return (
            <Fragment>
                <SelectLine
                    label={"Carta principale"}
                    placeHolder={"Seleziona"}
                    elements={CartaPrincipale.map(v => ({label: v}))}
                    value={this.$.cartaPrincipale}
                    onChange={v => this.$.cartaPrincipale = v}
                    showError={!this.$.cartaPrincipale}
                    errorMessage={SezioneAlbumPrincipale.ErrorMessages.CartaPrincipaleError}/>
                <TextLine
                    label={"Numero di fogli"}
                    type={"number"}
                    min={1}
                    max={200}
                    value={this.$.numeroFogli.toString()}
                    onChange={v => this.$.numeroFogli = isNaN(parseInt(v)) ? 1 : parseInt(v)}
                    showError={ (this.CountImmagini(StorageDataMapper.StampeFogli) + this.$.lavorazioniCartoncini.length) !== this.$.numeroFogli}
                    errorMessage={SezioneAlbumPrincipale.ErrorMessages['NumeroFogliError']}/>
                <SezioneUploaderMultifunzionale
                    storage={this.props.storage}
                    data={this.props.data}
                    SetParentState={this.props.SetParentState}/>
            </Fragment>
        )
    }

    private _SezioneFogliVistaAlternativa(){
        return (
            <Fragment>
                <TextLine
                    label={"Numero di fogli"}
                    type={"number"}
                    min={1}
                    max={this.$.rilegaturaFlag ? undefined : this.$.tipoFotolibro === "blocco_book" ? 60 : 200}
                    value={this.$.numeroFogli.toString()}
                    onChange={v => this.$.numeroFogli = isNaN(parseInt(v)) ? 1 : parseInt(v)}/>
                <IfContainer condition={this.$.rilegaturaFlag}>
                    <SelectLine
                        label={"Carta principale"}
                        placeHolder={"Seleziona"}
                        elements={CartaPrincipale.map(v => ({label: v}))}
                        value={this.$.cartaPrincipale}
                        onChange={v => this.$.cartaPrincipale = v}
                        showError={!this.$.cartaPrincipale}
                        errorMessage={SezioneAlbumPrincipale.ErrorMessages.CartaPrincipaleError}/>
                </IfContainer>
                <IfContainer condition={!this.props.modalitaConfiguratore && !this.$.rilegaturaFlag}>
                    <CheckLine
                        label={`Veline ${this.$.tipoFotolibro === "tradizionale" ? 'classiche' : 'GSK'}`}
                        checkType={"checkbox"}
                        onChange={v => this.$.velineFlag = v}
                        checked={this.$.velineFlag}
                        reduce={true}/>
                    <SelectLine
                        label={"Colore foglio"}
                        placeHolder={"Seleziona"}
                        elements={[{label: "Nero"}, {label: "Bianco"}, {label: "Avorio"}]}
                        value={this.$.coloreFoglio}
                        onChange={v => this.$.coloreFoglio = v as ColoreFoglioType}/>
                </IfContainer>
            </Fragment>
        )
    }

    public render() {
        return (
            <CategorySectionLayout
                categoryLabel={"Album principale"}
                size={"largeRelative"}>
                {this._SezioneOrientamento()}
                <IfContainer condition={this.$.stampaFlag}>
                    {this._SezioneStampeAbilitate()}
                </IfContainer>
                <IfContainer condition={
                    ['tradizionale', 'blocco_book'].includes(this.$.tipoFotolibro) ||
                    this.props.modalitaConfiguratore ||
                    this.$.rilegaturaFlag
                }>
                    {this._SezioneFogliVistaAlternativa()}
                </IfContainer>
            </CategorySectionLayout>
        );
    }
}
