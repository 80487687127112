import React from "react";
import ResponsiveLabel from "../../../../../../Core/ResponsiveLabel/ResponsiveLabel";
import {
    CheckLine,
    SelectLine,
} from "../../../../../../Widgets/Configuratore/CComponents";
import IfContainer from "../../../../../../Layout/IfContainer/IfContainer";
import Modello from "../../../../../../DatabaseData/Modello";
import {StorageDataMapper, StorageManagerContext, StorageManagerStatus} from "../../../Storage/StorageManager";
import SelettoreUv from "../../../../../../Primary/SelettoreUv/SelettoreUv";
import { SelettoreImmagineSezione } from "../../../../../../Widgets/Configuratore/RenderizzatoreWidgets/SelettoreImmagineSezioneRenderizzatore";
import {
    GenerateGroup, GenerateRiepilogoData,
    GenerateRiepilogoInternalBox,
    RiepilogoBox,
    RiepilogoData,
    RiepilogoInternalBox
} from "../../../Commons/RiepilogoDatoModel";
import SezioneCustodia from "./SezioneCustodia";
import SezioneAlbumRidottoCopertina from "../../Copertina/CopertinaSezioni/SezioneAlbumRidottoCopertina";
import TextArea from "../../../../../../Core/TextArea/TextArea";
import SezioneGenerica from "../../SezioneGenerica";
import {FotolibroModel} from "tici_commons";
import CategorySectionLayout from "../../../../../../Layout/CategorySectionLayout/CategorySectionLayout";

export interface SezioneCofanettoPiccoloProps{
    modalitaConfiguratore?: boolean
}

export default class SezioneCofanettoPiccolo extends SezioneGenerica<"custodia", SezioneCofanettoPiccoloProps, {}>{
    public static ErrorMessages = {
        ImmagineTelaError: "L'immagine tela non è stata caricata"
    }

    public generaRiepilogo(fotolibro: FotolibroModel, storage: StorageManagerStatus, modalitaConfiguratore: boolean): RiepilogoBox | RiepilogoInternalBox | RiepilogoInternalBox[] | RiepilogoData[] {
        return GenerateRiepilogoInternalBox(
            'Custodie piccole',
            ...GenerateGroup(
                fotolibro.informazioniBase.albumRidottoFlag && fotolibro.informazioniBase.albumRidottoQuantita > 0 && fotolibro.custodia.cofanettoPiccoloFlag,
                GenerateRiepilogoData(
                    `Modello custodia: ${fotolibro.custodia.modelloCofanettoPiccolo}`,
                    SezioneCustodia.ErrorMessages.ModelloCustodiaError,
                    fotolibro.custodia.modelloCofanettoPiccolo.length === 0
                ),
                GenerateRiepilogoData(
                    `Immagine tela: ${fotolibro.custodia.fileAssociatoCofanettoPiccolo}`,
                    SezioneCofanettoPiccolo.ErrorMessages.ImmagineTelaError,
                    false,
                    fotolibro.custodia.fileAssociatoCofanettoPiccolo.length > 0
                ),
                GenerateRiepilogoData(
                    `Codice uv: ${fotolibro.custodia.uvAssociatoCofanettoPiccolo}`,
                    SezioneAlbumRidottoCopertina.ErrorMessages.CodiceUvError,
                    false,
                    fotolibro.custodia.uvAssociatoCofanettoPiccolo.length > 0
                ),
                GenerateRiepilogoData(
                    `Note: ${fotolibro.custodia.noteCofanettoPiccolo}`,
                    undefined,
                    false,
                    fotolibro.custodia.noteCofanettoPiccolo.length !== 0
                )
            )
        );
    }

    private _SezioneModificaCofanettoPiccolo() {
        return (
            <StorageManagerContext.Consumer>{
                storage => (
                    <IfContainer condition={!!this.$.modelloCofanettoPiccolo}>
                        <IfContainer condition={Modello.HasFile(this.$.modelloCofanettoPiccolo)}>
                            <SelettoreImmagineSezione
                                label={"Immagine tela"}
                                tipoImmagine={"immagineTela"}
                                storageData={storage.GetStorageData(StorageDataMapper.ImmagineCofanettoRidotto)[0]}
                                onImageUpload={(tipoImmagine, nomeImmagine, base64Encoding) => {
                                    this.$.fileAssociatoCofanettoPiccolo = nomeImmagine;
                                    storage.DeleteStorageData(StorageDataMapper.ImmagineCofanettoRidotto);
                                    storage.SetStorageData(StorageDataMapper.ImmagineCofanettoRidotto, nomeImmagine, undefined, base64Encoding);
                                }}
                                onDeleteImage={() => {
                                    this.$.fileAssociatoCofanettoPiccolo = "";
                                    storage.DeleteStorageData(StorageDataMapper.ImmagineCofanettoRidotto);
                                }}
                                escludiPlex={true}
                            />
                        </IfContainer>
                        <IfContainer condition={Modello.HasUv(this.$.modelloCofanettoPiccolo)}>
                            <SelettoreUv
                                label={"Codice Uv"}
                                placeHolder={"Seleziona"}
                                errorMessage={SezioneAlbumRidottoCopertina.ErrorMessages.CodiceUvError}
                                currentImageName={this.$.uvAssociatoCofanettoPiccolo}
                                currentImage={storage.GetStorageData(StorageDataMapper.UvCofanettoRidotto)[0]}
                                onChange={(imageName, file, base64) => {
                                    if(imageName){
                                        this.$.uvAssociatoCofanettoPiccolo = imageName;
                                        storage.DeleteStorageData(StorageDataMapper.UvCofanettoRidotto);
                                        if(base64)
                                            storage.SetStorageData(StorageDataMapper.UvCofanettoRidotto, imageName, undefined, base64);
                                        else storage.SetStorageData(StorageDataMapper.UvCofanettoRidotto, imageName, file);
                                    }else{
                                        this.$.uvAssociatoCofanettoPiccolo = "";
                                        storage.DeleteStorageData(StorageDataMapper.UvCofanettoRidotto);
                                    }
                                }}/>
                        </IfContainer>
                        <ResponsiveLabel
                            content={"Note:"}
                            type={"medium"}
                            alignment={"left"}/>
                        <TextArea
                            value={this.$.noteCofanettoPiccolo}
                            onChange={v => this.$.noteCofanettoPiccolo = v}/>
                    </IfContainer>
                )
            }</StorageManagerContext.Consumer>
        )
    }

    public render() {
        return (
            <CategorySectionLayout categoryLabel={"Album ridotti"} size={"largeRelative"} center={true}>
                <CheckLine
                    label={"Custodie piccole"}
                    checkType={"checkbox"}
                    onChange={v => this.$.cofanettoPiccoloFlag = v}
                    checked={this.$.cofanettoPiccoloFlag}
                    reduce={true}/>
                <IfContainer condition={this.$.cofanettoPiccoloFlag}>
                    <SelectLine
                        label={"Modello custodia"}
                        placeHolder={"Seleziona"}
                        errorMessage={SezioneCustodia.ErrorMessages.ModelloCustodiaError}
                        showError={!this.$.modelloCofanettoPiccolo}
                        elements={Modello.GetModelli("custodia", undefined, true)}
                        onChange={v => this.$.modelloCofanettoPiccolo = v}
                        value={this.$.modelloCofanettoPiccolo}/>
                    {this._SezioneModificaCofanettoPiccolo()}
                    <ResponsiveLabel content={"I materiali del cofanetto piccolo saranno uguali a quelli del grande"} type={"medium"} alignment={"right"}/>
                </IfContainer>
            </CategorySectionLayout>
        );
    }
}
