import React from "react";
import ModalLayout from "../../../Layout/ModalLayout/ModalLayout";
import ResponsiveLabel from "../../../Core/ResponsiveLabel/ResponsiveLabel";
import Responsive2Col from "../../../Layout/Responsive2Col/Responsive2Col";
import Button from "../../../Core/Buttons/Button";

export type ConfirmWindowType = "SingleButton" | "DoubleButton" | "DoubleButtonSiNo";

export interface ConfirmWindowState{
    title: string,
    content: string,
    windowType: ConfirmWindowType,
    isOpen: boolean
}

export interface ConfirmWindowContextState{
    showConfirmWindow: (title: string, content: string, windowType?: ConfirmWindowType, onAcceptCallback?: () => void, onRejectCallback?: () => void) => void,
    hideConfirmWindow: () => void
}

export const ConfirmWindowContext = React.createContext<ConfirmWindowContextState>(undefined);

export default class ConfirmWindow extends React.Component<{children: any}, ConfirmWindowState>{
    private _onAcceptCallback: () => void;     //Callback di conferma del pannello
    private _onRejectCallback: () => void;      //Caòòbacl do reject del pannello

    constructor(props: Readonly<{ children: any }> | { children: any }) {
        super(props);
        this.state = {
            isOpen: false,
            title: "Titolo",
            content: "Contenuto",
            windowType: "SingleButton"
        }
    }

    private _showConfirmWindow(title: string, content: string, windowType: ConfirmWindowType = "SingleButton", onAcceptCallback?: () => void, onRejectCallback?: () => void){
        this.setState({
            title: title,
            content: content,
            isOpen: true,
            windowType: windowType
        });

        this._onAcceptCallback = onAcceptCallback;
        this._onRejectCallback = onRejectCallback;
    }

    private _hideConfirmWindow(){
        this.setState({isOpen: false})
        this._onAcceptCallback = undefined;
        this._onRejectCallback = undefined;
    }

    render() {
        return (
            <ConfirmWindowContext.Provider value={{showConfirmWindow: this._showConfirmWindow.bind(this), hideConfirmWindow: this._hideConfirmWindow.bind(this)}}>
                <ModalLayout
                    isOpen={this.state.isOpen}
                    openStateChange={(openState) => {
                        !openState && this._onAcceptCallback && this._onAcceptCallback();
                    }}
                    showXClose={false}
                    header={<ResponsiveLabel content={this.state.title} type={"large"} alignment={"center"}/>}
                    body={typeof this.state.content === "string" ? <ResponsiveLabel content={this.state.content} type={"medium"} alignment={"center"}/> : this.state.content}
                    footer={
                        this.state.windowType === "DoubleButton" || this.state.windowType === "DoubleButtonSiNo" ?
                            <Responsive2Col>
                                <Button
                                    content={this.state.windowType === 'DoubleButton' ? "Conferma" : "Sì"}
                                    type={"medium"}
                                    buttonType={"full-normal"}
                                    onClick={() => {
                                        this._onAcceptCallback && this._onAcceptCallback()
                                    }}/>
                                <Button
                                    content={this.state.windowType === 'DoubleButton' ? "Annulla" : "No"}
                                    type={"medium"}
                                    buttonType={"full-normal-negative"}
                                    onClick={() => {
                                        this._onRejectCallback && this._onRejectCallback()
                                        this._hideConfirmWindow();
                                    }}/>
                            </Responsive2Col>:
                            <Button
                                content={"Conferma"}
                                type={"medium"}
                                buttonType={"submit"}
                                alignment={"center"}
                                onClick={() => {
                                    this._onAcceptCallback && this._onAcceptCallback()
                                    this._hideConfirmWindow();
                                }}/>
                    }>
                </ModalLayout>
                {this.props.children}
            </ConfirmWindowContext.Provider>
        );
    }
}
