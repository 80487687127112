import {StorageData, StorageDataMapper, StorageManagerStatus} from "../Pages/SitoInterno/Configuratore/Storage/StorageManager";
import {FotolibroModel} from "tici_commons";

export default class OrganizzatoreImmaginiService {
    /**
     * Organizza le immagini dell'album principale
     * @param storage Storage delle immagini
     * @param fotoLibro Informazioni del fotolibro
     */
    public static OrganizzaImmaginiAlbumPrincipale(storage: StorageManagerStatus, fotoLibro: FotolibroModel): [string, StorageData][]{
        const esito: [string, StorageData][] = [];

        if(storage && fotoLibro && fotoLibro.informazioniBase.stampaFlag){
            const immagini = storage.GetStorageData(StorageDataMapper.StampeFogli);

            const mappaCartaExtra = new Map(fotoLibro.informazioniBase.lavorazioniCartaExtra);
            const mappaLavorazioni = new Map(fotoLibro.informazioniBase.lavorazioniLavorazione);
            for(const immagine of immagini){
                let immagineName = immagine.name;
                if(mappaLavorazioni.has(immagine.name))
                    immagineName = `${immagine.name.split('.')[0]}-(Lavorazione ${mappaLavorazioni.get(immagine.name)}).${immagine.name.split('.')[1]}`;
                if(mappaCartaExtra.has(immagine.name)) {
                    const cartaExtra = mappaCartaExtra.get(immagine.name);
                    immagine.name = immagineName;
                    esito.push([`AlbumPrincipale/CartaExtra-${cartaExtra}`, immagine]);
                }else {
                    immagine.name = immagineName;
                    esito.push([`AlbumPrincipale/CartaPrincipale`, immagine])
                }
            }
        }

        return esito;
    }

    /**
     * Organizza le immagini degli albumetti
     * @param storage Storage delle immagini
     * @param fotolibro Informazioni del fotolibro
     */
    public static OrganizzaImmaginiAlbumetti(storage: StorageManagerStatus, fotolibro: FotolibroModel): [string, StorageData][]{
        const esito: [string, StorageData][] = [];

        if(storage && fotolibro && fotolibro.informazioniBase.stampaFlag){
            //Recupero delle informazioni per album ridotto
            if(fotolibro.informazioniBase.albumRidottoFlag){
                let albumRidottoIndex = 0;
                for(const albumRidotto of fotolibro.informazioniBase.albumRidottoInformazioni){
                    if(
                        (!albumRidotto.albumRidottoStesseStampeDelGrandeFlag && !albumRidotto.albumRidottoStesseStampeDelPrimoFlag) ||
                        (!albumRidotto.albumRidottoStesseStampeDelPrimoFlag && albumRidottoIndex === 0)
                    ){
                        const immagini = storage.GetStorageData(`AlbumRidotto${albumRidottoIndex}StampeFogli`);
                        for(const immagine of immagini){
                            esito.push([`Albumetti/AlbumRidotto-${albumRidottoIndex + 1}`, immagine]);
                        }
                    }
                    albumRidottoIndex++;
                }
            }
        }

        return esito;
    }

    /**
     * Organizza le immagini della rilegatura
     * @param storage Storage delle immagini
     * @param fotolibro Informazioni del fotolibro
     */
    public static OrganizzaImmaginiRilegatura(storage: StorageManagerStatus, fotolibro: FotolibroModel): [string, StorageData][]{
        const esito: [string, StorageData][] = [];

        if(fotolibro.rilegatura.coloreFodera.toLowerCase().includes('grafica')){
            let immagini = storage.GetStorageData(StorageDataMapper.ImmagineFoderaInizio);
            if(immagini.length > 0) {
                immagini[0].name = `ImmagineFoderaInizio`
                esito.push(['Rilegatura/ImmagineFodera', immagini[0]]);
            }
            immagini = storage.GetStorageData(StorageDataMapper.ImmagineFoderaFine);
            if(immagini.length > 0) {
                immagini[0].name = `ImmagineFoderaFine`
                esito.push(['Rilegatura/ImmagineFodera', immagini[0]]);
            }
        }

        if(fotolibro.rilegatura.logoFoderaFineFlag || fotolibro.rilegatura.logoFoderaInizioFlag){
            const immagini = storage.GetStorageData(StorageDataMapper.LogoFodera);
            if(immagini.length > 0)
                esito.push(['Rilegatura/ImmagineLogo', immagini[0]]);
        }

        if(fotolibro.informazioniBase.lavorazioniConAggiunte.length > 0){
            for(const lavorazioneConAggiunte of fotolibro.informazioniBase.lavorazioniConAggiunte){
                const immagini = storage.GetStorage().filter(storageData => storageData.category.includes(`lavorazione-${lavorazioneConAggiunte[0]}`));
                for(const immagine of immagini){
                    esito.push([`Lavorazioni/${lavorazioneConAggiunte[1].nomeLavorazione}/${lavorazioneConAggiunte[0]}`, immagine]);
                }
            }
        }

        return esito;
    }

    /**
     * Organizza le immagini della copertina
     * @param storage Storage delle immagini
     * @param fotolibro Informazioni del fotolibro
     */
    public static OrganizzaImmaginiCopertina(storage: StorageManagerStatus, fotolibro: FotolibroModel): [string, StorageData][]{
        const esito: [string, StorageData][] = [];

        if(storage){
            const immaginiCopertina = storage.GetStorage().filter(data => data.category.toLowerCase().startsWith('copertina'));
            for(const immagine of immaginiCopertina) {
                immagine.name = `${immagine.category}.${immagine.name.split('.')[1]}`;
                esito.push(["Copertina/AlbumPrincipale", immagine]);
            }

            if(fotolibro.informazioniBase.albumRidottoFlag && fotolibro.informazioniBase.albumRidottoQuantita > 0){
                if(fotolibro.copertina.copertinaAlbumRidottoStampaCodiceUvFlag) {
                    const uvRidotto = storage.GetStorageData(StorageDataMapper.UvRidotto);
                    if (uvRidotto.length > 0) {
                        uvRidotto[0].name = `uv.${uvRidotto[0].name.split('.')[1]}`;
                        esito.push(["Copertina/AlbumRidotto", uvRidotto[0]]);
                    }
                }
                if(fotolibro.copertina.copertinaAlbumRidottoFotoCopertina){
                    const fotoCopertina = storage.GetStorageData(StorageDataMapper.FotoCopertinaRidotto);
                    if(fotoCopertina.length > 0) {
                        fotoCopertina[0].name = `fotoCopertina.${fotoCopertina[0].name.split('.')[1]}`;
                        esito.push(["Copertina/AlbumRidotto", fotoCopertina[0]]);
                    }
                }
            }
        }

        return esito;
    }

    /**
     * Organizza le immagini del cofanetto
     * @param storage Storage delle immagini
     * @param fotolibro Informazioni del fotolibro
     */
    public static OrganizzaImmaginiCofanetto(storage: StorageManagerStatus, fotolibro: FotolibroModel): [string, StorageData][]{
        const esito: [string, StorageData][] = [];

        if(storage){
            if(fotolibro.informazioniBase.custodiaFlag){
                const immaginiCofanetto = storage.GetStorage().filter(data => data.category.toLowerCase().startsWith('custodia'));
                for(const immagine of immaginiCofanetto) {
                    immagine.name = `${immagine.category}.${immagine.name.split('.')[1]}`;
                    esito.push(["Cofanetto/AlbumPrincipale", immagine]);
                }
                if(fotolibro.informazioniBase.albumRidottoQuantita > 0 && fotolibro.custodia.cofanettoPiccoloFlag){
                    const immagineCofanettoRidotto = storage.GetStorageData(StorageDataMapper.ImmagineCofanettoRidotto);
                    if(immagineCofanettoRidotto.length > 0){
                        immagineCofanettoRidotto[0].name = `immagineCofanetto.${immagineCofanettoRidotto[0].name.split('.')[1]}`;
                        esito.push(["Cofanetto/AlbumRidotto", immagineCofanettoRidotto[0]]);
                    }
                    const uvCofanettoRidotto = storage.GetStorageData(StorageDataMapper.UvCofanettoRidotto);
                    if(uvCofanettoRidotto.length > 0){
                        uvCofanettoRidotto[0].name = `uvCofanetto.${uvCofanettoRidotto[0].name.split('.')[1]}`;
                        esito.push(["Cofanetto/AlbumRidotto", uvCofanettoRidotto[0]]);
                    }
                }
            }
        }

        return esito;
    }

    /**
     * Organizza le immagini degli accessori
     * @param storage Storage delle immagini
     * @param fotolibro Informazioni del fotolibro
     */
    public static OrganizzaImmaginiAccessori(storage: StorageManagerStatus, fotolibro: FotolibroModel): [string, StorageData][]{
        const esito: [string, StorageData][] = [];

        let index = 0;
        for(const accessorio of fotolibro.accessori.accessori){
            const immaginiAccessorio = storage.GetStorage().filter(storageData => storageData.category.includes(`Accessorio-${index}`));
            if(immaginiAccessorio.length > 0){
                for(const immagine of immaginiAccessorio){
                    immagine.name = immagine.category.replaceAll(`Accessorio-${index}-`, '').trim() + "." + immagine.name;
                    esito.push([`Accessori/Acessorio-${accessorio[0]}-${index}`, immagine]);
                }
            }
            index++;
        }

        return esito;
    }

    /**
     * Organizza le immagini da inviare
     * @param storage Storage delle immagini
     * @param fotoLibro Informazioni del fotolibro
     */
    public static OrganizzaImmagini(storage: StorageManagerStatus, fotoLibro: FotolibroModel): [string, StorageData][]{
        const esito: [string, StorageData][] = [];

        if(storage){
            esito.push(...OrganizzatoreImmaginiService.OrganizzaImmaginiAlbumPrincipale(storage, fotoLibro));
            esito.push(...OrganizzatoreImmaginiService.OrganizzaImmaginiAlbumetti(storage, fotoLibro));
            esito.push(...OrganizzatoreImmaginiService.OrganizzaImmaginiRilegatura(storage, fotoLibro));
            esito.push(...OrganizzatoreImmaginiService.OrganizzaImmaginiCopertina(storage, fotoLibro));
            esito.push(...OrganizzatoreImmaginiService.OrganizzaImmaginiCofanetto(storage, fotoLibro));
            esito.push(...OrganizzatoreImmaginiService.OrganizzaImmaginiAccessori(storage, fotoLibro));
        }

        return esito;
    }
}
