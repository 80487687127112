import React from "react";
import ModalLayout from "../../Layout/ModalLayout/ModalLayout";
import Config from "../../Static/Config";
import SectionLayout from "../../Layout/SectionLayout/SectionLayout";
import ResponsiveLabel from "../../Core/ResponsiveLabel/ResponsiveLabel";
import Button from "../../Core/Buttons/Button";
import IfContainer from "../../Layout/IfContainer/IfContainer";

export interface ModaleInformativaState {
    isSafari: boolean
}

export default class ModaleInformativa extends React.Component<{}, ModaleInformativaState>{
    constructor(props: Readonly<{}> | {}) {
        super(props);
        this.state = {
            isSafari: false
        }
    }

    public componentDidMount() {
        this._init();
    }

    private _init(){
        const userAgent = navigator.userAgent.toLowerCase();
        const isSafari = userAgent.includes('safari') && !userAgent.includes('chrome') && !userAgent.includes('edg');
        this.setState({isSafari});
    }

    public render() {
        return (
            <IfContainer condition={this.state.isSafari}>
                <ModalLayout
                    showXClose={true}
                    isOpen={true}
                    body={
                        <SectionLayout size={"largeRelative"}>
                            <ResponsiveLabel content={"Informativa di compatibilità"} type={"large"} alignment={"center"}/>
                            <ResponsiveLabel content={
                                "Abbiamo notato che stai utilizzando il browser 'Safari'. " +
                                "Attualmente il nostro programma non é stato ottimizzato per l'utilizzo su Safari, " +
                                "pertanto potebbero verificarsi dei malfunzionamenti."
                            } type={"medium"} alignment={"left"}/>
                            <ResponsiveLabel content={
                                "Consigliamo per un corretto funzionamento di scaricare 'Google Chrome'"
                            } type={"medium"} alignment={"left"}/>
                            <Button
                                content={"Scarica Chrome"}
                                type={"large"}
                                buttonType={"full-normal"}
                                onClick={() => window.open('https://www.google.it/intl/it/chrome')}/>
                        </SectionLayout>
                    }/>
            </IfContainer>
        );
    }
}
