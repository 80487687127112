import React from "react";
import {
    ChiavettaUsbModel,
    FotolibroModel,
    PannelliAlufoamModel,
    PannelliPlexModel,
    StampaLegnoModel,
    StampaTelaModel, StampeModel,
    MultiDataAccessoriType, MultiDataAccessoriMapType
} from "tici_commons";
import {StorageData, StorageManagerStatus} from "../../Storage/StorageManager";
import {RiepilogoBox, RiepilogoData, RiepilogoInternalBox} from "../../Commons/RiepilogoDatoModel";

export type SezioneAccessoriGenericaAccessorioType =
    ChiavettaUsbModel |
    StampaTelaModel |
    StampaLegnoModel |
    PannelliPlexModel |
    PannelliAlufoamModel |
    StampeModel

export interface SezioneAccessoriGenericaProps<T extends keyof MultiDataAccessoriMapType, D = MultiDataAccessoriMapType[T]> {
    indexAccessorio: number,
    data: [T, D],
    setAccessorioData: (index: number, data: MultiDataAccessoriType) => void,
    storage: StorageManagerStatus
}

export default abstract class SezioneAccessoriGenerica<T extends keyof MultiDataAccessoriMapType, P, S, D = MultiDataAccessoriMapType[T]>
    extends React.Component<P & SezioneAccessoriGenericaProps<T>, S>{
    public abstract generaRiepilogo(accessorio: D, indexAccessorio: number, fotolibro: FotolibroModel, storage: StorageManagerStatus, modalitaConfiguratore: boolean): RiepilogoBox | RiepilogoBox[] | RiepilogoInternalBox | RiepilogoInternalBox[] | RiepilogoData[];

    /**
     * Permette di accedere e modificare la sottointerfaccia di sezione
     * @protected
     */
    protected get $() {
        const that = this;
        type FullType = (Object & D)
        return new Proxy<FullType>(this.props.data[1] as FullType, {
            // @ts-ignore
            get(target: FullType, p: keyof FullType): FullType[typeof p]{
                let response: FullType[typeof p] = undefined;
                if(p in target)
                    response = target[p];
                return response
            },
            // @ts-ignore
            set(target: FullType, p: keyof FullType, value: FullType[p]): boolean {
                const dataToReplace: any = that.props.data;
                dataToReplace[1][p] = value;
                that.props.setAccessorioData(that.props.indexAccessorio, dataToReplace);
                return true;
            }
        })
    }

    /**
     * Carica un immagine nello storage
     * @param categoriaImmagine Categoria dell'immagine da caricare
     * @param nomeImmagine Nome dell'immagine da caricare
     * @param fileImmagine File dell'immagine da caricare
     * @param base64Data Codifica base64 da caricicare
     * @constructor
     * @protected
     */
    protected UplodaImmagine(categoriaImmagine: string, nomeImmagine: string, fileImmagine: File, base64Data?: string){
        if(this.props.storage)
            this.props.storage.SetStorageData(`Accessorio-${this.props.indexAccessorio}-${categoriaImmagine}`, nomeImmagine, fileImmagine, base64Data);
    }

    /**
     * Elimina un immagine dallo storage
     * @param categoriaImmagine Categoria dell'immagine da eliminare
     * @param nomeImmagine Nome dell'immagine da eliminare
     * @constructor
     * @protected
     */
    protected DeleteImmagine(categoriaImmagine: string, nomeImmagine?: string){
        if(this.props.storage)
            this.props.storage.DeleteStorageData(`Accessorio-${this.props.indexAccessorio}-${categoriaImmagine}`, nomeImmagine);
    }

    /**
     * Restituisce le immagini dalla categoria
     * @param categoriaImmagini Categoria delle immagini da recuperare
     * @protected
     */
    protected ImmaginiDaCategoria(categoriaImmagini: string): StorageData[]{
        const esito: StorageData[] = [];

        if(this.props.storage)
            esito.push(...this.props.storage.GetStorageData(`Accessorio-${this.props.indexAccessorio}-${categoriaImmagini}`));

        return esito;
    }

    /**
     * Recupera l'immagine dallo storage
     * @param categoriaImmagine Categoria dell'imaagine da recuperare
     * @param nomeImmagine Nome dell'immagine da recuperare
     * @constructor
     * @protected
     */
    protected Immagine(categoriaImmagine: string, nomeImmagine: string): StorageData | undefined{
        let esito = undefined;

        if(this.props.storage){
            const data = this.props.storage.GetStorageData(`Accessorio-${this.props.indexAccessorio}-${categoriaImmagine}`, nomeImmagine);
            if(data.length > 0)
                esito = data[0];
        }

        return esito;
    }

    /**
     * Restituisce il numero di immagini di una data categoria
     * @param categoriaImmagini Categoria da cui recuperare le immagini
     * @protected
     */
    protected CountImmagini(categoriaImmagini: string): number{
        return this.ImmaginiDaCategoria(categoriaImmagini).length;
    }
}
