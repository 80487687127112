import {FilterRiepilogoBox, RiepilogoBox, RiepilogoInternalBox} from "../../Commons/RiepilogoDatoModel";
import React, {Fragment} from "react";
import ResponsiveLabel from "../../../../../Core/ResponsiveLabel/ResponsiveLabel";
import IfContainer from "../../../../../Layout/IfContainer/IfContainer";
import {RecapElement} from "../../../../../Widgets/Configuratore/CComponents";
import SectionLayout from "../../../../../Layout/SectionLayout/SectionLayout";

export interface RenderizzatoreRiepilogoProps {
    riepiloghi: RiepilogoBox[],
    modalitaConfiguratore?: boolean
    errorEmitter?: (errorStatus: boolean) => void
}

export interface RenderizzatoreRiepilogoState {
    riepiloghi: RiepilogoBox[]
}

export default class RenderizzatoreRiepilogo extends React.Component<RenderizzatoreRiepilogoProps, RenderizzatoreRiepilogoState>{
    constructor(props: Readonly<RenderizzatoreRiepilogoProps> | RenderizzatoreRiepilogoProps) {
        super(props);
        this.state = {
            riepiloghi: []
        }
    }

    /**
     * Filtra l'input
     * @param riepiloghiBox Box di riepilogo da filtrare
     * @private
     */
    private _filterInput(riepiloghiBox: RiepilogoBox[]){
        if(riepiloghiBox){
            this.setState({
                riepiloghi: riepiloghiBox.filter(riepilogoBox => {
                    FilterRiepilogoBox(riepilogoBox);
                    return riepilogoBox.riepiloghiInternalBox && riepilogoBox.riepiloghiInternalBox.length > 0
                })
            })
        }
    }

    public componentDidMount() {
        this._filterInput(this.props.riepiloghi);
        if(this.props.errorEmitter){
            const jsonRiepiloghi = JSON.stringify(this.props.riepiloghi);
            if(/"error":true/.test(jsonRiepiloghi)){
                this.props.errorEmitter(true);
            }else{
                this.props.errorEmitter(false);
            }
        }
    }

    public componentDidUpdate(prevProps: Readonly<RenderizzatoreRiepilogoProps>, prevState: Readonly<RenderizzatoreRiepilogoState>, snapshot?: any) {
        if(JSON.stringify(prevProps.riepiloghi) !== JSON.stringify(this.props.riepiloghi))
            this._filterInput(this.props.riepiloghi);
    }

    /**
     * Effettua il rendering di un internalBox
     * @param riepilogoInternal Internal box da renderizzatore
     * @param riepilogoInternalIndex Indice dell'internoal box
     * @private
     */
    private _renderRiepilogoInternalBox(riepilogoInternal: RiepilogoInternalBox, riepilogoInternalIndex: number){
        return (
            riepilogoInternal && riepilogoInternal.riepilogoData && riepilogoInternal.riepilogoData.length > 0 &&
            <Fragment key={`InternalBox-${riepilogoInternalIndex}`}>
                <IfContainer condition={riepilogoInternalIndex !== 0}>
                    <ResponsiveLabel
                        content={riepilogoInternal.title}
                        type={"large"}
                        alignment={"left"}
                        labelType={"separatorLabel"}/>
                </IfContainer>
                {
                    riepilogoInternal.riepilogoData.filter(riepilogoData => (riepilogoData.error && riepilogoData.errorLabel) || (!riepilogoData.error && riepilogoData.infoLabel)).map((riepilogoData, index) => (
                        <RecapElement
                            key={`RiepilogoData-${index}`}
                            value={riepilogoData.error ? riepilogoData.errorLabel : riepilogoData.infoLabel}
                            messageType={riepilogoData.error ? (this.props.modalitaConfiguratore ? "warning" : "error") : "recap"}/>
                    ))
                }
            </Fragment>
        )
    }

    /**
     * Effettua il rendering del box di riepilogo
     * @param riepilogoBox Box di riepilogo
     * @private
     */
    private _renderRiepilogoBox(riepilogoBox: RiepilogoBox): React.JSX.Element{
        let esito = undefined;

        if(riepilogoBox && riepilogoBox.riepiloghiInternalBox && riepilogoBox.riepiloghiInternalBox.length > 0){
            esito = (
                <SectionLayout
                    key={`RiepilogoBox-${Math.random()}`}
                    size={"largeRelative"}>
                    <ResponsiveLabel
                        content={riepilogoBox.title}
                        type={"large"}
                        alignment={"left"}
                        labelType={"separatorLabel"}/>
                    {
                        riepilogoBox.riepiloghiInternalBox.map((riepilogoInternalBox, index) => (
                            this._renderRiepilogoInternalBox(riepilogoInternalBox, index)
                        ))
                    }
                </SectionLayout>
            )
        }

        return esito;
    }

    public render() {
        return (
            this.state.riepiloghi.map(riepilogoBox => (
                this._renderRiepilogoBox(riepilogoBox)
            ))
        );
    }
}
