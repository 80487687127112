import React from "react";
import SectionLayout from "../../Layout/SectionLayout/SectionLayout";
import ResponsiveLabel from "../../Core/ResponsiveLabel/ResponsiveLabel";
import VSLoader from "../VSLoader/VSLoader";

export default class LabelLoader extends React.Component<{label: string}, {}>{
    public render() {
        return (
            <SectionLayout size={"largeRelative"}>
                <ResponsiveLabel
                    content={this.props.label}
                    type={"medium"}
                    alignment={"center"}/>
                <VSLoader gapMobile={10} gapTablet={20} gapDesktop={30}/>
            </SectionLayout>
        );
    }
}
