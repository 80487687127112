import "./ChatIcon.scss";
import messageIcon from "../../Media/Images/Icons/messageIcon.png";

import React from "react";
import IfContainer from "../../Layout/IfContainer/IfContainer";
import AutoRefreshComponent from "../../Core/Arch/AutoRefreshComponent";
import ChatService from "../../Servicies/ChatService";

export interface ChatIconProps{
    ordineId: number
}

export interface ChatIconState{
    messageCount: number
}

export default class ChatIcon extends AutoRefreshComponent<ChatIconProps, ChatIconState>{
    constructor(props: Readonly<ChatIconProps> | ChatIconProps) {
        super(props);
        this.Delay = 3 * 60 * 1000; //Aggiorna ogni 3 minuti
        this.state = {
            messageCount: 0
        };
    }

    public cycleNotificheChat = async () => {
        this.setState({messageCount: await ChatService.MessaggiNonLettiOrdine(this.props.ordineId)});
    }

    public render() {
        return (
            <div className={"ChatContainerIcon"}>
                <IfContainer condition={this.state.messageCount > 0}>
                    <span className={"counter"}>{this.state.messageCount}</span>
                </IfContainer>
                <img alt={"ChatIcon"} className={`ChatIcon`} src={messageIcon}/>
            </div>
        );
    }
}
