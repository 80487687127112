import './SelettoreUv.scss';
import React, {Fragment} from "react";
import {
    ImageVisualizer,
    SelectLine,
    SelectLineProps,
    TextLine
} from "../../Widgets/Configuratore/CComponents";
import UV from "../../DatabaseData/UV";
import PsdVisualizer from "../PsdVisualizer/PsdVisualizer";
import Config from "../../Static/Config";
import SectionLayout from "../../Layout/SectionLayout/SectionLayout";
import IfContainer from "../../Layout/IfContainer/IfContainer";
import Loader from "../../Core/Loader/Loader";
import VerticalSpace from "../../Layout/VerticalSpace/VerticalSpace";
import Button from "../../Core/Buttons/Button";
import {pLog} from "tici_commons";
import {StorageData} from "../../Pages/SitoInterno/Configuratore/Storage/StorageManager";

export interface SelettoreUvProps extends Omit<SelectLineProps, 'elements' | 'onChange' | 'value'>{
    currentImageName?: string,
    currentImage?: StorageData,
    onChange?: (imageName: string, file?: File, base64Encoding?: string) => void,
    addPaddingAndBorder?: boolean
}

type SelettoreUvMode = 'default' | 'custom';

export interface SelettoreUvState{
    isLoading: boolean
}

export default class SelettoreUv extends React.Component<SelettoreUvProps, SelettoreUvState>{
    constructor(props: Readonly<SelettoreUvProps> | SelettoreUvProps) {
        super(props);
        this.state = {
            isLoading: false
        }
    }

    private _sezioneLoader(){
        return (
            <Fragment>
                <VerticalSpace gapMobile={10} gapTablet={20} gapDesktop={30}/>
                <Loader/>
                <VerticalSpace gapMobile={10} gapTablet={20} gapDesktop={30}/>
            </Fragment>
        );
    }

    /**
     * Effettua il controllo sul current name per determinare se il select degli uv contiene il nome passato
     * @param name Nome da ricercare all'interno della lista degli uv
     * @private
     */
    private _hasUvName(name: string): boolean{
        let esito = false;

        if(this.props.currentImageName)
            esito = UV.GetUv().filter(uv => uv[1].toUpperCase() === name.toUpperCase()).length === 1;

        return esito;
    }

    /**
     * Restituisce lo stato corrente del selettore
     * @private
     */
    private _currentSelectorMode(): SelettoreUvMode{
        let esito: SelettoreUvMode = 'default';

        if(this.props.currentImageName){
            if(this.props.currentImageName === '' || this._hasUvName(this.props.currentImageName)){
                esito = 'default';
            }else esito = 'custom';
        }

        return esito;
    }

    /**
     * Esegue la logica di selezione dell'uv. PS l'uv ci viene passato in uppercase dalla select
      * @param selectedUv Uv selezionato dal select
     * @private
     */
    private async _selectUv(selectedUv: string){
        this.setState({isLoading: true});
        const uvList = UV.GetUv().filter(uv => uv[1].toUpperCase() === selectedUv);

        try{
            if(uvList.length === 1){
                const psdImage = await PsdVisualizer.GetPsdImage(`${Config.PublicPath}/UV/${uvList[0][0]}`);
                this.props.onChange && this.props.onChange(selectedUv, undefined, psdImage);
            }
        }catch(e){
            pLog('SelettoreUv', 'e', "Errore nella selezione dell'uv");
        }

        this.setState({isLoading: false});
    }

    /**
     * Scarica il file psd selezionato dalla lista
     * @private
     */
    private _downloadPsd(){
        if(this._currentSelectorMode() === 'default'){
            const uvList = UV.GetUv().filter(uv => uv[1].toUpperCase() === this.props.currentImageName);
            if(uvList.length === 1){
                const link = document.createElement('a');
                link.href = `${Config.PublicPath}/UV/${uvList[0][0]}`;
                link.click();
            }
        }
    }

    /**
     * Effettua l'eliminazione del dato salvato
     * @private
     */
    private _deleteData(){
        this.props.onChange && this.props.onChange("", undefined, "");
    }

    public render() {
        return (
            <SectionLayout size={'largeRelative'} showBorder={this.props.addPaddingAndBorder} addPadding={this.props.addPaddingAndBorder}>
                <IfContainer condition={this._currentSelectorMode() === 'default'}>
                    <SelectLine
                        label={this.props.label}
                        placeHolder={this.props.placeHolder}
                        elements={UV.GetUv().map(uv => ({label: uv[1].toUpperCase(), image: `${Config.NoMapping}/Uv/${uv[1]}.jpg`}))}
                        value={this.props.currentImageName}
                        showError={this.props.showError}
                        errorMessage={this.props.errorMessage}
                        onChange={this._selectUv.bind(this)}
                        disabled={this.state.isLoading}/>
                </IfContainer>
                <IfContainer condition={!this.state.isLoading} elseComponent={this._sezioneLoader()}>
                    <IfContainer condition={UV.GetUv().map(uv => uv[1].toUpperCase()).includes(this.props.currentImageName)}>
                        <Button
                            content={"Scarica il psd del modello uv"}
                            type={"medium"}
                            alignment={"right"}
                            buttonType={'simple-text'}
                            onClick={this._downloadPsd.bind(this)}/>
                    </IfContainer>
                    <IfContainer condition={!!this.props.currentImage}>
                        <ImageVisualizer
                            storageData={this.props.currentImage}
                            onDelete={this._deleteData.bind(this)}/>
                    </IfContainer>
                </IfContainer>
                <IfContainer condition={!this.state.isLoading}>
                    <TextLine
                        label={'Carica uv personalizzato'}
                        type={'file'}
                        value={this._currentSelectorMode() === 'custom' ? this.props.currentImageName : ''}
                        onFileUpload={(fileName, fileType, file, base64Encoding) => {
                            if(fileType.toLowerCase().match('png|jpg|jpeg'))
                                this.props.onChange && this.props.onChange(fileName, file, base64Encoding);
                        }}/>
                </IfContainer>
            </SectionLayout>
        );
    }
}
