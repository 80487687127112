import React from "react";
import "./TwoSpaceLine.scss";
import ResponsiveLabel, {ResponsiveLabelType} from "../../Core/ResponsiveLabel/ResponsiveLabel";

export interface TwoSpaceLineProps{
    label: string,
    children?: any,
    reduce?: boolean,
    formMode?: boolean,
    labelType?: ResponsiveLabelType,
    wrapOnMobile?: boolean,
    showError?: boolean,
    onlyMobileError?: boolean
    errorMessage?: string,
}
export default class TwoSpaceLine extends React.Component<TwoSpaceLineProps, {}>{
    render() {
        return (
            <span className={`TwoSpaceLine ${this.props.onlyMobileError && 'onlyMobileError'} ${this.props.formMode && 'formMode'}`}>
                {
                    this.props.showError &&
                    this.props.errorMessage &&
                    <ResponsiveLabel content={this.props.errorMessage} type={"xsmall"} alignment={"right"} labelType={"errorLabel"}/>
                }
                <div className={`TwoSpaceContainer ${this.props.reduce && 'reduce'} ${this.props.wrapOnMobile === false && 'noWrap'}`}>
                    <ResponsiveLabel
                        content={`${this.props.label ? `${this.props.label}:` : ''}`}
                        type={"medium"}
                        alignment={"right"}
                        labelType={this.props.labelType}/>
                    <span className={`Content ${this.props.showError && "error"}`}>{this.props.children}</span>
                </div>
            </span>
        );
    }
}
