import React, {Fragment} from "react";
import CategorySwitcher from "../../../Primary/CategorySwitcher/CategorySwitcher/CategorySwitcher";
import CategorySwitcherElement
    from "../../../Primary/CategorySwitcher/CategorySwirtcherElement/CategorySwitcherElement";
import ImageService from "../../../Servicies/ImageService";
import VerticalSpace from "../../../Layout/VerticalSpace/VerticalSpace";
import IfContainer from "../../../Layout/IfContainer/IfContainer";
import {ConfiguratoreLayout} from "../../../PagesLayout/PagesLayout";
import RegistrazioneAgente from "./RegistrazioneAgente";
import ConfigurazioneAgenti from "./ConfigurazioneAgenti";

export interface AgentiState{
    sezioneCorrente: "creazioneAgente" | "configurazioneAgente";
}

export default class Agenti extends React.Component<{}, AgentiState>{
    constructor(props: Readonly<{}> | {}) {
        super(props);
        this.state = {
            sezioneCorrente: "creazioneAgente"
        }
    }

    public render() {
        return (
            <Fragment>
                <CategorySwitcher>
                    <CategorySwitcherElement
                        elementIndex={0}
                        icon={ImageService.getImage('userIcon')}
                        grow={true}
                        label={"Creazione agente"}
                        selected={this.state.sezioneCorrente === 'creazioneAgente'}
                        onClick={() => {this.setState({sezioneCorrente: "creazioneAgente"})}}/>
                    <CategorySwitcherElement
                        elementIndex={1}
                        icon={ImageService.getImage('gearIcon')}
                        grow={true}
                        label={"Configurazione agente"}
                        selected={this.state.sezioneCorrente === 'configurazioneAgente'}
                        onClick={() => {this.setState({sezioneCorrente: "configurazioneAgente"})}}/>
                </CategorySwitcher>
                <IfContainer condition={this.state.sezioneCorrente === 'creazioneAgente'}>
                    <RegistrazioneAgente/>
                </IfContainer>
                <IfContainer condition={this.state.sezioneCorrente === 'configurazioneAgente'}>
                    <ConfigurazioneAgenti/>
                </IfContainer>
            </Fragment>
        );
    }
}
