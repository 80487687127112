import React from "react";
import "./ConfigurazioneLine.scss";
import {
    ConfigurazioneFotografoRouteResponseModel,
    TipoConfigurazioneEnum
} from "tici_commons";
import ResponsiveLabel from "../../Core/ResponsiveLabel/ResponsiveLabel";
import ImageService from "../../Servicies/ImageService";
import {ConfigurazioneService} from "../../Servicies/ConfigurazioneService";

export interface ConfigurazioneLineProps {
    configurazione: ConfigurazioneFotografoRouteResponseModel,
    onEdit: () => void,
    onDelete: () => void
}

export default class ConfigurazioneLine extends React.Component<ConfigurazioneLineProps, {}>{
    /**
     * Effettua il download della configurazione
     * @private
     */
    private async _downloadConfigurazione(){
        const response = await ConfigurazioneService.GetDettaglioConfigurazione(this.props.configurazione.idConfigurazione);
        if(response) {
            const element = document.createElement('a');
            element.setAttribute('href', 'data:application/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(response)));
            element.setAttribute('download', `${this.props.configurazione.idConfigurazione}-${this.props.configurazione.nomeConfigurazione}.json`);
            element.click();
        }
    }

    /**
     * Effettua il mapping sul tipo di configurazione
     * @param tipoConfigurazione Tipologia di configurazione
     * @private
     */
    public static MappaTipoConfigurazione(tipoConfigurazione: TipoConfigurazioneEnum): string{
        let esito = {
            [`${TipoConfigurazioneEnum.Utente}`]: "Configurazione Personale",
            [`${TipoConfigurazioneEnum.Cliente}`]: "Configurazione Cliente",
            [`${TipoConfigurazioneEnum.Art}`]: "Art Project",
            [`${TipoConfigurazioneEnum.Junior}`]: "Junior Project",
            [`${TipoConfigurazioneEnum.Wedding}`]: "Wedding Project"

        }[`${tipoConfigurazione}`];

        return esito?? ''
    }

    /**
     * Effettua il mapping inverso della label di configurazione
     * @param tipoConfigurazione Tipo di configurazione da mappare
     * @constructor
     */
    public static MappaInversaTipoConfigurazione(tipoConfigurazione: string): number{
        let esito = 0;

        switch (tipoConfigurazione){
            case "Configurazione Utente":
                esito = TipoConfigurazioneEnum.Utente;
                break;
            case "Art Project":
                esito = TipoConfigurazioneEnum.Art;
                break;
            case "Junior Project":
                esito = TipoConfigurazioneEnum.Junior;
                break;
            case "Wedding Project":
                esito = TipoConfigurazioneEnum.Wedding;
                break;
        }

        return esito;
    }

    public render() {
        return (
            <span className={"ConfigurazioneLine"}>
                <ResponsiveLabel content={`${ConfigurazioneLine.MappaTipoConfigurazione(this.props.configurazione.tipoConfigurazione)}: ${this.props.configurazione.nomeConfigurazione}`} labelType={"whiteLabel"} type={"medium"} alignment={"left"}/>
                <span className={'buttonZone'}>
                    <img alt={"downloadIcon"} src={ImageService.getImage('downloadIcon')} onClick={() => this._downloadConfigurazione()}/>
                    <img alt={"penIcon"} src={ImageService.getImage('penIcon')} onClick={() => {this.props.onEdit && this.props.onEdit()}}/>
                    <img alt={"xIcon"} src={ImageService.getImage('xIcon')} onClick={() => {this.props.onDelete && this.props.onDelete()}}/>
                </span>
            </span>
        );
    }
}
