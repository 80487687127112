import React, {Fragment} from "react";
import ResponsiveLabel from "../../../../../../Core/ResponsiveLabel/ResponsiveLabel";
import TextArea from "../../../../../../Core/TextArea/TextArea";
import {StorageManagerStatus} from "../../../Storage/StorageManager";
import {
    GenerateGroup, GenerateRiepilogoData,
    GenerateRiepilogoInternalBox,
    RiepilogoBox,
    RiepilogoData,
    RiepilogoInternalBox
} from "../../../Commons/RiepilogoDatoModel";
import {SelectLine} from "../../../../../../Widgets/Configuratore/CComponents";
import SezioneGenerica from "../../SezioneGenerica";
import {FotolibroModel} from "tici_commons";
import CategorySectionLayout from "../../../../../../Layout/CategorySectionLayout/CategorySectionLayout";
import IfContainer from "../../../../../../Layout/IfContainer/IfContainer";
import {SupportaDorsoDritto} from "../../../Commons/Utils";

export interface SezioneInformazioniAggiuntiveCopertinaProps{
    formatoAlbumPrincipale: string
}

export default class SezioneInformazioniAggiuntiveCopertina extends SezioneGenerica<"copertina", SezioneInformazioniAggiuntiveCopertinaProps, {}> {
    public generaRiepilogo = (fotolibro: FotolibroModel, storage: StorageManagerStatus, modalitaConfiguratore: boolean): RiepilogoInternalBox | RiepilogoData[] | RiepilogoBox | RiepilogoInternalBox[] => {
        return GenerateRiepilogoInternalBox(
            'Informazioni aggiuntive',
            GenerateRiepilogoData(
                `Dorso: ${fotolibro.copertina.dorsoCopertina}`,
            ),
            ...GenerateGroup(
                fotolibro.copertina.noteAggiuntive.trim().length > 0,
                GenerateRiepilogoData(`Note: ${fotolibro.copertina.noteAggiuntive}`)
            )
        )
    }

    public render() {
        return (
            <CategorySectionLayout categoryLabel={"Informazioni aggiuntive"} size={"largeRelative"} center={true}>
                <IfContainer
                    condition={SupportaDorsoDritto(this.props.formatoAlbumPrincipale)}>
                    <SelectLine
                        label={"Dorso"}
                        placeHolder={"Seleziona"}
                        elements={[{label: "Standard"}, {label: "Dritto"}]}
                        onChange={v => this.$.dorsoCopertina = v}
                        value={this.$.dorsoCopertina}/>
                </IfContainer>
                <ResponsiveLabel content={"Note aggiuntive"} type={"medium"} alignment={"left"}/>
                <TextArea
                    maxLenght={300}
                    value={this.$.noteAggiuntive}
                    onChange={v => this.$.noteAggiuntive = v}/>
            </CategorySectionLayout>
        );
    }
}
