import React from "react";
import {ItemElementInterface} from "./ElementSelect";

export interface ItemElementProps extends ItemElementInterface{
    onClick?: (value: string) => void
}

export interface ItemElementState{
    imageUrl: string;
    imageScaleX: number;
    imageScaleY: number;
}

export default class ItemElement extends React.Component<ItemElementProps, ItemElementState>{
    constructor(props: Readonly<ItemElementProps> | ItemElementProps) {
        super(props);
        this.state = {
            imageUrl: '',
            imageScaleX: 1,
            imageScaleY: 1
        }
    }

    /**
     * Carica l'immagine da visualizzare
     * @private
     */
    private _lodaImage(){
        if(this.props.image){
            if(typeof this.props.image === 'string'){
                this.setState({imageUrl: this.props.image});
            }else{
                this.props.image.then(value => {
                    this.setState({imageUrl: value});
                }).catch(() => this.setState({imageUrl: ''}))
            }
        }
    }

    public componentDidMount() {
        this._lodaImage()
    }

    public componentDidUpdate(prevProps: Readonly<ItemElementProps>, prevState: Readonly<ItemElementState>, snapshot?: any) {
        if(prevProps.image !== this.props.image)
            this._lodaImage();
    }

    public render() {
        return (
            <span
                className={`ItemElement ${!this.props.onClick && "noSelect"} ${this.props.image && "image"}`}
                onClick={() => {
                    this.props.onClick && this.props.onClick(this.props.label)
                }}>
                {
                    this.props.image &&
                    <span className={"image"} style={{backgroundImage: `url("${this.state.imageUrl}")`}}/>
                }
                {this.props.label}
            </span>
        );
    }
}
