import React from "react";
import VerticalSpace from "../../../../../Layout/VerticalSpace/VerticalSpace";
import {CheckLine, SelectLine, TextLine} from "../../../../../Widgets/Configuratore/CComponents";
import {FotolibroModel, TipoConfigurazioneEnum} from "tici_commons";
import IfContainer from "../../../../../Layout/IfContainer/IfContainer";
import ResponsiveLabel from "../../../../../Core/ResponsiveLabel/ResponsiveLabel";
import Modello from "../../../../../DatabaseData/Modello";
import Formati from "../../../../../DatabaseData/Formati";
import CategorySectionLayout from "../../../../../Layout/CategorySectionLayout/CategorySectionLayout";

export interface CoordinatoData{
    modalitaCoordinato: boolean
    tipologiaCoordinato: TipoConfigurazioneEnum,
    vincoliFormato: [string, string][]
}

export interface SezioneCoordinatoProps{
    fotolibro: FotolibroModel,
    coordinatoData: CoordinatoData,
    updateCoordinatoData: (coordinatoData: CoordinatoData) => void
}

export interface SezioneCoordinatoState{
    isLoading: boolean
}

export default class SezioneCoordinato extends React.Component<SezioneCoordinatoProps, SezioneCoordinatoState>{
    constructor(props: Readonly<SezioneCoordinatoProps> | SezioneCoordinatoProps) {
        super(props);
        this.state = {
            isLoading: false
        }
    }

    private _updateCoordinatoData<T extends keyof CoordinatoData>(key: T, value: CoordinatoData[T]){
        const clone = JSON.parse(JSON.stringify(this.props.coordinatoData));
        clone[key] = value;
        this.props.updateCoordinatoData && this.props.updateCoordinatoData(clone);
    }

    /**
     * Mappa la label del fotolibro
     * @param label Label da mappare
     * @private
     */
    private _mappaLabelFotolibro(label: string): TipoConfigurazioneEnum{
        let esito = TipoConfigurazioneEnum.Wedding;
        switch (label){
            case "Wedding":
                esito = TipoConfigurazioneEnum.Wedding;
                break;
            case "Art":
                esito = TipoConfigurazioneEnum.Art;
                break;
            case "Junior":
                esito = TipoConfigurazioneEnum.Junior
                break;
        }
        return esito;
    }

    /**
     * Mappa la label del fotolibro
     * @param code Codice da mappare
     * @private
     */
    private _mappaLabelInversaFotolibro(code: TipoConfigurazioneEnum): string{
        let esito = "Wedding";
        switch (code){
            case TipoConfigurazioneEnum.Wedding:
                esito = "Wedding";
                break;
            case TipoConfigurazioneEnum.Art:
                esito = "Art";
                break;
            case TipoConfigurazioneEnum.Junior:
                esito = "Junior"
                break;
        }
        return esito;
    }

    /**
     * Restituisce il valore dal formato
     * @param formato Formato di cui recuperare il dato
     * @private
     */
    private _getValueFromConfigurazione(formato: string): string{
        let esito = "";
        const map = new Map(this.props.coordinatoData.vincoliFormato);
        if(map.has(formato)){
            esito = map.get(formato);
        }
        return esito;
    }

    /**
     * Aggiorna la riga formato della configurazione
     * @param formato Formato da aggiornare
     * @param valore Valore da settare nel formato
     * @private
     */
    private _aggiornaValoreConfigurazione(formato: string, valore: string){
        const map = new Map(this.props.coordinatoData.vincoliFormato);
        map.set(formato, valore);
        this._updateCoordinatoData("vincoliFormato", Array.from(map));
    }

    public render() {
        return (
            <CategorySectionLayout categoryLabel={"Coordinato"} size={"largeRelative"} center={true}>
                <CheckLine
                    label={"Configurazione coordinato"}
                    checkType={"switch"}
                    checked={this.props.coordinatoData.modalitaCoordinato}
                    onChange={v => this._updateCoordinatoData("modalitaCoordinato", v)}
                    reduce={true}/>
                <IfContainer condition={this.props.coordinatoData.modalitaCoordinato}>
                    <TextLine
                        label={"Nome coordinato"}
                        value={this.props.fotolibro.informazioniBase.nomeProgetto}
                        errorMessage={"Bisogna inserire il nome del coordinato nella sezione informazioni base"}
                        showError={this.props.fotolibro.informazioniBase.nomeProgetto.length === 0}
                        disabled={true}/>
                    <TextLine
                        label={"Modello copertina"}
                        value={this.props.fotolibro.copertina.modelloCopertina}
                        disabled={true}/>
                    <TextLine
                        label={"Modello custodia"}
                        value={this.props.fotolibro.custodia.modelloCustodia}
                        disabled={true}/>
                    <SelectLine
                        label={"Tipo coordinato"}
                        placeHolder={"Seleziona"}
                        elements={[{label: 'Wedding'}, {label: "Junior"}, {label: "Art"}]}
                        value={this._mappaLabelInversaFotolibro(this.props.coordinatoData.tipologiaCoordinato)}
                        onChange={v => this._updateCoordinatoData('tipologiaCoordinato', this._mappaLabelFotolibro(v))}/>
                    <VerticalSpace gapMobile={10} gapTablet={20} gapDesktop={30}/>
                    <ResponsiveLabel
                        content={"Configurazione formati ridotti"}
                        type={"large"}
                        alignment={"center"}/>
                    <ResponsiveLabel
                        content={"Inserisci nel formato csv i formati per i ridotti in relazione al formato grande"}
                        type={"medium"}
                        alignment={"center"}/>
                    <ResponsiveLabel
                        content={"ESEMPIO: 30x40: 30x25;10x23;...;10x15"}
                        type={"medium"}
                        alignment={"center"}/>
                    {
                        Modello.VincoliFormato(this.props.fotolibro.custodia.modelloCustodia).map(vincolo => (
                            <TextLine
                                label={`Formato: ${Formati.GetFromatoFromId(vincolo)}`}
                                value={this._getValueFromConfigurazione(Formati.GetFromatoFromId(vincolo))}
                                onChange={v => this._aggiornaValoreConfigurazione(Formati.GetFromatoFromId(vincolo), v)}/>
                        ))
                    }
                </IfContainer>
            </CategorySectionLayout>
        );
    }
}
