import {FotolibroModel, NomeSezioneInformazioneType} from "tici_commons";
import React from "react";
import {StorageDataMapper, StorageManagerStatus} from "../../../Storage/StorageManager";
import DragAndDropMultifunzionale from "../../../../../../Primary/DragAndDropMultifunzionale/DragAndDropMultifunzionale";
import TopLevelComponentStorage from "../../../../../../Core/Arch/TopLevelComponentStorage";
import {
    GenerateRiepilogoData,
    GenerateRiepilogoInternalBox,
    RiepilogoBox,
    RiepilogoData,
    RiepilogoInternalBox
} from "../../../Commons/RiepilogoDatoModel";
import {SortFogliWithCartaExtra} from "../../../Commons/Utils";
import SezioneGenerica from "../../SezioneGenerica";
import LocalStorageService from "../../../../../../Servicies/LocalStorageService";

export default class SezioneUploaderMultifunzionale extends SezioneGenerica<"informazioniBase", {}, {}>{
    public generaRiepilogo = (fotolibro: FotolibroModel, storage: StorageManagerStatus, modalitaConfiguratore: boolean): RiepilogoInternalBox | RiepilogoData[] | RiepilogoBox | RiepilogoInternalBox[] => {
        const esito: RiepilogoInternalBox = GenerateRiepilogoInternalBox('Carta extra');

        const organizzatoreCarte = new Map<string, RiepilogoData[]>();
        fotolibro.informazioniBase.lavorazioniCartaExtra.sort(SortFogliWithCartaExtra).forEach(cartaExtra => {
            if(!organizzatoreCarte.has(cartaExtra[1]))
                organizzatoreCarte.set(cartaExtra[1], []);
            organizzatoreCarte.get(cartaExtra[1]).push(GenerateRiepilogoData(`Foglio ${cartaExtra[0]}: ${cartaExtra[1]}`));
        });

        for(const carta of organizzatoreCarte.keys()){
            esito.riepilogoData.push(GenerateRiepilogoData(`Quantita ${carta}: ${organizzatoreCarte.get(carta).length}`));
            for(const entryCarta of organizzatoreCarte.get(carta)){
                esito.riepilogoData.push(entryCarta);
            }
        }

        for(const cartoncino of fotolibro.informazioniBase.lavorazioniCartoncini){
            esito.riepilogoData.push(GenerateRiepilogoData(`Posizione ${cartoncino[0]}: ${cartoncino[1]}`));
        }

        return esito
    }

    /**
     * Gestisce l'evento di modifica dei fogli caricati per le lavorazioni
     * @param nomeFoglio Nome del foglio da eliminare
     * @private
     */
    private _updateLavorazioni(nomeFoglio: string){
        const stampe: string[] = this.props.storage.GetStorageData(StorageDataMapper.StampeFogli).map(stampa => stampa.name);

        //Eliminiamo la carta extra e i cartoncini
        const cartaExtra = this.$.lavorazioniCartaExtra.filter(lavorazione => stampe.includes(lavorazione[0]));
        const cartoncini = this.$.lavorazioniCartoncini.filter(cartoncino => cartoncino[0] <= stampe.length + this.$.lavorazioniCartoncini.length);

        this.$.lavorazioniCartaExtra = cartaExtra;
        this.$.lavorazioniCartoncini = cartoncini;

        //Eliminiamo dallo storage le lavorazioni
        this._eliminaLavorazione(nomeFoglio);
    }

    /**
     * Elimina una carta extra
     * @param nomeFile Nome del file su cui è associata la carta extra da cancellare
     * @private
     */
    private _cancellaCartaExtra(nomeFile: string){
        let lavorazioniCartaExtra = new Map(this.$.lavorazioniCartaExtra);
        lavorazioniCartaExtra.delete(nomeFile);
        this.$.lavorazioniCartaExtra = Array.from(lavorazioniCartaExtra);
    }

    /**
     * Inserisce una nuova carta extra
     * @param immaginiSelezionate Immagini selezionate a cui associare la carta extra
     * @param cartaExtraSelezionata Carta extra da associare alle immagini
     * @private
     */
    private _aggiungiCartaExtraHandle(immaginiSelezionate: string[], cartaExtraSelezionata: string){
        const lavorazioniCartaExtra = new Map(this.$.lavorazioniCartaExtra);
        for(const immagine of immaginiSelezionate){
            if(!lavorazioniCartaExtra.has(immagine))
                lavorazioniCartaExtra.set(immagine, cartaExtraSelezionata);
        }
        this.$.lavorazioniCartaExtra = Array.from(lavorazioniCartaExtra);
    }

    /**
     * Gestisce l'evento di eliminazione di un immagine dallo storage
     * @param nomeImmagine Nome dell'immagine da eliminare
     * @private
     */
    private _deleteImmagineHandle(nomeImmagine: string){
        this.DeleteImmagine(StorageDataMapper.StampeFogli, nomeImmagine);
        this._updateLavorazioni(nomeImmagine);
    }

    /**
     * Inserisce un nuovo cartoncino
     * @param cartoncinoPosizioneSelezionata Posizione su cui inserire il cartoncino
     * @param cartoncinoSelezionato Cartoncino selezionato
     * @private
     */
    private _aggiungiCartoncinoHandler(cartoncinoPosizioneSelezionata: number, cartoncinoSelezionato: string){
        const cartoncini = new Map(this.$.lavorazioniCartoncini);
        if(!cartoncini.has(cartoncinoPosizioneSelezionata)) {
            cartoncini.set(cartoncinoPosizioneSelezionata, cartoncinoSelezionato);
            this.$.lavorazioniCartoncini = Array.from(cartoncini);
        }else{
            TopLevelComponentStorage.GetTopLevel('confirmWindow').showConfirmWindow(
                'Posizione sbagliata',
                "Nella posizione indicata è già presente un cartoncino",
                'SingleButton'
            );
        }
    }

    /**
     * Effettua l'eliminazione di un cartoncino
     * @param posizione Posizione del cartoncino da eliminare
     * @private
     */
    private _cancellaCartoncino(posizione: number){
        const cartoncini = new Map(this.$.lavorazioniCartoncini);
        if(cartoncini.has(posizione)) {
            cartoncini.delete(posizione);
            this.$.lavorazioniCartoncini = Array.from(cartoncini);
        }
    }

    /**
     * Aggiunge una lavorazione
     * @param nomeImmagine Nome dell'immagine su cui inserire la lavorazione
     * @param nomeLavorazione Nome della lavorazione da inserire
     * @param lavorazioneSezioni Sezioni della lavorazione che si sta inserendo se è con aggiunte
     * @private
     */
    private _aggiungiLavorazioneHandle(nomeImmagine: string, nomeLavorazione: string, lavorazioneSezioni?: NomeSezioneInformazioneType[]): boolean{
        let esitoInserimento = false;
        if(nomeImmagine && nomeLavorazione){
            const hasLavorazione = new Map(this.$.lavorazioniConAggiunte).has(nomeImmagine) || new Map(this.$.lavorazioniLavorazione).has(nomeImmagine)
            if(!hasLavorazione){
                if(lavorazioneSezioni !== undefined){
                    let lavorazioniLavorazioneConAggiunte = new Map(this.$.lavorazioniConAggiunte);

                    if(LocalStorageService.SezioniObbligatorieLavorazione === lavorazioneSezioni.length){
                        lavorazioniLavorazioneConAggiunte.set(
                            nomeImmagine,
                            {
                                nomeLavorazione,
                                aggiunteLavorazione: lavorazioneSezioni.map(sezione => [sezione[0], sezione[1].informazione])
                            }
                        );
                        this.$.lavorazioniConAggiunte = Array.from(lavorazioniLavorazioneConAggiunte);
                        esitoInserimento = true;
                    }else{
                        TopLevelComponentStorage.GetTopLevel('confirmWindow').showConfirmWindow(
                            'Errore inserimento',
                            "Alcune informazioni della lavorazione non sono state compilate"
                        );
                    }
                }else{
                    let lavorazioniLavorazione = new Map(this.$.lavorazioniLavorazione);
                    lavorazioniLavorazione.set(nomeImmagine, nomeLavorazione);
                    this.$.lavorazioniLavorazione = Array.from(lavorazioniLavorazione);
                    esitoInserimento = true;
                }
            }
        }
        return esitoInserimento;
    }

    /**
     * Elimina una singola lavorazione dallo storage e dall'array target
     * @param nomeFoglio Nome del foglio da eliminare
     * @private
     */
    private _eliminaLavorazione(nomeFoglio: string){
        //Prendiamo l'array di lavorazioni che ci interessa lavorare e lo trasformiamo in una mappa
        let lavorazioneConAggiunte = new Map(this.$.lavorazioniConAggiunte).has(nomeFoglio);
        const targetMap = lavorazioneConAggiunte ? new Map(this.$.lavorazioniConAggiunte) : new Map(this.$.lavorazioniLavorazione);

        if(targetMap.has(nomeFoglio)){
            //Eliminiamo dalla mappa il foglio che è stato rimosso e inizializziamo l'object che dovrà essere successivamente inserito nelle informazioni del fotolibro
            targetMap.delete(nomeFoglio);
            if(lavorazioneConAggiunte)
                this.$.lavorazioniConAggiunte = Array.from(targetMap as any);
            else this.$.lavorazioniLavorazione = Array.from(targetMap as any);

            if(lavorazioneConAggiunte) {
                //Eliminiamo dallo storage i dati
                const lavorazioniStorageData = this.props.storage.GetStorage().filter(storage => storage.category.includes(`lavorazione-${nomeFoglio}`));
                for(const storageData of lavorazioniStorageData){
                    this.DeleteImmagine(storageData.category)
                }
            }
        }
    }

    public render() {
        return (
            <DragAndDropMultifunzionale
                immagini={this.ImmaginiDaCategoria(StorageDataMapper.StampeFogli)}
                indicatoreMassimoFogli={this.$.numeroFogli}
                modificheCartaExtra={true}
                modificheLavorazioni={true}
                onUpload={file => this.UplodaImmagine(StorageDataMapper.StampeFogli, file.name, file)}
                onDelete={nomeImmagine => this._deleteImmagineHandle(nomeImmagine)}
                cartaExtra={this.$.lavorazioniCartaExtra}
                aggiungiCartaExtra={this._aggiungiCartaExtraHandle.bind(this)}
                onDeleteCartaExtra={this._cancellaCartaExtra.bind(this)}
                cartoncini={this.$.lavorazioniCartoncini}
                aggiungiCartoncino={this._aggiungiCartoncinoHandler.bind(this)}
                onDeleteCartoncino={this._cancellaCartoncino.bind(this)}
                lavorazioni={this.$.lavorazioniLavorazione}
                lavorazioniConAggiunte={this.$.lavorazioniConAggiunte}
                aggiungiLavorazione={this._aggiungiLavorazioneHandle.bind(this)}
                onDeleteLavorazione={this._eliminaLavorazione.bind(this)}
                formatoAlbumPrincipale={this.$.formatoAlbum}/>
        );
    }
}
