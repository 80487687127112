import {
    ConfigurazionePostBodyInterface, ConfigurazioneRenderizzatoreInterface,
    MaterialiPostBodyInterface,
    ModelloPostBodyInterface,
    Rest,
    SpessoreMateriale,
    TipoModelloType
} from "tici_commons";
import Config from "../Static/Config";

export default class SuperUserService{
    public static async EliminaMateriale(nomeMateriale: string, famigliaMateriale: string, lussuriaMateriale: string): Promise<boolean>{
        let esito = false;

        try {
            const response = await Rest.Delete(`${Config.PrivatePath}/Materiali/${nomeMateriale}/${famigliaMateriale}/${lussuriaMateriale}`);
            if(response && response.statusCode === 200)
                esito = true;
        }catch (e){
            console.error("Non é stato possibile eliminare il materiale");
        }

        return esito;
    }

    public static async AggiungiMateriale(
        nomeMateriale: string,
        famigliaMateriale: string,
        lussuriaMateriale: string,
        spessoreMateriale: SpessoreMateriale,
        nomeImmagineMateriale: string,
        immagineMaterialeBase64: string,
        uvFlag: boolean
    ): Promise<boolean>{
        let esito = false;

        try {
            const body: MaterialiPostBodyInterface = {
                nomeMateriale,
                famigliaMateriale,
                lussuriaMateriale,
                spessoreMateriale,
                nomeImmagineMateriale,
                immagineMaterialeBase64,
                uvFlag
            }
            const response = await Rest.Post(`${Config.PrivatePath}/Materiali`, undefined, body);
            if(response && response.statusCode === 200)
                esito =true;
        }catch (e){
            console.error("Non é stato possibile aggiungere il materiale", String(e));
        }

        return esito;
    }

    public static async AggiungiModello(
        nomeModello: string,
        tipoModello: TipoModelloType,
        modelloRidottoCofanettoFlag: boolean,
        modelloRidottoCofanettoFileFlag: boolean,
        modelloRidottoCofanettoUvFlag: boolean,
        nomeConfigurazione: string
    ): Promise<boolean>{
        let esito = false;

        try {
            const body: ModelloPostBodyInterface = {
                nomeModello,
                tipoModello,
                modelloRidottoCofanettoFlag,
                modelloRidottoCofanettoFileFlag,
                modelloRidottoCofanettoUvFlag,
                nomeConfigurazione
            }
            const response = await Rest.Post(`${Config.PrivatePath}/Modello`, undefined, body);
            if(response && response.statusCode === 200)
                esito = true;
        }catch (e){
            console.error("Non é stato possibile aggiungere un modello", String(e));
        }

        return esito;
    }

    public static async EliminaModello(nomeModello: string): Promise<boolean>{
        let esito = false;

        try {
            const response = await Rest.Delete(`${Config.PrivatePath}/Modello/${nomeModello}`);
            if(response && response.statusCode === 200)
                esito = true;
        }catch (e){
            console.error("Non é stato possibile eliminare un modello", String(e));
        }

        return esito;
    }

    public static async AggiungiConfigurazione(
        nomeConfigurazione: string,
        nomeModello: string,
        modelloBase64: string,
        configurazione: ConfigurazioneRenderizzatoreInterface,
        put = false
    ): Promise<boolean>{
        let esito = false;

        try{
            const body: ConfigurazionePostBodyInterface = {
                nomeConfigurazione,
                nomeModello,
                modelloBase64,
                configurazione
            }

            const method = put ? Rest.Put : Rest.Post;
            const response = await method(`${Config.PrivatePath}/Configurazione`, undefined, body);
            if(response && response.statusCode === 200)
                esito = true;
        }catch (e){
            console.error("Non é stato possibile aggiungere una configurazione", String(e));
        }

        return esito;
    }

    public static async EliminaConfigurazione(nomeConfigurazione: string): Promise<boolean>{
        let esito = false;

        try{
            const response = await Rest.Delete(`${Config.PrivatePath}/${nomeConfigurazione}`);
            if(response && response.statusCode === 200)
                esito = true;
        }catch (e){
            console.error("Non é stato possibile eliminare una configurazione", String(e));
        }

        return esito;
    }
}
