import React from "react";
import "./Fr2frLayout.scss";
import IfContainer from "../IfContainer/IfContainer";

export interface Fr2frLayoutProps {
    children: any,
    disabled?: boolean
}

export default class Fr2frLayout extends React.Component<Fr2frLayoutProps, {}>{
    public render() {
        return (
            <IfContainer
                condition={this.props.disabled === undefined || !this.props.disabled}
                elseComponent={this.props.children}>
                <div className={"Fr2frLayout"}>
                    {this.props.children}
                </div>
            </IfContainer>

        );
    }
}
