import Config from "../Static/Config";
import {ItemElementInterface} from "../Primary/Selects/ElementSelect/ElementSelect";
import {FormatoInterface, Rest} from "tici_commons";
import {CoordinatoData} from "../Pages/SitoInterno/Configuratore/Sezioni/Riepilogo/SezioneCoordinato";

export default class Formati{
    private static _formati: FormatoInterface[] = [];
    static {
        console.warn("Loading formati");
        Rest.Get<FormatoInterface[]>(`${Config.PublicPath}/Formati`).then(value => {
            if(value && value.statusCode === 200)
                Formati._formati = value.response;
        });
    }

    /**
     * Effettua il filtro dei formati caricati dal database
     * @param orientamento Orientamento target da recuperare
     * @param ridotto Flag di controllo del ridotto, se impostato su true verranno restituiti solo gli album ridotti
     * @param refR Riferimento del formato per gli album ridotti
     * @constructor
     */
    public static GetFormati(orientamento: string | '*', ridotto = false, refR = ""): ItemElementInterface[]{
        const esito: string[] = [];

        for(const data of Formati._formati){
            let label = "";
            if(data.orientamento === orientamento.toLowerCase() || orientamento === '*') {
                if((ridotto && data.ridotto === 1 && refR.includes(data.ref)) || (!ridotto && data.ridotto === 0))
                    label = data.formato;

                if(label.length !== 0){
                    if(data.sicily) label = `${label} (Sicily)`;
                    else if(data.panoramico) label = `${label} (Panoramico)`;

                    if(!esito.includes(label))
                        esito.push(label);
                }
            }
        }

        return esito.map(formato => ({label: formato}));
    }

    /**
     * Restituisce i formati vincolati per il coordinato
     * @param items Item da vincolare
     * @param coordinatoData Dati del coordinato
     * @constructor
     */
    public static GetFormatiVincolatiCoordinato(items: ItemElementInterface[], coordinatoData: CoordinatoData): ItemElementInterface[]{
        const esito = [];
        if(items && coordinatoData){

            const vincoli = coordinatoData.vincoliFormato.map(vincoli => vincoli[0]);
            esito.push(...new Set(items.filter(item => vincoli.includes(item.label))));
        }
        return esito;
    }

    /**
     * Restituisce i formati ridotti per il singolo formato grande nella modalità coordinato
     * @param formatoAlbumPrincipale Formato attuale dell'album principale
     * @param coordinatoData Dati del coordinato
     * @constructor
     */
    public static GetFormatiRidottiVincolatiCoordinato(formatoAlbumPrincipale: string, coordinatoData: CoordinatoData): ItemElementInterface[]{
        const esito: ItemElementInterface[] = [];
        if(formatoAlbumPrincipale && coordinatoData){
            console.log(coordinatoData.vincoliFormato, ".", formatoAlbumPrincipale);
            const map = new Map(coordinatoData.vincoliFormato);
            if(map.has(formatoAlbumPrincipale)){
                const formatiRidotti = map.get(formatoAlbumPrincipale);
                const splittedValues = formatiRidotti.split(';');
                for(const splittedValue of splittedValues){
                    if(splittedValue.trim().length > 0 && splittedValue.toLowerCase().split('x').length === 2)
                        esito.push({label: splittedValue.toLowerCase()});
                }
            }
        }
        return esito;
    }

    public static GetIdFromFormato(formato: string): number{
        let esito = -1;

        for(const data of Formati._formati){
            if(data.ridotto === 0 && formato === data.formato){
                esito = data.id;
            }
        }

        return esito;
    }

    public static GetFromatoFromId(id: number): string{
        let esito = "";

        for(const data of Formati._formati){
            if(data.ridotto === 0 && id === data.id){
                esito = data.formato;
            }
        }

        return esito;
    }

    /**
     * Restituisce l'aspect ratio dal formato
     * @param formato Formato da cui estrapolare l'aspect ratio
     * @constructor
     */
    public static GetAspectRatioFromFormato(formato: string): number{
        let esito = 1;
        if(formato){
            const parts = formato.toLowerCase().replace(" ", "").split("x");
            if(parts.length === 2){
                const p1 = parseFloat(parts[0].trim());
                const p2 = parseFloat(parts[1].trim());
                if(!isNaN(p1) && !isNaN(p2))
                    esito = p1 / p2;
            }

        }

        return esito;
    }
}
