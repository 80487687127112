import React from "react";
import "./AddressLayout.scss";

export default class AddressLayout extends React.Component<{ children: any }, {}>{
    public render() {
        return (
            <div className={"AddressLayout"}>
                {this.props.children}
            </div>
        );
    }
}
