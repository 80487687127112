import React, {ReactNode} from "react";
import ModalLayout from "../../../Layout/ModalLayout/ModalLayout";
import ResponsiveLabel from "../../../Core/ResponsiveLabel/ResponsiveLabel";
import Loader from "../../../Core/Loader/Loader";
import SectionLayout from "../../../Layout/SectionLayout/SectionLayout";

export interface LoadingWindowState{
    title: string,
    content: string,
    isOpen: boolean,
}

export interface LoadingWindowContextState{
    showLoadingWindow: (title: string, content: string) => void,
    hideLoadingWindow: () => void
}

export const LoadingWindowContext = React.createContext<LoadingWindowContextState>(undefined);

export default class LoadingWindow extends React.Component<{children?: any}, LoadingWindowState>{
    constructor(props: Readonly<{}> | {}) {
        super(props);
        this.state = {
            title: "Titolo",
            content: "Contenuto",
            isOpen: false
        }
    }

    private _showLoadingWindow(title: string, content: string){
        this.setState({title: title, content: content, isOpen: true});
    }

    private _hideLoadingWindow(){
        this.setState({isOpen: false})
    }

    private _openStateChange(openState: boolean){
        this.setState({isOpen: openState});
    }

    public render(): ReactNode {
        return (
            <LoadingWindowContext.Provider value={{showLoadingWindow: this._showLoadingWindow.bind(this), hideLoadingWindow: this._hideLoadingWindow.bind(this)}}>
                <ModalLayout
                    isOpen={this.state.isOpen}
                    openStateChange={this._openStateChange.bind(this)}
                    header={
                        <ResponsiveLabel content={this.state.title} type={"large"} alignment={"center"}/>
                    }
                    body={
                        <SectionLayout size={"largeRelative"}>
                            <ResponsiveLabel content={this.state.content} type={"medium"} alignment={"center"}/>
                            <Loader/>
                        </SectionLayout>
                    }>
                </ModalLayout>
                {this.props.children}
            </LoadingWindowContext.Provider>
        );
    }
}
