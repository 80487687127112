import "./ChatOverlay.scss";

import React, {Fragment, RefObject} from "react";
import ChatOverlayHead from "./ChatOverlayHead/ChatOverlayHead";
import ChatOverlaySender from "./ChatOverlaySender/ChatOverlaySender";
import {ChatModel, UserContextResponseModel} from "tici_commons";
import AutoRefreshComponent from "../../../Core/Arch/AutoRefreshComponent";
import ChatOverlayMessage from "./ChatOverlayMessage/ChatOverlayMessage";
import ChatService from "../../../Servicies/ChatService";
import UserService from "../../../Servicies/UserService";

export interface ChatOverlayState{
    chatOpen: boolean,
    mouseDownStatus: boolean,
    messages: ChatModel[],

    userContext: UserContextResponseModel
}

export interface ChatOverlayProps{
    title: string
    ordineId?: number,
    onClose?: () => void,
}

export default class ChatOverlay extends AutoRefreshComponent<ChatOverlayProps, ChatOverlayState> {
    private _cicloOutbound: number;                                                     //Ciclo per evitare l'outbound del container
    private _chatContainer: RefObject<HTMLDivElement> = React.createRef();              //Riferimento al chatContainer
    private _messagesContainer: RefObject<HTMLDivElement> = React.createRef();          //Container dei messaggi

    private _lastMouseX  = -1;                      //Ultima posizione X del mouse
    private _lastMouseY  = -1;                      //Ultima posizione Y del mouse

    private _currentChatIndex = -1;                 //Contatore dei messaggi della chat. Utilizzato per aggiornare i messaggi della chat

    constructor(props: Readonly<ChatOverlayProps> | ChatOverlayProps) {
        super(props);
        this.state = {
            chatOpen: true,
            mouseDownStatus: false,
            messages: [],

            userContext: undefined
        }

        UserService.GetUserContext().then(userContext => this.setState({userContext}));

        this.Delay = 5000;
    }

    public componentDidMount() {
        super.componentDidMount();
        window.addEventListener("mousemove", (ev) => {
            this._mouseMoving(ev);
        });
        window.addEventListener("mouseup", () => {
            this._resetDownStatus();
        });
        this._cicloOutbound = window.setInterval(() => this._avoidOutbound(), 100);
    }

    public componentWillUnmount() {
        super.componentWillUnmount();
        window.clearInterval(this._cicloOutbound);
    }

    /**
     * Aggiorna la lista dei messaggi
     */
    public cycleChatMessages = async () => {
        const currentMessages = this.state.messages;
        const newMessages = await ChatService.RecuperaMessaggiOrdine(this.props.ordineId);

        let addedMessage = false;
        if(newMessages.length > 0){
            for(const message of newMessages){
                if(message.id > this._currentChatIndex) {
                    this._currentChatIndex = message.id;
                    currentMessages.push(message);
                    addedMessage = true;
                    if(message.lettoDa === 0)
                        await ChatService.LeggiMessaggio(message.id);
                }
            }

            addedMessage && this.setState({messages: currentMessages}, () => {
                if(this._messagesContainer.current)
                    this._messagesContainer.current.scrollTop = this._messagesContainer.current.scrollHeight;
            });
        }
    }

    /**
     * Effettua il reset dello stato di down del mouse
     * @private
     */
    private _resetDownStatus() {
        this.setState({mouseDownStatus: false});
        this._lastMouseX = -1;
        this._lastMouseY = -1;
    }

    /**
     * Esegue la logica per il movimento della finestra di chat
     * @private
     */
    private _mouseMoving(ev: MouseEvent){
        if(this.state.mouseDownStatus){
            if(this._lastMouseX === -1)
                this._lastMouseX = ev.clientX;
            if(this._lastMouseY === -1)
                this._lastMouseY = ev.clientY;

            const dX = ev.clientX - this._lastMouseX;
            const dY = ev.clientY - this._lastMouseY;

            this._lastMouseX = ev.clientX;
            this._lastMouseY = ev.clientY;

            if(this._chatContainer && this._chatContainer.current){
                const native = this._chatContainer.current;

                const nX = native.getBoundingClientRect().x;
                const nY = native.getBoundingClientRect().y;
                const nX1 = nX + native.getBoundingClientRect().width;
                const nY1 = nY + native.getBoundingClientRect().height;

                //Bounding control
                if(nX + dX >= 0 && nX1 + dX <= window.innerWidth)
                    native.style.setProperty("--chatX", `${nX + dX}px`);
                if(nY + dY >= 0 && nY1 + dY <= window.innerHeight)
                    native.style.setProperty("--chatY", `${nY + dY}px`);
            }
        }
    }

    /**
     * Evita l'outbound della finestra
     */
    private _avoidOutbound = () => {
        if(this._chatContainer && this._chatContainer.current){
            const native = this._chatContainer.current;

            const nX = native.getBoundingClientRect().x;
            const nY = native.getBoundingClientRect().y;
            const nX1 = native.getBoundingClientRect().width;
            const nY1 = native.getBoundingClientRect().height;

            if(nX < 0)
                native.style.setProperty("--chatX", `0px`);
            if(nX + nX1 > window.innerWidth)
                native.style.setProperty("--chatX", `${window.innerWidth - nX1}px`);
            if(nY < 0)
                native.style.setProperty("--chatY", `0px`);
            if(nY + nY1 > window.innerHeight)
                native.style.setProperty("--chatY", `${window.innerHeight - nY1}px`);
        }
    }

    public render() {
        return (
            <Fragment>
                <div ref={this._chatContainer} className={`ChatOverlay ${this.state.mouseDownStatus && "drag"}`}>
                    <ChatOverlayHead
                        chatTitle={this.props.title}
                        isOpen={this.state.chatOpen}
                        onMousePressChange={(pressed => {
                            if(pressed)
                                this.setState({mouseDownStatus: true})
                            else this._resetDownStatus();
                        })}
                        onChangeOpenStatus={(newStatus) => this.setState({chatOpen: newStatus})}
                        onClose={() => this.props.onClose && this.props.onClose()}/>
                    <div className={`ChatContainer ${!this.state.chatOpen && "closed"}`}>
                        <Fragment>
                            <div ref={this._messagesContainer} className={"MessagesContainer"}>{
                                //Stampa dei messaggi ricevuti
                                this.state.messages.map(message => (
                                    <ChatOverlayMessage
                                        key={`${message.id}-${message.mittente}`}
                                        message={message}
                                        owner={this.state.userContext ? message.mittente === this.state.userContext.email : false}/>
                                ))
                            }</div>
                            <ChatOverlaySender
                                ordineId={this.props.ordineId}
                                onSendMessage={() => this.cycleChatMessages()}/>
                        </Fragment>
                    </div>
                </div>
            </Fragment>
        );
    }
}
