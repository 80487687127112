import React from "react";
import {CheckLine, ImageVisualizer, SelectLine, TextLine} from "../../../../../Widgets/Configuratore/CComponents";
import IfContainer from "../../../../../Layout/IfContainer/IfContainer";
import SectionLayout from "../../../../../Layout/SectionLayout/SectionLayout";
import {ConfigurazioneSezioneInterface, TipologiaMaterialeSupportato} from "tici_commons";

export interface MenuSezioneProps{
    configurazioneSezione: ConfigurazioneSezioneInterface,
    onChange: (configurazioneSezione: ConfigurazioneSezioneInterface) => void,
    listaGruppi: string[],
    listaSezioni: string[]
}

export default class MenuSezione extends React.Component<MenuSezioneProps, {}>{
    /**
     * Aggiorna la configurazione della sezione
     * @param key Chiave del valore da modificare
     * @param value Valore da inserire al posto della chiave
     * @param resetFlag Se impostato su true applica la logica di reset dei flag
     * @private
     */
    private _updateConfigurazioneSezione<T extends keyof ConfigurazioneSezioneInterface>(key: T, value: ConfigurazioneSezioneInterface[T], resetFlag = false){
        const reset = {
            trasparenteFlag: false,
            singoloColoreFlag: false,
            legnoFlag: false,
            codiceUvFlag: false,
            customFlag: false,
            customPlexFlag: false,
            customTelaFlag: false,
            plexFlag: false,
            singolaImmagineFlag: false,
            singolaImmagine: "",
            singolaIMmagineName: ""
        }

        if(this.props.configurazioneSezione){
            const newConfigurazione = {...this.props.configurazioneSezione, ...(resetFlag ? reset : {}), [key]: value};
            this.props.onChange && this.props.onChange(newConfigurazione);
        }
    }

    public render() {
        return (
            this.props.configurazioneSezione &&
            <SectionLayout size={"largeRelative"} addPadding={true} showBorder={true} order={this.props.configurazioneSezione.ordine} lightBackground={true}>
                <TextLine
                    label={"Nome reale"}
                    value={this.props.configurazioneSezione.nomeReale}
                    disabled={true}/>
                <CheckLine
                    label={"Non visibile"}
                    checkType={"switch"}
                    checked={this.props.configurazioneSezione.nonVisibileFlag}
                    onChange={value => this._updateConfigurazioneSezione("nonVisibileFlag", value, true)}/>
                <IfContainer condition={!this.props.configurazioneSezione.nonVisibileFlag}>
                    <TextLine
                        label={"Nome visualizzato"}
                        value={this.props.configurazioneSezione.nomeVisualizzato}
                        onChange={value => this._updateConfigurazioneSezione("nomeVisualizzato", value)}/>
                    <SelectLine
                        label={"Gruppo"}
                        placeHolder={"Seleziona"}
                        elements={this.props.listaGruppi && this.props.listaGruppi.map(value => ({label: value}))}
                        autocompleteMode={true}
                        value={this.props.configurazioneSezione.nomeGruppo}
                        onChange={value => this._updateConfigurazioneSezione("nomeGruppo", value)}/>
                    <TextLine
                        label={"Ordine"}
                        type={"number"}
                        value={this.props.configurazioneSezione.ordine.toString()}
                        onChange={value => this._updateConfigurazioneSezione("ordine", parseInt(value))}/>
                    <TextLine
                        label={"Colore sezione (esadecimale)"}
                        value={this.props.configurazioneSezione.coloreSezione}
                        onChange={value => this._updateConfigurazioneSezione("coloreSezione", value)}/>
                    <CheckLine
                        label={"Obbligatoria"}
                        checkType={"switch"}
                        checked={this.props.configurazioneSezione.obbligatoriaFlag}
                        onChange={value => this._updateConfigurazioneSezione("obbligatoriaFlag", value)}/>
                    <CheckLine
                        label={"Trasparente"}
                        checkType={"switch"}
                        checked={this.props.configurazioneSezione.trasparenteFlag}
                        onChange={value => this._updateConfigurazioneSezione("trasparenteFlag", value, true)}/>
                    <IfContainer condition={this.props.configurazioneSezione.trasparenteFlag}>
                        <TextLine
                            label={"Grado trasparenza in %"}
                            value={this.props.configurazioneSezione.livelloTrasparenza.toString()}
                            type={"number"}
                            min={0}
                            max={100}
                            onChange={value => this._updateConfigurazioneSezione("livelloTrasparenza", parseFloat(value))}/>
                    </IfContainer>
                    <CheckLine
                        label={"Custom"}
                        checkType={"switch"}
                        checked={this.props.configurazioneSezione.customFlag}
                        onChange={value => this._updateConfigurazioneSezione("customFlag", value, true)}/>
                    <CheckLine
                        label={"Custom plex"}
                        checkType={"switch"}
                        checked={this.props.configurazioneSezione.customPlexFlag}
                        onChange={value => this._updateConfigurazioneSezione("customPlexFlag", value, true)}/>
                    <CheckLine
                        label={"Custom tela"}
                        checkType={"switch"}
                        checked={this.props.configurazioneSezione.customTelaFlag}
                        onChange={value => this._updateConfigurazioneSezione("customTelaFlag", value, true)}/>
                    <CheckLine
                        label={"Legno"}
                        checkType={"switch"}
                        checked={this.props.configurazioneSezione.legnoFlag}
                        onChange={value => this._updateConfigurazioneSezione("legnoFlag", value, true)}/>
                    <CheckLine
                        label={"Plex"}
                        checkType={"switch"}
                        checked={this.props.configurazioneSezione.plexFlag}
                        onChange={value => this._updateConfigurazioneSezione("plexFlag", value, true)}/>
                    <CheckLine
                        label={"Singolo colore"}
                        checkType={"switch"}
                        checked={this.props.configurazioneSezione.singoloColoreFlag}
                        onChange={value => this._updateConfigurazioneSezione("singoloColoreFlag", value, true)}/>
                    <IfContainer condition={this.props.configurazioneSezione.singoloColoreFlag}>
                        <TextLine
                            label={"Codice colore (esadecimale)"}
                            value={this.props.configurazioneSezione.singoloColore}
                            onChange={value => this._updateConfigurazioneSezione("singoloColore", value)}/>
                    </IfContainer>
                    <CheckLine
                        label={"Codice uv"}
                        checkType={"switch"}
                        checked={this.props.configurazioneSezione.codiceUvFlag}
                        onChange={value => this._updateConfigurazioneSezione("codiceUvFlag", value, true)}/>
                    <IfContainer condition={this.props.configurazioneSezione.codiceUvFlag}>
                        <SelectLine
                            label={"Sezione associata"}
                            placeHolder={"Seleziona"}
                            elements={this.props.listaSezioni && this.props.listaSezioni.map(value => ({label: value}))}
                            value={this.props.configurazioneSezione.sezioneAssociataUv}
                            onChange={value => {this._updateConfigurazioneSezione("sezioneAssociataUv", value)}}/>
                    </IfContainer>
                    <CheckLine
                        label={"Singola immagine"}
                        checkType={"switch"}
                        checked={this.props.configurazioneSezione.singolaImmagineFlag}
                        onChange={value => this._updateConfigurazioneSezione("singolaImmagineFlag", value, true)}/>
                    <IfContainer condition={this.props.configurazioneSezione.singolaImmagineFlag}>
                        <ImageVisualizer
                            imageData={this.props.configurazioneSezione.singolaImmagine}
                            onDelete={() => {
                                this._updateConfigurazioneSezione("singolaImmagineName", "");
                                this._updateConfigurazioneSezione("singolaImmagine", "");
                            }}/>
                        <TextLine
                            label={"Carica l'immagine"}
                            type={"file"}
                            value={this.props.configurazioneSezione.singolaImmagineName}
                            onFileUpload={((fileName, fileType, file,  base64Encoding) => {
                                if("png;jpg;jpeg".includes(fileType.toLowerCase())){
                                    this._updateConfigurazioneSezione("singolaImmagineName", fileName);
                                    this._updateConfigurazioneSezione("singolaImmagine", base64Encoding);
                                }
                            })}/>
                    </IfContainer>
                    <SelectLine
                        label={"Tipologia materiale supportato"}
                        placeHolder={"Seleziona"}
                        value={this.props.configurazioneSezione.tipologiaMaterialeSupportato}
                        elements={[
                            {label: "Sottile"},
                            {label: "Spesso"},
                            {label: "Sottile/Spesso"}
                        ]}
                        onChange={value => this._updateConfigurazioneSezione("tipologiaMaterialeSupportato", value as TipologiaMaterialeSupportato)}/>
                </IfContainer>
            </SectionLayout>
        )
    }
}
