import './Header.scss';
import React from "react";
import Logo from "../../Widgets/Logo/Logo";
import ResponsiveLabel from "../../Core/ResponsiveLabel/ResponsiveLabel";
import {UserContextResponseModel} from "tici_commons";
import SentenceLayout from "../../Layout/SentenceLayout/SentenceLayout";
import UserService from '../../Servicies/UserService';
import RedirectService from "../../Servicies/RedirectService";

export interface HeaderProps{
    utente: UserContextResponseModel
}

export default class Header extends React.Component<HeaderProps, {}>{
    public render() {
        return (
            <div className={"HeaderContainer"}>
                <Logo logoBaseMode={true}/>
                <div
                    className={"HeaderUserInfoContainer"}
                    onClick={() => RedirectService.GoToAccount()}>
                    <div className={"HeaderUserInfoContainerLeftSide"}>
                        <SentenceLayout alignment={"left"}>
                            <ResponsiveLabel
                                content={`Bentornato`}
                                type={"xsmall"}
                                alignment={"left"}
                                labelType={"whiteLabel"}/>
                            <ResponsiveLabel
                                content={`${this.props?.utente?.nome} ${this.props?.utente?.cognome}`}
                                type={"xsmall"}
                                alignment={"left"}
                                labelType={"whiteLabel"}
                                bold={true}/>
                        </SentenceLayout>
                        <ResponsiveLabel
                            content={`${this.props?.utente?.tipoUtente}`}
                            uppercase={true}
                            type={"xsmall"}
                            alignment={"right"}
                            labelType={"whiteLabel"}
                            bold={true}/>
                    </div>
                    <div
                        style={{backgroundImage: `url("${UserService.UserImageUrl()}")`}}
                        className={"HeaderUserInfoContainerRightSide"}/>
                </div>
            </div>
        );
    }
}
