import React from "react";
import ContainerLayout from "../../Layout/ContainerLayout/ContainerLayout";
import ResponsiveLabel from "../../Core/ResponsiveLabel/ResponsiveLabel";
import IfContainer from "../../Layout/IfContainer/IfContainer";
import VSLoader from "../../Primary/VSLoader/VSLoader";
import SectionLayout from "../../Layout/SectionLayout/SectionLayout";
import Button from "../../Core/Buttons/Button";
import VerticalSpace from "../../Layout/VerticalSpace/VerticalSpace";
import PaddingLayout from "../../Layout/PaddingLayout/PaddingLayout";
import UserService from "../../Servicies/UserService";
import RedirectService from "../../Servicies/RedirectService";

export interface ConfermaEmailState {
    loading: boolean,
    esitoOperazione: boolean
}

export default class ConfermaEmail extends React.Component<{}, ConfermaEmailState>{
    constructor(props: Readonly<{}> | {}) {
        super(props);
        this.state = {
            loading: true,
            esitoOperazione: false
        }
    }

    public componentDidMount() {
        const params = new URLSearchParams(window.location.search);
        if(params.get('key')){
            const parsedKey = atob(params.get('key'));
            UserService.AttivazioneEmail(parsedKey).then(response => {
                this.setState({loading: false, esitoOperazione: response});
            })
        }else{
            RedirectService.GoToLogin();
        }
    }

    public render() {
        return (
            <PaddingLayout
                paddingMobile={"0 16px"}
                paddingTablet={"0"}
                paddingDesktop={"0"}>
                <ContainerLayout size={"mediumRelative"} center={true}>
                    <VerticalSpace gapMobile={20} gapTablet={40} gapDesktop={60}/>
                    <IfContainer
                        condition={!this.state.loading}
                        elseComponent={<VSLoader gapMobile={10} gapTablet={20} gapDesktop={30}/>}>
                        <IfContainer
                            condition={this.state.esitoOperazione}
                            elseComponent={
                                <SectionLayout size={"largeRelative"}>
                                    <ResponsiveLabel
                                        content={"Errore nell'attivazione"}
                                        type={"large"}
                                        alignment={"center"}
                                        labelType={"recapLabel"}/>
                                    <ResponsiveLabel
                                        content={"Errore durante l'attivazione della mail, riprova più tardi"}
                                        type={"medium"}
                                        alignment={"center"}/>
                                </SectionLayout>
                            }>
                            <SectionLayout size={"largeRelative"}>
                                <ResponsiveLabel
                                    content={"Email attivata correttamente"}
                                    type={"large"}
                                    alignment={"center"}
                                    labelType={"recapLabel"}/>
                                <ResponsiveLabel
                                    content={"La tua email è stata attivata correttamente, riceverai una mail di conferma e un'altra email quando il tuo account sarà attivato da un amministratore"}
                                    type={"medium"}
                                    alignment={"center"}/>
                            </SectionLayout>
                        </IfContainer>
                        <Button
                            content={"Torna al login"}
                            type={"large"}
                            alignment={"center"}
                            buttonType={"submit"}
                            onClick={() => RedirectService.GoToLogin()}/>
                    </IfContainer>
                </ContainerLayout>
            </PaddingLayout>
        );
    }
}
