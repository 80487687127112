import {FotolibroModel, StampaTelaModel} from "tici_commons";
import React from "react";
import {StorageDataMapper, StorageManagerStatus} from "../../../Storage/StorageManager";
import ResponsiveLabel from "../../../../../../Core/ResponsiveLabel/ResponsiveLabel";
import {CheckLine, TextLine} from "../../../../../../Widgets/Configuratore/CComponents";
import IfContainer from "../../../../../../Layout/IfContainer/IfContainer";
import {
    GenerateRiepilogoBox,
    GenerateRiepilogoData, GenerateRiepilogoInternalBox,
    RiepilogoBox,
    RiepilogoData,
    RiepilogoInternalBox
} from "../../../Commons/RiepilogoDatoModel";
import CategorySectionLayout from "../../../../../../Layout/CategorySectionLayout/CategorySectionLayout";
import DragAndDropMultifunzionale from "../../../../../../Primary/DragAndDropMultifunzionale/DragAndDropMultifunzionale";
import SezioneAccessoriGenerica from "../SezioneAccessoriGenerica";

export default class SezioneFotoTela extends SezioneAccessoriGenerica<"StampaTela", {}, {}>{
    public static ErrorMessages = {
        FormatoErratoError: 'Il formato inserito non rispetta i criteri, NxM',
        TritticoError: 'Non è stato caricato il numero corretto di immagini'
    }

    public generaRiepilogo(accessorio: StampaTelaModel, indexAccessorio: number, fotolibro: FotolibroModel, storage: StorageManagerStatus, modalitaConfiguratore: boolean): RiepilogoBox | RiepilogoBox[] | RiepilogoInternalBox | RiepilogoInternalBox[] | RiepilogoData[] {
        return GenerateRiepilogoBox(
            `Foto tela ${indexAccessorio + 1}`,
            GenerateRiepilogoInternalBox(
                `Foto tela ${indexAccessorio + 1}`,
                GenerateRiepilogoData(
                    `Formato: ${accessorio.formatoStampaTela}`,
                    SezioneFotoTela.ErrorMessages.FormatoErratoError,
                    accessorio.formatoStampaTela.length === 0
                ),
                GenerateRiepilogoData(
                    "Trittico",
                    undefined, undefined, accessorio.tritticoStampaTelaFlag
                ),
                GenerateRiepilogoData(
                    accessorio.stampaTela2CmFlag ? 'Tela da 2cm' : 'Tela da 4cm'
                ),
                GenerateRiepilogoData(
                    undefined,
                    SezioneFotoTela.ErrorMessages.TritticoError,
                    true,
                    storage && storage.GetStorageData(`Accessorio-${indexAccessorio}-${StorageDataMapper.FotoTela}`).length !==  1
                )
            )
        )
    }

    public render() {
        return (
            <CategorySectionLayout categoryLabel={`Foto tela ${this.props.indexAccessorio + 1}`} size={"largeRelative"} center={true}>
                <TextLine
                    label={"Formato tela"}
                    errorMessage={"Il formato della tela non rispetta i criteri"}
                    showError={!new RegExp(/^[0-9]{1,3}x[0-9]{1,3}$/gi).test(this.$.formatoStampaTela)}
                    placeHolder={"NxM"}
                    value={this.$.formatoStampaTela}
                    onChange={v => this.$.formatoStampaTela = v}/>
                <CheckLine
                    label={this.$.stampaTela2CmFlag ? "Tela da 2cm" : "Tela da 4cm"}
                    checkType={"switch"}
                    checked={this.$.stampaTela2CmFlag}
                    onChange={v => this.$.stampaTela2CmFlag = v}
                    reduce={true}/>
                <IfContainer condition={this.CountImmagini(StorageDataMapper.FotoTela) !== 1}>
                    <ResponsiveLabel content={SezioneFotoTela.ErrorMessages.TritticoError} type={"small"} alignment={"center"} labelType={"errorLabel"}/>
                </IfContainer>
                <CheckLine
                    label={"Trittico"}
                    checkType={"switch"}
                    checked={this.$.tritticoStampaTelaFlag}
                    onChange={v => this.$.tritticoStampaTelaFlag = v}
                    reduce={true}/>
                <DragAndDropMultifunzionale
                    immagini={this.ImmaginiDaCategoria(StorageDataMapper.FotoTela)}
                    indicatoreMassimoFogli={1}
                    onUpload={file => this.UplodaImmagine(StorageDataMapper.FotoTela, file.name, file)}
                    onDelete={(nomeImmagine) => this.DeleteImmagine(StorageDataMapper.FotoTela, nomeImmagine)}/>
            </CategorySectionLayout>
        );
    }
}
