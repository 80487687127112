import React from "react";
import "./StopLight.scss";

export interface StopLightProps{
    lightNumber: 0 | 1 | 2 | 3
}

export default class StopLight extends React.Component<StopLightProps, {}>{
    public render(): React.ReactNode {
        return(
            <div className={`StopLightContainer`}>
                <div className={`light ${this.props.lightNumber === 1 && 'r' }`}/>
                <div className={`light ${this.props.lightNumber === 2 && 'y' }`}/>
                <div className={`light ${this.props.lightNumber === 3 && 'g' }`}/>
            </div>
        )
    }

}
