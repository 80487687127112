import { StorageData } from "../Storage/StorageManager"
import {orderFromImageName} from "../../../../Utils/CommonFunctions";

export const SortFogliWithStorageData = (a: StorageData, b: StorageData): number => {
    const va = orderFromImageName(a.name);
    const vb = orderFromImageName(b.name);
    return va - vb;
}

export const SortFogliWithCartaExtra = (a: [string, string], b: [string, string]): number => {
    const va = orderFromImageName(a[0]);
    const vb = orderFromImageName(b[0]);
    return va - vb;
}

/**
 * Controlla che il formato passato possa utilizzare il formato principale
 * @param formatoAlbumPrincipale Formato dell'album principale
 * @private
 */
export const SupportaDorsoDritto = (formatoAlbumPrincipale: string): boolean => {
    let esito = false;

    if(formatoAlbumPrincipale){
        const splittedValue = formatoAlbumPrincipale.split('x');
        if(splittedValue.length === 2){
            const b = parseInt(splittedValue[0]);
            const h = parseInt(splittedValue[1]);
            esito = b + h >= 70; //devono essere minimo 35x35
        }
    }

    return esito;
}
