import React, {Fragment} from "react";
import CategorySwitcherElement from "../../../../Primary/CategorySwitcher/CategorySwirtcherElement/CategorySwitcherElement";
import {isCatalogo, isNormalView, TipoFotolibroMapper, TipoFotolibroType} from "tici_commons";
import IfContainer from "../../../../Layout/IfContainer/IfContainer";
import CategorySwitcher from "../../../../Primary/CategorySwitcher/CategorySwitcher/CategorySwitcher";
import ResponsiveLabel from "../../../../Core/ResponsiveLabel/ResponsiveLabel";
import Responsive2Col from "../../../../Layout/Responsive2Col/Responsive2Col";
import ImageService from "../../../../Servicies/ImageService";
import CategorySectionLayout from "../../../../Layout/CategorySectionLayout/CategorySectionLayout";

export interface FotolibroMenuProps{
    nomeProgetto?: string,
    tipoFotolibro?: TipoFotolibroType,
    modalitaConfiguratore?: boolean,
    currentSection?: number,
    setSection?: (index: number) => void
}

export default class FotolibroMenu extends React.Component<FotolibroMenuProps, {}>{
    /**
     * Emette l'evento di cambiamento della sezione
     * @param newSection Nuova sezione da settare
     * @private
     */
    private _emitSectionEvent(newSection: number){
        this.props.setSection && this.props.setSection(newSection);
    }

    public render() {
        return (
            <Fragment>
                <CategorySwitcher>
                    <CategorySwitcherElement
                        elementIndex={0}
                        icon={isCatalogo(this.props.tipoFotolibro) ? ImageService.getImage('coordinatiIcon') : ImageService.getImage('informazioniBaseIcon')}
                        label={this.props.modalitaConfiguratore ? 'Informazioni configurazione' : 'Informazioni di base'}
                        selected={this.props.currentSection === 0}
                        onClick={v => this._emitSectionEvent(v)}
                        grow={true}/>
                    <IfContainer condition={this.props.tipoFotolibro !== 'accessori'}>
                        <IfContainer condition={isNormalView(this.props.tipoFotolibro)}>
                            <CategorySwitcherElement
                                elementIndex={1}
                                icon={ImageService.getImage('rilegaturaIcon')}
                                label={"Blocco"}
                                selected={this.props.currentSection === 1}
                                onClick={v => this._emitSectionEvent(v)}
                                grow={true}/>
                        </IfContainer>
                        <CategorySwitcherElement
                            elementIndex={2}
                            icon={ImageService.getImage('coordinatiIcon')}
                            label={"Copertina"}
                            selected={this.props.currentSection === 2}
                            onClick={v => this._emitSectionEvent(v)}
                            grow={true}/>
                    </IfContainer>
                    <CategorySwitcherElement
                        elementIndex={3}
                        icon={ImageService.getImage('cofanettoIcon')}
                        label={"Custodia"}
                        selected={this.props.currentSection === 3}
                        onClick={v => this._emitSectionEvent(v)}
                        grow={true}/>
                    <CategorySwitcherElement
                        elementIndex={4}
                        icon={ImageService.getImage('accessoriIcon')}
                        label={"Accessori"}
                        selected={this.props.currentSection === 4}
                        onClick={v => this._emitSectionEvent(v)}
                        grow={true}/>
                    <IfContainer
                        condition={!this.props.modalitaConfiguratore}
                        elseComponent={
                            <CategorySwitcherElement
                                elementIndex={6}
                                icon={ImageService.getImage('riepilogoIcon')}
                                label={"Salvataggio configurazione"}
                                selected={this.props.currentSection === 6}
                                onClick={v => this._emitSectionEvent(v)}
                                grow={true}/>
                        }>
                        <CategorySwitcherElement
                            elementIndex={5}
                            icon={ImageService.getImage('riepilogoIcon')}
                            label={"Riepilogo"}
                            selected={this.props.currentSection === 5}
                            onClick={v => this._emitSectionEvent(v)}
                            grow={true}/>
                    </IfContainer>
                </CategorySwitcher>
                <IfContainer condition={this.props.currentSection > 0 && this.props.currentSection < 5}>
                    <CategorySectionLayout categoryLabel={"Informazioni di base"} size={"largeRelative"}>
                        <Responsive2Col>
                            <ResponsiveLabel
                                content={`Nome dell'ordine: ${this.props.nomeProgetto}`}
                                type={"medium"}
                                alignment={"left"}/>
                            <ResponsiveLabel
                                content={`Tipologia di ordine: ${TipoFotolibroMapper(this.props.tipoFotolibro)}`}
                                type={"medium"}
                                alignment={"left"}/>
                        </Responsive2Col>
                    </CategorySectionLayout>
                </IfContainer>
            </Fragment>
        )
    }
}
