import {Rest, TicketConfermaPutBodyInterface} from "tici_commons";
import Config from "../Static/Config";

export default class TicketService{
    public static async CambiaStatoConferma(idTicket: number, conferma: boolean): Promise<boolean>{
        let esito = false;

        try{
            const body: TicketConfermaPutBodyInterface = {
                idTicket,
                richiesta: conferma ? 'conferma' : 'annullaConferma'
            }
            const response = await Rest.Put(
                `${Config.PrivatePath}/TicketConferma`,
                undefined,
                body
            );
            if(response && response.statusCode === 200)
                esito = true;
        }catch (e){
            console.error("Errore nella modifica dello stato di conferma", String(e));
        }

        return esito;
    }

    public static async CambiaStatoSegnalazione(idTicket: number, errore: boolean): Promise<boolean>{
        let esito = false;

        try{
            const body: TicketConfermaPutBodyInterface = {
                idTicket,
                richiesta: errore ? 'segnalaErrore' : 'risolviSegnalazione'
            }
            const response = await Rest.Put(
                `${Config.PrivatePath}/TicketConferma`,
                undefined,
                body
            );
            if(response && response.statusCode === 200)
                esito = true;
        }catch (e){
            console.error("Errore nella modifica dello stato della segnalazione", String(e));
        }

        return esito;
    }
}
