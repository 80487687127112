import {ConfigurazioneRenderizzatoreInterface} from "tici_commons";
import React from "react";
import Button from "../../../../../Core/Buttons/Button";
import {
    ConfigurazioneGestore3DBridgeContext,
    ConfigurazioneGestore3DBridgeState
} from "../../Bridge/ConfigurazioneGestore3DBridge";
import RedirectService from "../../../../../Servicies/RedirectService";

export interface VistaDownloadProps{
    configurazione: ConfigurazioneRenderizzatoreInterface,
    vistaDaGestore?: boolean
}

export default class VistaDownload extends React.Component<VistaDownloadProps, {}>{
    /**
     * Torna alla pagina di gestione dei modelli
     * @private
     */
    private _goToGestioneModelli(bridge: ConfigurazioneGestore3DBridgeState){
        if(bridge){
            const lastPayload = bridge.payload;
            const lastMetadata = bridge.metaData;

            bridge.setMetadata({...lastMetadata});
            bridge.setPayload({
                configurazione: this.props.configurazione,
                nomeModello: lastPayload.nomeModello,
                pathModello: lastPayload.pathModello,
                nomeConfigurazioneJSON: "Attualmente modificata"
            });

            RedirectService.GoToGestioneModelli3D();
        }
    }

    public render() {
        return (
            <ConfigurazioneGestore3DBridgeContext.Consumer>{
                bridge => (
                    <Button
                        content={this.props.vistaDaGestore ? "Torna alla gestione modelli" : "Scarica configurazione modello"}
                        type={"large"}
                        buttonType={"full-normal"}
                        onClick={() => {
                            if(this.props.vistaDaGestore)
                                this._goToGestioneModelli(bridge);
                            else{
                                const element = document.createElement('a');
                                element.setAttribute('href', 'data:application/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(this.props.configurazione)));
                                element.setAttribute('download', 'config.json');
                                element.click();
                            }
                        }}
                    />
                )
            }</ConfigurazioneGestore3DBridgeContext.Consumer>
        );
    }
}
