import React from "react";
import ResponsiveLabel from "../Core/ResponsiveLabel/ResponsiveLabel";

export default class _404 extends React.Component<{}, {}>{
    public render() {
        return (
            <ResponsiveLabel content={"404/Pagina non trovata"} type={"large"} alignment={"center"} labelType={"_404"}/>
        );
    }
}
