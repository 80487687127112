import {StorageData} from "../../../Pages/SitoInterno/Configuratore/Storage/StorageManager";
import React, {Fragment} from "react";
import {loadImage} from "../../../Utils/CommonFunctions";
import IfContainer from "../../../Layout/IfContainer/IfContainer";
import ResponsiveLabel from "../../../Core/ResponsiveLabel/ResponsiveLabel";
import {ImageVisualizer, SelectLine} from "../CComponents";
import {RenderizzatoreSezioneInterface} from "../../../Primary/Renderizzatore/Renderizzatore";
import {ConfigurazioneSezioneInterface, TipoSezioneConfiguratoreType} from "tici_commons";

export interface SelettoreImmagineSezioneProps {
    label: string,
    escludiPlex?: boolean,
    escludiTela?: boolean,
    tipoImmagine?: 'immagineTela' | 'immaginePlex',
    showError?: boolean,
    storageData?: StorageData,
    onImageUpload?: (tipoImmagine: 'tela' | 'plex', nomeImmagine: string, base64Encoding: string) => void,
    onDeleteImage?: () => void,

}

export const SelettoreImmagineSezione = (props: SelettoreImmagineSezioneProps) => {
    const LoadImage = (tipoImmagine: string) => {
        loadImage("png;jpg;jpeg").then(image => {
            image && props.onImageUpload && props.onImageUpload(tipoImmagine as 'plex' | 'tela', image[0], image[2])
        })
    }

    return (
        <Fragment>
            <SelectLine
                label={props.label}
                placeHolder={"Seleziona tipo immagine"}
                errorMessage={"Bisogna caricare un immagine"}
                showError={props.showError}
                elements={
                    [{label: "tela"}, {label: "plex"}]
                        .filter(value => props.escludiTela ? value.label === 'plex' : (props.escludiPlex ? value.label === 'tela' : true) )
                }
                onChange={LoadImage.bind(this)}/>
            <IfContainer condition={!!props.tipoImmagine && !!props.storageData}>
                <ResponsiveLabel
                    content={props.tipoImmagine ? props.tipoImmagine.toLowerCase().includes('tela') ? 'Immagine tela' : 'Immagine plex' : ''}
                    type={"medium"}
                    alignment={"right"}/>
                <ImageVisualizer
                    storageData={props.storageData}
                    onDelete={() => {props.onDeleteImage && props.onDeleteImage()}}/>
            </IfContainer>
        </Fragment>
    );
}

export interface SelettoreImmagineSezioneConfiguratoreProps {
    sezione: RenderizzatoreSezioneInterface,
    configurazioneSezione: ConfigurazioneSezioneInterface,
    hasSezioneData: (nomeSezione: string) => boolean,
    getSezioneData: (nomeSezione: string) => string,
    getSezioneType: (nomeSezione: string) => TipoSezioneConfiguratoreType,
    setSezioneData: (nomeSezione: string, tipoSezione: TipoSezioneConfiguratoreType, valoreSezione: string, materialeSezione: string) => void,
    deleteSezioneData: (nomeSezione: string) => void,
    setStorageData: (nomeFile: string, file?: File, base64?: string) => void,
    getStorageData: (nomeFile: string) => StorageData[],
    deleteStorageData: () => void

}

export const SelettoreImmagineSezioneRenderizzatore = (props: SelettoreImmagineSezioneConfiguratoreProps) => {
    return (
        <SelettoreImmagineSezione
            label={props.sezione.nomeSezione}
            showError={!props.hasSezioneData(props.sezione.nomeSezione) && props.sezione.obbligatoria}
            tipoImmagine={props.getSezioneType(props.sezione.nomeSezione) as any}
            storageData={props.hasSezioneData(props.sezione.nomeSezione) && props.getStorageData(props.getSezioneData(props.sezione.nomeSezione))[0]}
            escludiPlex={props.configurazioneSezione.customTelaFlag}
            escludiTela={props.configurazioneSezione.customPlexFlag}
            onImageUpload={(tipoImmagine, nomeImmagine, base64Encoding) => {
                props.setSezioneData(props.sezione.nomeSezione, tipoImmagine === 'tela' ? 'immagineTela' : 'immaginePlex', nomeImmagine, base64Encoding);
                props.setStorageData(nomeImmagine, undefined, base64Encoding);
            }}
            onDeleteImage={() => {
                props.deleteSezioneData(props.sezione.nomeSezione);
                props.deleteStorageData();
            }}/>
    )
}
