import SezioneRequiredProps from "../../../Commons/SezioneRiquiredProps";
import React, {Fragment} from "react";
import {SelectLine} from "../../../../../../Widgets/Configuratore/CComponents";
import Modello from "../../../../../../DatabaseData/Modello";
import ListaMaterialiConfiguratore
    from "../../../../../../Primary/ListaMaterialiConfiguratore/ListaMaterialiConfiguratore";
import Formati from "../../../../../../DatabaseData/Formati";
import {StorageManagerStatus} from "../../../Storage/StorageManager";
import {
    GenerateRiepilogoData,
    GenerateRiepilogoInternalBox,
    RiepilogoBox,
    RiepilogoData,
    RiepilogoInternalBox
} from "../../../Commons/RiepilogoDatoModel";
import IfContainer from "../../../../../../Layout/IfContainer/IfContainer";
import SentenceLayout from "../../../../../../Layout/SentenceLayout/SentenceLayout";
import ResponsiveLabel from "../../../../../../Core/ResponsiveLabel/ResponsiveLabel";
import Loader from "../../../../../../Core/Loader/Loader";
import LocalStorageService from "../../../../../../Servicies/LocalStorageService";
import SezioneGenerica from "../../SezioneGenerica";
import {FotolibroContextInterface} from "../../../Fotolibro/Fotolibro";
import {FotolibroModel, isCatalogo, TipoFotolibroType} from "tici_commons";
import CategorySectionLayout from "../../../../../../Layout/CategorySectionLayout/CategorySectionLayout";

export interface SezioneCustodiaState {
    isLoading: boolean
}

export interface SezioneCustodiaProps {
    tipoFotolibro: TipoFotolibroType,
    formatoAlbumPrincipale: string
}

export default class SezioneCustodia extends SezioneGenerica<"custodia", SezioneCustodiaProps, SezioneCustodiaState>{
    constructor(props: SezioneCustodiaProps & SezioneRequiredProps<"custodia"> & { data: FotolibroContextInterface["custodia"]; storage: StorageManagerStatus }) {
        super(props);
        this.state = {
            isLoading: false
        }
    }

    public static ErrorMessages = {
        ModelloCustodiaError: "Il modello della custodia non è stato selezionato"
    }

    public generaRiepilogo(fotolibro: FotolibroModel, storage: StorageManagerStatus, modalitaConfiguratore: boolean): RiepilogoBox | RiepilogoInternalBox | RiepilogoInternalBox[] | RiepilogoData[] {
        return GenerateRiepilogoInternalBox(
            'Custodia',
            GenerateRiepilogoData(
                `Modello custodia: ${fotolibro.custodia.modelloCustodia}`,
                SezioneCustodia.ErrorMessages.ModelloCustodiaError,
                fotolibro.custodia.modelloCustodia.length === 0
            ),
            GenerateRiepilogoData(
                undefined,
                "Alcune sezioni non sono state compilate",
                true,
                storage && fotolibro.custodia.sezioniCustodia.length < LocalStorageService.SezioniObbligatorieCustodia
            ),
            ...fotolibro.custodia.sezioniCustodia.map(sezione => GenerateRiepilogoData(`${sezione[0]}: ${sezione[1].informazione.split('-')[0]}`))
        );
    }

    public render() {
        return (
            <CategorySectionLayout categoryLabel={"Custodia"} size={"largeRelative"} center={true}>
                <IfContainer
                    condition={!this.state.isLoading}
                    elseComponent={
                        <Fragment>
                            <SentenceLayout alignment={"center"}>
                                <ResponsiveLabel content={"Controllo vincoli"} type={"medium"} alignment={"left"}/>
                                <Loader/>
                            </SentenceLayout>
                        </Fragment>
                    }>
                    <SelectLine
                        label={"Modello custodia"}
                        placeHolder={"Seleziona"}
                        errorMessage={"Bisogna selezionare il modello della custodia"}
                        showError={!this.$.modelloCustodia.length}
                        elements={Modello.GetModelli("custodia", this.props.formatoAlbumPrincipale)}
                        onChange={v => this.$.modelloCustodia = v}
                        value={this.$.modelloCustodia}
                        disabled={isCatalogo(this.props.tipoFotolibro)}/>
                </IfContainer>
                <ListaMaterialiConfiguratore
                    idManager={"custodia"}
                    nomeConfigurazioneModello={Modello.GetNomeConfigurazione(this.$.modelloCustodia)}
                    sezioniData={this.$.sezioniCustodia}
                    onSectionChanges={value => this.$.sezioniCustodia = value}
                    aspectRatioModello={Formati.GetAspectRatioFromFormato(this.props.formatoAlbumPrincipale)}/>
            </CategorySectionLayout>
        );
    }
}
