import DefaultInput, {DefaultInputProps} from "../../Core/DefaultInput/DefaultInput";
import TwoSpaceLine from "../../Layout/TwoSpaceLine/TwoSpaceLine";
import React from "react";
import ElementSelect, {ElementSelectProps} from "../../Primary/Selects/ElementSelect/ElementSelect";
import CheckboxProps from "../../Core/Checkbox/CheckboxProps";
import Checkbox from "../../Core/Checkbox/Checkbox/Checkbox";

export interface TextLineProps extends DefaultInputProps{
    label: string,
    showError?: boolean,
    errorMessage?: string,
    onlyMobileError?: boolean
}
export function TextLine(props: TextLineProps){
    return(
        <TwoSpaceLine
            label={props.label}
            formMode={true}
            labelType={'whiteLabel'}
            showError={props.showError}
            errorMessage={props.errorMessage}
            onlyMobileError={props.onlyMobileError}>
            <DefaultInput
                inputRef={props.inputRef}
                placeHolder={props.placeHolder}
                onChange={props.onChange}
                onFileUpload={props.onFileUpload}
                disabled={props.disabled}
                type={props.type}
                value={props.value}
                min={props.min}
                max={props.max}/>
        </TwoSpaceLine>
    );
}

export interface CheckLineProps extends CheckboxProps{
    label: string,
    showError?: boolean,
    errorMessage?: string,
    onlyMobileError?: boolean
}
export function CheckLine(props: CheckLineProps){
    return (
        <TwoSpaceLine
            label={props.label}
            formMode={true}
            labelType={'whiteLabel'}
            showError={props.showError}
            errorMessage={props.errorMessage}
            onlyMobileError={props.onlyMobileError}>
            <Checkbox
                disabled={props.disabled}
                onChange={props.onChange}
                checked={props.checked}/>
        </TwoSpaceLine>
    )
}

export interface SelectLineProps extends Omit<ElementSelectProps, "label" | "onFileUpload">{
    label: string,
    showError?: boolean,
    errorMessage?: string,
    placeHolder: string,
    autocompleteMode?: boolean
    onlyMobileError?: boolean
}
export function SelectLine(props: SelectLineProps){
    return (
        <TwoSpaceLine
            label={props.label}
            formMode={true}
            showError={props.showError}
            errorMessage={props.errorMessage}
            labelType={"whiteLabel"}
            onlyMobileError={props.onlyMobileError}>
            <ElementSelect
                label={props.placeHolder}
                elements={props.elements}
                onChange={props.onChange}
                disabled={props.disabled}
                value={props.value}
                maxHeight={props.maxHeight}
                autocompleteMode={props.autocompleteMode}/>
        </TwoSpaceLine>
    );
}
