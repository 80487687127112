import {FotolibroModel} from "tici_commons";
import React from "react";
import ResponsiveLabel from "../../../../../../Core/ResponsiveLabel/ResponsiveLabel";
import {StorageManagerStatus} from "../../../Storage/StorageManager";
import {
    GenerateGroup, GenerateRiepilogoData,
    GenerateRiepilogoInternalBox,
    RiepilogoBox,
    RiepilogoData,
    RiepilogoInternalBox
} from "../../../Commons/RiepilogoDatoModel";
import SezioneGenerica from "../../SezioneGenerica";
import CategorySectionLayout from "../../../../../../Layout/CategorySectionLayout/CategorySectionLayout";
import TextArea from "../../../../../../Core/TextArea/TextArea";


export default class SezioneLavorazioni extends SezioneGenerica<"informazioniBase", {}, {}>{
    public generaRiepilogo = (fotolibro: FotolibroModel, storage: StorageManagerStatus, modalitaConfiguratore: boolean): RiepilogoInternalBox | RiepilogoData[] | RiepilogoBox | RiepilogoInternalBox[] => {
        const esito = GenerateRiepilogoInternalBox(
            "Lavorazioni",
            ...GenerateGroup(
                fotolibro.informazioniBase.lavorazioniLavorazione.length > 0,
                ...fotolibro.informazioniBase.lavorazioniLavorazione.map(lavorazione => (GenerateRiepilogoData(`Foglio ${lavorazione[0]}: ${lavorazione[1]}`)))
            ),
            ...GenerateGroup(
                fotolibro.informazioniBase.lavorazioniConAggiunte.length > 0,
                ...fotolibro.informazioniBase.lavorazioniConAggiunte.map(lavorazione => GenerateRiepilogoData(`Foglio ${lavorazione[0]}: ${lavorazione[1].nomeLavorazione}`))
            ),
            GenerateRiepilogoData(
                `Specifiche lavorazioni: ${fotolibro.informazioniBase.specificheLavorazioni}`,
                undefined,
                undefined,
                !!fotolibro.informazioniBase.specificheLavorazioni.length
            )
        )

        return esito;
    }

    public render() {
        return (
            <CategorySectionLayout
                categoryLabel={"Specifiche lavorazioni"}
                size={"largeRelative"}>
                <ResponsiveLabel content={"Inserisci le lavorazioni specifiche:"} type={"medium"} alignment={"left"}/>
                <TextArea
                    value={this.$.specificheLavorazioni}
                    onChange={v => this.$.specificheLavorazioni = v}/>
            </CategorySectionLayout>
        );
    }
}
