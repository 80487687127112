import React, {ReactNode} from "react";
import "./CategorySwitcherElement.scss";
import ResponsiveLabel from "../../../Core/ResponsiveLabel/ResponsiveLabel";

export interface CategorySwitcherElementProps{
    elementIndex: number,
    label: string,
    icon: string,
    grow?: boolean,
    selected?: boolean,
    onClick?: (elementIndex: number) => void
}

export default class CategorySwitcherElement extends React.Component<CategorySwitcherElementProps, {}>{
    public render(): ReactNode {
        return (
            <div
                className={`CategorySwitcherElement ${this.props.selected && "selected"} ${this.props.grow && "grow"}`}
                onClick={() => {this.props.onClick && this.props.onClick(this.props.elementIndex)}}>
                <img className={"icon"} alt={this.props.label} src={this.props.icon}/>
                <span className={"CategoryLabel"}>
                    <ResponsiveLabel content={this.props.label} type={"xsmall"} alignment={"center"} labelType={"whiteLabel"}/>
                </span>
            </div>
        );
    }
}
