import {FotolibroModel, PannelliPlexModel} from "tici_commons";
import React from "react";
import ResponsiveLabel from "../../../../../../Core/ResponsiveLabel/ResponsiveLabel";
import {TextLine} from "../../../../../../Widgets/Configuratore/CComponents";
import IfContainer from "../../../../../../Layout/IfContainer/IfContainer";
import SezioneFotoTela from "./SezioneFotoTela";
import {StorageDataMapper, StorageManagerStatus} from "../../../Storage/StorageManager";
import {
    GenerateRiepilogoBox,
    GenerateRiepilogoData,
    GenerateRiepilogoInternalBox,
    RiepilogoBox, RiepilogoData, RiepilogoInternalBox
} from "../../../Commons/RiepilogoDatoModel";
import CategorySectionLayout from "../../../../../../Layout/CategorySectionLayout/CategorySectionLayout";
import DragAndDropMultifunzionale from "../../../../../../Primary/DragAndDropMultifunzionale/DragAndDropMultifunzionale";
import SezioneAccessoriGenerica from "../SezioneAccessoriGenerica";

export default class SezionePannelliPlex extends SezioneAccessoriGenerica<"PannelliPlex", {}, {}>{
    public static ErrorMessages = {
        QuantitaErrataError: 'Bisogna selezionare almeno un unità',
        CaricamentiErratiError: 'Il numero di immagini caricate non coincide con il numero di pannelli'
    }

    public generaRiepilogo(accessorio: PannelliPlexModel, indexAccessorio: number, fotolibro: FotolibroModel, storage: StorageManagerStatus, modalitaConfiguratore: boolean): RiepilogoBox | RiepilogoBox[] | RiepilogoInternalBox | RiepilogoInternalBox[] | RiepilogoData[] {
        return GenerateRiepilogoBox(
            `Pannelli plex ${indexAccessorio + 1}`,
            GenerateRiepilogoInternalBox(
                `Pannelli plex ${indexAccessorio + 1}`,
                GenerateRiepilogoData(
                    `Numero pannelli: ${accessorio.quantitaPannelliPlex}`,
                    SezionePannelliPlex.ErrorMessages.QuantitaErrataError,
                    accessorio.quantitaPannelliPlex === 0
                ),
                GenerateRiepilogoData(
                    `Formato: ${accessorio.formatoPannelliPlex}`,
                    SezioneFotoTela.ErrorMessages.FormatoErratoError,
                    accessorio.formatoPannelliPlex.length === 0
                ),
                GenerateRiepilogoData(
                    undefined,
                    SezionePannelliPlex.ErrorMessages.CaricamentiErratiError,
                    true,
                    storage && storage.GetStorageData(`Accessorio-${indexAccessorio}-${StorageDataMapper.PannelliPlex}`).length !== accessorio.quantitaPannelliPlex
                )
            )
        );
    }

    public render() {
        return (
            <CategorySectionLayout categoryLabel={`Pannello plex ${this.props.indexAccessorio + 1}`} size={"largeRelative"} center={true}>
                <TextLine
                    label={"Numero pannelli"}
                    type={"number"}
                    min={0}
                    max={200}
                    value={String(this.$.quantitaPannelliPlex)}
                    onChange={v => this.$.quantitaPannelliPlex = isNaN(parseInt(v)) ? 0 : parseInt(v)}
                    showError={this.$.quantitaPannelliPlex === 0}
                    errorMessage={SezionePannelliPlex.ErrorMessages.QuantitaErrataError}/>
                <TextLine
                    label={"Formato"}
                    type={"text"}
                    placeHolder={"NxM"}
                    value={this.$.formatoPannelliPlex}
                    onChange={v => this.$.formatoPannelliPlex = v}
                    errorMessage={SezioneFotoTela.ErrorMessages.FormatoErratoError}
                    showError={!new RegExp(/^[0-9]{1,3}x[0-9]{1,3}$/gi).test(this.$.formatoPannelliPlex)}/>
                <IfContainer condition={this.CountImmagini(StorageDataMapper.PannelliPlex) !== this.$.quantitaPannelliPlex}>
                    <ResponsiveLabel
                        content={SezionePannelliPlex.ErrorMessages.CaricamentiErratiError}
                        labelType={"errorLabel"}
                        type={"small"}
                        alignment={"center"}/>
                </IfContainer>
                <DragAndDropMultifunzionale
                    indicatoreMassimoFogli={this.$.quantitaPannelliPlex}
                    immagini={this.ImmaginiDaCategoria(StorageDataMapper.PannelliPlex)}
                    onUpload={(file) => this.UplodaImmagine(StorageDataMapper.PannelliPlex, file.name, file) }
                    onDelete={(fileName) => this.DeleteImmagine(StorageDataMapper.PannelliPlex, fileName)}/>
            </CategorySectionLayout>
        );
    }
}
