import {FotolibroModel} from "tici_commons";
import React from "react";
import {StorageManagerStatus} from "../../../Storage/StorageManager";
import ResponsiveLabel from "../../../../../../Core/ResponsiveLabel/ResponsiveLabel";
import {SelectLine, TextLine} from "../../../../../../Widgets/Configuratore/CComponents";
import IfContainer from "../../../../../../Layout/IfContainer/IfContainer";
import {
    GenerateRiepilogoData,
    GenerateRiepilogoInternalBox,
    RiepilogoBox,
    RiepilogoData,
    RiepilogoInternalBox
} from "../../../Commons/RiepilogoDatoModel";
import SezioneAlbumRidotto from "./SezioneAlbumRidotto";
import SezioneGenerica from "../../SezioneGenerica";
import CategorySectionLayout from "../../../../../../Layout/CategorySectionLayout/CategorySectionLayout";
import VerticalSpace from "../../../../../../Layout/VerticalSpace/VerticalSpace";


export default class SezioneAlbumPocket extends SezioneGenerica<"informazioniBase", {}, {}>{
    public generaRiepilogo(fotolibro: FotolibroModel, storage: StorageManagerStatus, modalitaConfiguratore: boolean): RiepilogoBox | RiepilogoInternalBox | RiepilogoInternalBox[] | RiepilogoData[] {
        return fotolibro.informazioniBase.albumPocketFlag &&
            GenerateRiepilogoInternalBox(
                'Album pocket',
                GenerateRiepilogoData(
                    `Quantità: ${fotolibro.informazioniBase.albumPocketQuantita}`,
                    SezioneAlbumRidotto.ErrorMessages.QuantitaAlbumError,
                    fotolibro.informazioniBase.albumPocketQuantita === 0
                ),
                GenerateRiepilogoData(`Tipo album pocket: ${fotolibro.informazioniBase.tipoAlbumPocket}`),
                GenerateRiepilogoData("Stampe album pocket uguali a quelle dell'album principale", undefined, undefined, fotolibro.informazioniBase.stampaFlag)
            );
    }

    /**
     * Funzione di variazione della quantita dell'album pocket
     * @param value Valore da gestire
     * @private
     */
    private _quantitaChangeFunction(value: string){
        let parsedValue = parseInt(value);
        if(isNaN(parsedValue))
            parsedValue = 2;

        if(parsedValue % 2 === 0){
            this.$.albumPocketQuantita = parsedValue;
        }else{
            const diff = parseInt(value) - this.$.albumPocketQuantita;
            if(Math.abs(diff) === 1){
                this.$.albumPocketQuantita = this.$.albumPocketQuantita + (diff * 2);
            }
        }
    }

    public render() {
        return (
            <CategorySectionLayout
                categoryLabel={"Album pocket"}
                size={"largeRelative"}>
                <TextLine
                    label={"Quantita"}
                    type={"number"}
                    min={1}
                    max={100}
                    errorMessage={SezioneAlbumRidotto.ErrorMessages.QuantitaAlbumError}
                    showError={this.$.albumPocketQuantita === 0}
                    value={this.$.albumPocketQuantita.toString()}
                    onChange={v => {this._quantitaChangeFunction(v)}}/>
                <SelectLine
                    label={"Tipo album pocket"}
                    placeHolder={"Seleziona"}
                    elements={[{label: "Standard"}, {label: "Sicily"}]}
                    value={this.$.tipoAlbumPocket}
                    onChange={v => this.$.tipoAlbumPocket = v}/>
                <VerticalSpace gapMobile={10} gapTablet={20} gapDesktop={20}/>
                <IfContainer condition={this.$.stampaFlag}>
                    <ResponsiveLabel
                        content={"L'album pocket avrà le stesse stampe dell'album principale"}
                        type={"medium"}
                        alignment={"center"}/>
                </IfContainer>
            </CategorySectionLayout>
        )
    }
}
