import React from "react";
import "./FormLayout.scss";

export interface FormLayoutProps{
    children: any,
    versione: 0 | 1
}

export default class FormLayout extends React.Component<FormLayoutProps, {}>{
    public render() {
        return (
            <div className={`FormLayout versione-${this.props.versione}`}>
                {this.props.children}
            </div>
        );
    }
}
