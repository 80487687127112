import React, {RefObject} from "react";
import "./PaddingLayout.scss"

export interface PaddingLayoutProps{
    children: any,
    paddingMobile: string,
    paddingTablet: string,
    paddingDesktop: string
}
export default class PaddingLayout extends React.Component<PaddingLayoutProps, {}>{
    private _containerRef: RefObject<HTMLDivElement> = React.createRef();

    componentDidMount() {
        if(this._containerRef.current){
            this._containerRef.current.style.setProperty("--paddingMobile", this.props.paddingMobile);
            this._containerRef.current.style.setProperty("--paddingTablet", this.props.paddingTablet);
            this._containerRef.current.style.setProperty("--paddingDesktop", this.props.paddingDesktop);
        }
    }

    public render() {
        return (
            <div ref={this._containerRef} className={"PaddingLayout"}>
                {this.props.children}
            </div>
        );
    }
}
