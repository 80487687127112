import React from "react";
import "./Responsive2Col.scss";
import IfContainer from "../IfContainer/IfContainer";

export interface Responsive2ColProps{
    children?: any
    alwaysOnPrint?: boolean
    onlyOnPrint?: boolean
}
export default class Responsive2Col extends React.Component<Responsive2ColProps, {}>{
    public render() {
        return (
            <IfContainer
                condition={this.props.onlyOnPrint}
                elseComponent={
                    <div className={`Responsive2Col ${this.props.alwaysOnPrint && 'alwaysOnPrint'}`}>
                        {this.props.children}
                    </div>
                }>
                {this.props.children}
            </IfContainer>
        );
    }
}
