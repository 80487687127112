import {FotolibroModel} from "tici_commons";
import {filtraFotolibro} from "../Utils/CommonFunctions";

export default class LocalStorageService{
    //Caricamento nel configuratore della configurazione caricata
    static set ConfigurazioneTiciAttuale(idConfigurazione: number | undefined){
        if(idConfigurazione !== undefined)
            sessionStorage.setItem('ConfigurazioneTiciAttuale', `${idConfigurazione}`);
        else sessionStorage.removeItem('ConfigurazioneTiciAttuale');
    }
    static get ConfigurazioneTiciAttuale(): number | undefined{
        let esito = undefined;

        const data = sessionStorage.getItem('ConfigurazioneTiciAttuale');
        if(data)
            esito = parseInt(data);

        return esito;
    }

    //Salvataggio e mantenimento delle immagini generate dal renderizzatore
    static set CopertinaImmagineGenerata(copertinaImmagineGenerata: string | undefined){
        if(copertinaImmagineGenerata === undefined)
            sessionStorage.removeItem('CopertinaImmagineGenerata');
        else sessionStorage.setItem('CopertinaImmagineGenerata', copertinaImmagineGenerata);
    }
    static get CopertinaImmagineGenerata(): string | undefined{
        let esito = undefined;

        const data = sessionStorage.getItem('CopertinaImmagineGenerata');
        if(data)
            esito = data;

        return esito;
    }
    static set CustodiaImmagineGenerata(custodiaImmagineGenerata: string | undefined){
        if(custodiaImmagineGenerata === undefined)
            sessionStorage.removeItem('CustodiaImmagineGenerata');
        else sessionStorage.setItem('CustodiaImmagineGenerata', custodiaImmagineGenerata);
    }
    static get CustodiaImmagineGenerata(): string | undefined{
        let esito = undefined;

        const data = sessionStorage.getItem('CustodiaImmagineGenerata');
        if(data)
            esito = data;

        return esito;
    }

    //Stato di apertura del menu
    static get MenuOpenStatus(): boolean{
        let esito = true;

        const data = sessionStorage.getItem('MenuOpenStatus');
        if(data)
            esito = JSON.parse(sessionStorage.getItem('MenuOpenStatus'));

        return esito;
    }
    static set MenuOpenStatus(status: boolean){
        sessionStorage.setItem('MenuOpenStatus', JSON.stringify(status));
    }

    //Redirect all'ordine indicato
    static set RedirectOrdineNotifica(idOrdine: number | undefined){
        if(idOrdine === undefined)
            sessionStorage.removeItem('RedirectOrdineNotifica');
        else sessionStorage.setItem('RedirectOrdineNotifica', JSON.stringify(idOrdine));
    }
    static get RedirectOrdineNotifica(): number | undefined{
        let esito = undefined;

        const data = sessionStorage.getItem('RedirectOrdineNotifica');
        if(data)
            esito = JSON.parse(data);

        return esito;
    }

    //Sezioni obbligatorie
    static set SezioniObbligatorieCopertina(sezioniObbligatorie: number){
        sessionStorage.setItem("SezioniObbligatorieCopertina", `${sezioniObbligatorie}`);
    }
    static get SezioniObbligatorieCopertina(): number{
        let esito = 0;

        const data = sessionStorage.getItem("SezioniObbligatorieCopertina");
        if(data)
            esito = parseInt(data);

        return esito;
    }
    static set SezioniObbligatorieCustodia(sezioniObbligatorie: number){
        sessionStorage.setItem("SezioniObbligatorieCustodia", `${sezioniObbligatorie}`);
    }
    static get SezioniObbligatorieCustodia(): number{
        let esito = 0;

        const data = sessionStorage.getItem("SezioniObbligatorieCustodia");
        if(data)
            esito = parseInt(data);

        return esito;
    }
    static set SezioniObbligatorieLavorazione(sezioniObbligatorie: number){
        sessionStorage.setItem("SezioniObbligatorieLavorazione", `${sezioniObbligatorie}`);
    }
    static get SezioniObbligatorieLavorazione(): number{
        let esito = 0;

        const data = sessionStorage.getItem("SezioniObbligatorieLavorazione");
        if(data)
            esito = parseInt(data);

        return esito;
    }

    //Salva per dopo
    static set SalvaPerDopoData(fotolibro: FotolibroModel | undefined){
        if(fotolibro === undefined)
            localStorage.removeItem('SalvaPerDopoData');
        else {
            const fotolibroDaSalvare = JSON.stringify(filtraFotolibro(fotolibro));
            localStorage.setItem('SalvaPerDopoData', fotolibroDaSalvare);
        }
    }
    static get SalvaPerDopoData(): FotolibroModel | undefined{
        let esito = undefined;

        const data = localStorage.getItem('SalvaPerDopoData');
        if(data)
            esito = JSON.parse(data);

        return esito;
    }

    //Ordini in corso
    static set OrdiniInCorso(quantita: number){
        sessionStorage.setItem('OrdiniInCorsoCaricamento', JSON.stringify(quantita));
    }
    static get OrdiniInCorso(): number{
        let esito = 0;

        const data = sessionStorage.getItem('OrdiniInCorsoCaricamento');
        if(data)
            esito = JSON.parse(data);

        return esito;
    }
}
