import {Row} from "../../Primary/BasicComponents";
import "./CComponents.scss";
import React, {Fragment, useEffect, useState} from "react";
import xIcon from "../../Media/Images/Icons/xIcon.png";
import redAlertIcon from "../../Media/Images/Icons/redAlertIcon.png";
import yellowAlertIcon from "../../Media/Images/Icons/yellowAlertIcon.png";
import DefaultInput, {DefaultInputProps} from "../../Core/DefaultInput/DefaultInput";
import TwoSpaceLine from "../../Layout/TwoSpaceLine/TwoSpaceLine";
import CheckboxProps from "../../Core/Checkbox/CheckboxProps";
import Checkbox from "../../Core/Checkbox/Checkbox/Checkbox";
import Switch from "../../Core/Checkbox/Switch/Switch";
import ElementSelect, {ElementSelectProps} from "../../Primary/Selects/ElementSelect/ElementSelect";
import ResponsiveLabel, {ResponsiveLabelType} from "../../Core/ResponsiveLabel/ResponsiveLabel";
import {RenderizzatoreSezioneInterface} from "../../Primary/Renderizzatore/Renderizzatore";
import {StorageData, StorageDataRenderer} from "../../Pages/SitoInterno/Configuratore/Storage/StorageManager";
import {ConfigurazioneSezioneInterface, TipoSezioneConfiguratoreType} from "tici_commons";
import {SelettoreMaterialeRenderizzatore} from "./RenderizzatoreWidgets/SelettoreMaterialeRenderizzatore";
import {SelettoreImmagineSezioneRenderizzatore} from "./RenderizzatoreWidgets/SelettoreImmagineSezioneRenderizzatore";
import {SelettoreLegnoRenderizzatore} from "./RenderizzatoreWidgets/SelettoreLegnoRenderizzatore";
import {SelettorePlexRenderizzatore} from "./RenderizzatoreWidgets/SelettorePlexRenderizzatore";
import {SelettoreUvRenderizzatore} from "./RenderizzatoreWidgets/SelettoreUvRenderizzatore";

export interface TextLineProps extends DefaultInputProps{
    label: string,
    showError?: boolean,
    errorMessage?: string,
    labelType?: ResponsiveLabelType
}
export function TextLine(props: TextLineProps){
    return(
        <TwoSpaceLine
            label={props.label}
            showError={props.showError}
            errorMessage={props.errorMessage}
            labelType={props.labelType}>
            <DefaultInput
                inputRef={props.inputRef}
                placeHolder={props.placeHolder}
                onChange={props.onChange}
                onFileUpload={props.onFileUpload}
                disabled={props.disabled}
                readonly={props.readonly}
                type={props.type}
                value={props.value}
                min={props.min}
                max={props.max}/>
        </TwoSpaceLine>
    );
}

export interface CheckLineProps extends CheckboxProps{
    label: string,
    showError?: boolean,
    errorMessage?: string,
    reduce?: boolean,
    labelType?: ResponsiveLabelType,
    checkType: "checkbox" | "switch",
}
export function CheckLine(props: CheckLineProps){
    return (
        <TwoSpaceLine
            label={props.label}
            showError={props.showError}
            errorMessage={props.errorMessage}
            wrapOnMobile={false}
            reduce={props.reduce}
            labelType={props.labelType}>
            {
                props.checkType === "checkbox" &&
                <Checkbox
                    disabled={props.disabled}
                    onChange={props.onChange}
                    checked={props.checked}/>
            }
            {
                props.checkType === "switch" &&
                <Switch
                    disabled={props.disabled}
                    onChange={props.onChange}
                    checked={props.checked}/>
            }
        </TwoSpaceLine>
    )
}

export interface SelectLineProps extends Omit<ElementSelectProps, "label" | "onFileUpload">{
    label: string,
    showError?: boolean,
    errorMessage?: string,
    labelType?: ResponsiveLabelType,
    placeHolder: string,
    autocompleteMode?: boolean
}
export function SelectLine(props: SelectLineProps){
    return (
        <TwoSpaceLine
            label={props.label}
            showError={props.showError}
            errorMessage={props.errorMessage}
            wrapOnMobile={true}
            labelType={props.labelType}>
            <ElementSelect
                label={props.placeHolder}
                elements={props.elements}
                onChange={props.onChange}
                disabled={props.disabled}
                value={props.value}
                maxHeight={props.maxHeight}
                autocompleteMode={props.autocompleteMode}/>
        </TwoSpaceLine>
    );
}

export interface ImageVisualizerProps{
    imageData?: string;
    storageData?: StorageData;
    onDelete?: () => void;
}
export function ImageVisualizer(props: ImageVisualizerProps){
    return (
        <TwoSpaceLine label={""}>
            <div className={"ImageVisualizer"}>
                {props.imageData && <img alt={"imageVisualizer"} className={"image"} src={props.imageData}/>}
                {props.storageData && <StorageDataRenderer storageData={props.storageData} className={"image"}/>}
                <div onClick={() => {props.onDelete && props.onDelete()}} className={"deleteButton"}/>
            </div>
        </TwoSpaceLine>
    )
}

export interface RenderizzatoreSezioneConfiguratoreProps{
    sezione: RenderizzatoreSezioneInterface,
    hasSezioneData: (nomeSezione: string) => boolean,
    getSezioneData: (nomeSezione: string) => string,
    getSezioneType: (nomeSezione: string) => TipoSezioneConfiguratoreType,
    isSingolaImmagine: (nomeSezione: string) => boolean,
    isSingoloColore: (nomeSezione: string) => boolean,
    setSezioneData: (nomeSezione: string, tipoSezione: TipoSezioneConfiguratoreType, valoreSezione: string, materialeSezione: string) => void,
    deleteSezioneData: (nomeSezione: string) => void,
    setStorageData: (nomeFile: string, file?: File, base64?: string) => void,
    getStorageData: (nomeFile: string) => StorageData[],
    deleteStorageData: () => void,
    configurazioneSezione?: ConfigurazioneSezioneInterface,
    filtroMateriale: 'spesso' | 'sottile' | "*",
    famiglieEscluse: string[]
}
export function RenderizzatoreSezioneConfiguratore(props: RenderizzatoreSezioneConfiguratoreProps){
    return(
        <Fragment>
            {props.sezione.tipoSezione === "materiale" &&
                <SelettoreMaterialeRenderizzatore
                    sezione={props.sezione}
                    hasSezioneData={props.hasSezioneData}
                    getSezioneData={props.getSezioneData}
                    setSezioneData={props.setSezioneData}
                    filtroMateriali={props.filtroMateriale}
                    famiglieMaterialiEscluse={props.famiglieEscluse}/>}
            {props.sezione.tipoSezione === "immagine" &&
                <SelettoreImmagineSezioneRenderizzatore
                    sezione={props.sezione}
                    configurazioneSezione={props.configurazioneSezione}
                    hasSezioneData={props.hasSezioneData}
                    getSezioneData={props.getSezioneData}
                    setSezioneData={props.setSezioneData}
                    deleteStorageData={props.deleteStorageData}
                    getStorageData={props.getStorageData}
                    setStorageData={props.setStorageData}
                    deleteSezioneData={props.deleteSezioneData}
                    getSezioneType={props.getSezioneType}/>}
            {props.sezione.tipoSezione === "legno" &&
                <SelettoreLegnoRenderizzatore
                    sezione={props.sezione}
                    hasSezioneData={props.hasSezioneData}
                    getSezioneData={props.getSezioneData}
                    setSezioneData={props.setSezioneData}/>}
            {props.sezione.tipoSezione === "plex" &&
                <SelettorePlexRenderizzatore
                    sezione={props.sezione}
                    hasSezioneData={props.hasSezioneData}
                    getSezioneData={props.getSezioneData}
                    setSezioneData={props.setSezioneData}/>}
            {props.sezione.tipoSezione === "uv" && props.configurazioneSezione && props.configurazioneSezione.sezioneAssociataUv &&
                <SelettoreUvRenderizzatore
                    sezione={props.sezione}
                    hasSezioneData={props.hasSezioneData}
                    getSezioneData={props.getSezioneData}
                    setSezioneData={props.setSezioneData}
                    deleteStorageData={props.deleteStorageData}
                    getStorageData={props.getStorageData}
                    setStorageData={props.setStorageData}
                    deleteSezioneData={props.deleteSezioneData}
                    getSezioneType={props.getSezioneType}
                    isSingoloColore={props.isSingoloColore}
                    isSingolaImmagine={props.isSingolaImmagine}
                    configurazioneSezione={props.configurazioneSezione}/>}
        </Fragment>
    )
}

export interface ImmagineMiniaturaProps{
    immagineData?: string;
}

export function ImmagineMiniatura (props: ImmagineMiniaturaProps){
    return (
        props.immagineData &&
        <img alt={"CopertinaImage"} style={{width: '100%'}} src={props.immagineData}/>
    )
}

/**
 * Descrive una riga larga secondo gli standard del configuratore
 * @param props
 * @constructor
 */
export function LargeRow(props: {children: any}){
    return (
        <Row
            className={"n_w60p r_w80p g_rma g_lma g_bm1"}>
            {props.children}
        </Row>
    )
}

/**
 * Descrive una riga media secondo gli standard del configuratore
 * @param props
 * @constructor
 */
export function MediumRow(props: {children: any}){
    return (
        <Row
            className={"n_w50p r_w70p g_rma g_lma g_bm1"}>
            {props.children}
        </Row>
    )
}

export interface QuadImageProps{
    src: string | StorageData
}
export function QuadImage(props: QuadImageProps){
    const [src, setSrc] = useState<string>();

    useEffect(() => {
        if(typeof props.src === 'string')
            setSrc(props.src);
        else {
            setSrc(props.src.url());
        }
    }, [props.src]);

    return (
        <img
            alt={"Immagine foglio"}
            className={`QuadImage`}
            src={src}/>
    )
}

export function ScrollZone(props: {children?: any}){
    return (
        <div className={"ScrollZone"}>
            {props.children}
        </div>
    )
}

export interface ScrollZoneElementProps{
    content: string,
    onDeleteClick?: () => void
}
export function ScrollZoneElement(props: ScrollZoneElementProps){
    return (
        <span className={"ScrollZoneElement"}>
            <ResponsiveLabel content={props.content} type={"medium"} alignment={"left"}/>
            <img onClick={() => {
                props.onDeleteClick && props.onDeleteClick();
            }} className={"deleteButton"} src={xIcon} alt={"xIcon"}/>
        </span>
    )
}

export interface RecapElementProps{
    value: string;
    messageType: "recap" | "error" | "warning";
}
export function RecapElement(props: RecapElementProps){
    const labelType = props.messageType === "error" ? "errorLabel" : (props.messageType === "warning" ? "warningLabel" : "recapLabel")
    return (
        <span className={"RecapElement"}>
            {props.messageType === "error" && <img alt={"alertIcon"} className={"image"} src={redAlertIcon}/>}
            {props.messageType === "warning" && <img alt={"alertIcon"} className={"image"} src={yellowAlertIcon}/>}
            <ResponsiveLabel content={props.value} type={"medium"} alignment={"left"} labelType={labelType}/>
        </span>
    )
}
