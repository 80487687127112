import {
    AttivazioneRoutePutBodyInterface,
    FotografoModel,
    LoginResponseAccountStatusEnum,
    LoginResponseModel,
    LoginRoutePostBodyInterface,
    ModificaUtenteRoutePutBodyInterface,
    RegistrazioneRoutePostBodyInterface,
    RegistrazioneRoutePutBodyInterface,
    Rest,
    UserContextResponseModel,
    UtenteModel,
    RecuperoPasswordRoutePostBodyInterface, AggiornamentoLocazionePutBodyInterface
} from "tici_commons";
import Config from "../Static/Config";

export default class UserService{
    public static set UID(uid: string | undefined){
        if(uid !== undefined)
            localStorage.setItem('UID', uid);
        else localStorage.removeItem('UID');
    }
    public static get UID(): string | undefined{
        let esito = undefined;

        const data = localStorage.getItem('UID');
        if(data)
            esito = data;

        return esito;
    }
    public static set UserContext(userContext: UserContextResponseModel | undefined){
        if(userContext === undefined)
            localStorage.removeItem("UserContext");
        else localStorage.setItem('UserContext', JSON.stringify(userContext));
    }
    public static get UserContext(): UserContextResponseModel | undefined{
        let userContext = undefined;

        const data = localStorage.getItem("UserContext");
        if(data)
            userContext = JSON.parse(data);

        return userContext
    }

    public static async Login(email: string, password: string): Promise<false | LoginResponseAccountStatusEnum>{
        let esito: false | LoginResponseAccountStatusEnum = false;

        const body: LoginRoutePostBodyInterface = {
            email,
            password
        }

        try {
            const response = await Rest.Post<LoginResponseModel>(
                `${Config.BackEnd}/Login`,
                undefined,
                body
            );

            if(response && response.statusCode === 200){
                esito = response.response.accountStatus;
                if(response.response.uid !== undefined) {
                    UserService.UID = response.response.uid;
                    Rest.UID = UserService.UID;
                }
            }
        }catch (e){
            console.error("Non è stato possibile eseguire la login", String(e));
        }

        return esito;
    }

    public static async RecuperaPassword(emailRecupero: string): Promise<boolean>{
        let esito = false;

        try{
            const body: RecuperoPasswordRoutePostBodyInterface = {
                emailRecupero
            }
            const response = await Rest.Post(`${Config.BackEnd}/Recupero`, undefined, body);
            if(response && response.statusCode === 200)
                esito = true;
        }catch (e){
            console.error("Non é stato possibile recuperare la password", String(e));
        }

        return esito;
    }

    public static async IsLogged(): Promise<boolean>{
        let esito = false;

        try {
            const response = await Rest.Get<boolean>(`${Config.BackEnd}/Login`);
            if(response && response.response)
                esito = true;
        }catch (e){
            console.error("Non è stato possibile eseguire isLogged", String(e));
        }

        return esito;
    }
    public static Logout() {
        UserService.UID = undefined;
        Rest.UID = undefined;
        UserService.UserContext = undefined;
    }
    public static async Registrazione(utente: UtenteModel, fotografo: FotografoModel, image: string): Promise<boolean>{
        let esito = false;

        try{
            const body: RegistrazioneRoutePostBodyInterface = {
                utente,
                fotografo,
                image
            }
            const response = await Rest.Post(`${Config.BackEnd}/Registrazione`, undefined, body);
            if(response && response.statusCode === 200)
                esito = true;
        }catch(e){
            console.error("Non è stato possibile registrare l'utente", String(e));
        }

        return esito;
    }
    public static async AttivazioneEmail(emailUtente: string): Promise<boolean>{
        let esito = false;

        try {
            const body: RegistrazioneRoutePutBodyInterface = {emailUtente}
            const response = await Rest.Put(
                `${Config.BackEnd}/Registrazione`,
                undefined,
                body
            );
            if(response && response.statusCode === 200)
                esito = true;
        }catch (e){
            console.error("Non è stato possibile attivare la mail", String(e));
        }

        return esito;
    }
    public static async AttivazioneAccount(emailUtente: string, statoAttivazione: boolean): Promise<boolean>{
        let esito = false;

        try {
            const body: AttivazioneRoutePutBodyInterface = {
                emailUtente,
                statoAttivazione
            }
            const response = await Rest.Put(`${Config.PrivatePath}/Attivazione`, undefined, body);
            if(response && response.statusCode === 200)
                esito = true;
        }catch (e){
            console.error("Non è stato possibile attivare l'account", String(e));
        }

        return esito;
    }
    public static async EliminazioneAccount(emailUtente?: string): Promise<boolean>{
        let esito = false;

        try {
            const response = await Rest.Delete(`${Config.PrivatePath}/Attivazione${emailUtente ? `/${emailUtente}` : ''}`);
            if(response && response.statusCode === 200)
                esito = true;
        }catch (e){
            console.error("Non è stato possibile eliminare l'account", String(e));
        }

        return esito;
    }
    public static async ModificaAccount(modificheAccount: ModificaUtenteRoutePutBodyInterface): Promise<boolean>{
        let esito = false;

        try{
            const response = await Rest.Put(
                `${Config.PrivatePath}/ModificaUtente`,
                undefined,
                modificheAccount
            );
            if(response && response.statusCode === 200)
                esito = true;
        }catch (e){
            console.error("Non è stato possibile modificare le informazioni dell'account", String(e));
        }

        return esito;
    }
    public static async ModificaLocazioneFotografo(idFotografo: number, locazione: string): Promise<boolean>{
        let esito = false;

        try {
            const body: AggiornamentoLocazionePutBodyInterface = {
                idFotografo,
                regioneAppartenenza: locazione
            }
            const response = await Rest.Put(`${Config.PrivatePath}/AggiornamentoLocazione`, undefined, body);
            if(response && response.statusCode === 200)
                esito = true;
        }catch (e){
            console.error("Non é stato possibile aggiornare la locazione del fotografo", String(e));
        }

        return esito;
    }
    public static async GetUserContext(): Promise<UserContextResponseModel | undefined>{
        let esito = undefined;

        const localStorageResponseContext = UserService.UserContext;
        if(localStorageResponseContext)
            esito = localStorageResponseContext;
        else{
            try{
                const response = await Rest.Get<UserContextResponseModel>(`${Config.PrivatePath}/UserContext`);
                if(response && response.statusCode === 200){
                    esito = response.response;
                    UserService.UserContext = esito;
                }
            }catch (e){
                console.error("Non è stato possibile recuperare la user context", String(e));
            }
        }

        return esito;
    }
    public static async IsSuperUser(): Promise<boolean>{
        let esito = false;

        if(Rest.PERSONIFICATION === undefined){
            try{
                const response = await Rest.Get<boolean>(`${Config.PrivatePath}/SuperUser`);
                if(response && response.statusCode === 200)
                    esito = response.response;
            }catch (e){
                console.error("Errore chiamata verifica SuperUser", String(e));
            }
        }

        return esito;
    }
    public static UserImageUrl(email?: string, immagineProfilo?: string): string{
        let esito = "";

        if(email && immagineProfilo){
            esito = `${Config.UserImage}/${email}/${immagineProfilo}`;
        }else if(UserService.UserContext){
            const context: UserContextResponseModel = UserService.UserContext;
            esito = `${Config.UserImage}/${context.email}/${context.immagineProfilo}`;
        }

        return esito.replaceAll(' ', '%20');
    }
}
