import {ColoreFodera, FotolibroModel} from "tici_commons";
import React from "react";
import {StorageDataMapper, StorageManagerStatus} from "../../../Storage/StorageManager";

import {ImageVisualizer, SelectLine, TextLine} from "../../../../../../Widgets/Configuratore/CComponents";
import IfContainer from "../../../../../../Layout/IfContainer/IfContainer";
import {
    GenerateRiepilogoData,
    GenerateRiepilogoInternalBox,
    RiepilogoBox,
    RiepilogoData,
    RiepilogoInternalBox
} from "../../../Commons/RiepilogoDatoModel";
import SezioneGenerica from "../../SezioneGenerica";
import CategorySectionLayout from "../../../../../../Layout/CategorySectionLayout/CategorySectionLayout";
import SectionLayout from "../../../../../../Layout/SectionLayout/SectionLayout";
import Responsive2Col from "../../../../../../Layout/Responsive2Col/Responsive2Col";
import ResponsiveLabel from "../../../../../../Core/ResponsiveLabel/ResponsiveLabel";


export default class SezioneInformazioniRilegatura extends SezioneGenerica<"rilegatura", {}, {}>{
    public static ErrorMessages = {
        ColoreFoderaError: "Il colore della fodera non è stato selezionato",
        ImmagineFoderaError: "Bisogna caricare almeno un immagine della fodera all'inizio o alla fine"
    }

    public generaRiepilogo(fotolibro: FotolibroModel, storage: StorageManagerStatus, modalitaConfiguratore: boolean): RiepilogoBox | RiepilogoInternalBox | RiepilogoInternalBox[] | RiepilogoData[] {
        return GenerateRiepilogoInternalBox(
            'Blocco',
            GenerateRiepilogoData(
                `Colore fodera: ${fotolibro.rilegatura.coloreFodera}`,
                SezioneInformazioniRilegatura.ErrorMessages.ColoreFoderaError,
                fotolibro.rilegatura.coloreFodera.length === 0
            ),
            GenerateRiepilogoData(
                undefined,
                SezioneInformazioniRilegatura.ErrorMessages.ImmagineFoderaError,
                true,
                fotolibro.rilegatura.coloreFodera.toLowerCase().includes('grafica') &&
                storage &&
                storage.GetStorageData(StorageDataMapper.ImmagineFoderaInizio).length === 0 &&
                storage.GetStorageData(StorageDataMapper.ImmagineFoderaFine).length === 0
            ),
            GenerateRiepilogoData(
                `Tassello: ${fotolibro.rilegatura.tassello}`,
                undefined,
                false
            )
        );
    }

    private _SezioneColoreFoderaGrafica(immagineFoderaInizio: boolean){
        return (
            <SectionLayout size={"largeRelative"}>
                <IfContainer condition={this.CountImmagini(StorageDataMapper[immagineFoderaInizio ? 'ImmagineFoderaInizio' : 'ImmagineFoderaFine']) === 1}>
                    <ImageVisualizer
                        storageData={this.ImmaginiDaCategoria(StorageDataMapper[immagineFoderaInizio ? 'ImmagineFoderaInizio' : 'ImmagineFoderaFine'])[0]?? undefined}
                        onDelete={() => this.DeleteImmagine(StorageDataMapper[immagineFoderaInizio ? 'ImmagineFoderaInizio' : 'ImmagineFoderaFine'])}/>
                </IfContainer>
                <TextLine
                    label={immagineFoderaInizio ? 'Immagine fodera inizio' : 'Immagine fodera fine'}
                    type={"file"}
                    value={
                        this.CountImmagini(StorageDataMapper[immagineFoderaInizio ? 'ImmagineFoderaInizio' : 'ImmagineFoderaFine']) === 1 ?
                            this.ImmaginiDaCategoria(StorageDataMapper[immagineFoderaInizio ? 'ImmagineFoderaInizio' : 'ImmagineFoderaFine'])[0].name : ""
                    }
                    onFileUpload={(fileName, fileType, file) => {
                        if(['png', 'jpg', 'jpeg'].includes(fileType)){
                            this.DeleteImmagine(StorageDataMapper[immagineFoderaInizio ? 'ImmagineFoderaInizio' : 'ImmagineFoderaFine']);
                            this.UplodaImmagine(StorageDataMapper[immagineFoderaInizio ? 'ImmagineFoderaInizio' : 'ImmagineFoderaFine'], fileName, file)
                        }
                    }}/>
            </SectionLayout>
        )
    }

    public render() {
        return (
            <CategorySectionLayout categoryLabel={"Fodera"} size={"largeRelative"} center={true}>
                <SelectLine
                    label={"Fodera"}
                    placeHolder={"Seleziona"}
                    elements={ColoreFodera.map(v => ({label: v}))}
                    value={this.$.coloreFodera}
                    onChange={v => this.$.coloreFodera = v}
                    showError={!this.$.coloreFodera}
                    errorMessage={SezioneInformazioniRilegatura.ErrorMessages.ColoreFoderaError}/>
                <IfContainer condition={this.$.coloreFodera.toLowerCase().includes("grafica")}>
                    <IfContainer condition={this.CountImmagini(StorageDataMapper.ImmagineFoderaInizio) === 0 && this.CountImmagini(StorageDataMapper.ImmagineFoderaFine) === 0}>
                        <ResponsiveLabel
                            content={SezioneInformazioniRilegatura.ErrorMessages.ImmagineFoderaError}
                            type={"small"}
                            alignment={"center"}
                            labelType={"errorLabel"}/>
                    </IfContainer>
                    <Responsive2Col>
                        {this._SezioneColoreFoderaGrafica(true)}
                        {this._SezioneColoreFoderaGrafica(false)}
                    </Responsive2Col>
                </IfContainer>
                <SelectLine
                    label={"Tassello"}
                    placeHolder={"Seleziona"}
                    elements={[{label: "Standard"}, {label: "Bianco"}, {label: "Nero"}]}
                    value={this.$.tassello}
                    onChange={v => this.$.tassello = v}/>
            </CategorySectionLayout>
        );
    }
}
