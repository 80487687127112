import {FotografoContextResponseModel, Rest} from "tici_commons";
import Config from "../Static/Config";

export default class FotografoService{
    public static async GetFotografoContext(): Promise<FotografoContextResponseModel | FotografoContextResponseModel[] | undefined>{
        let esito = undefined;

        try {
            const response = await Rest.Get<FotografoContextResponseModel | FotografoContextResponseModel[]>(`${Config.PrivatePath}/FotografoContext`);
            if(response && response.statusCode === 200)
                esito = response.response
        }catch (e){
            console.error("Non è stato possibile recuperare il contesto del fotografo", String(e));
        }

        return esito;
    }
}
