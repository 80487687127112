export interface RiepilogoBox{
    title: string;
    riepiloghiInternalBox?: RiepilogoInternalBox[];
}

export const GenerateRiepilogoBox = (title: string, ...riepiloghiInternalBox: RiepilogoInternalBox[]): RiepilogoBox => {
    return {
        title: title,
        riepiloghiInternalBox: [...riepiloghiInternalBox]
    }
}

export interface RiepilogoInternalBox{
    title: string;
    riepilogoData?: RiepilogoData[];
}

export const GenerateRiepilogoInternalBox = (title: string, ...riepilogoData: RiepilogoData[]): RiepilogoInternalBox => {
    return {
        title: title,
        riepilogoData: [...riepilogoData.filter(riepilogoData => riepilogoData !== undefined)]
    }
}

export interface RiepilogoData{
    infoLabel?: string;
    errorLabel?: string;
    error?: boolean;
}

export const GenerateRiepilogoData = (infoLabel?: string, errorLabel?: string, error?: boolean, visible = true): RiepilogoData => {
    return visible ? {
        infoLabel: infoLabel,
        errorLabel: errorLabel,
        error: error
    } : undefined
}

export const GenerateGroup = (condizione: boolean, ...data: any[]): any[] => {
    const esito = [];
    if(condizione)
        esito.push(...data);
    return esito;
}

/**
 * Filtra tutti gli elementi del singolo riepilogo box
 * @param riepilogoBox Riepilogo box da contrllare
 * @private
 */
export const FilterRiepilogoBox = (riepilogoBox: RiepilogoBox) => {
    if(riepilogoBox.riepiloghiInternalBox && riepilogoBox.riepiloghiInternalBox.length > 0){
        riepilogoBox.riepiloghiInternalBox = riepilogoBox.riepiloghiInternalBox.filter(
            (riepilogoInternalBox) => {
                let esito = false;
                if(riepilogoInternalBox && riepilogoInternalBox.riepilogoData && riepilogoInternalBox.riepilogoData.length > 0){
                    riepilogoInternalBox.riepilogoData = riepilogoInternalBox.riepilogoData.filter(
                        (riepilogoData) => {
                            let esito = false;
                            if(riepilogoData){
                                esito = riepilogoData.error ? !!riepilogoData.errorLabel : !!riepilogoData.infoLabel
                            }
                            return esito;
                        }
                    );
                    esito = riepilogoInternalBox.riepilogoData.length > 0
                }
                return esito;
            }
        )
    }
}
