import React from "react";
import "./Responsive3Col.scss";

export interface Responsive3ColProps{
    children?: any
    modalitaSingolaColonna?: boolean
}
export default class Responsive3Col extends React.Component<Responsive3ColProps, {}>{
    public render() {
        return (
            <div className={`Responsive3Col ${this.props.modalitaSingolaColonna && 'singolaColonna'}`}>
                {this.props.children}
            </div>
        );
    }
}
