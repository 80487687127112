import React from "react";
import ResponsiveLabel from "../../../Core/ResponsiveLabel/ResponsiveLabel";
import SectionLayout from "../../../Layout/SectionLayout/SectionLayout";
import Button from "../../../Core/Buttons/Button";
import RedirectService from "../../../Servicies/RedirectService";
import Responsive3Col from "../../../Layout/Responsive3Col/Responsive3Col";

export default class NuovoOrdine extends React.Component<{}, {}>{
    public render() {
        return (
            <SectionLayout size={"largeRelative"}>
                <ResponsiveLabel alignment={"center"} content={"Che tipo di ordine vuoi creare?"} type={"large"} uppercase={true} bold={true}/>
                <SectionLayout size={"mediumRelative"} showBorder={true} addPadding={true} lightBackground={true}>
                    <ResponsiveLabel alignment={"center"} content={"Progetti da catalogo"} type={"large"}/>
                    <Responsive3Col>
                        <Button
                            content={"Wedding Project"}
                            type={"medium"}
                            buttonType={"full-normal"}
                            alignment={"center"}
                            onClick={() => RedirectService.GoTo("Wedding Project", "/homepage/coordinato_wedding")}/>
                        <Button
                            content={"Junior Project"}
                            type={"medium"}
                            buttonType={"full-normal"}
                            alignment={"center"}
                            onClick={() => RedirectService.GoTo("Junior Project", "/homepage/coordinato_junior")}/>
                        <Button
                            content={"Art Project"}
                            type={"medium"}
                            buttonType={"full-normal"}
                            alignment={"center"}
                            onClick={() => RedirectService.GoTo("Art Project", "/homepage/coordinato_art")}/>
                    </Responsive3Col>
                    <ResponsiveLabel alignment={"center"} content={"Configurabili"} type={"large"}/>
                    <Button
                        content={"Album tradizionale"}
                        type={"medium"}
                        buttonType={"full-normal"}
                        alignment={"center"}
                        onClick={() => RedirectService.GoTo("Album tradizionale", "/homepage/album_tradizionale")}/>
                    <Button
                        content={"Blocco book"}
                        type={"medium"}
                        buttonType={"full-normal"}
                        alignment={"center"}
                        onClick={() => RedirectService.GoTo("Blocco book", "/homepage/album_blocco_book")}/>
                    <Button
                        content={"Fotolibro configurazione libera"}
                        type={"medium"}
                        buttonType={"full-normal"}
                        alignment={"center"}
                        onClick={() => RedirectService.GoTo("Configurazione libera", "/homepage/fotolibro_configurazione_libera")}/>
                    <Button
                        content={"Accessori"}
                        type={"medium"}
                        buttonType={"full-normal"}
                        alignment={"center"}
                        onClick={() => RedirectService.GoTo("Accessori", "/homepage/accessori")}/>
                </SectionLayout>
            </SectionLayout>
        );
    }
}
