import React, {Fragment} from "react";
import ContainerLayout from "../../../../../Layout/ContainerLayout/ContainerLayout";
import {CheckLine, SelectLine, TextLine} from "../../../../../Widgets/Configuratore/CComponents";
import IfContainer from "../../../../../Layout/IfContainer/IfContainer";
import SectionLayout from "../../../../../Layout/SectionLayout/SectionLayout";
import {TipoModelloType} from "tici_commons";
import Responsive2Col from "../../../../../Layout/Responsive2Col/Responsive2Col";
import Button from "../../../../../Core/Buttons/Button";
import SuperUserService from "../../../../../Servicies/SuperUserService";
import TopLevelComponentStorage from "../../../../../Core/Arch/TopLevelComponentStorage";

export interface VistaModelliProps {
    listaModelli: string[],
    listaConfigurazioni: string[]
}

export interface VistaModelliState {
    modelloSelezionato: string,

    nomeModello: string,
    tipoModello: TipoModelloType,

    modelloCofanettoRidotto: boolean,
    modelloCofanettoRidottoFile: boolean,
    modelloCofanettoRidottoUv: boolean,

    configurazioneSelezionata: string,
}

export default class VistaModelli extends React.Component<VistaModelliProps, VistaModelliState>{
    private _nuovoModelloLabel = "Nuovo modello";                                //Label per il nuovo modello

    constructor(props: Readonly<VistaModelliProps> | VistaModelliProps) {
        super(props);
        this.state = {
            modelloSelezionato: "",

            nomeModello: "",
            tipoModello: "copertina",

            modelloCofanettoRidotto: false,
            modelloCofanettoRidottoFile: false,
            modelloCofanettoRidottoUv: false,

            configurazioneSelezionata: ""
        }
    }

    /**
     * Aggiunge un singolo modello al sito
     */
    public async _aggiungiModello(){
        if(this.state.nomeModello && this.state.tipoModello && this.state.configurazioneSelezionata){
            const esito = await SuperUserService.AggiungiModello(
                this.state.nomeModello,
                this.state.tipoModello,
                this.state.modelloCofanettoRidotto,
                this.state.modelloCofanettoRidottoFile,
                this.state.modelloCofanettoRidottoUv,
                this.state.configurazioneSelezionata
            )

            if(esito){
                this.setState({
                    nomeModello: "",
                    tipoModello: "copertina",
                    configurazioneSelezionata: ""
                });
                TopLevelComponentStorage.GetTopLevel('confirmWindow').showConfirmWindow(
                    "Modello caricato",
                    "Il modello è stato caricato correttamente"
                );
            }else{
                TopLevelComponentStorage.GetTopLevel('confirmWindow').showConfirmWindow(
                    "Modello non caricato",
                    "Il modello non è stato caricato"
                );
            }
        }
    }

    /**
     * Elimina un modello dalla configurazione
     * @private
     */
    private _eliminaModello(){
        TopLevelComponentStorage.GetTopLevel('confirmWindow').showConfirmWindow(
            "Eliminazione modello",
            "Eliminando il modello esso verrà rimosso permanentemente dal configuratore 3D",
            "DoubleButton",
            async () => {
                const esito = await SuperUserService.EliminaModello(this.state.modelloSelezionato);
                TopLevelComponentStorage.GetTopLevel('confirmWindow').showConfirmWindow(
                    esito ? "Eliminazione completata" : "Eliminazione fallita",
                    esito ? "Abbiamo eliminato il modello" : "Non é stato possibile eliminare il modello"
                );
            },
            () => TopLevelComponentStorage.GetTopLevel('confirmWindow').hideConfirmWindow()
        )
    }

    public render() {
        return (
            <Fragment>
                <SelectLine
                    label={"Modelli"}
                    placeHolder={"Seleziona"}
                    elements={
                        this.props.listaModelli ?
                            [{label: this._nuovoModelloLabel}, ...this.props.listaModelli.map(modello => ({label: modello}))] : [{label: this._nuovoModelloLabel}]
                    }
                    value={this.state.modelloSelezionato}
                    onChange={v => this.setState({modelloSelezionato: v})}/>
                <IfContainer condition={!!this.state.modelloSelezionato}>
                    <SectionLayout size={"largeRelative"}>
                        <TextLine
                            label={"Nome modello"}
                            value={this.state.modelloSelezionato === this._nuovoModelloLabel ? this.state.nomeModello : this.state.modelloSelezionato}
                            onChange={v => this.setState({nomeModello: v})}
                            showError={!this.state.nomeModello && this.state.modelloSelezionato === this._nuovoModelloLabel}
                            errorMessage={"Il nome del modello non può essere vuoto"}/>
                        <IfContainer condition={this.state.modelloSelezionato === this._nuovoModelloLabel}>
                            <SelectLine
                                label={"Tipo modello"}
                                placeHolder={"Seleziona"}
                                value={this.state.tipoModello}
                                onChange={v => this.setState({tipoModello: v as TipoModelloType})}
                                elements={[{label: "copertina"}, {label: "custodia"}, {label: "lavorazione"}]}/>
                            <CheckLine
                                label={"Modello cofanetto ridotto"}
                                checkType={"checkbox"}
                                checked={this.state.modelloCofanettoRidotto}
                                onChange={v => {
                                    this.setState({modelloCofanettoRidotto: v});
                                    if(!v) this.setState({modelloCofanettoRidottoFile: false, modelloCofanettoRidottoUv: false})
                                }}/>
                            <IfContainer condition={this.state.modelloCofanettoRidotto}>
                                <CheckLine
                                    label={"Modello cofanetto ridotto file"}
                                    checkType={"switch"}
                                    checked={this.state.modelloCofanettoRidottoFile}
                                    onChange={v => this.setState({modelloCofanettoRidottoFile: v})}/>
                                <CheckLine
                                    label={"Modello cofanetto ridotto uv"}
                                    checkType={"switch"}
                                    checked={this.state.modelloCofanettoRidottoUv}
                                    onChange={v => this.setState({modelloCofanettoRidottoUv: v})}/>
                            </IfContainer>
                            <SelectLine
                                label={"Configurazione"}
                                placeHolder={"Seleziona"}
                                value={this.state.configurazioneSelezionata}
                                onChange={v => this.setState({configurazioneSelezionata: v})}
                                elements={
                                    this.props.listaConfigurazioni ?
                                        this.props.listaConfigurazioni.map(configurazione => ({label: configurazione})) : []
                                }
                                showError={!this.state.configurazioneSelezionata}
                                errorMessage={"La configurazione non può essere vuota"}/>
                        </IfContainer>
                    </SectionLayout>
                    <Responsive2Col>
                        <Button
                            content={"Aggiungi"}
                            disabled={this.state.modelloSelezionato !== this._nuovoModelloLabel}
                            type={"large"}
                            buttonType={"full-normal"}
                            onClick={() => this._aggiungiModello()}/>
                        <Button
                            content={"Elimina"}
                            disabled={this.state.modelloSelezionato === this._nuovoModelloLabel}
                            type={"large"}
                            buttonType={"full-normal-negative"}
                            onClick={() => this._eliminaModello()}/>
                    </Responsive2Col>
                </IfContainer>
            </Fragment>
        )
    }
}
