import React from "react";
import {FotolibroContextInterface} from "../Fotolibro/Fotolibro";
import {FotolibroModel} from "tici_commons";
import SezioneRequiredProps from "../Commons/SezioneRiquiredProps";
import {StorageData, StorageManagerStatus} from "../Storage/StorageManager";
import {RiepilogoBox, RiepilogoData, RiepilogoInternalBox} from "../Commons/RiepilogoDatoModel";

export default abstract class SezioneGenerica<T extends keyof FotolibroContextInterface, P, S>
    extends React.Component<P & SezioneRequiredProps<T> & {data: FotolibroContextInterface[T], storage: StorageManagerStatus}, S>{

    public abstract generaRiepilogo(fotolibro: FotolibroModel, storage: StorageManagerStatus, modalitaConfiguratore: boolean): RiepilogoBox | RiepilogoBox[] | RiepilogoInternalBox | RiepilogoInternalBox[] | RiepilogoData[];

    /**
     * Permette di accedere e modificare la sottointerfaccia di sezione
     * @protected
     */
    protected get $() {
        const that = this;
        return new Proxy<typeof this.props.data>(this.props.data, {
            // @ts-ignore
            get(target: (typeof that.props.data), p: keyof (typeof that.props.data)): (typeof that.props.data)[p]{
                let response: (typeof that.props.data)[typeof p] = undefined;
                if(p in target)
                    response = target[p];
                return response
            },
            // @ts-ignore
            set(target: (typeof that.props.data), p: keyof (typeof that.props.data), value: (typeof that.props.data)[p]): boolean {
                // @ts-ignore
                that.props.SetParentState({[p]: value});
                return true;
            }
        })
    }

    /**
     * Carica un immagine nello storage
     * @param categoriaImmagine Categoria dell'immagine da caricare
     * @param nomeImmagine Nome dell'immagine da caricare
     * @param fileImmagine File dell'immagine da caricare
     * @param base64Data Codifica base64 da caricicare
     * @constructor
     * @protected
     */
    protected UplodaImmagine(categoriaImmagine: string, nomeImmagine: string, fileImmagine: File, base64Data?: string){
        if(this.props.storage)
            this.props.storage.SetStorageData(categoriaImmagine, nomeImmagine, fileImmagine, base64Data);
    }

    /**
     * Elimina un immagine dallo storage
     * @param categoriaImmagine Categoria dell'immagine da eliminare
     * @param nomeImmagine Nome dell'immagine da eliminare
     * @constructor
     * @protected
     */
    protected DeleteImmagine(categoriaImmagine: string, nomeImmagine?: string){
        if(this.props.storage)
            this.props.storage.DeleteStorageData(categoriaImmagine, nomeImmagine);
    }

    /**
     * Restituisce le immagini dalla categoria
     * @param categoriaImmagini Categoria delle immagini da recuperare
     * @protected
     */
    protected ImmaginiDaCategoria(categoriaImmagini: string): StorageData[]{
        const esito: StorageData[] = [];

        if(this.props.storage)
            esito.push(...this.props.storage.GetStorageData(categoriaImmagini));

        return esito;
    }

    /**
     * Recupera l'immagine dallo storage
     * @param categoriaImmagine Categoria dell'imaagine da recuperare
     * @param nomeImmagine Nome dell'immagine da recuperare
     * @constructor
     * @protected
     */
    protected Immagine(categoriaImmagine: string, nomeImmagine: string): StorageData | undefined{
        let esito = undefined;

        if(this.props.storage){
            const data = this.props.storage.GetStorageData(categoriaImmagine, nomeImmagine);
            if(data.length > 0)
                esito = data[0];
        }

        return esito;
    }

    /**
     * Restituisce il numero di immagini di una data categoria
     * @param categoriaImmagini Categoria da cui recuperare le immagini
     * @protected
     */
    protected CountImmagini(categoriaImmagini: string): number{
        return this.ImmaginiDaCategoria(categoriaImmagini).length;
    }
}
