import React from "react";
import {StorageDataMapper, StorageManagerStatus} from "../../../Storage/StorageManager";
import {
    CheckLine,
    SelectLine,
    TextLine
} from "../../../../../../Widgets/Configuratore/CComponents";
import IfContainer from "../../../../../../Layout/IfContainer/IfContainer";
import Materiali from "../../../../../../DatabaseData/Materiali";
import SelettoreUv from "../../../../../../Primary/SelettoreUv/SelettoreUv";
import { SelettoreImmagineSezione } from "../../../../../../Widgets/Configuratore/RenderizzatoreWidgets/SelettoreImmagineSezioneRenderizzatore";
import {
    GenerateGroup, GenerateRiepilogoData,
    GenerateRiepilogoInternalBox,
    RiepilogoBox,
    RiepilogoData,
    RiepilogoInternalBox
} from "../../../Commons/RiepilogoDatoModel";
import SezioneGenerica from "../../SezioneGenerica";
import {FotolibroModel, SezioneGenericaModel} from "tici_commons";
import CategorySectionLayout from "../../../../../../Layout/CategorySectionLayout/CategorySectionLayout";
import ResponsiveLabel from "../../../../../../Core/ResponsiveLabel/ResponsiveLabel";

export default class SezioneAlbumRidottoCopertina extends SezioneGenerica<"copertina", {}, {}>{
    public static ErrorMessages = {
        MaterialeAssenteError: 'Il materiale non è stato selezionato',
        CodiceUvError: 'Il codice uv non è stato selezionato'
    }

    generaRiepilogo(fotolibro: FotolibroModel, storage: StorageManagerStatus, modalitaConfiguratore: boolean): RiepilogoBox | RiepilogoInternalBox | RiepilogoInternalBox[] | RiepilogoData[] {
        return GenerateRiepilogoInternalBox(
            'Copertina album ridotto',
            ...GenerateGroup(
                fotolibro.informazioniBase.albumRidottoFlag && fotolibro.informazioniBase.albumRidottoQuantita > 0,
                GenerateRiepilogoData('Stessi materiali del grande', undefined, undefined, !!fotolibro.copertina.copertinaAlbumRidottoStessiMaterialiDelGrandeFlag),
                ...GenerateGroup(
                    !fotolibro.copertina.copertinaAlbumRidottoStessiMaterialiDelGrandeFlag,
                    GenerateRiepilogoData(
                        undefined,
                        "L'immagine di copertina o il materiale della copertina non sono stati selezionati",
                        true,
                        !!(!fotolibro.copertina.copertinaAlbumRidottoFotoCopertina && fotolibro.copertina.copertinaAlbumRidottoCopertina.length === 0)
                    ),
                    ...GenerateGroup(
                        !(!fotolibro.copertina.copertinaAlbumRidottoFotoCopertina && fotolibro.copertina.copertinaAlbumRidottoCopertina.length === 0),
                        GenerateRiepilogoData(
                            `Immagine copertina: ${fotolibro.copertina.copertinaAlbumRidottoFotoCopertina && fotolibro.copertina.copertinaAlbumRidottoFotoCopertina.informazione}`,
                            undefined,
                            undefined,
                            !!fotolibro.copertina.copertinaAlbumRidottoFotoCopertina
                        ),
                       GenerateRiepilogoData(
                            `Copertina: ${fotolibro.copertina.copertinaAlbumRidottoCopertina}`,
                            undefined,
                            undefined,
                            !!fotolibro.copertina.copertinaAlbumRidottoCopertina
                        )
                    ),
                    GenerateRiepilogoData(
                        `Dorso: ${fotolibro.copertina.copertinaAlbumRidottoDorso}`,
                        `Bisogna selezionare il materiale del dorso`,
                        fotolibro.copertina.copertinaAlbumRidottoDorso.length === 0
                    ),
                    GenerateRiepilogoData(
                        `Retro: ${fotolibro.copertina.copertinaAlbumRidottoRetro}`,
                        `Bisogna selezionare il materiale del retro`,
                        fotolibro.copertina.copertinaAlbumRidottoRetro.length === 0
                    )
                ),
                GenerateRiepilogoData("Stesso uv del grande", undefined, undefined, fotolibro.copertina.copertinaAlbumRidottoStessoUvDelGrandeFlag),
                ...GenerateGroup(
                    !fotolibro.copertina.copertinaAlbumRidottoStessoUvDelGrandeFlag &&
                    ( !fotolibro.copertina.copertinaAlbumRidottoStessiMaterialiDelGrandeFlag ? (Materiali.CanSupportUv(fotolibro.copertina.copertinaAlbumRidottoCopertina) || !!fotolibro.copertina.copertinaAlbumRidottoFotoCopertina) : true ) &&
                    fotolibro.copertina.copertinaAlbumRidottoStampaCodiceUvFlag,
                    GenerateRiepilogoData(
                        `Codice UV: ${fotolibro.copertina.copertinaAlbumRidottoCodiceUv}`,
                        SezioneAlbumRidottoCopertina.ErrorMessages.CodiceUvError,
                        !fotolibro.copertina.copertinaAlbumRidottoCodiceUv
                    ),
                    GenerateRiepilogoData(
                        `Specifiche di testo: ${fotolibro.copertina.copertinaAlbumRidottoSpecificheTesto}`,
                        undefined,
                        undefined,
                        fotolibro.copertina.copertinaAlbumRidottoSpecificheTesto.trim().length > 0
                    ),
                    GenerateRiepilogoData(
                        `Specifiche di posizione: ${fotolibro.copertina.copertinaAlbumRidottoSpecifichePosizione}`,
                        undefined,
                        undefined,
                        fotolibro.copertina.copertinaAlbumRidottoSpecificheTesto.trim().length > 0
                    )
                )
            )
        );
    }

    public render() {
        return (
            <CategorySectionLayout categoryLabel={"Album ridotto"} size={"largeRelative"} center={true}>
                <CheckLine
                    label={"Stessi materiali del grande"}
                    checkType={"checkbox"}
                    onChange={v => this.$.copertinaAlbumRidottoStessiMaterialiDelGrandeFlag = v}
                    checked={this.$.copertinaAlbumRidottoStessiMaterialiDelGrandeFlag}
                    reduce={true}/>
                <IfContainer condition={!this.$.copertinaAlbumRidottoStessiMaterialiDelGrandeFlag}>
                    <SelettoreImmagineSezione
                        label={"Immagine copertina"}
                        onImageUpload={(tipoImmagine, nomeImmagine, base64Encoding) => {
                            const fotoCopertina: SezioneGenericaModel = {
                                tipoSezione: tipoImmagine === 'tela' ? "immagineTela" : "immaginePlex",
                                informazione: nomeImmagine
                            };
                            this.$.copertinaAlbumRidottoFotoCopertina = fotoCopertina;
                            this.$.copertinaAlbumRidottoCopertina = "";
                            this.DeleteImmagine(StorageDataMapper.FotoCopertinaRidotto);
                            this.UplodaImmagine(StorageDataMapper.FotoCopertinaRidotto, nomeImmagine, undefined, base64Encoding);
                        }}
                        storageData={this.ImmaginiDaCategoria(StorageDataMapper.FotoCopertinaRidotto)[0]}
                        tipoImmagine={this.$.copertinaAlbumRidottoFotoCopertina?.tipoSezione as any}
                        onDeleteImage={() => {
                            this.DeleteImmagine(StorageDataMapper.FotoCopertinaRidotto);
                            this.$.copertinaAlbumRidottoCopertina = undefined
                        }}/>
                    <SelectLine
                        label={"Copertina"}
                        autocompleteMode={true}
                        placeHolder={"Seleziona"}
                        elements={Materiali.GetMateriali()}
                        value={this.$.copertinaAlbumRidottoCopertina}
                        onChange={v => {
                            this.$.copertinaAlbumRidottoFotoCopertina = undefined;
                            this.$.copertinaAlbumRidottoCopertina = v;
                            this.DeleteImmagine(StorageDataMapper.FotoCopertinaRidotto);
                        }}/>
                    <SelectLine
                        label={"Dorso"}
                        autocompleteMode={true}
                        placeHolder={"Seleziona"}
                        errorMessage={SezioneAlbumRidottoCopertina.ErrorMessages.MaterialeAssenteError}
                        showError={!this.$.copertinaAlbumRidottoDorso.length}
                        elements={Materiali.GetMateriali()}
                        value={this.$.copertinaAlbumRidottoDorso}
                        onChange={v => this.$.copertinaAlbumRidottoDorso = v}/>
                    <SelectLine
                        label={"Retro"}
                        autocompleteMode={true}
                        placeHolder={"Seleziona"}
                        errorMessage={SezioneAlbumRidottoCopertina.ErrorMessages.MaterialeAssenteError}
                        showError={!this.$.copertinaAlbumRidottoRetro.length}
                        elements={Materiali.GetMateriali()}
                        value={this.$.copertinaAlbumRidottoRetro}
                        onChange={v => this.$.copertinaAlbumRidottoRetro = v}/>
                </IfContainer>
                <CheckLine
                    label={"Stesso uv del grande"}
                    checkType={"checkbox"}
                    onChange={v => this.$.copertinaAlbumRidottoStessoUvDelGrandeFlag = v}
                    checked={this.$.copertinaAlbumRidottoStessoUvDelGrandeFlag}
                    reduce={true}/>
                <IfContainer condition={!this.$.copertinaAlbumRidottoStessoUvDelGrandeFlag}>
                    <IfContainer
                        condition={
                            !this.$.copertinaAlbumRidottoStessiMaterialiDelGrandeFlag ?
                                ( Materiali.CanSupportUv(this.$.copertinaAlbumRidottoCopertina) || !!this.$.copertinaAlbumRidottoFotoCopertina ) : true
                        }
                        elseComponent={
                            <ResponsiveLabel content={"Non é possibile applicare l'uv alla sezione"} type={"medium"} alignment={"right"}/>
                        }>
                        <CheckLine
                            label={"Codice Uv"}
                            checkType={"checkbox"}
                            onChange={v => this.$.copertinaAlbumRidottoStampaCodiceUvFlag = v}
                            checked={this.$.copertinaAlbumRidottoStampaCodiceUvFlag}
                            reduce={true}/>
                        <IfContainer condition={this.$.copertinaAlbumRidottoStampaCodiceUvFlag}>
                            <SelettoreUv
                                label={"Codice Uv"}
                                placeHolder={"Seleziona"}
                                errorMessage={SezioneAlbumRidottoCopertina.ErrorMessages.CodiceUvError}
                                showError={!this.$.copertinaAlbumRidottoCodiceUv}
                                currentImageName={this.$.copertinaAlbumRidottoCodiceUv}
                                currentImage={this.ImmaginiDaCategoria(StorageDataMapper.UvRidotto)[0]?? undefined}
                                onChange={(imageName, file, base64) => {
                                    if(imageName){
                                        this.$.copertinaAlbumRidottoCodiceUv = imageName;
                                        this.DeleteImmagine(StorageDataMapper.UvRidotto);
                                        if(base64)
                                            this.UplodaImmagine(StorageDataMapper.UvRidotto, imageName, undefined, base64);
                                        else this.UplodaImmagine(StorageDataMapper.UvRidotto, imageName, file);
                                    }else{
                                        this.$.copertinaAlbumRidottoCodiceUv = "";
                                        this.DeleteImmagine(StorageDataMapper.UvRidotto);
                                    }
                                }}/>
                            <TextLine
                                label={"Specifiche di testo"}
                                value={this.$.copertinaAlbumRidottoSpecificheTesto}
                                onChange={v => this.$.copertinaAlbumRidottoSpecificheTesto = v}/>
                            <TextLine
                                label={"Specifiche di posizione"}
                                value={this.$.copertinaAlbumRidottoSpecifichePosizione}
                                onChange={v => this.$.copertinaAlbumRidottoSpecifichePosizione = v}/>
                        </IfContainer>
                    </IfContainer>
                </IfContainer>
            </CategorySectionLayout>
        );
    }
}
