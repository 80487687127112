import {AlbumRidottoInformazioniBaseModel, FotolibroModel, isCatalogo} from "tici_commons";
import React, {Fragment} from "react";
import {StorageManagerStatus} from "../../../Storage/StorageManager";
import ResponsiveLabel from "../../../../../../Core/ResponsiveLabel/ResponsiveLabel";
import {CheckLine, SelectLine, TextLine} from "../../../../../../Widgets/Configuratore/CComponents";
import Formati from "../../../../../../DatabaseData/Formati";
import IfContainer from "../../../../../../Layout/IfContainer/IfContainer";
import {
    GenerateGroup,
    GenerateRiepilogoData,
    GenerateRiepilogoInternalBox,
    RiepilogoBox,
    RiepilogoData,
    RiepilogoInternalBox
} from "../../../Commons/RiepilogoDatoModel";
import SezioneAlbumPrincipale from "./SezioneAlbumPrincipale";
import {CoordinatoContext} from "../../../Fotolibro/Fotolibro";
import SezioneGenerica from "../../SezioneGenerica";
import CategorySectionLayout from "../../../../../../Layout/CategorySectionLayout/CategorySectionLayout";
import VerticalSpace from "../../../../../../Layout/VerticalSpace/VerticalSpace";
import LabelLoader from "../../../../../../Primary/LabelLoader/LabelLoader";
import DragAndDropMultifunzionale from "../../../../../../Primary/DragAndDropMultifunzionale/DragAndDropMultifunzionale";


export default class SezioneAlbumRidotto extends SezioneGenerica<"informazioniBase", {}, {}> {
    public static ErrorMessages = {
        QuantitaAlbumError: "Bisogna selezionare almeno un album"
    }

    public componentDidMount() {
        this._quantitaAlbumRidottiChange(this.$.albumRidottoQuantita, this.props.storage);
    }

    public generaRiepilogo = (fotolibro: FotolibroModel, storage: StorageManagerStatus, modalitaConfiguratore: boolean): RiepilogoBox | RiepilogoInternalBox | RiepilogoInternalBox[] | RiepilogoData[] => {
        const esito: RiepilogoInternalBox[] = [];

        if(fotolibro.informazioniBase.albumRidottoFlag && fotolibro.informazioniBase.orientamentoAlbum && fotolibro.informazioniBase.formatoAlbum){
            esito.push(
                GenerateRiepilogoInternalBox(
                    'Album ridotti',
                    GenerateRiepilogoData(
                        `Quantità: ${fotolibro.informazioniBase.albumRidottoQuantita}`,
                        SezioneAlbumRidotto.ErrorMessages.QuantitaAlbumError,
                        fotolibro.informazioniBase.albumRidottoQuantita === 0
                    )
                ),
                ...fotolibro.informazioniBase.albumRidottoInformazioni.map((albumRidotto, index) => GenerateRiepilogoInternalBox(
                    `Album ridotto ${index + 1}`,
                    GenerateRiepilogoData(
                        `Formato chiuso: ${albumRidotto.albumRidottoFormatoAlbum}`,
                        SezioneAlbumPrincipale.ErrorMessages.FormatoAsssenteError,
                        albumRidotto.albumRidottoFormatoAlbum.length === 0
                    ),
                    ...GenerateGroup(
                        fotolibro.informazioniBase.stampaFlag,
                        ...GenerateGroup(
                            albumRidotto.albumRidottoStesseStampeDelGrandeFlag || (albumRidotto.albumRidottoStesseStampeDelPrimoFlag && index > 0),
                            GenerateRiepilogoData('Stesse stampe del grande', undefined, undefined, albumRidotto.albumRidottoStesseStampeDelGrandeFlag),
                            GenerateRiepilogoData('Stessa configurazione del primo album ridotto', undefined, undefined, albumRidotto.albumRidottoStesseStampeDelPrimoFlag)
                        ),
                        ...GenerateGroup(
                            !(albumRidotto.albumRidottoStesseStampeDelGrandeFlag || (albumRidotto.albumRidottoStesseStampeDelPrimoFlag && index > 0)),
                            GenerateRiepilogoData(
                                `Numero di fogli: ${albumRidotto.albumRidottoNumeroFogli}`,
                                SezioneAlbumPrincipale.ErrorMessages.NumeroFogliError,
                                storage && storage.GetStorageData(`AlbumRidotto${index}StampeFogli`).length !== albumRidotto.albumRidottoNumeroFogli
                            )
                        )
                    )
                ))
            )
        }

        return esito;
    };


    /**
     * Aggiunge o rimuove un album ridotto
     * @param albumRidottiNumber Numero corrente selezionato di album ridotti
     * @param storage Storage delle immagini
     */
    private _quantitaAlbumRidottiChange = (albumRidottiNumber: number, storage: StorageManagerStatus) => {
        let albumRidottoInformazioni = [...this.$.albumRidottoInformazioni];
        if(albumRidottiNumber > albumRidottoInformazioni.length){
            //Aggiungiamo gli elementi mancanti
            for(let i = albumRidottoInformazioni.length; i < albumRidottiNumber; i++)
                albumRidottoInformazioni.push({
                    albumRidottoFormatoAlbum: "",
                    albumRidottoStesseStampeDelGrandeFlag: true,
                    albumRidottoStesseStampeDelPrimoFlag: false,
                    albumRidottoNumeroFogli: 0
                });
        }else if(albumRidottiNumber < albumRidottoInformazioni.length) {
            //Rimuoviamo gli elementi assenti
            albumRidottoInformazioni = albumRidottoInformazioni.filter((value, index) => {
                let esito = true;
                if(index > albumRidottiNumber - 1){
                    esito = false;
                    storage.DeleteStorageData(`AlbumRidotto${index}StampeFogli`);
                }
                return esito;
            });
        }
        this.$.albumRidottoQuantita = albumRidottiNumber;
        this.$.albumRidottoInformazioni = albumRidottoInformazioni
    }

    private _SezioneQuantitaAlbumRidotto(){
        return (
            <IfContainer condition={this.$.albumRidottoFlag}>
                <TextLine
                    label={"Quantità"}
                    type={"number"}
                    min={1}
                    max={100}
                    value={this.$.albumRidottoQuantita.toString()}
                    onChange={v => this._quantitaAlbumRidottiChange(parseInt(v), this.props.storage)}
                    showError={this.$.albumRidottoQuantita === 0}
                    errorMessage={SezioneAlbumRidotto.ErrorMessages.QuantitaAlbumError}/>
            </IfContainer>
        )
    }

    private _SezioneAlbumRidotti(){
        return (
            <Fragment>
                {this.$.albumRidottoInformazioni.map((albumRidotto, index) => this._SezioneAlbumRidotto(index, albumRidotto, this.props.storage))}
            </Fragment>
        )
    }

    private _SezioneAlbumRidotto(albumRidottoIndex: number, albumRidotto: AlbumRidottoInformazioniBaseModel, storage: StorageManagerStatus){
        return (
            <CategorySectionLayout categoryLabel={`Album ridotto ${albumRidottoIndex + 1}`} size={"largeRelative"} key={`AlbumRidotto${albumRidottoIndex}`}>
                <IfContainer condition={albumRidottoIndex === 0 || !albumRidotto.albumRidottoStesseStampeDelPrimoFlag}>
                    <CoordinatoContext.Consumer>{
                        coordinatoData => (
                            <SelectLine
                                label={"Formato chiuso"}
                                placeHolder={"Seleziona"}
                                errorMessage={SezioneAlbumPrincipale.ErrorMessages.FormatoAsssenteError}
                                showError={!albumRidotto.albumRidottoFormatoAlbum}
                                elements={
                                    isCatalogo(this.$.tipoFotolibro) ?
                                        Formati.GetFormatiRidottiVincolatiCoordinato(this.$.formatoAlbum, coordinatoData) :
                                        Formati.GetFormati(this.$.orientamentoAlbum, true, this.$.formatoAlbum)
                                }
                                value={albumRidotto.albumRidottoFormatoAlbum}
                                onChange={v => {
                                    const albumRidottoInformazioni = Array.from(this.$.albumRidottoInformazioni);
                                    albumRidottoInformazioni[albumRidottoIndex].albumRidottoFormatoAlbum = v;
                                    this.$.albumRidottoInformazioni = albumRidottoInformazioni;
                                }}/>
                        )
                    }</CoordinatoContext.Consumer>
                </IfContainer>
                <IfContainer condition={this.$.stampaFlag}>
                    <CheckLine
                        label={"Stesse stampe del grande"}
                        checkType={"switch"}
                        checked={albumRidotto.albumRidottoStesseStampeDelGrandeFlag}
                        onChange={v => {
                            const albumRidottoInformazioni = Array.from(this.$.albumRidottoInformazioni);
                            albumRidottoInformazioni[albumRidottoIndex].albumRidottoStesseStampeDelGrandeFlag = v;
                            if(v) albumRidottoInformazioni[albumRidottoIndex].albumRidottoStesseStampeDelPrimoFlag = !v;
                            this.$.albumRidottoInformazioni = albumRidottoInformazioni
                        }}/>
                    <IfContainer condition={albumRidottoIndex !== 0 && !this.$.albumRidottoInformazioni[0].albumRidottoStesseStampeDelGrandeFlag}>
                        {/*Visualizzato solo quando l'album renderizzato non è il primo e il primo album non ha le stesse stampe del grande*/}
                        <CheckLine
                            label={"Stessa configurazione del primo album ridotto"}
                            checkType={"switch"}
                            checked={albumRidotto.albumRidottoStesseStampeDelPrimoFlag}
                            onChange={v => {
                                const albumRidottoInformazioni = Array.from(this.$.albumRidottoInformazioni);
                                albumRidottoInformazioni[albumRidottoIndex].albumRidottoStesseStampeDelPrimoFlag = v;
                                if(v) albumRidottoInformazioni[albumRidottoIndex].albumRidottoStesseStampeDelGrandeFlag = !v;
                                this.$.albumRidottoInformazioni = albumRidottoInformazioni
                            }}/>
                    </IfContainer>
                    <IfContainer condition={
                        (!albumRidotto.albumRidottoStesseStampeDelGrandeFlag && !albumRidotto.albumRidottoStesseStampeDelPrimoFlag) ||
                        (!albumRidotto.albumRidottoStesseStampeDelGrandeFlag && albumRidottoIndex === 0)
                    }>
                        {/*Visualizziamo questo compoertamento quando il primo non ha le stesse stampe del grande o quando uno diverso dal primo non ha le stesse stampe del grande e non del primo*/}
                        <TextLine
                            label={"Numero di fogli"}
                            type={"number"}
                            showError={storage.GetStorageData(`AlbumRidotto${albumRidottoIndex}StampeFogli`).length !== albumRidotto.albumRidottoNumeroFogli}
                            errorMessage={SezioneAlbumPrincipale.ErrorMessages.NumeroFogliError}
                            min={0}
                            max={200}
                            value={albumRidotto.albumRidottoNumeroFogli.toString()}
                            onChange={v => {
                                const albumRidottoInformazioni = Array.from(this.$.albumRidottoInformazioni);
                                albumRidottoInformazioni[albumRidottoIndex].albumRidottoNumeroFogli = isNaN(parseInt(v)) ? 0 : parseInt(v);
                                this.$.albumRidottoInformazioni = albumRidottoInformazioni;
                            }}/>
                        <DragAndDropMultifunzionale
                            immagini={this.ImmaginiDaCategoria(`AlbumRidotto${albumRidottoIndex}StampeFogli`)}
                            onUpload={file => this.UplodaImmagine(`AlbumRidotto${albumRidottoIndex}StampeFogli`, file.name, file)}
                            onDelete={nomeImmagine => this.DeleteImmagine(`AlbumRidotto${albumRidottoIndex}StampeFogli`, nomeImmagine)}
                            indicatoreMassimoFogli={albumRidotto.albumRidottoNumeroFogli}
                            formatoAlbumPrincipale={albumRidotto.albumRidottoFormatoAlbum}/>
                    </IfContainer>
                </IfContainer>
            </CategorySectionLayout>
        );
    }

    public render() {
        return (
            <IfContainer
                condition={this.$.orientamentoAlbum.length !== 0 && this.$.formatoAlbum.length !== 0}
                elseComponent={
                    <CategorySectionLayout
                        categoryLabel={"Album ridotti"}
                        size={"largeRelative"}>
                        <LabelLoader
                            label={"Per visualizzare questa sezione è necessario aver inserito l'orientamento e il formato dell'album principale"}/>
                    </CategorySectionLayout>
                }>
                <CategorySectionLayout
                    categoryLabel={"Informazioni Album ridotti"}
                    size={"largeRelative"}>
                    {this._SezioneQuantitaAlbumRidotto()}
                </CategorySectionLayout>
                <IfContainer condition={this.$.albumRidottoQuantita > 0}>
                    {this._SezioneAlbumRidotti()}
                </IfContainer>
            </IfContainer>
        );
    }
}
