import React, {Fragment} from "react";
import {
    ConfigurazioneBlank,
    ConfigurazioneRenderizzatoreInterface,
    ConfigurazioneSezioneBlank,
    SezioneGenericaModel
} from "tici_commons";
import ContainerLayout from "../../../../Layout/ContainerLayout/ContainerLayout";
import SectionLayout from "../../../../Layout/SectionLayout/SectionLayout";
import {
    CheckLine,
    TextLine
} from "../../../../Widgets/Configuratore/CComponents";
import Renderizzatore from "../../../../Primary/Renderizzatore/Renderizzatore";
import IfContainer from "../../../../Layout/IfContainer/IfContainer";
import CategorySwitcher from "../../../../Primary/CategorySwitcher/CategorySwitcher/CategorySwitcher";
import CategorySwitcherElement
    from "../../../../Primary/CategorySwitcher/CategorySwirtcherElement/CategorySwitcherElement";
import copertinaIcon from "../../../../Media/Images/Icons/CategorySwitcher/copertinaIcon.png";
import coordinatiIcon from "../../../../Media/Images/Icons/CategorySwitcher/coordinatiIcon.png";
import riepilogoIcon from "../../../../Media/Images/Icons/CategorySwitcher/riepilogoIcon.png";
import VerticalSpace from "../../../../Layout/VerticalSpace/VerticalSpace";
import {ConfiguratoreLayout} from "../../../../PagesLayout/PagesLayout";
import AutoRefreshComponent from "../../../../Core/Arch/AutoRefreshComponent";
import ListaMaterialiConfiguratore from "../../../../Primary/ListaMaterialiConfiguratore/ListaMaterialiConfiguratore";
import StorageManager from "../../Configuratore/Storage/StorageManager";
import VistaConfigurazione from "./VisteConfiguratore/VistaConfigurazione";
import SwitchContainer from "../../../../Layout/SwitchContainer/SwitchContainer";
import {
    ConfigurazioneGestore3DBridgeContext, ConfigurazioneGestore3DBridgeState
} from "../Bridge/ConfigurazioneGestore3DBridge";
import VistaDownload from "./VisteConfiguratore/VistaDownload";
import VistaTest from "./VisteConfiguratore/VistaTest";

type MenuCategory = "configurazione" | "test" | "download";

export interface ConfiguratoreModelli3DState{
    configurazione: ConfigurazioneRenderizzatoreInterface;
    pathModello: string;
    nomeModello: string;

    currentCategory: MenuCategory
    mostraPreview: boolean,                                         //Flag di visualizzazione della preview
    sezioniData: [string, SezioneGenericaModel][],                  //Dati di configurazione del modello
    aspectRatio: number,                                            //Aspect ratio attuale del modello

    caricamentoDaBridge: boolean
}

export default class ConfiguratoreModelli3D extends AutoRefreshComponent<{}, ConfiguratoreModelli3DState>{
    constructor(props: Readonly<{}> | {}) {
        super(props);
        this.state = {
            configurazione: undefined,
            pathModello: undefined,
            nomeModello: undefined,

            currentCategory: "configurazione",
            mostraPreview: true,
            sezioniData: [],
            aspectRatio: 1,

            caricamentoDaBridge: false
        }
    }

    /**
     * Effettua il caricamento del modello dal bridge
     * @private
     */
    private _caricaModelloDaBridge(){
        const bridge = this.context as ConfigurazioneGestore3DBridgeState;

        if(bridge && bridge.hasData() && bridge.payload){
            const configurazione = ConfigurazioneBlank(bridge.payload.configurazione);
            this.setState({
                nomeModello: bridge.payload.nomeModello,
                pathModello: bridge.payload.pathModello,
                configurazione: configurazione,
                caricamentoDaBridge: true
            })
        }
    }

    public componentDidMount() {
        this._caricaModelloDaBridge();
    }

    /**
     * Carica un modello 3D
     * @param fileName Nome del file che deve essere caricato
     * @param fileType Tipologia del modello che deve essere caricato
     * @param base64Encoding Codifica base64 del modello da caricare
     * @private
     */
    private async _caricaModello(fileName: string, fileType: string, base64Encoding: string){
        if(fileType === "obj") {
            const configurazione = ConfigurazioneBlank();
            try{
                const partiModello = await Renderizzatore.GetPartiModello(base64Encoding);
                for(const parte of partiModello)
                    configurazione.configurazioniSezioni.push(ConfigurazioneSezioneBlank(parte));
                this.setState({
                    pathModello: base64Encoding,
                    nomeModello: fileName,
                    configurazione: configurazione
                })
            }catch(e){
                console.error("Errore nel recupero delle parti del modello", String(e));
            }
        }
    }

    public render() {
        return (
            <Fragment>
                <CategorySwitcher>
                    <CategorySwitcherElement
                        selected={this.state.currentCategory === "configurazione"}
                        elementIndex={0}
                        label={"Configurazione"}
                        icon={coordinatiIcon}
                        grow={true}
                        onClick={() => this.setState({currentCategory: "configurazione"})}/>
                    <IfContainer condition={this.state.configurazione !== undefined}>
                        <CategorySwitcherElement
                            selected={this.state.currentCategory === "test"}
                            elementIndex={1}
                            label={"Test"}
                            icon={copertinaIcon}
                            grow={true}
                            onClick={() => this.setState({currentCategory: "test"})}/>
                        <CategorySwitcherElement
                            selected={this.state.currentCategory === "download"}
                            elementIndex={1}
                            label={"Download"}
                            icon={riepilogoIcon}
                            grow={true}
                            onClick={() => this.setState({currentCategory: "download"})}/>
                    </IfContainer>
                </CategorySwitcher>
                <SectionLayout size={"largeRelative"}>
                    <SectionLayout size={"largeRelative"} center={true}>
                        <IfContainer condition={!this.state.caricamentoDaBridge}>
                            <TextLine
                                label={"Modello"}
                                type={"file"}
                                value={this.state.nomeModello?? ''}
                                onFileUpload={(fileName, fileType, file, base64Encoding) => this._caricaModello(fileName, fileType, base64Encoding)}/>
                        </IfContainer>
                        <IfContainer condition={this.state.currentCategory !== "test"}>
                            <CheckLine
                                label={"Mostra preview"}
                                checkType={"switch"}
                                checked={this.state.mostraPreview}
                                onChange={value => this.setState({mostraPreview: value})}/>
                        </IfContainer>
                    </SectionLayout>
                    <IfContainer condition={this.state.mostraPreview || this.state.currentCategory === 'test'} hiddenMode={true}>
                        <SectionLayout
                            size={"smallRelative"}
                            center={true}
                            showBorder={this.state.currentCategory !== 'test'}
                            addPadding={this.state.currentCategory !== 'test'}
                            lightBackground={this.state.currentCategory !== 'test'}>
                            <StorageManager>
                                <ListaMaterialiConfiguratore
                                    idManager={"configuratore"}
                                    nomeConfigurazioneModello={""}
                                    sezioniData={this.state.sezioniData}
                                    onSectionChanges={value => this.setState({sezioniData: value})}
                                    aspectRatioModello={this.state.aspectRatio}
                                    nascondiEditor={this.state.currentCategory !== 'test'}
                                    configurazioneModello={this.state.configurazione ? JSON.parse(JSON.stringify(this.state.configurazione)) : undefined}
                                    urlModello={this.state.pathModello}
                                    modalitaProgettazione={true}/>
                            </StorageManager>
                        </SectionLayout>
                    </IfContainer>
                    <ContainerLayout size={"largeRelative"} grow={true} scroll={this.state.currentCategory !== 'test'}>
                        <SectionLayout size={"largeRelative"}>
                            <SwitchContainer caseNumber={this.state.currentCategory === "configurazione" ? 0 : (this.state.currentCategory === "test" ? 1 : 2)}>
                                <VistaConfigurazione
                                    configurazione={this.state.configurazione}
                                    onChange={configurazione => this.setState({configurazione: configurazione})}/>
                                <VistaTest onChange={aspectRatio => this.setState({aspectRatio: aspectRatio})}/>
                                <VistaDownload configurazione={this.state.configurazione} vistaDaGestore={this.state.caricamentoDaBridge}/>
                            </SwitchContainer>
                        </SectionLayout>
                    </ContainerLayout>
                </SectionLayout>
            </Fragment>
        );
    }
}

ConfiguratoreModelli3D.contextType = ConfigurazioneGestore3DBridgeContext;
