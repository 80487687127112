import "./NotificaIcon.scss";
import bellIcon from "../../Media/Images/Icons/bellIcon.png";

import React from "react";
import IfContainer from "../../Layout/IfContainer/IfContainer";
import AutoRefreshComponent from "../../Core/Arch/AutoRefreshComponent";
import {Notifica, NotificaChatResponseModel} from "tici_commons";
import NotificheService from "../../Servicies/NotificheService";

export interface NotificaIconProps{
    children?: any,
}

export interface NotificaIconState{
    notifiche: Notifica[],
    notificheChat: NotificaChatResponseModel[],
}

export const NotificaContext = React.createContext<NotificaIconState>(undefined);

export default class NotificaProvider extends AutoRefreshComponent<NotificaIconProps, NotificaIconState>{
    constructor(props: Readonly<NotificaIconProps> | NotificaIconProps) {
        super(props);
        this.Delay = 30000;
        this.state = {
            notifiche: [],
            notificheChat: []
        };
    }

    public static NotificaIcon() {
        return (
            <NotificaContext.Consumer>{
                notifiche => (
                    <div className={"NotificaContainerIcon"}>
                        <IfContainer condition={notifiche.notifiche.length + notifiche.notificheChat.length > 0}>
                            <span className={"counter"}>{notifiche.notifiche.length + notifiche.notificheChat.length}</span>
                        </IfContainer>
                        <img alt={"NotificaIcon"} className={`NotificaIcon menuItemIconClass`} src={bellIcon}/>
                    </div>
                )
            }</NotificaContext.Consumer>
        )
    }

    public cycleNotifiche = async () => {
        const notificheChat = await NotificheService.NotificheChat();
        this.setState({
            notificheChat: [...notificheChat]
        })
    }

    public render() {
        return (
            <NotificaContext.Provider value={this.state}>
                {this.props.children}
            </NotificaContext.Provider>
        );
    }
}
