import React, {Fragment} from "react";
import "./ImagePreviewOverlay.scss";
import ReactDOM from "react-dom";
import ResponsiveLabel from "../../Core/ResponsiveLabel/ResponsiveLabel";
import xIcon from "../../Media/Images/Icons/xIcon.png";

export interface ImagePreviewOverlayProps{
    nomeImmagine?: string,
    sorgenteImmagine?: string
    onCloseClick?: () => void
}

export default class ImagePreviewOverlay extends React.Component<ImagePreviewOverlayProps, {}>{
    public render() {
        return (
            <Fragment>
                {ReactDOM.createPortal(
                    <div className={`ImagePreviewOverlay`}>
                        <div className={"imageContainer"}>
                            <span className={"xIconLine"}>
                                <ResponsiveLabel content={this.props.nomeImmagine?? ""} type={"medium"} alignment={"left"} labelType={"whiteLabel"}/>
                                <img className={"closeIcon"} alt={"xIcon"} src={xIcon} onClick={() => this.props.onCloseClick && this.props.onCloseClick()}/>
                            </span>
                            <img className={"fullImage"} alt={"fullImage"} src={this.props.sorgenteImmagine?? ""}/>
                        </div>
                    </div>,
                    document.body
                )}
            </Fragment>
        )
    }
}
