import React, {Fragment} from "react";
import {ConfiguratoreLayout} from "../../../PagesLayout/PagesLayout";
import CategorySwitcherElement from "../../../Primary/CategorySwitcher/CategorySwirtcherElement/CategorySwitcherElement";
import CategorySwitcher from "../../../Primary/CategorySwitcher/CategorySwitcher/CategorySwitcher";
import VerticalSpace from "../../../Layout/VerticalSpace/VerticalSpace";
import ContainerLayout from "../../../Layout/ContainerLayout/ContainerLayout";
import SectionLayout from "../../../Layout/SectionLayout/SectionLayout";
import ResponsiveLabel from "../../../Core/ResponsiveLabel/ResponsiveLabel";
import IfContainer from "../../../Layout/IfContainer/IfContainer";
import {ConfigurazioneService} from "../../../Servicies/ConfigurazioneService";
import {
    ConfigurazioneFotografoRouteResponseModel,
    TipoConfigurazioneEnum
} from "tici_commons";
import ConfigurazioneLine from "../../../Primary/ConfigurazioneLine/ConfigurazioneLine";
import LocalStorageService from "../../../Servicies/LocalStorageService";
import TopLevelComponentStorage from "../../../Core/Arch/TopLevelComponentStorage";
import RedirectService from "../../../Servicies/RedirectService";
import UserService from "../../../Servicies/UserService";
import ImageService from "../../../Servicies/ImageService";
import LabelLoader from "../../../Primary/LabelLoader/LabelLoader";

export interface ConfigurazioniSalvateState {
    isLoading: boolean,
    currentSection: number,
    configurazioniSalvate: ConfigurazioneFotografoRouteResponseModel[],
    isSuperUser: boolean,
}

export default class ConfigurazioniFotografo extends React.Component<{}, ConfigurazioniSalvateState>{
    constructor(props: Readonly<{}> | {}) {
        super(props);
        this.state = {
            isLoading: false,
            currentSection: 0,
            configurazioniSalvate: [],
            isSuperUser: false,
        }
    }

    public componentDidMount() {
        this._caricaConfigurazioniSalvate();
    }

    /**
     * Carica le configurazioni salvate sul database
     * @private
     */
    private async _caricaConfigurazioniSalvate() {
        this.setState({isLoading: true});
        const configurazioni = [];
        configurazioni.push(...await ConfigurazioneService.GetConfigurazioni(TipoConfigurazioneEnum.Utente));
        configurazioni.push(...await ConfigurazioneService.GetConfigurazioni(TipoConfigurazioneEnum.Cliente));

        let isSuperUser = false;
        if(await UserService.IsSuperUser()){
            isSuperUser = true;
            configurazioni.push(...await ConfigurazioneService.GetConfigurazioni(TipoConfigurazioneEnum.Wedding));
            configurazioni.push(...await ConfigurazioneService.GetConfigurazioni(TipoConfigurazioneEnum.Art));
            configurazioni.push(...await ConfigurazioneService.GetConfigurazioni(TipoConfigurazioneEnum.Junior));
        }
        this.setState({configurazioniSalvate: configurazioni, isLoading: false, isSuperUser});
    }

    /**
     * Cancella una configurazione salvata
     * @param idConfigurazione Identificativo della configurazione
     * @private
     */
    private _deleteConfigurazione(idConfigurazione: number){
        TopLevelComponentStorage.GetTopLevel('confirmWindow').showConfirmWindow(
            "Cancellazione configurazione",
            "Sicuro di voler eliminare la configurazione? Questa configurazione non sarà più recuperabile",
            'DoubleButton',
            async () => {
                TopLevelComponentStorage.GetTopLevel('confirmWindow').hideConfirmWindow()
                TopLevelComponentStorage.GetTopLevel('loadingWindow').showLoadingWindow("Cancellazione", "Stiamo cancellando la configurazione");
                const esito = await ConfigurazioneService.DeleteConfigurazione(idConfigurazione);
                TopLevelComponentStorage.GetTopLevel('loadingWindow').hideLoadingWindow();
                TopLevelComponentStorage.GetTopLevel('confirmWindow').showConfirmWindow(
                    esito ? "Cancellazione avvenuta" : "Cancellazione non avvenuta",
                    esito ? "La configurazione è stata eliminata correttamente" : "Errore nell'eliminazione",
                    "SingleButton",
                    async () => {
                        await this._caricaConfigurazioniSalvate();
                        TopLevelComponentStorage.GetTopLevel('confirmWindow').hideConfirmWindow();
                    }
                )
            },
            () => TopLevelComponentStorage.GetTopLevel('confirmWindow').hideConfirmWindow()
        )
    }

    /**
     * Restituisce la lista ordini filtrata per categoria
     * @private
     */
    private _ordiniFiltratiPerCategoria(): ConfigurazioneFotografoRouteResponseModel[]{
        return this.state.configurazioniSalvate
            .filter(configurazione => {
                let esito: boolean;
                if(this.state.currentSection === 0)
                    esito = configurazione.tipoConfigurazione === TipoConfigurazioneEnum.Utente;
                else if(this.state.currentSection === 1)
                    esito = configurazione.tipoConfigurazione === TipoConfigurazioneEnum.Cliente;
                else
                    esito = ![TipoConfigurazioneEnum.Cliente, TipoConfigurazioneEnum.Utente].includes(configurazione.tipoConfigurazione);

                return !!esito;
            });
    }

    private _MenuConfigurazioni(){
        return (
            <CategorySwitcher>
                <CategorySwitcherElement
                    elementIndex={0}
                    icon={ImageService.getImage("listIcon")}
                    grow={true}
                    label={"Configurazioni personali"}
                    selected={this.state.currentSection === 0}
                    onClick={() => {
                        this.setState({currentSection: 0})
                    }}/>
                <CategorySwitcherElement
                    elementIndex={1}
                    icon={ImageService.getImage("listIcon")}
                    grow={true}
                    label={"Configurazioni clienti"}
                    selected={this.state.currentSection === 1}
                    onClick={() => {
                        this.setState({currentSection: 1})
                    }}/>
                <IfContainer condition={this.state.isSuperUser}>
                    <CategorySwitcherElement
                        elementIndex={2}
                        icon={ImageService.getImage("listIcon")}
                        grow={true}
                        label={"Configurazioni progetti"}
                        selected={this.state.currentSection === 2}
                        onClick={() => {
                            this.setState({currentSection: 2})
                        }}/>
                </IfContainer>
            </CategorySwitcher>
        )
    }

    public render() {
        return (
            <Fragment>
                {this._MenuConfigurazioni()}
                <IfContainer
                    condition={!this.state.isLoading}
                    elseComponent={<LabelLoader label={"Caricamento delle configurazioni"}/>}>
                    <SectionLayout size={"largeRelative"}>
                        <IfContainer
                            condition={this._ordiniFiltratiPerCategoria().length > 0}
                            elseComponent={
                                <ResponsiveLabel content={"Nessuna configurazione salvata"} type={"large"} alignment={"center"}/>
                            }>{
                            this._ordiniFiltratiPerCategoria().map((configurazione, index) => (
                                <ConfigurazioneLine
                                    key={`Configurazione-${index}`}
                                    configurazione={configurazione}
                                    onEdit={() => {
                                        LocalStorageService.ConfigurazioneTiciAttuale = configurazione.idConfigurazione;
                                        RedirectService.GoToConfiguratore();
                                    }}
                                    onDelete={() => {
                                        this._deleteConfigurazione(configurazione.idConfigurazione);
                                    }}/>
                            ))
                        }</IfContainer>
                    </SectionLayout>
                </IfContainer>
            </Fragment>
        )
    }
}
