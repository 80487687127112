import React, {Fragment} from "react";
import ReactDOM from "react-dom";
import ChatOverlay from "../ChatOverlay/ChatOverlay";
import Config from "../../../Static/Config";
import SectionLayout from "../../../Layout/SectionLayout/SectionLayout";
import VerticalSpace from "../../../Layout/VerticalSpace/VerticalSpace";

export interface ChatContainerState {
    currentWindowWidth: number,
    currentChatMap: [number, string][]
}

export interface ChatContextState {
    openChat: (ordineId: number, title: string) => void
}

export const ChatContext = React.createContext<ChatContextState>(undefined);

export default class ChatContainer extends React.Component<{children: any}, ChatContainerState>{
    constructor(props: Readonly<{ children: any }> | { children: any }) {
        super(props);
        this.state = {
            currentWindowWidth: document.body.getBoundingClientRect().width,
            currentChatMap: []
        }
    }

    componentDidMount() {
        window.addEventListener("resize", () => {
            this.setState({currentWindowWidth: document.body.getBoundingClientRect().width});
        })
    }

    /**
     * Apre una nuova chat
     * @param ordineId Id dell'ordine
     * @param title Titolo della chat
     * @private
     */
    private _openChat(ordineId: number, title: string){
        const chatMap = new Map(this.state.currentChatMap);
        if(!chatMap.has(ordineId))
            chatMap.set(ordineId, title);
        this.setState({currentChatMap: Array.from(chatMap)});
    }

    /**
     * Chiude una chat aperta
     * @param ordineId Id dell'ordine associato alla chat
     * @private
     */
    private _closeChat(ordineId: number){
        const chatMap = new Map(this.state.currentChatMap);
        if(chatMap.has(ordineId))
            chatMap.delete(ordineId)
        this.setState({currentChatMap: Array.from(chatMap)});
    }

    private _chatList() {
        return (
            this.state.currentChatMap.map(chat => (
                <ChatOverlay key={`${chat[0]}-${chat[1]}`} onClose={() => this._closeChat(chat[0])} ordineId={chat[0]} title={chat[1]}/>
            ))
        )
    }

    public render() {
        return (
            <Fragment>
                {
                    this.state.currentWindowWidth > Config.TabletBkp &&
                    ReactDOM.createPortal(
                        <Fragment>{
                            this._chatList()
                        }</Fragment>,
                        window.document.body
                    )
                }
                <ChatContext.Provider value={{
                    openChat: this._openChat.bind(this)
                }}>
                    {
                        this.state.currentWindowWidth <= Config.TabletBkp &&
                        <Fragment>
                            <SectionLayout size={"largeRelative"}>{
                                this._chatList()
                            }</SectionLayout>
                            <VerticalSpace gapMobile={5} gapTablet={10} gapDesktop={15}/>
                        </Fragment>
                    }
                    {this.props.children}
                </ChatContext.Provider>
            </Fragment>

        );
    }
}
