import React, {ReactNode} from "react";
import "./CategorySwitcher.scss";
import {SubmenuContext} from "../../Submenu/Submenu";
import {createPortal} from "react-dom";

export interface CategorySwitcherProps{
    children?: any
}

export default class CategorySwitcher extends React.Component<CategorySwitcherProps, {}>{
    public render(): ReactNode {
        return (
            <SubmenuContext.Consumer>{
                value => (
                    Object.keys(value).length > 0 ?
                    createPortal(this.props.children, document.getElementById('SubmenuItemsContainer')) :
                    <div className={"CategorySwitcherContainer"}>
                        <div className={`CategorySwitcher ${this.props.children.length < 3 && 'fr2Mobile'}`}>
                            {this.props.children}
                        </div>
                    </div>
                )
            }</SubmenuContext.Consumer>

        );
    }
}

CategorySwitcher.contextType = SubmenuContext;
