import React from "react";
import './SwitchContainer.scss'

export interface SwitchContainerProps{
    children: any[],
    caseNumber: number
}

export default class SwitchContainer extends React.Component<SwitchContainerProps, {}>{
    public render() {
        return (
            this.props.children.filter((child, index) => index === this.props.caseNumber)
        );
    }
}
