import React from "react";
import CategorySwitcher from "../../../Primary/CategorySwitcher/CategorySwitcher/CategorySwitcher";
import CategorySwitcherElement from "../../../Primary/CategorySwitcher/CategorySwirtcherElement/CategorySwitcherElement";
import {TextLine} from "../../../Widgets/Configuratore/CComponents";
import AutoRefreshComponent from "../../../Core/Arch/AutoRefreshComponent";
import FotografoService from "../../../Servicies/FotografoService";
import CardFotografo from "../../../Primary/CardFotografo/CardFotografo";
import IfContainer from "../../../Layout/IfContainer/IfContainer";
import {FotografoContextResponseModel} from "tici_commons";
import UserService from "../../../Servicies/UserService";
import ImageService from "../../../Servicies/ImageService";
import LabelLoader from "../../../Primary/LabelLoader/LabelLoader";
import TopLevelComponentStorage from "../../../Core/Arch/TopLevelComponentStorage";
import SectionLayout from "../../../Layout/SectionLayout/SectionLayout";
import OverflowTag from "../../../Layout/OverflowTag/OverflowTag";

export interface AccettazioneFotografiState{
    loading: boolean,
    fotografi: FotografoContextResponseModel[],
    sezioneCorrente: "fotografi" | "fotografiRidotti" | "listaConferme",
    filtroFotografi: string
}

export default class AccettazioneFotografi extends AutoRefreshComponent<{}, AccettazioneFotografiState>{
    constructor(props: Readonly<{}> | {}) {
        super(props);
        this.state = {
            loading: true,
            fotografi: [],
            sezioneCorrente: "fotografi",
            filtroFotografi: ""
        };

        this.Delay = 5 * 60 * 1000;
    }

    /**
     * Si occupa di aggiornare la lista degli studi fotografici
     * @private
     */
    public cycleFotografi = async () => {
        this._updateFotografi(false);
    }

    /**
     * Aggiorna la lista dei fotografi
     * @param showLoading Flag di visibilità del loader
     * @private
     */
    private async _updateFotografi(showLoading: boolean){
        if(showLoading)
            this.setState({loading: true});
        const response = await FotografoService.GetFotografoContext();
        if(response) {
            this.setState({
                fotografi: response as FotografoContextResponseModel[],
                loading: false
            });
        }
    }

    /**
     * Genera la stringa di filtro
     * @param fotografo Fotografo da cui generare la stringa
     * @private
     */
    private _prepareFilterString(fotografo: FotografoContextResponseModel): string{
        return `${Object.values(fotografo).join()} ${Object.values(fotografo).join(' ')} ${fotografo.nuovoCliente ? 'Nuovo cliente' : "Vecchio cliente"}`.toLowerCase();
    }

    public render() {
        return (
            <SectionLayout size={"largeRelative"}>
                <CategorySwitcher>
                    <CategorySwitcherElement
                        elementIndex={0}
                        icon={ImageService.getImage('userIcon')}
                        grow={true}
                        label={"Fotografi"}
                        selected={this.state.sezioneCorrente === 'fotografi'}
                        onClick={() => {this.setState({sezioneCorrente: "fotografi"})}}/>
                    <CategorySwitcherElement
                        elementIndex={1}
                        icon={ImageService.getImage('conferenceIcon')}
                        grow={true}
                        label={"Lista fotografi"}
                        selected={this.state.sezioneCorrente === 'fotografiRidotti'}
                        onClick={() => {this.setState({sezioneCorrente: "fotografiRidotti"})}}/>
                    <CategorySwitcherElement
                        elementIndex={2}
                        icon={ImageService.getImage('listIcon')}
                        grow={true}
                        label={"Lista conferme"}
                        selected={this.state.sezioneCorrente === 'listaConferme'}
                        onClick={() => {this.setState({sezioneCorrente: "listaConferme"})}}/>
                </CategorySwitcher>
                <TextLine
                    label={"Filtro ricerca"}
                    value={this.state.filtroFotografi}
                    onChange={v => this.setState({filtroFotografi: v})}/>
                <SectionLayout size={"largeRelative"} scroll={true}>
                    <OverflowTag/>
                    <IfContainer
                        condition={!this.state.loading}
                        elseComponent={<LabelLoader label={"Caricamento dei fotografi"}/>}>{
                        this.state.fotografi
                            .filter(fotografo => ['fotografi', 'fotografiRidotti'].includes(this.state.sezioneCorrente) ? fotografo.statoAttivazione === 1 : fotografo.statoAttivazione === 0)
                            .filter(fotografo => this.state.filtroFotografi ? this._prepareFilterString(fotografo).includes(this.state.filtroFotografi.toLowerCase()) : true)
                            .sort((a, b) => a.regioneAppartenenza ? a.regioneAppartenenza.localeCompare(b.regioneAppartenenza) : 0)
                            .map(fotografo => (
                                <CardFotografo
                                    key={`Fotografo-${fotografo.identificativo}`}
                                    fotografo={fotografo}
                                    modalitaRidotta={this.state.sezioneCorrente === 'fotografiRidotti'}
                                    updateFotografi={() => this._updateFotografi(true)}
                                    onDelete={() => this._deleteFotografo(fotografo.email)}
                                    onConfirm={() => this._changeFotografoStatus(fotografo.email, fotografo.statoAttivazione)}
                                    onReject={() => this._changeFotografoStatus(fotografo.email, fotografo.statoAttivazione)}/>
                            ))
                    }</IfContainer>
                </SectionLayout>
            </SectionLayout>
        );
    }

    private _deleteFotografo(emailFotografo: string){
        TopLevelComponentStorage.GetTopLevel('confirmWindow').showConfirmWindow(
            "Eliminazione account",
            "Eliminando l'account andranno persi anche tutti gli ordini da esso inviati, procedere?",
            "DoubleButton",
            async () => {
                TopLevelComponentStorage.GetTopLevel('confirmWindow').hideConfirmWindow();
                TopLevelComponentStorage.GetTopLevel('loadingWindow').showLoadingWindow("Eliminazione account", "Stiamo cancellando l'account");
                let esito = await UserService.EliminazioneAccount(emailFotografo);
                TopLevelComponentStorage.GetTopLevel('loadingWindow').hideLoadingWindow();
                TopLevelComponentStorage.GetTopLevel('confirmWindow').showConfirmWindow(
                    esito ? "Eliminazione riuscita" : "Eliminazione non riuscita",
                    esito ? "Account eliminato con successo" : "Non è stato possibile eliminare l'account",
                )
                await this._updateFotografi(true);
            },
            () => TopLevelComponentStorage.GetTopLevel('confirmWindow').hideConfirmWindow()
        );
    }

    private async _changeFotografoStatus(emailFotografo: string, statoFotografo: number){
        TopLevelComponentStorage.GetTopLevel('loadingWindow').showLoadingWindow(
            statoFotografo === 0 ? "Stiamo attivando l'account" : "Stiamo disattivando l'account",
            `Stiamo eseguendo la procedura di ${statoFotografo === 1 ? "attivazione" : "disattivazione"} dell'account`);
        const esito = await UserService.AttivazioneAccount(emailFotografo, statoFotografo === 0);
        TopLevelComponentStorage.GetTopLevel('loadingWindow').hideLoadingWindow();
        TopLevelComponentStorage.GetTopLevel('confirmWindow').showConfirmWindow(
            esito? `${statoFotografo === 0 ? "Account attivato" : "Account disattivato"}` : "Errore",
            esito ?
                `Lo studio fotografico è stato ${statoFotografo === 0 ? "attivato" : "disattivato"} correttamente` :
                "Non è stato possibile cambiare lo stato dello studio fotografico"
        );
        await this._updateFotografi(true);
    }
}
