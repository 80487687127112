import React, {Fragment} from "react";
import {FotolibroContextInterface} from "../../Fotolibro/Fotolibro";
import SezioneRequiredProps from "../../Commons/SezioneRiquiredProps";
import {ConfigurazioneFotografoModel, FotolibroModel, isNormalView} from "tici_commons";
import SezioneBase from "./InformazioniBaseSezioni/SezioneBase";
import SezioneAlbumPrincipale from "./InformazioniBaseSezioni/SezioneAlbumPrincipale";
import SezioneAlbumRidotto from "./InformazioniBaseSezioni/SezioneAlbumRidotto";
import SezioneAlbumPocket from "./InformazioniBaseSezioni/SezioneAlbumPocket";
import IfContainer from "../../../../../Layout/IfContainer/IfContainer";
import {
    GenerateGroup,
    GenerateRiepilogoBox,
    GenerateRiepilogoData, GenerateRiepilogoInternalBox,
    RiepilogoData, RiepilogoInternalBox
} from "../../Commons/RiepilogoDatoModel";
import SezioneConfigurazioni from "./InformazioniBaseSezioni/SezioneConfigurazioni";
import { StorageManagerStatus } from "../../Storage/StorageManager";
import SezioneUploaderMultifunzionale from "./InformazioniBaseSezioni/SezioneUploaderMultifunzionale";
import SezioneGenerica from "../SezioneGenerica";
import SezioneLavorazioni from "./InformazioniBaseSezioni/SezioneLavorazioni";

interface InformazioniBaseProps{
    loadConfigurazione: (configurazione: ConfigurazioneFotografoModel) => void,
    resetInformazioniCopertinaCustodia: () => void,
    resetInformazioniDorsoCopertina: () => void,
    modalitaConfiguratore?: boolean
    fileDatCaricato?: boolean,
    caricaFileDat?: (datBase64: string) => void
}

export interface InformazioniBaseState {
    isLoading: boolean
    configurazioni: ConfigurazioneFotografoModel[]
    configurazioneSalvata: string
}

export default class InformazioniBase extends SezioneGenerica<"informazioniBase", InformazioniBaseProps, InformazioniBaseState>{
    constructor(props: InformazioniBaseProps & SezioneRequiredProps<"informazioniBase"> & { data: FotolibroContextInterface["informazioniBase"]; storage: StorageManagerStatus }) {
        super(props);
        this.state = {
            isLoading: false,
            configurazioni: [],
            configurazioneSalvata: ""
        }
    }

    public generaRiepilogo(fotolibro: FotolibroModel, storage: StorageManagerStatus, modalitaConfiguratore: boolean): RiepilogoInternalBox | RiepilogoData[] {
        return GenerateRiepilogoBox(
            "InformazioniBase",
            new SezioneBase(undefined).generaRiepilogo(fotolibro, storage, modalitaConfiguratore) as RiepilogoInternalBox,
            ...GenerateGroup(
                fotolibro.informazioniBase.tipoFotolibro !== "accessori",
                new SezioneAlbumPrincipale(undefined).generaRiepilogo(fotolibro, storage, modalitaConfiguratore) as RiepilogoInternalBox,
                new SezioneLavorazioni(undefined).generaRiepilogo(fotolibro, storage, modalitaConfiguratore) as RiepilogoInternalBox,
                new SezioneUploaderMultifunzionale(undefined).generaRiepilogo(fotolibro, storage, modalitaConfiguratore) as RiepilogoInternalBox,
                ...(new SezioneAlbumRidotto(undefined).generaRiepilogo(fotolibro, storage, modalitaConfiguratore) as RiepilogoInternalBox[]),
                new SezioneAlbumPocket(undefined).generaRiepilogo(fotolibro, storage, modalitaConfiguratore) as RiepilogoInternalBox,
                ...GenerateGroup(
                    fotolibro.informazioniBase.note.trim().length !== 0,
                    GenerateRiepilogoInternalBox("Note", GenerateRiepilogoData(fotolibro.informazioniBase.note))
                )
            )
        );
    }

    render() {
        return (
            <Fragment>
                <SezioneConfigurazioni
                    SetParentState={this.props.SetParentState}
                    data={this.props.data}
                    storage={this.props.storage}
                    modalitaConfiguratore={this.props.modalitaConfiguratore}
                    loadConfigurazione={this.props.loadConfigurazione}/>
                <SezioneBase
                    SetParentState={this.props.SetParentState}
                    data={this.props.data}
                    storage={this.props.storage}
                    modalitaConfiguratore={this.props.modalitaConfiguratore}
                    fileDatCaricato={this.props.fileDatCaricato}
                    caricaFileDat={this.props.caricaFileDat}/>
                <IfContainer
                    condition={this.$.tipoFotolibro !== "accessori"}>
                    <SezioneAlbumPrincipale
                        SetParentState={this.props.SetParentState}
                        storage={this.props.storage}
                        data={this.props.data}
                        modalitaConfiguratore={this.props.modalitaConfiguratore}
                        resetInformazioniCopertinaCustodia={this.props.resetInformazioniCopertinaCustodia}
                        resetInformazioniDorsoCopertina={this.props.resetInformazioniDorsoCopertina}/>
                    <IfContainer condition={this.$.stampaFlag && this.$.numeroFogli > 0}>
                        <SezioneLavorazioni
                            storage={this.props.storage}
                            data={this.props.data}
                            SetParentState={this.props.SetParentState}/>
                    </IfContainer>
                    <IfContainer condition={this.$.albumRidottoFlag}>
                        <SezioneAlbumRidotto
                            SetParentState={this.props.SetParentState}
                            storage={this.props.storage}
                            data={this.props.data}/>
                    </IfContainer>
                    <IfContainer condition={isNormalView(this.$.tipoFotolibro) && this.$.albumPocketFlag}>
                        <SezioneAlbumPocket
                            SetParentState={this.props.SetParentState}
                            storage={this.props.storage}
                            data={this.props.data}/>
                    </IfContainer>
                </IfContainer>
            </Fragment>
        );
    }
}
