import PaddingLayout from "../Layout/PaddingLayout/PaddingLayout";
import ContainerLayout from "../Layout/ContainerLayout/ContainerLayout";
import VerticalSpace from "../Layout/VerticalSpace/VerticalSpace";
import React from "react";
import Logo, {LogoTotem} from "../Widgets/Logo/Logo";
import FormLayout from "../Layout/FormLayout/FormLayout";
import DesktopModeConfiguratoreLayout from "../Layout/DesktopModeConfiguratoreLayout/DesktopModeConfiguratoreLayout";
import loginBackground from "../Media/Images/Backgrounds/homepage.jpg";
import ResponsiveLabel from "../Core/ResponsiveLabel/ResponsiveLabel";
import IfContainer from "../Layout/IfContainer/IfContainer";
import SectionLayout from "../Layout/SectionLayout/SectionLayout";

export interface ConfiguratoreLayoutProps{
    children: any,
    contenutoDiContesto?: boolean,
    labelDiContesto?: string
}
export function ConfiguratoreLayout(props: ConfiguratoreLayoutProps){
    return(
        <DesktopModeConfiguratoreLayout abilitato={props.contenutoDiContesto} leftLabelText={props.labelDiContesto}>
            <ContainerLayout size={"largeRelative"} scroll={props.contenutoDiContesto} fullHeight={true} gap={false} backgroundBlur={!props.contenutoDiContesto}>
                <PaddingLayout paddingMobile={"0 40px"} paddingTablet={"0"} paddingDesktop={props.contenutoDiContesto ? "0 10px" : "0"}>
                    <ContainerLayout size={props.contenutoDiContesto ? "largeRelative" : "mediumRelative"} fullHeight={true} center={true} gap={false}>
                        <VerticalSpace gapMobile={5} gapTablet={10} gapDesktop={15}/>
                        {props.children}
                        <VerticalSpace gapMobile={5} gapTablet={10} gapDesktop={15}/>
                    </ContainerLayout>
                </PaddingLayout>
            </ContainerLayout>
        </DesktopModeConfiguratoreLayout>
    )
}

export interface LRRLayoutProps{
    children: any,
    versioneEstesa?: boolean
}
export function LRRLayout(props: LRRLayoutProps){
    return(
        <div
            style={{
                width: "100%",
                height: "100%",
                backgroundSize: "cover",
                backgroundImage: `url(${loginBackground})`
            }}>
            <ContainerLayout size={"largeRelative"} fullHeight={true}>
                <VerticalSpace gapMobile={10} gapTablet={20} gapDesktop={20}/>
                <SectionLayout size={"largeRelative"}>
                    <LogoTotem/>
                    <IfContainer condition={props.versioneEstesa}>
                        <ResponsiveLabel content={"Libera la tua creatività"} italic={true} type={"xxlarge"} alignment={"center"} labelType={"whiteLabel"}/>
                    </IfContainer>
                </SectionLayout>
                <ContainerLayout size={"smallRelative"} scroll={true} center={true} grow={true}>
                    <FormLayout versione={1}>
                        {props.children}
                    </FormLayout>
                </ContainerLayout>
                <IfContainer condition={props.versioneEstesa}>
                    <SectionLayout size={"largeRelative"}>
                        <ResponsiveLabel content={"By"} type={"medium"} alignment={"center"} labelType={"whiteLabel"} italic={true}/>
                        <Logo />
                        <VerticalSpace gapMobile={10} gapTablet={20} gapDesktop={20}/>
                    </SectionLayout>
                </IfContainer>
                <ResponsiveLabel
                    content={"Servizio offerto da Cartotecnica tici s.r.l - tutti i diritti sono riservati - nessuno userà i tuoi dati senza permesso"}
                    type={"medium"}
                    alignment={"center"}
                    italic={true}
                    labelType={"footer"}/>
            </ContainerLayout>
        </div>
    )
}
