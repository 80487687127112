import React from "react";
import {ChatPersonaleResponseModel, NotificaChatResponseModel} from "tici_commons";
import AutoRefreshComponent from "../../../Core/Arch/AutoRefreshComponent";
import ChatService from "../../../Servicies/ChatService";
import SectionLayout from "../../../Layout/SectionLayout/SectionLayout";
import ResponsiveLabel from "../../../Core/ResponsiveLabel/ResponsiveLabel";
import IfContainer from "../../../Layout/IfContainer/IfContainer";
import LabelLoader from "../../../Primary/LabelLoader/LabelLoader";
import ChatLine from "../../../Primary/ChatLine/ChatLine";
import OverflowTag from "../../../Layout/OverflowTag/OverflowTag";
import ChatContainer from "../../../Primary/ChatOverlay/ChatContainer/ChatContainer";
import LocalStorageService from "../../../Servicies/LocalStorageService";
import RedirectService from "../../../Servicies/RedirectService";

export interface ChatState{
    isLoading: boolean,
    chatPesonali: ChatPersonaleResponseModel[]
}

export default class Chat extends AutoRefreshComponent<{}, ChatState>{
    constructor(props: Readonly<{}> | {}) {
        super(props);
        this.Delay = 60000;
        this.state = {
            isLoading: true,
            chatPesonali: []
        }
    }

    public cycleChat = async () => {
        const chatPersonali = await ChatService.MessaggiChatPersonale();
        this.setState({
            chatPesonali: [...chatPersonali],
            isLoading: false
        })
    }

    public render() {
        return (
            <SectionLayout size={"largeRelative"}>
                <ResponsiveLabel content={"Le tue chat"} type={"large"} alignment={"center"} uppercase={true} bold={true}/>
                <SectionLayout size={"mediumRelative"}>
                    <OverflowTag/>
                    <ChatContainer>
                        <IfContainer
                            condition={!this.state.isLoading}
                            elseComponent={<LabelLoader label={"Caricamento delle chat"}/>}>
                            <IfContainer
                                condition={this.state.chatPesonali.length > 0}
                                elseComponent={
                                    <SectionLayout size={"largeRelative"} addPadding={true} showBorder={true} lightBackground={true}>
                                        <ResponsiveLabel content={"Nessuna chat presente"} type={"medium"} alignment={"center"}/>
                                    </SectionLayout>
                                }>
                                {
                                    this.state.chatPesonali.map( (chat, index) => (
                                        <ChatLine key={`Chat-${index}`} chat={chat}/>
                                    ))
                                }
                            </IfContainer>
                        </IfContainer>
                    </ChatContainer>
                </SectionLayout>
            </SectionLayout>
        );
    }
}
