import React, {RefObject} from "react";
import "./SectionLayout.scss";

export interface SectionLayoutInterface{
    children?: any,
    size: "largeRelative" | "mediumRelative" | "smallRelative" | "large" | "medium" | "small"
    center?: boolean
    showBorder?: boolean
    addPadding?: boolean
    scroll?: boolean
    lightBackground?: boolean
    order?: number
}
export default class SectionLayout extends React.Component<SectionLayoutInterface, {}>{
    private _containerRef: RefObject<HTMLDivElement> = React.createRef();

    /**
     * Aggiorna l'ordinamento della sezione
     * @private
     */
    private _aggiornaOrdinamento(){
        if(this._containerRef.current && this.props.order)
            this._containerRef.current.style.order = this.props.order.toString();
    }

    public componentDidMount() {
        this._aggiornaOrdinamento();
    }

    public componentDidUpdate(prevProps: Readonly<SectionLayoutInterface>, prevState: Readonly<{}>, snapshot?: any) {
        this._aggiornaOrdinamento();
    }

    public get containerHeight(): number{
        return this._containerRef.current.getBoundingClientRect().height;
    }

    public render() {
        return (
            <div
                ref={this._containerRef}
                className={`Section ${this.props.size} ${this.props.lightBackground && "lightBackground"} ${this.props.scroll && "scroll"} ${this.props.showBorder && "showBorder"} ${this.props.addPadding && "addPadding"} ${this.props.center && "center"}`}>
                {this.props.children}
            </div>
        );
    }
}
