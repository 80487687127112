import React from "react";
import Logo from "../Widgets/Logo/Logo";
export default class Default extends React.Component<{}, {}>{
    render() {
        return (
            <div style={{flexGrow: 1, margin: '100px auto'}}>
                <Logo/>
            </div>
        )
    }
}
