import React from "react";
import "./Switch.scss";
import CheckboxProps from "../CheckboxProps";

export default class Switch extends React.Component<CheckboxProps, {}>{
    render() {
        return (
            <div className={`Switch ${this.props.checked && "on"} ${this.props.disabled && "disabled"}`}
                 onClick={() => {
                    if(!this.props.disabled && this.props.onChange)
                        this.props.onChange(!this.props.checked)
                 }
            }/>
        );
    }
}
