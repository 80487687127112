import "./Ordine.scss";
import React, {Fragment} from "react";
import Config from "../../Static/Config";
import {
    DettaglioOrdineModel,
    InformazioniBaseModel,
    RilegaturaModel,
    CopertinaModel,
    CustodiaModel,
    AccessoriModel,
    FotolibroModel,
    InformazioniBaseInitializer,
    RilegaturaInitializer,
    CopertinaInitializer,
    AccessoriInitializer,
    CustodiaInitializer, UserContextResponseModel
} from "tici_commons";
import SectionLayout from "../../Layout/SectionLayout/SectionLayout";
import Button from "../../Core/Buttons/Button";
import Responsive2Col from "../../Layout/Responsive2Col/Responsive2Col";
import {ChatContext} from "../ChatOverlay/ChatContainer/ChatContainer";
import {Miniatura, OrdineLineRecap, OrdineLineTopSeparator} from "../../Widgets/Ordine/OrdineLineComponents";
import RendererData from "./RendererData";
import InformazioniBase from "../../Pages/SitoInterno/Configuratore/Sezioni/InformazioniBase/InformazioniBase";
import Rilegatura from "../../Pages/SitoInterno/Configuratore/Sezioni/Rilegatura/Rilegatura";
import Copertina from "../../Pages/SitoInterno/Configuratore/Sezioni/Copertina/Copertina";
import Custodia from "../../Pages/SitoInterno/Configuratore/Sezioni/Custodia/Custodia";
import Accessori from "../../Pages/SitoInterno/Configuratore/Sezioni/Accessori/Accessori";
import IfContainer from "../../Layout/IfContainer/IfContainer";
import TopLevelComponentStorage from "../../Core/Arch/TopLevelComponentStorage";
import TicketService from "../../Servicies/TicketService";
import OrdineService from "../../Servicies/OrdineService";
import {RiepilogoBox} from "../../Pages/SitoInterno/Configuratore/Commons/RiepilogoDatoModel";
import SentenceLayout from "../../Layout/SentenceLayout/SentenceLayout";
import CheckboxButton from "../../Core/Checkbox/CheckboxButton/CheckboxButton";
import PreviewStampaOrdine from "../PreviewStampaOrdine/PreviewStampaOrdine";
import LabelLoader from "../LabelLoader/LabelLoader";
import UserService from "../../Servicies/UserService";

export interface OrdineProps{
    ordine: DettaglioOrdineModel,
    vistaAmministrazione?: boolean,
    updateOrdini?: () => void;
}

export interface OrdineState extends FotolibroModel{
    contestoUtente: UserContextResponseModel,
    stampeDisponibili: boolean,
    miniaturaCopertina: boolean,
    miniaturaCustodia: boolean,
    miniaturaIndex: boolean,
    modalitaPreviewStampa: boolean,
    isLoading: boolean
}

export default class Ordine extends React.Component<OrdineProps, OrdineState>{
     constructor(props: Readonly<OrdineProps> | OrdineProps) {
        super(props);
        this.state = {
            contestoUtente: undefined,
            informazioniBase: undefined,
            rilegatura: undefined,
            copertina: undefined,
            custodia: undefined,
            accessori: undefined,
            stampeDisponibili: false,
            miniaturaCopertina: false,
            miniaturaCustodia: false,
            miniaturaIndex: false,
            modalitaPreviewStampa: false,
            isLoading: true
        };

        UserService.GetUserContext().then(contestoUtente => this.setState({contestoUtente}));
    }

    public componentDidMount() {
         this._getOrdineData();
    }

    /**
     * Recupera tutte le informazioni dell'ordine
     * @private
     */
    private async _getOrdineData(){
        if(this.props.ordine){
            this.setState({isLoading: true});
            this.setState({stampeDisponibili: await OrdineService.OrdineFileExist(this.props.ordine.id)});

            const ordineData = await OrdineService.RecuperaOrdineData(this.props.ordine.id);

            const informazioniBaseData = ordineData.find(data => data.tipoDatoOrdine === 'InformazioniBase');
            const copertinaData = ordineData.find(data => data.tipoDatoOrdine === 'Copertina');
            const custodiaData = ordineData.find(data => data.tipoDatoOrdine === 'Custodia');

            const informazioniBase = InformazioniBaseInitializer(informazioniBaseData?.data as unknown as InformazioniBaseModel);
            const rilegatura = RilegaturaInitializer(ordineData.find(data => data.tipoDatoOrdine === 'Rilegatura')?.data as unknown as RilegaturaModel);
            const copertina = CopertinaInitializer(copertinaData?.data as unknown as CopertinaModel);
            const custodia = CustodiaInitializer(custodiaData?.data as unknown as CustodiaModel);
            const accessori = AccessoriInitializer(ordineData.find(data => data.tipoDatoOrdine === 'Accessori')?.data as unknown as AccessoriModel);

            const miniaturaIndex = informazioniBase && informazioniBaseData.metaData && (informazioniBaseData.metaData as any).miniatura;
            const miniaturaCopertina = copertinaData && copertinaData.metaData && (copertinaData.metaData as any).miniatura;
            const miniaturaCustodia = custodiaData && custodiaData.metaData && (custodiaData.metaData as any).miniatura;

            this.setState({
                informazioniBase,
                rilegatura,
                copertina,
                custodia,
                accessori,
                miniaturaCopertina,
                miniaturaCustodia,
                miniaturaIndex,
                isLoading: false
            })
        }
    }

    /**
     * Cambia lo stato di conferma per un ordine
     * @private
     */
    private async _cambiaStatoConferma(){
        TopLevelComponentStorage.GetTopLevel('loadingWindow').showLoadingWindow("Caricamento", "Stiamo inviando il cambiamento sul server");
        const esito = await TicketService.CambiaStatoConferma(this.props.ordine.idTicket, this.props.ordine.statoConferma === 0);
        TopLevelComponentStorage.GetTopLevel('loadingWindow').hideLoadingWindow();
        TopLevelComponentStorage.GetTopLevel('confirmWindow').showConfirmWindow(
            esito ? "Conferma" : "Errore",
            esito ? "Modifica avvenuta" : "Modifica non avvenuta",
            "SingleButton",
            () => this.props.updateOrdini && this.props.updateOrdini()
        );
    }

    /**
     * Cambia lo stato della segnalazione per un ordine
     * @private
     */
    private async _cambiaStatoSegnalazione(){
        TopLevelComponentStorage.GetTopLevel('loadingWindow').showLoadingWindow("Caricamento", "Stiamo inviando il cambiamento sul server");
        const esito = await TicketService.CambiaStatoSegnalazione(this.props.ordine.idTicket, this.props.ordine.statoErrore === 0);
        TopLevelComponentStorage.GetTopLevel('loadingWindow').hideLoadingWindow();
        TopLevelComponentStorage.GetTopLevel('confirmWindow').showConfirmWindow(
            esito ? "Conferma" : "Errore",
            esito ? "Modifica avvenuta" : "Modifica non avvenuta",
            "SingleButton",
            () => this.props.updateOrdini && this.props.updateOrdini()
        );
    }

    /**
     * Scarica le immagini del progetto
     * @private
     */
    private async _downloadImmagini(){
        TopLevelComponentStorage.GetTopLevel('loadingWindow').showLoadingWindow("Preparazione", "Stiamo recuperando i file per quest'ordine");
        const fileProgettoPath = await OrdineService.OrdineFilePath(this.props.ordine.id);
        TopLevelComponentStorage.GetTopLevel('loadingWindow').hideLoadingWindow();
        let hasError = true;
        if(fileProgettoPath){
            hasError = false;
            TopLevelComponentStorage.GetTopLevel('confirmWindow').showConfirmWindow(
                "Download file",
                "Tutto é pronto per il download",
                "SingleButton",
                () => {
                    const a = document.createElement('a');
                    a.href = `${Config.PublicPath}/ProjectData/${fileProgettoPath}`;
                    a.download = `${fileProgettoPath.split('.')[0]}-${this.props.ordine.nomeFotografo}-${this.props.ordine.cognomeFotografo}-${this.props.ordine.emailFotografo}.${fileProgettoPath.split('.')[1]}`;
                    a.click();
                }
            )
        }

        if(hasError){
            TopLevelComponentStorage.GetTopLevel('confirmWindow').showConfirmWindow(
                "Errore recupero file",
                "Non è stato possibile recuperare i file dell'ordine",
            );
        }
    }

    /**
     * Scarica l'index
     * @private
     */
    private async _downloadIndex(){
        const image = new Image();
        image.addEventListener('load', () => {
            const canvas = document.createElement('canvas') as HTMLCanvasElement;
            canvas.width = image.width;
            canvas.height = image.height;
            const context = canvas.getContext('2d');
            context.drawImage(image, 0, 0);

            const a = document.createElement('a');
            a.href = canvas.toDataURL();
            a.download = 'index.png';
            a.click();
        });
        image.crossOrigin="anonymous"
        image.src = OrdineService.GetOrdineMiniaturaUrl(this.props.ordine.id, 'indice');
    }

    /**
     * Cancella l'ordine
     * @private
     */
    private async _cancellaOrdine(){
        if(this.props.ordine.statoConferma === 0){
            TopLevelComponentStorage.GetTopLevel('confirmWindow').showConfirmWindow(
                "Sicuro?",
                "Sicuro di voler eliminare quest'ordine? Le sue informazioni non saranno più recuperabili",
                "DoubleButton",
                async () => {
                    const esito = await OrdineService.EliminaOrdine(this.props.ordine.id);
                    this.props.updateOrdini && this.props.updateOrdini();
                    TopLevelComponentStorage.GetTopLevel('confirmWindow').showConfirmWindow(
                        `Eliminazione ${esito ? 'completata' : 'non completata'}`,
                        esito ? "L'eliminazione dell'ordine è avvenuta" : "L'eliminazione dell'ordine è fallita",
                    )
                },
                () => TopLevelComponentStorage.GetTopLevel('confirmWindow').hideConfirmWindow()
            )
        }else{
            TopLevelComponentStorage.GetTopLevel('confirmWindow').showConfirmWindow(
                "Ordine già confermato",
                "Quest'ordine risulta già confermato e non può essere eliminato",
            );
        }

    }

    private _sezioneSelezionePreview(){
        return (
            <OrdineLineTopSeparator>
                <SentenceLayout alignment={"left"}>
                    <CheckboxButton
                        content={"Preview Ordine"}
                        type={"large"}
                        alignment={"left"}
                        labelType={"recapLabel"}
                        checked={!this.state.modalitaPreviewStampa}
                        onChange={v => this.setState({modalitaPreviewStampa: !v})}/>
                    <CheckboxButton
                        content={"Preview Stampa"}
                        type={"large"}
                        alignment={"left"}
                        labelType={"recapLabel"}
                        checked={this.state.modalitaPreviewStampa}
                        onChange={v => this.setState({modalitaPreviewStampa: v})}/>
                </SentenceLayout>
            </OrdineLineTopSeparator>
        )
    }

    private _sezioneStandarOrdine(){
        return (
            <OrdineLineTopSeparator label={"Informazioni Generali"}>
                <OrdineLineRecap label={"ID ordine"} content={this.props.ordine.id}/>
                <OrdineLineRecap label={"Studio fotografico"} content={this.props.ordine.studioFotografo}/>
                <OrdineLineRecap label={"Fotografo"} content={`${this.props.ordine.nomeFotografo} ${this.props.ordine.cognomeFotografo}`}/>
                <OrdineLineRecap label={"Email fotografo"} content={`${this.props.ordine.emailFotografo}`}/>
                <OrdineLineRecap label={"Telefono fotografo"} content={`${this.props.ordine.telefonoFotografo}`}/>
                <OrdineLineRecap label={"Data ordine"} content={`${this.props.ordine.dataCreazione.split('-').reverse().join('/')}`}/>
                <IfContainer condition={this.state.contestoUtente?.tipoUtente === 'amministrazione'}>
                    <OrdineLineRecap label={"Zona fotografo"} content={`${this.props.ordine.locazioneFotografo}`}/>
                </IfContainer>
            </OrdineLineTopSeparator>
        )
    }

    private _informazioniOrdineTopLevelView(){
        return (
            <SectionLayout size={"largeRelative"}>
                {this._sezioneSelezionePreview()}
                <IfContainer
                    condition={!this.state.modalitaPreviewStampa}
                    elseComponent={this._previewStampaOrdine()}>
                    {this._informazioniOrdine()}
                </IfContainer>
            </SectionLayout>
        )
    }

    private _informazioniOrdine(){
        return (
            <Fragment>
                {this._sezioneStandarOrdine()}
                {this.state.informazioniBase &&
                    <RendererData data={ new InformazioniBase(undefined).generaRiepilogo(this.state, undefined, false) as RiepilogoBox}/>}
                {this.state.rilegatura && this.state.informazioniBase.tipoFotolibro !== 'accessori' &&
                    <RendererData data={ new Rilegatura(undefined).generaRiepilogo(this.state, undefined, false) as RiepilogoBox}/>}
                {this.state.copertina && this.state.informazioniBase.tipoFotolibro !== 'accessori' &&
                    <RendererData data={ new Copertina(undefined).generaRiepilogo(this.state, undefined, false) as RiepilogoBox}/>}
                {this.state.custodia &&
                    <RendererData data={ new Custodia(undefined).generaRiepilogo(this.state, undefined, false) as RiepilogoBox}/>}
                {this.state.accessori &&
                    (new Accessori(undefined).generaRiepilogo(this.state, undefined, false) as RiepilogoBox[])
                        .map(accessorio => (<RendererData data={accessorio}/>))}
                <IfContainer condition={this.state.miniaturaCustodia || this.state.miniaturaCopertina || this.state.informazioniBase?.stampaFlag || this.state.miniaturaIndex}>
                    <OrdineLineTopSeparator label={"Miniature"}>
                        <Responsive2Col>
                            {this.state.miniaturaCopertina && <Miniatura url={OrdineService.GetOrdineMiniaturaUrl(this.props.ordine.id, 'copertina')}/>}
                            {this.state.miniaturaCustodia && <Miniatura url={OrdineService.GetOrdineMiniaturaUrl(this.props.ordine.id, 'custodia')}/>}
                        </Responsive2Col>
                        <IfContainer condition={(this.state.informazioniBase && this.state.informazioniBase.stampaFlag) || this.state.miniaturaIndex}>
                            <Miniatura url={OrdineService.GetOrdineMiniaturaUrl(this.props.ordine.id, 'indice')}/>
                        </IfContainer>
                    </OrdineLineTopSeparator>
                </IfContainer>
            </Fragment>
        )
    }

    private _previewStampaOrdine(){
        return (
            <PreviewStampaOrdine idOrdine={this.props.ordine.id}>
                {this._informazioniOrdine()}
            </PreviewStampaOrdine>
        )
    }

    private _menuAmministrazione(){
        return (
            <SectionLayout size={"largeRelative"}>
                <IfContainer condition={this.state.stampeDisponibili}>
                    <Button
                        content={"Scarica stampe"}
                        type={"large"}
                        alignment={"center"}
                        buttonType={"full-normal"}
                        onClick={() => this._downloadImmagini()}/>
                </IfContainer>
                <Responsive2Col>
                    <Button
                        content={`${this.props.ordine.statoConferma === 0 ? 'Conferma ordine' : 'Annulla conferma ordine'}`}
                        type={"large"}
                        alignment={"center"}
                        buttonType={"full-normal"}
                        onClick={() => {
                            this._cambiaStatoConferma();
                        }}/>
                    <Button
                        content={`${this.props.ordine.statoErrore === 0 ? 'Segnala errore' : 'Risolvi segnalazione'}`}
                        type={"large"}
                        alignment={"center"}
                        buttonType={"full-normal"}
                        onClick={() => {
                            this._cambiaStatoSegnalazione();
                        }}/>
                </Responsive2Col>
            </SectionLayout>
        )
    }

    private _menuFotografo(){
        return (
            <SectionLayout size={"largeRelative"} center={true}>
                <Button
                    content={"Elimina l'ordine"}
                    type={"large"}
                    alignment={"center"}
                    buttonType={"full-normal"}
                    onClick={() => {
                        this._cancellaOrdine()
                    }}/>
            </SectionLayout>
        )
    }

    private _menuComune(){
        return (
            <SectionLayout size={"largeRelative"} center={true}>
                <IfContainer condition={this.state.informazioniBase && this.state.informazioniBase.stampaFlag}>
                    <Button
                        content={"Scarica l'index"}
                        type={"large"}
                        alignment={"center"}
                        buttonType={"full-normal"}
                        onClick={() => this._downloadIndex()}/>
                </IfContainer>
                <ChatContext.Consumer>{
                    chat => (
                        <Button
                            content={"Apri chat ordine"}
                            type={"large"}
                            alignment={"center"}
                            buttonType={"full-normal"}
                            onClick={() => chat.openChat(this.props.ordine.id, `${this.props.ordine.id} - ${this.props.ordine.nomeFotografo} ${this.props.ordine.cognomeFotografo} - ${this.props.ordine.nomeOrdine}`)}/>
                    )
                }</ChatContext.Consumer>
            </SectionLayout>
        )
    }

    public render() {
        return (
            <div className={"OrdineContainer"}>
                <IfContainer
                    condition={!this.state.isLoading}
                    elseComponent={
                        <OrdineLineTopSeparator>
                            <LabelLoader label={'Caricamento dell\'ordine'}/>
                        </OrdineLineTopSeparator>
                    }>
                    {this._informazioniOrdineTopLevelView()}
                    <SectionLayout size={"largeRelative"}>
                        {this.props.vistaAmministrazione && this._menuAmministrazione()}
                        {!this.props.vistaAmministrazione && this._menuFotografo()}
                        {this._menuComune()}
                    </SectionLayout>
                </IfContainer>
            </div>
        );
    }
}
