import React, {Fragment} from "react";
import "./Button.scss";
import ResponsiveLabel, {ResponsiveLabelProps} from "../ResponsiveLabel/ResponsiveLabel";

export type ButtonType =
    "simple" |
    "simple-underline" |
    "simple-text" |
    "simple-text-no-underline" |
    "simple-light" |
    "basic" |
    "ordine" |
    "salva-per-dopo" |
    "submit" |
    "full-normal" |
    "full-normal-login" |
    "full-normal-negative" |
    "circle"

export interface BasicButtonProps extends Omit<ResponsiveLabelProps, "alignment">{
    alignment?: "left" | "center" | "right",
    disabled?: boolean,
    submit?: boolean,
    onClick?: () => void,
    buttonType?: ButtonType,
}

export default class Button extends React.Component<BasicButtonProps, {}>{
    public render(): React.ReactNode {
        return (
            <Fragment>
                <button
                    className={`Button ${this.props.buttonType?? "basic"} ${this.props.alignment && this.props.alignment}`}
                    onClick={() => {this.props.onClick && !this.props.disabled && this.props.onClick()}}
                    disabled={this.props.disabled}>
                    <ResponsiveLabel content={this.props.content} type={this.props.type} alignment={"center"} labelType={this.props.labelType}/>
                </button>
            </Fragment>
        );
    }
}
