import React from "react";
import "./DesktopModeConfiguratoreLayout.scss";
import PaddingLayout from "../PaddingLayout/PaddingLayout";
import IfContainer from "../IfContainer/IfContainer";

export interface DesktopModeConfiguratoreLayoutProps{
    abilitato?: boolean,
    children?: any,
    leftLabelText?: string
}

export default class DesktopModeConfiguratoreLayout extends React.Component<DesktopModeConfiguratoreLayoutProps, {}>{
    public render() {
        return (
            <IfContainer
                condition={this.props.abilitato}
                elseComponent={this.props.children}>
                <div className={"BlurBackground"}>
                    <PaddingLayout paddingMobile={"0"} paddingTablet={"0"} paddingDesktop={"0 30px"}>
                        <div className={"DesktopModeConfiguratoreLayout"}>
                            <div className={"LeftSide"}>
                                <span className={"VerticalLabel"}>{this.props.leftLabelText}</span>
                            </div>
                            {this.props.children}
                            <div className={"RightSide"}/>
                        </div>
                    </PaddingLayout>
                </div>
            </IfContainer>

        );
    }
}
