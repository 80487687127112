import {ItemElementInterface} from "../Primary/Selects/ElementSelect/ElementSelect";
import Config from "../Static/Config";
import {
    ConfigurazioneModelloDBInterface,
    ModelloInterface,
    Rest,
    TipoModelloType
} from "tici_commons";
import Formati from "./Formati";

export interface ConfigurazioneRidotta{
    nomeConfigurazione: string
    vincoliFormati: number[]
}

export default class Modello{
    private static _modelli: ModelloInterface[] = [];
    private static _configurazioni: ConfigurazioneRidotta[] = [];

    static {
        console.warn("Loading modelli");
        Rest.Get<ModelloInterface[]>(`${Config.PublicPath}/Modello`).then(async v => {
            if(v && v.statusCode === 200) {
                Modello._modelli = v.response;
                await Modello._RecuperaConfigurazioniRidotte()
            }
        });
    }

    /**
     * Inizializza l'array delle configurazioni ridotte chiamando le configurazioni sul database per ogni singolo modello
     * @private
     */
    private static async _RecuperaConfigurazioniRidotte(){
        try{
            const configurazioni = await Rest.Get<ConfigurazioneModelloDBInterface[]>(`${Config.PublicPath}/Configurazione`);
            if(configurazioni && configurazioni.statusCode === 200){
                for(const configurazione of configurazioni.response){
                    this._configurazioni.push({
                        nomeConfigurazione: configurazione.nomeConfigurazione,
                        vincoliFormati: configurazione.configurazioneModello.configurazioneModello.vincoliFormati
                    })
                }
            }
        }catch(e){
            console.error("Errore nel recupero della configurazioni ridotte");
        }
    }

    /**
     * Restituisce i vincoli di formato di un modello
     * @param nomeModello Nome del modello
     * @private
     */
    public static VincoliFormato(nomeModello: string): number[]{
        const esito: number[] = [];

        const configurazione = Modello._configurazioni.find(configurazione => configurazione.nomeConfigurazione === Modello.GetNomeConfigurazione(nomeModello));
        if(configurazione && configurazione.vincoliFormati)
            esito.push(...configurazione.vincoliFormati);

        return esito;
    }

    /**
     * Dato il nome del modello e il formato viene controllato se il modello può essere utilizzato con il formato indicato
     * @param nomeModello Nome del modello da controllare
     * @param formato Formato da controllare
     * @constructor
     */
    private static _SupportFormat(nomeModello: string, formato: string): boolean{
        let esito = true;

        const nomeConfigurazione = Modello.GetNomeConfigurazione(nomeModello);
        const configurazioneRidotta = Modello._configurazioni.find(configurazione => configurazione.nomeConfigurazione === nomeConfigurazione);
        if(configurazioneRidotta){
            const vincoliFormati = configurazioneRidotta.vincoliFormati;
            if(vincoliFormati && vincoliFormati.length > 0){
                esito = vincoliFormati.includes(Formati.GetIdFromFormato(formato));
            }
        }

        return esito;
    }

    /**
     * Recupera gli item element dei modelli
     * @param tipoModello Tipologia di modello da recuperare
     * @param vincoloFormato Vincolo del formato
     * @param modelloRidottoCofanetto Flag che indica se il modelli devono supportare il ridotto
     * @constructor
     */
    public static GetModelli(tipoModello: TipoModelloType, vincoloFormato?: string, modelloRidottoCofanetto?: boolean): ItemElementInterface[] {
        let modelli = Modello._modelli.filter(v => v.tipoModello === tipoModello);
        if(vincoloFormato)
            modelli = modelli.filter(v => Modello._SupportFormat(v.nomeModello, vincoloFormato));
        if(modelloRidottoCofanetto)
            modelli = modelli.filter(v => v.modelloRidottoCofanettoFlag === 1);
        return modelli.map(v => ({label: v.nomeModello, image: `${Config.PublicPath}/NoMapping/${tipoModello === 'custodia' ? 'Cofanetti' : 'Copertine'}/${v.nomeModello}.jpg`}));
    }

    /**
     * Controlla che il modello esista oppure no
     * @param nomeRicerca Nome del modello
     * @constructor
     */
    public static ModelExist(nomeRicerca: string): boolean{
        return Modello._modelli.filter(modello => modello.nomeModello === nomeRicerca).length === 1;
    }

    /**
     * Controlla che il modello abbia o meno un file nella situazione di ridotto
     * @param nomeModello Nome del modello da controllare
     * @constructor
     */
    public static HasFile(nomeModello: string): boolean{
        let esito = false;
        const index = Modello._modelli.map(v => v.nomeModello).indexOf(nomeModello);
        if(index !== -1)
            esito = Modello._modelli[index].modelloRidottoCofanettoFileFlag === 1;
        return esito;
    }

    /**
     * Controlla che il modello abbia o meno l'uv nella situazione di ridotto
     * @param nomeModello Nome del modello da controllare
     * @constructor
     */
    public static HasUv(nomeModello: string): boolean{
        let esito = false;
        const index = Modello._modelli.map(v => v.nomeModello).indexOf(nomeModello);
        if(index !== -1)
            esito = Modello._modelli[index].modelloRidottoCofanettoUvFlag === 1;
        return esito;
    }

    /**
     * Restituisce il nome della configurazione per un particolare modello
     * @param nomeModello Nome del modello da cui prelevare la configurazione
     * @constructor
     */
    public static GetNomeConfigurazione(nomeModello: string): string | undefined{
        let esito: string | undefined;
        const index = Modello._modelli.map(v => v.nomeModello).indexOf(nomeModello);
        if(index !== -1)
            esito = Modello._modelli[index].configurazioneAssociata;
        return esito;
    }

    /**
     * Restituisce una configurazione sulla base del nome
     * @param nomeConfigurazione Nome della configurazione
     * @constructor
     */
    public static GetConfigurazione(nomeConfigurazione: string): Promise<ConfigurazioneModelloDBInterface | false>{
        return new Promise<ConfigurazioneModelloDBInterface | false>(resolve => {
            Rest.Get<ConfigurazioneModelloDBInterface[]>(`${Config.PublicPath}/Configurazione/${nomeConfigurazione}`).then(value => {
                if(value && value.statusCode === 200 && value.response){
                    resolve(value.response[0]);
                }else resolve(false);
            }).catch(() => resolve(false));
        });
    }
}
