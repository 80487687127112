import React from "react";
import ResponsiveLabel from "../../../Core/ResponsiveLabel/ResponsiveLabel";
import ContainerLayout from "../../../Layout/ContainerLayout/ContainerLayout";
import Button from "../../../Core/Buttons/Button";
import SectionLayout from "../../../Layout/SectionLayout/SectionLayout";
import {ImageVisualizer, TextLine} from "../../../Widgets/Configuratore/CComponents";
import {CFRegex, CheckUtente, EmailRegex, TelefonoRegex, UtenteModel} from "tici_commons";
import TopLevelComponentStorage from "../../../Core/Arch/TopLevelComponentStorage";
import AgenteService from "../../../Servicies/AgenteService";

export interface RegistrazioneAgenteState{
    utente: UtenteModel
}

export default class RegistrazioneAgente extends React.Component<{}, RegistrazioneAgenteState>{
    private _immagineProfiloBase64: string;

    constructor(props: Readonly<{}> | {}) {
        super(props);
        this.state = {
            utente: {
                tipoUtente: 'agente',
                email: '',
                password: '',
                numeroTelefono: '',
                immagineProfilo: '',
                codiceFiscale: '',
                cognome: '',
                nome: '',
                superUser: 0,
                statoAttivazione: 1,
                statoAttivazioneEmail: 1,
                privacyAccettata: 0
            }
        }
    }

    /**
     * Effetta il reset delle infromazioni utente all'interno dello state
     * @private
     */
    private _resetUtente(){
        this._immagineProfiloBase64 = undefined;
        this.setState({
            utente: {
                tipoUtente: 'agente',
                email: '',
                password: '',
                numeroTelefono: '',
                immagineProfilo: '',
                codiceFiscale: '',
                cognome: '',
                nome: '',
                superUser: 0,
                statoAttivazione: 1,
                statoAttivazioneEmail: 1,
                privacyAccettata: 0
            }
        });
    }

    /**
     * Aggiorna le informazioni dell'utente
     * @param data
     * @private
     */
    private _updateUserData<K extends keyof UtenteModel>(data: Pick<UtenteModel, K>){
        this.setState(currentState => ({utente: {...currentState.utente, ...data}}));
    }

    /**
     * Effettua la registrazione dell'agente
     * @private
     */
    private async _registraAgente(){
        TopLevelComponentStorage.GetTopLevel('loadingWindow').showLoadingWindow(
            'Registrazione agente',
            'Stiamo registrando l\'agente'
        );

        const esito = await AgenteService.RegistraAgente(this.state.utente, this._immagineProfiloBase64);
        TopLevelComponentStorage.GetTopLevel('loadingWindow').hideLoadingWindow();
        TopLevelComponentStorage.GetTopLevel('confirmWindow').showConfirmWindow(
            esito ? 'Registrazione agente avvenuta' : 'Registrazione agente fallita',
            esito ? 'La registrazione é andata a buon fine' : 'La registrazione non é andata a buon fine'
        );

        if(esito)
            this._resetUtente();
    }

    public render() {
        return (
            <SectionLayout size={"largeRelative"}>
                <ResponsiveLabel alignment={"center"} content={"Registra un agente"} type={"large"} bold={true} uppercase={true}/>
                <SectionLayout size={"mediumRelative"} addPadding={true} showBorder={true} lightBackground={true}>
                    <ImageVisualizer
                        imageData={this._immagineProfiloBase64}
                        onDelete={() => {
                            this._immagineProfiloBase64 = undefined;
                            this._updateUserData({immagineProfilo: ""});
                        }}/>
                    <TextLine
                        label={"Immagine profilo"}
                        type={"file"}
                        value={this.state.utente.immagineProfilo}
                        onFileUpload={(fileName, fileType, file, base64Encoding) => {
                            if(['png', 'jpeg', 'jpg'].includes(fileType.toLowerCase())){
                                this._immagineProfiloBase64 = base64Encoding;
                                this._updateUserData({immagineProfilo: fileName});
                            }
                        }}
                        errorMessage={"Bisogna inserire un immagine di profilo"}
                        showError={this.state.utente.immagineProfilo.length === 0}/>
                    <TextLine
                        label={"Nome"}
                        value={this.state.utente.nome}
                        onChange={v => this._updateUserData({nome: v})}
                        errorMessage={"Bisgona inserire un nome"}
                        showError={this.state.utente.nome.length === 0}/>
                    <TextLine
                        label={"Cognome"}
                        value={this.state.utente.cognome}
                        onChange={v => this._updateUserData({cognome: v})}
                        errorMessage={"Bisgona inserire un cognome"}
                        showError={this.state.utente.cognome.length === 0}/>
                    <TextLine
                        label={"Email"}
                        value={this.state.utente.email}
                        onChange={v => this._updateUserData({email: v})}
                        errorMessage={"Bisgona inserire una email valida"}
                        showError={!EmailRegex.test(this.state.utente.email)}/>
                    <TextLine
                        label={"Codice fiscale"}
                        value={this.state.utente.codiceFiscale.toUpperCase()}
                        onChange={v => this._updateUserData({codiceFiscale: v.toUpperCase()})}
                        errorMessage={"Bisgona inserire un codice fiscale valido"}
                        showError={!CFRegex.test(this.state.utente.codiceFiscale)}/>
                    <TextLine
                        label={"Numero di telefono"}
                        value={this.state.utente.numeroTelefono}
                        onChange={v => this._updateUserData({numeroTelefono: v})}
                        errorMessage={"Bisgona inserire un numero di telefono valido"}
                        showError={!TelefonoRegex.test(this.state.utente.numeroTelefono)}/>
                    <Button
                        content={"Salva"}
                        type={"large"}
                        buttonType={"full-normal"}
                        disabled={!CheckUtente(this.state.utente, false)}
                        alignment={"center"}
                        onClick={() => this._registraAgente()}/>
                </SectionLayout>
            </SectionLayout>
        );
    }
}
