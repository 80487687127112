import backgroundBase from '../Assets/backgroundBase.jpeg';
import backgroundConfigurazione from '../Assets/backgroundConfigurazione.jpeg';
import logo from '../Assets/logo.jpeg';

export default class ImageLoader{
    public static async backgroundBase(): Promise<string>{
        return backgroundBase;
    }

    public static async backgroundConfigurazione(): Promise<string>{
        return backgroundConfigurazione;
    }

    public static async logo(): Promise<string>{
        return logo;
    }
}
