import React from "react";
import {ScrollZone, ScrollZoneElement} from "../../../Widgets/Configuratore/CComponents";

export interface DADMCartonciniListProps{
    cartoncini?: [number, string][],
    onDeleteCartoncino?: (posizioneCartoncino: number) => void
}

export default class DADMCartonciniList extends React.Component<DADMCartonciniListProps, {}>{
    public render() {
        return (
            <ScrollZone>
                {
                    this.props.cartoncini && this.props.cartoncini.map(cartoncino => (
                        <ScrollZoneElement
                            key={"Cartoncino-" + cartoncino[0]}
                            content={`Posizione ${cartoncino[0]}: ${cartoncino[1]}`}
                            onDeleteClick={() => this.props.onDeleteCartoncino && this.props.onDeleteCartoncino(cartoncino[0])}/>
                    ))
                }
            </ScrollZone>
        );
    }
}
