import "./Logo.scss";
import logoTotem from "../../Media/Images/Logos/logoTotem.png";
import ResponsiveLabel from "../../Core/ResponsiveLabel/ResponsiveLabel";

interface LogoProps{
    logoBaseMode?: boolean
}
const Logo = (props: LogoProps): JSX.Element => {
    return (
        <span className={`${props.logoBaseMode ? 'LogoBase' : 'Logo'}`}>
            <span className={"title"}>TICI</span>
            <span className={"subtitle"}>ALBUM</span>
        </span>
    );
}

export const LogoTotem = () => {
    return (
        <span className={"LogoTotem"}>
            <img className={"LogoImage"} src={logoTotem}/>
            <span className={"LabelZone"}>
                <ResponsiveLabel content={"TiciTotem"} type={"medium"} alignment={"center"} labelType={"whiteLabel"}/>
            </span>
        </span>
    )
}

export default Logo;
