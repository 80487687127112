import {FotolibroModel, ModelliStampaLegnoData, StampaLegnoModel} from "tici_commons";
import React from "react";
import {StorageDataMapper, StorageManagerStatus} from "../../../Storage/StorageManager";
import {ImageVisualizer, SelectLine, TextLine} from "../../../../../../Widgets/Configuratore/CComponents";
import IfContainer from "../../../../../../Layout/IfContainer/IfContainer";
import Config from "../../../../../../Static/Config";
import SezioneChiavettaUsb from "./SezioneChiavettaUsb";
import SezioneFotoTela from "./SezioneFotoTela";
import {
    GenerateRiepilogoBox, GenerateRiepilogoData, GenerateRiepilogoInternalBox,
    RiepilogoBox,
    RiepilogoData,
    RiepilogoInternalBox
} from "../../../Commons/RiepilogoDatoModel";
import CategorySectionLayout from "../../../../../../Layout/CategorySectionLayout/CategorySectionLayout";
import SezioneAccessoriGenerica from "../SezioneAccessoriGenerica";

export default class SezioneStampaLegno extends SezioneAccessoriGenerica<"StampaLegno", {}, {}>{
    public static ErrorMessages = {
        StampaNonCaricata: "La stampa non è stata caricata"
    }

    public generaRiepilogo(accessorio: StampaLegnoModel, indexAccessorio: number, fotolibro: FotolibroModel, storage: StorageManagerStatus, modalitaConfiguratore: boolean): RiepilogoBox | RiepilogoBox[] | RiepilogoInternalBox | RiepilogoInternalBox[] | RiepilogoData[] {
        return GenerateRiepilogoBox(
            `Stampa su legno ${indexAccessorio + 1}`,
            GenerateRiepilogoInternalBox(
                `Stampa su legno ${indexAccessorio + 1}`,
                GenerateRiepilogoData(
                    `Modello: ${accessorio.modelloStampaLegno}`,
                    SezioneChiavettaUsb.ErrorMessages.ModelloAssenteError,
                    accessorio.modelloStampaLegno.length === 0
                ),
                GenerateRiepilogoData(
                    `Formato: ${accessorio.formatoStampaLegno}`,
                    SezioneFotoTela.ErrorMessages.FormatoErratoError,
                    accessorio.formatoStampaLegno.length === 0
                ),
                GenerateRiepilogoData(
                    undefined,
                    SezioneStampaLegno.ErrorMessages.StampaNonCaricata,
                    true,
                    storage && storage.GetStorageData(`Accessorio-${indexAccessorio}-${StorageDataMapper.StampaLegno}`).length === 0
                )
            )
        );
    }

    public render() {
        return (
            <CategorySectionLayout categoryLabel={`Stampa su legno ${this.props.indexAccessorio + 1}`} size={"largeRelative"} center={true}>
                <SelectLine
                    label={"Modello stampa su legno"}
                    placeHolder={"Seleziona"}
                    errorMessage={SezioneChiavettaUsb.ErrorMessages.ModelloAssenteError}
                    showError={!this.$.modelloStampaLegno.length}
                    elements={ModelliStampaLegnoData.map(value => ({label: value, image: `${Config.NoMapping}/StampeLegno/${value}.jpg`}))}
                    value={this.$.modelloStampaLegno}
                    onChange={v => this.$.modelloStampaLegno = v}/>
                <TextLine
                    label={"Formato stampa su legno"}
                    errorMessage={SezioneFotoTela.ErrorMessages.FormatoErratoError}
                    showError={!new RegExp(/^[0-9]{1,3}x[0-9]{1,3}$/gi).test(this.$.formatoStampaLegno)}
                    placeHolder={"NxM"}
                    value={this.$.formatoStampaLegno}
                    onChange={v => this.$.formatoStampaLegno = v}/>
                <IfContainer condition={this.CountImmagini(StorageDataMapper.StampaLegno) === 1}>
                    <ImageVisualizer
                        storageData={this.ImmaginiDaCategoria(StorageDataMapper.StampaLegno)[0]?? undefined}
                        onDelete={() => this.DeleteImmagine(StorageDataMapper.StampaLegno)}/>
                </IfContainer>
                <TextLine
                    label={"Carica la stampa su legno"}
                    errorMessage={SezioneStampaLegno.ErrorMessages.StampaNonCaricata}
                    showError={this.CountImmagini(StorageDataMapper.StampaLegno) === 0}
                    type={"file"}
                    value={this.ImmaginiDaCategoria(StorageDataMapper.StampaLegno)[0]?.name?? ""}
                    onFileUpload={(fileName, fileType, file) => {
                        if(["png", "jpg"].includes(fileType)){
                            this.DeleteImmagine(StorageDataMapper.StampaLegno);
                            this.UplodaImmagine(StorageDataMapper.StampaLegno, fileName, file);
                        }
                    }}/>
            </CategorySectionLayout>
        );
    }
}
