import React, {Fragment} from "react";
import ContainerLayout from "../../../../../Layout/ContainerLayout/ContainerLayout";
import SezioneChiavettaUsb from "./AccessoriSezioni/SezioneChiavettaUsb";
import SezioneFotoTela from "./AccessoriSezioni/SezioneFotoTela";
import SezioneStampaLegno from "./AccessoriSezioni/SezioneStampaLegno";
import {StorageManagerStatus} from "../../Storage/StorageManager";
import {
    GenerateRiepilogoBox, GenerateRiepilogoData, GenerateRiepilogoInternalBox,
    RiepilogoBox,
    RiepilogoData,
    RiepilogoInternalBox
} from "../../Commons/RiepilogoDatoModel";
import SezionePannelliPlex from "./AccessoriSezioni/SezionePannelliPlex";
import SezionePannelliAlufoam from "./AccessoriSezioni/SezionePannelliAlufoam";
import SezioneStampeAccessori from "./AccessoriSezioni/SezioneStampeAccessori";
import SezioneGenerica from "../SezioneGenerica";
import {
    ChiavettaUsbInitializer,
    FotolibroModel,
    MappaAccessori, MultiDataAccessoriType,
    PannelliAlufoamInitializer,
    PannelliPlexInitializer, StampaLegnoInitializer, StampaTelaInitializer, StampeInitializer
} from "tici_commons";
import {SelectLine} from "../../../../../Widgets/Configuratore/CComponents";
import CategorySectionLayout from "../../../../../Layout/CategorySectionLayout/CategorySectionLayout";
import SezioneRequiredProps from "../../Commons/SezioneRiquiredProps";
import {FotolibroContextInterface} from "../../Fotolibro/Fotolibro";
import Button from "../../../../../Core/Buttons/Button";
import Responsive2Col from "../../../../../Layout/Responsive2Col/Responsive2Col";


export interface AccessoriState {
    tipoAccessorioSelezionato: keyof typeof MappaAccessori
}

export default class Accessori extends SezioneGenerica<"accessori", {}, AccessoriState>{
    constructor(props: SezioneRequiredProps<"accessori"> & { data: FotolibroContextInterface["accessori"]; storage: StorageManagerStatus }) {
        super(props);
        this.state = {
            tipoAccessorioSelezionato: "Chiavetta"
        }
    }

    generaRiepilogo(fotolibro: FotolibroModel, storage: StorageManagerStatus, modalitaConfiguratore: boolean): RiepilogoBox | RiepilogoInternalBox | RiepilogoInternalBox[] | RiepilogoData[] {
        const esito: RiepilogoBox[] = [];
        let index = 0;
        for(const accessorio of fotolibro.accessori.accessori){
            switch (accessorio[0]){
                case "Chiavetta":
                    esito.push(new SezioneChiavettaUsb(undefined).generaRiepilogo(accessorio[1], index, fotolibro, storage, modalitaConfiguratore) as RiepilogoBox);
                    break;
                case "StampaTela":
                    esito.push(new SezioneFotoTela(undefined).generaRiepilogo(accessorio[1], index,fotolibro, storage, modalitaConfiguratore) as RiepilogoBox);
                    break;
                case "StampaLegno":
                    esito.push(new SezioneStampaLegno(undefined).generaRiepilogo(accessorio[1], index,fotolibro, storage, modalitaConfiguratore) as RiepilogoBox);
                    break;
                case "PannelliPlex":
                    esito.push(new SezionePannelliPlex(undefined).generaRiepilogo(accessorio[1], index,fotolibro, storage, modalitaConfiguratore) as RiepilogoBox);
                    break;
                case "PannelliAlufoam":
                    esito.push(new SezionePannelliAlufoam(undefined).generaRiepilogo(accessorio[1], index,fotolibro, storage, modalitaConfiguratore) as RiepilogoBox);
                    break;
                case "Stampe":
                    esito.push(new SezioneStampeAccessori(undefined).generaRiepilogo(accessorio[1], index,fotolibro, storage, modalitaConfiguratore) as RiepilogoBox);
                    break;
            }
            index++;
        }

        if(
            fotolibro.informazioniBase.tipoFotolibro === 'accessori' &&
            fotolibro.accessori.accessori.length === 0 &&
            !fotolibro.informazioniBase.custodiaFlag
        ){
            esito.push(GenerateRiepilogoBox(
                "Accessori",
                GenerateRiepilogoInternalBox(
                    "Accessori",
                    GenerateRiepilogoData(
                        undefined,
                        "Nessun accessorio o cofanetto selezionato",
                        true
                    )
                )
            ))
        }

        return esito;
    }

    /**
     * Aggiunge un nuovo accessorio al fotolibro
     * @private
     */
    private _aggiungiAccessorioHandler(){
        const arrayAccessori = this.$.accessori;
        switch (this.state.tipoAccessorioSelezionato){
            case "Chiavetta":
                arrayAccessori.push(['Chiavetta', ChiavettaUsbInitializer()]);
                break;
            case "PannelliAlufoam":
                arrayAccessori.push(['PannelliAlufoam', PannelliAlufoamInitializer()]);
                break;
            case "PannelliPlex":
                arrayAccessori.push(['PannelliPlex', PannelliPlexInitializer()]);
                break;
            case "StampaLegno":
                arrayAccessori.push(['StampaLegno', StampaLegnoInitializer()]);
                break;
            case "StampaTela":
                arrayAccessori.push(['StampaTela', StampaTelaInitializer()]);
                break;
            case "Stampe":
                arrayAccessori.push(['Stampe', StampeInitializer()]);
                break;
        }
        this.$.accessori = arrayAccessori;
    }

    /**
     * Rimuove l'ultimo accessorio inserito
     * @private
     */
    private _rimuoviAccessorioHandle() {
        let arrayAccessori = this.$.accessori;
        const lastIndex = arrayAccessori.length - 1;
        const storage = this.props.storage.GetStorage();
        for(const storageData of storage){
            if(storageData.category.includes(`Accessorio-${lastIndex}`))
                this.props.storage.DeleteStorageData(storageData.category);
        }
        arrayAccessori = arrayAccessori.filter((element, index) => index !== lastIndex);
        this.$.accessori = arrayAccessori;
    }

    /**
     * Setta il valore dell'accessorio
     * @param index Index dell'accessorio
     * @param data Data da impostare nell'accessorio
     * @private
     */
    private _setAccessorioData(index: number, data: MultiDataAccessoriType){
        const arrayAccessori = this.$.accessori;
        arrayAccessori[index] = {...data};
        this.$.accessori = arrayAccessori;
    }

    render() {
        return (
            <Fragment>
                <CategorySectionLayout categoryLabel={"Accessorio"} size={"largeRelative"} center={true}>
                    <SelectLine
                        label={"Seleziona tipo accessorio"}
                        placeHolder={"Seleziona"}
                        elements={Object.values(MappaAccessori).map(accessorio => ({label: accessorio}))}
                        value={MappaAccessori[this.state.tipoAccessorioSelezionato]}
                        onChange={v => this.setState({tipoAccessorioSelezionato: Object.keys(MappaAccessori).find(key => MappaAccessori[key as keyof typeof MappaAccessori] === v) as keyof typeof MappaAccessori})}/>
                    <Responsive2Col>
                        <Button onClick={() => this._aggiungiAccessorioHandler()} content={"Aggiungi accessorio"} type={"medium"} buttonType={"full-normal"}/>
                        <Button onClick={() => this._rimuoviAccessorioHandle()} content={"Rimuovi ultimo accessorio"} type={"medium"} buttonType={"full-normal-negative"}/>
                    </Responsive2Col>
                </CategorySectionLayout>
                {
                    [...this.$.accessori].map((accessorio, index) => (
                        <Fragment key={`Accessorio-${index}`}>
                            {accessorio[0] === 'Chiavetta' &&
                                <SezioneChiavettaUsb
                                    indexAccessorio={index}
                                    data={accessorio}
                                    setAccessorioData={this._setAccessorioData.bind(this)}
                                    storage={this.props.storage}/>}
                            {accessorio[0] === 'PannelliPlex' &&
                                <SezionePannelliPlex
                                    indexAccessorio={index}
                                    data={accessorio}
                                    setAccessorioData={this._setAccessorioData.bind(this)}
                                    storage={this.props.storage}/>}
                            {accessorio[0] === 'PannelliAlufoam' &&
                                <SezionePannelliAlufoam
                                    indexAccessorio={index}
                                    data={accessorio}
                                    setAccessorioData={this._setAccessorioData.bind(this)}
                                    storage={this.props.storage}/>}
                            {accessorio[0] === 'StampaTela' &&
                                <SezioneFotoTela
                                    indexAccessorio={index}
                                    data={accessorio}
                                    setAccessorioData={this._setAccessorioData.bind(this)}
                                    storage={this.props.storage}/>}
                            {accessorio[0] === 'StampaLegno' &&
                                <SezioneStampaLegno
                                    indexAccessorio={index}
                                    data={accessorio}
                                    setAccessorioData={this._setAccessorioData.bind(this)}
                                    storage={this.props.storage}/>}
                            {accessorio[0] === 'Stampe' &&
                                <SezioneStampeAccessori
                                    indexAccessorio={index}
                                    data={accessorio}
                                    setAccessorioData={this._setAccessorioData.bind(this)}
                                    storage={this.props.storage}/>}
                        </Fragment>
                    ))
                }
            </Fragment>
        )
    }
}
