import {FotografoBaseResponseModel, Rest} from "tici_commons";
import Config from "../Static/Config";

export default class ImpersonificazioneService {
    public static async GetFotografi(): Promise<FotografoBaseResponseModel[]>{
        const esito: FotografoBaseResponseModel[] = [];

        try{
            const response = await Rest.Get<FotografoBaseResponseModel[]>(`${Config.PrivatePath}/Impersonificazione`);
            if(response && response.statusCode === 200)
                esito.push(...response.response)
        }catch (e){
            console.error("Non é stato possibile recuperare i fotografi da impersonificare", String(e));
        }

        return esito;
    }

    public static async Impersonifica(emailFotografo: string): Promise<string | false>{
        let esito: string | false = false;

        try {
            const response = await Rest.Get<string>(`${Config.PrivatePath}/Impersonificazione/${emailFotografo}`);
            if(response && response.statusCode === 200)
                esito = response.response;
        }catch (e){
            console.error("Non é stato possibile impersonificare l'utentza", String(e));
        }

        return esito;
    }
}
