import React, {Fragment} from "react";
import SectionLayout from "../../Layout/SectionLayout/SectionLayout";
import Renderizzatore, {RenderizzatoreSezioneInterface} from "../Renderizzatore/Renderizzatore";
import Modello from "../../DatabaseData/Modello";
import {
    ConfigurazioneBlank,
    ConfigurazioneRenderizzatoreInterface,
    ConfigurazioneSezioneBlank, SpessoreMateriale
} from "tici_commons";
import Config from "../../Static/Config";
import ResponsiveLabel from "../../Core/ResponsiveLabel/ResponsiveLabel";
import IfContainer from "../../Layout/IfContainer/IfContainer";
import MaterialManagerComponent, {
    MaterialManagerComponentProps,
    MaterialManagerComponentState
} from "../../Core/Arch/MaterialManagerComponent";
import {StorageManagerContext} from "../../Pages/SitoInterno/Configuratore/Storage/StorageManager";
import {RenderizzatoreSezioneConfiguratore} from "../../Widgets/Configuratore/CComponents";
import {ConfigurazioneSezioneInterface} from "tici_commons";
import VSLoader from "../VSLoader/VSLoader";
import ReactDOM from "react-dom";
import LocalStorageService from "../../Servicies/LocalStorageService";

export interface ListaMaterialiConfiguratoreProps extends MaterialManagerComponentProps{
    nomeConfigurazioneModello: string,
    aspectRatioModello?: number,
    configurazioneModello?: ConfigurazioneRenderizzatoreInterface,
    urlModello?: string,
    nascondiEditor?: boolean,
    editorDestination?: HTMLElement,
    modalitaGrandeSchermo?: boolean,
    modalitaProgettazione?: boolean,
    nascondiLegenda?: boolean,
    onRenderizzatoreGenerateImage?: (base64Data: string) => void
}

export interface ListaMaterialiConfiguratoreState extends MaterialManagerComponentState{
    isLoading: boolean,

    pathModello: string,
    configurazioneModello: ConfigurazioneRenderizzatoreInterface,
    sezioni: RenderizzatoreSezioneInterface[],
}

export default class ListaMaterialiConfiguratore extends MaterialManagerComponent<ListaMaterialiConfiguratoreProps, ListaMaterialiConfiguratoreState>{
    constructor(props: Readonly<ListaMaterialiConfiguratoreProps> | ListaMaterialiConfiguratoreProps) {
        super(props);
        this.state = {
            isLoading: false,
            materiali: [],
            sezioni: [],
            configurazioneModello: undefined,
            pathModello: undefined,
            vincoliGruppoSpessore: [],
        }
    }

    /**
     * Descrive una singola sezione
     * @param sezione Sezione del renderizzatore di cui costrire il modificatore
     * @private
     */
    private _SingolaSezione(sezione: RenderizzatoreSezioneInterface) {
        return (
            <StorageManagerContext.Consumer>{
                storage => (
                    <RenderizzatoreSezioneConfiguratore
                        sezione={sezione}
                        configurazioneSezione={this._getSezioneFromConfigurazione(sezione.nomeSezione)}
                        hasSezioneData={this.HasSezioneData.bind(this)}
                        getSezioneData={this.GetSezioneData.bind(this)}
                        setSezioneData={this.SetSezioneData.bind(this)}
                        deleteSezioneData={this.DeleteSezioneData.bind(this)}
                        getSezioneType={this.GetSezioneType.bind(this)}
                        setStorageData={(nomeFile, file, base64) => storage.SetStorageData(`${this.props.idManager}-${sezione.nomeSezione}`, nomeFile, file, base64)}
                        deleteStorageData={() => storage.DeleteStorageData(`${this.props.idManager}-${sezione.nomeSezione}`)}
                        getStorageData={nomeFile => storage.GetStorageData(`${this.props.idManager}-${sezione.nomeSezione}`, nomeFile)}
                        isSingolaImmagine={this._isSingolaImmagine.bind(this)}
                        isSingoloColore={this._isSingoloColore.bind(this)}
                        famiglieEscluse={this.state.configurazioneModello.configurazioneModello.materialiEsclusi}
                        filtroMateriale={this._getFiltroMateriali(sezione.nomeSezione)}/>
                )
            }</StorageManagerContext.Consumer>
        )
    }

    /**
     * Renderizza l'editor
     * @private
     */
    private _RenderEditor(){
        return (
            <Fragment>{
                this.state.configurazioneModello && this.state.sezioni.map(sezioneRenderizzatore => (
                    this._getSezioneFromConfigurazione(sezioneRenderizzatore.nomeSezione) &&
                    (this.props.materialiFissi ? this.props.materialiFissi.filter(materiale => materiale[0] === sezioneRenderizzatore.nomeSezione).length === 0 : true) &&
                    <SectionLayout
                        key={`${this.props.idManager}-${sezioneRenderizzatore.nomeSezione}`}
                        size={"largeRelative"}
                        order={this._getSezioneFromConfigurazione(sezioneRenderizzatore.nomeSezione).ordine}>
                        {this._SingolaSezione(sezioneRenderizzatore)}
                    </SectionLayout>
                ))
            }</Fragment>
        )
    }

    public render() {
        return (
            <SectionLayout size={"largeRelative"}>
                <IfContainer
                    condition={!this.state.isLoading}
                    elseComponent={
                        <Fragment>
                            <ResponsiveLabel content={"Caricamento"} type={"medium"} alignment={"center"}/>
                            <VSLoader gapMobile={20} gapTablet={40} gapDesktop={60}/>
                        </Fragment>
                    }>
                    <IfContainer
                        condition={!!this.state.pathModello && !!this.state.configurazioneModello}
                        elseComponent={
                            <ResponsiveLabel content={"Nessun modello selezionato"} type={"medium"} alignment={"center"}/>
                        }>
                        <Renderizzatore
                            currentObjectPath={this.state.pathModello}
                            currentConfiguration={this.state.configurazioneModello}
                            onSectionChanged={sezioni => {
                                this.setState({sezioni: sezioni});
                            }}
                            materialsPath={this.state.materiali}
                            currentAspectRatio={this.props.aspectRatioModello?? 1}
                            onGenerateImage={(base64Data) => {
                                if(this.props.idManager === 'copertina')
                                    LocalStorageService.CopertinaImmagineGenerata = base64Data;
                                else if(this.props.idManager === 'custodia') LocalStorageService.CustodiaImmagineGenerata = base64Data;
                            }}
                            modalitaGrandeSchermo={this.props.modalitaGrandeSchermo}
                            helpersMode={this.props.modalitaProgettazione}
                            hideLegenda={this.props.nascondiLegenda}/>
                        <IfContainer condition={!this.props.nascondiEditor}>{
                            !this.props.editorDestination ?
                                this._RenderEditor() :
                                ReactDOM.createPortal(this._RenderEditor(), this.props.editorDestination)
                        }</IfContainer>
                    </IfContainer>
                </IfContainer>
            </SectionLayout>
        );
    }

    /**
     * Effettua il caricamento della configurazione del modello
     * @private
     */
    private _caricaConfigurazioneModello(){
        this.setState({
            pathModello: this.props.urlModello,
            configurazioneModello: this.props.configurazioneModello
        });
        if(this.props.nomeConfigurazioneModello){
            this.setState({isLoading: true});
            Modello.GetConfigurazione(this.props.nomeConfigurazioneModello).then(val => {
                if(val){
                    const sezioni: ConfigurazioneSezioneInterface[] = [];
                    for(const sezione of val.configurazioneModello.configurazioniSezioni)
                        sezioni.push(ConfigurazioneSezioneBlank('', sezione))

                    val.configurazioneModello = ConfigurazioneBlank({
                        configurazioneModello: val.configurazioneModello.configurazioneModello,
                        configurazioniSezioni: sezioni
                    });

                    this.setState({
                        pathModello: `${Config.PublicPath}/${val.pathModello}`,
                        configurazioneModello: val.configurazioneModello
                    });

                    let sezioniObbligatorie = 0;
                    val.configurazioneModello.configurazioniSezioni.forEach(value => {
                        if(value.obbligatoriaFlag)
                            sezioniObbligatorie ++;
                    })
                    if(this.props.idManager === "copertina")
                        LocalStorageService.SezioniObbligatorieCopertina = sezioniObbligatorie;
                    else if(this.props.idManager === 'custodia') LocalStorageService.SezioniObbligatorieCustodia = sezioniObbligatorie;
                    else if(this.props.idManager.includes('lavorazione')) LocalStorageService.SezioniObbligatorieLavorazione = sezioniObbligatorie;
                }
                this.setState({isLoading: false});
            })
        }
    }

    /**
     * Restituisce il filtro per il materiale
     * @param nomeSezione Nome della sezione di cui è necessario il filtro
     * @private
     */
    private _getFiltroMateriali(nomeSezione: string): SpessoreMateriale | '*' {
        const tipologiaMaterialeSupportato = this._getSezioneFromConfigurazione(nomeSezione).tipologiaMaterialeSupportato.toLowerCase();

        return tipologiaMaterialeSupportato === "spesso" ?
            'spesso' :
            (tipologiaMaterialeSupportato === 'sottile' ?
                'sottile' :
                this.GetSpessoreGruppo(this.GetGruppoSezione(nomeSezione), nomeSezione))
    }

    /**
     * Restitituisce la configurazione della sezione
     * @param nomeSezione Nome della sezione di cui recuperare la configurazione
     * @private
     */
    private _getSezioneFromConfigurazione(nomeSezione: string): ConfigurazioneSezioneInterface | undefined{
        let esito: ConfigurazioneSezioneInterface = undefined;

        if(this.state.configurazioneModello){
            for(const sezione of this.state.configurazioneModello.configurazioniSezioni){
                if(sezione.nomeVisualizzato === nomeSezione) {
                    esito = sezione;
                    break;
                }
            }
        }

        return esito;
    }

    /**
     * Restituisce true se la sezione è di singola immagine
     * @param nomeSezione Nome della sezione
     * @private
     */
    private _isSingolaImmagine(nomeSezione: string): boolean{
        let esito = false;
        if(this.state.configurazioneModello){
            for(const sezione of this.state.configurazioneModello.configurazioniSezioni){
                if(sezione.nomeVisualizzato === nomeSezione || sezione.nomeReale === nomeSezione) {
                    esito = sezione.singolaImmagineFlag;
                    break;
                }
            }
        }
        return esito;
    }

    /**
     * Restituisce true se la sezione è di singolo colore
     * @param nomeSezione Nome della sezione da controllare
     * @private
     */
    private _isSingoloColore(nomeSezione: string): boolean{
        let esito = false;
        if(this.state.configurazioneModello){
            for(const sezione of this.state.configurazioneModello.configurazioniSezioni){
                if(sezione.nomeVisualizzato === nomeSezione || sezione.nomeReale === nomeSezione) {
                    esito = sezione.singoloColoreFlag;
                    break;
                }
            }
        }
        return esito;
    }

    public componentDidMount() {
        super.componentDidMount();
        this._caricaConfigurazioneModello();
    }

    public componentDidUpdate(prevProps: Readonly<ListaMaterialiConfiguratoreProps>, prevState: Readonly<{}>, snapshot?: any) {
        super.componentDidUpdate(prevProps, undefined, snapshot);
        if (
            prevProps.nomeConfigurazioneModello !== this.props.nomeConfigurazioneModello ||
            JSON.stringify(prevProps.configurazioneModello) !== JSON.stringify(this.props.configurazioneModello) ||
            prevProps.urlModello !== this.props.urlModello
        ) {
            this._caricaConfigurazioneModello();
            this.ClearSezioni();
        }
    }

    protected GetGruppoSezione(nomeSezione: string): string {
        let esito = "";

        if(this.state.configurazioneModello){
            for(const sezione of this.state.configurazioneModello.configurazioniSezioni){
                if(sezione.nomeVisualizzato === nomeSezione) {
                    esito = sezione.nomeGruppo;
                    break;
                }
            }
        }

        return esito;
    }
}
