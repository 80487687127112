import React from "react";
import {formatoToAspectRatio} from "../../Utils/CommonFunctions";

export interface PlaceholderColoratoProps{
    colore: string,
    formato: string,
    order: number
}

export default class PlaceholderColorato extends React.Component<PlaceholderColoratoProps, {}>{
    public render() {
        return (
            <div
                style={{
                    aspectRatio: formatoToAspectRatio(this.props.formato),
                    order: this.props.order,
                    display: 'block',
                    width: '100%',
                    background: this.props.colore,
                    flexShrink: '0'
                }}/>
        );
    }
}
