import React from "react";
import {
    CFRegex,
    CheckUtenteFotografo,
    EmailRegex,
    FotografoModel,
    PasswordRegex, PVRegex,
    TelefonoRegex,
    UtenteModel
} from "tici_commons";
import {LRRLayout} from "../../PagesLayout/PagesLayout";
import SectionLayout from "../../Layout/SectionLayout/SectionLayout";
import Button from "../../Core/Buttons/Button";
import {TextLine} from "../../Widgets/Form/FComponents";
import RedirectService from "../../Servicies/RedirectService";
import TwoSpaceLine from "../../Layout/TwoSpaceLine/TwoSpaceLine";
import TopLevelComponentStorage from "../../Core/Arch/TopLevelComponentStorage";
import UserService from "../../Servicies/UserService";
import DefaultInput from "../../Core/DefaultInput/DefaultInput";
import AddressLayout from "../../Layout/AddressLayout/AddressLayout";
import IfContainer from "../../Layout/IfContainer/IfContainer";
import PrivacyLayout from "../../Layout/PrivacyLayout/PrivacyLayout";
import ResponsiveLabel from "../../Core/ResponsiveLabel/ResponsiveLabel";
import SentenceLayout from "../../Layout/SentenceLayout/SentenceLayout";
import Checkbox from "../../Core/Checkbox/Checkbox/Checkbox";

export interface RegistrationPageState{
    utente: UtenteModel,
    fotografo: FotografoModel,
    immagineProfilo: string,
    ripetiEmail: string,
    ripetiPassword: string,

    citta: string,
    provincia: string,
    cap: string,
    indirizzoStrada: string,
    civico: string
}

export default class RegistrationPage extends React.Component<{}, RegistrationPageState>{
    constructor(props: Readonly<{}> | {}) {
        super(props);
        this.state = {
            utente: {
                email: "",
                tipoUtente: "fotografo",
                superUser: 0,
                codiceFiscale: "",
                cognome: "",
                nome: "",
                immagineProfilo: "",
                numeroTelefono: "",
                password: "",
                statoAttivazioneEmail: 0,
                statoAttivazione: 0,
                privacyAccettata: 0
            },
            fotografo: {
                identificativo: -1,
                emailUtente: "",
                indirizzoStudio: "",
                nomeStudio: "",
                partitaIva: "",
                codiceUnivocoFatture: "",
                nuovoCliente: 0,
                regioneAppartenenza: ""
            },
            immagineProfilo: "",
            ripetiEmail: "",
            ripetiPassword: "",
            citta: "",
            provincia: "",
            cap: "",
            indirizzoStrada: "",
            civico: ""
        }
    }

    private _richiestaNuovoCliente(){
        const fotografo = this.state.fotografo;
        TopLevelComponentStorage.GetTopLevel('confirmWindow').showConfirmWindow(
            "Nuovo cliente?",
            "Sei un nuovo cliente di TICI?",
            "DoubleButtonSiNo",
            () => {
                fotografo.nuovoCliente = 1;
                TopLevelComponentStorage.GetTopLevel('confirmWindow').hideConfirmWindow();
                this.setState({fotografo}, () => this._registrazione());
            },
            () => {
                fotografo.nuovoCliente = 0;
                TopLevelComponentStorage.GetTopLevel('confirmWindow').hideConfirmWindow();
                this.setState({fotografo}, () => this._registrazione());
            }
        )
    }

    /**
     * Effettua la registrazione sul database dell'account
     * @private
     */
    private async _registrazione(){
        TopLevelComponentStorage.GetTopLevel('loadingWindow').showLoadingWindow("Registrazione", "Stiamo registrando il tuo account");
        const esito = await UserService.Registrazione(this.state.utente, this.state.fotografo, this.state.immagineProfilo);
        TopLevelComponentStorage.GetTopLevel('loadingWindow').hideLoadingWindow();
        if(esito){
            TopLevelComponentStorage.GetTopLevel('confirmWindow').showConfirmWindow(
                "Account creato correttamente",
                `Il tuo account è stato creato correttamente. Ti è stata inviata una mail all'indirizzo ${this.state.utente.email} con un link di conferma`,
                "SingleButton",
                () => {
                    TopLevelComponentStorage.GetTopLevel('confirmWindow').hideConfirmWindow();
                    RedirectService.GoToLogin();
                }
            );
        }else{
            TopLevelComponentStorage.GetTopLevel('confirmWindow').showConfirmWindow(
                "Errore nella registrazione",
                `Non è stato possibile creare l'account`,
                "SingleButton",
                () => {
                    TopLevelComponentStorage.GetTopLevel('confirmWindow').hideConfirmWindow();
                    RedirectService.GoToLogin();
                }
            );
        }
    }

    /**
     * Controlla se il form è valido oppure no
     * @private
     */
    private _isFormValid(): boolean{
        return CheckUtenteFotografo(this.state.utente, this.state.fotografo) &&
            this.state.civico.length !== 0 && this.state.indirizzoStrada.length !== 0 && this.state.civico.length !== 0;
    }

    /**
     * Aggiorna lo stato del numero civico
     * @private
     */
    private _aggiornaStatoCivico(){
        const fotografo = this.state.fotografo;
        fotografo.indirizzoStudio = `${this.state.citta}(${this.state.provincia})-${this.state.cap}, ${this.state.indirizzoStrada} ${this.state.civico}`;
        this.setState({fotografo});
    }

    /**
     * Controlla lo stato di errore del civico
     * @private
     */
    private _controllaStatoCivico(): boolean{
        return (
            this.state.citta.length === 0 ||
            this.state.provincia.length < 2 || this.state.provincia.length > 3 ||
            this.state.cap.length < 4 ||
            this.state.indirizzoStrada.length === 0 ||
            this.state.civico.length === 0
        )
    }

    render() {
        return (
            <LRRLayout>
                <SectionLayout size={"largeRelative"} center={true}>
                    <Button
                        content={"Indietro"}
                        type={"medium"}
                        alignment={"right"}
                        buttonType={"simple-light"}
                        onClick={() => RedirectService.GoToLogin()}/>
                    <SectionLayout size={"largeRelative"}>
                        <TextLine
                            label={"Immagine/Logo"}
                            type={"file"}
                            value={this.state.utente.immagineProfilo}
                            placeHolder={"Carica la tua immagine"}
                            errorMessage={"Bisogna inserire un'immagine"}
                            onlyMobileError={true}
                            showError={this.state.utente.immagineProfilo.length === 0}
                            onFileUpload={(fileName, fileType, file, base64Encoding) => {
                                const utente = this.state.utente;
                                utente.immagineProfilo = fileName;
                                this.setState({utente: utente, immagineProfilo: base64Encoding});
                            }}/>
                        <TextLine
                            label={"Nome"}
                            errorMessage={"Il nome non può essere vuoto"}
                            showError={this.state.utente.nome.length === 0}
                            onlyMobileError={true}
                            value={this.state.utente.nome}
                            onChange={v => {
                                const utente = this.state.utente;
                                utente.nome = v;
                                this.setState({utente: utente});
                            }}/>
                        <TextLine
                            label={"Cognome"}
                            errorMessage={"Il cognome non può essere vuoto"}
                            onlyMobileError={true}
                            showError={this.state.utente.cognome.length === 0}
                            value={this.state.utente.cognome}
                            onChange={v => {
                                const utente = this.state.utente;
                                utente.cognome = v;
                                this.setState({utente: utente});
                            }}/>
                        <TextLine
                            label={"Email"}
                            errorMessage={"Errore nel formato della mail"}
                            onlyMobileError={true}
                            showError={!EmailRegex.test(this.state.utente.email)}
                            value={this.state.utente.email}
                            onChange={v => {
                                const utente = this.state.utente;
                                utente.email = v;
                                const fotografo = this.state.fotografo;
                                fotografo.emailUtente = v;
                                this.setState({utente: utente, fotografo: fotografo});
                            }}/>
                        <IfContainer condition={this.state.utente.email.length > 0}>
                            <TextLine
                                label={"Ripeti email"}
                                errorMessage={"Le email non coincidono"}
                                onlyMobileError={true}
                                showError={this.state.utente.email !== this.state.ripetiEmail}
                                value={this.state.ripetiEmail}
                                onChange={v => {this.setState({ripetiEmail: v})}}/>
                        </IfContainer>
                        <TextLine
                            label={"Password"}
                            errorMessage={"La password non rispetta il formato"}
                            onlyMobileError={true}
                            showError={!PasswordRegex.test(this.state.utente.password)}
                            type={"password"}
                            value={this.state.utente.password}
                            onChange={v => {
                                const utente = this.state.utente;
                                utente.password = v;
                                this.setState({utente: utente});
                            }}/>
                        <IfContainer condition={this.state.utente.password.length > 0}>
                            <TextLine
                                label={"Ripeti password"}
                                errorMessage={"Le password non coincidono"}
                                onlyMobileError={true}
                                showError={this.state.utente.password !== this.state.ripetiPassword}
                                type={"password"}
                                value={this.state.ripetiPassword}
                                onChange={v => {this.setState({ripetiPassword: v})}}/>
                        </IfContainer>
                        <TextLine
                            label={"Codice fiscale"}
                            errorMessage={"Il codice fiscale non è formalmente valido"}
                            onlyMobileError={true}
                            showError={!CFRegex.test(this.state.utente.codiceFiscale)}
                            value={this.state.utente.codiceFiscale}
                            onChange={v => {
                                const utente = this.state.utente;
                                utente.codiceFiscale = v.toUpperCase();
                                this.setState({utente: utente});
                            }}/>
                        <TextLine
                            label={"Numero di telefono"}
                            errorMessage={"Il numero di telefono non è valido"}
                            showError={!TelefonoRegex.test(this.state.utente.numeroTelefono)}
                            onlyMobileError={true}
                            value={this.state.utente.numeroTelefono}
                            onChange={v => {
                                const utente = this.state.utente;
                                utente.numeroTelefono = v;
                                this.setState({utente: utente});
                            }}/>
                        <TextLine
                            label={"Nome studio"}
                            errorMessage={"Il nome dello studio non può essere vuoto"}
                            showError={this.state.fotografo.nomeStudio.length === 0}
                            onlyMobileError={true}
                            value={this.state.fotografo.nomeStudio}
                            onChange={v => {
                                const fotografo = this.state.fotografo;
                                fotografo.nomeStudio = v;
                                this.setState({fotografo: fotografo});
                            }}/>
                        <TwoSpaceLine
                            label={"Indirizzo studio"}
                            onlyMobileError={true}
                            formMode={true}
                            labelType={"whiteLabel"}
                            errorMessage={"L'indirizzo dello studio non può essere vuoto o malformato"}
                            showError={this._controllaStatoCivico()}>
                            <AddressLayout>
                                <DefaultInput
                                    placeHolder={"Città"}
                                    value={this.state.citta}
                                    onChange={v => {
                                        this.setState({citta: v}, () => this._aggiornaStatoCivico());
                                    }}/>
                                <DefaultInput
                                    placeHolder={"Provincia"}
                                    value={this.state.provincia}
                                    min={2}
                                    max={3}
                                    onChange={v => {
                                        this.setState({provincia: v}, () => this._aggiornaStatoCivico());
                                    }}/>
                                <DefaultInput
                                    placeHolder={"Cap"}
                                    value={this.state.cap}
                                    type={"number"}
                                    onChange={v => {
                                        this.setState({cap: v}, () => this._aggiornaStatoCivico());
                                    }}/>
                                <DefaultInput
                                    placeHolder={"Indirizzo"}
                                    value={this.state.indirizzoStrada}
                                    onChange={v => {
                                        this.setState({indirizzoStrada: v}, () => this._aggiornaStatoCivico());
                                    }}/>
                                <DefaultInput
                                    type={"number"}
                                    placeHolder={"Civico"}
                                    value={this.state.civico}
                                    onChange={v => {
                                        this.setState({civico: v}, () => this._aggiornaStatoCivico());
                                    }}/>
                            </AddressLayout>
                        </TwoSpaceLine>
                        <TextLine
                            label={"Partita IVA"}
                            errorMessage={"La partita IVA non è formalmente valida"}
                            showError={!PVRegex.test(this.state.fotografo.partitaIva)}
                            onlyMobileError={true}
                            value={this.state.fotografo.partitaIva}
                            onChange={v => {
                                const fotografo = this.state.fotografo;
                                fotografo.partitaIva = v;
                                this.setState({fotografo: fotografo});
                            }}/>
                        <TextLine
                            label={"Codice univoco"}
                            errorMessage={"Il codice fatture non può essere vuoto"}
                            value={this.state.fotografo.codiceUnivocoFatture}
                            onlyMobileError={true}
                            onChange={v => {
                                const fotografo = this.state.fotografo;
                                fotografo.codiceUnivocoFatture = v;
                                this.setState({fotografo: fotografo});
                            }}
                            showError={this.state.fotografo.codiceUnivocoFatture.length === 0}/>
                        <PrivacyLayout>
                            <SentenceLayout alignment={"left"}>
                                <ResponsiveLabel
                                    content={"Per concludere la registrazione é necessario confermare le"}
                                    type={"medium"}
                                    alignment={"left"}
                                    labelType={"recapLabel"}/>
                                <div
                                    style={{cursor: 'pointer'}}
                                    onClick={() => window.open('https://www.ticialbum.com/privacy-policy', '_blank')}>
                                    <ResponsiveLabel
                                        content={"informazioni di privacy"}
                                        type={"medium"}
                                        alignment={"left"}
                                        bold={true}
                                        labelType={"recapLabel"}/>
                                </div>
                                <ResponsiveLabel
                                    content={"."}
                                    type={"medium"}
                                    alignment={"left"}
                                    labelType={"recapLabel"}/>
                            </SentenceLayout>
                            <SentenceLayout alignment={"right"}>
                                <ResponsiveLabel
                                    content={"Accetto"}
                                    type={"medium"}
                                    alignment={"left"}
                                    labelType={"recapLabel"}/>
                                <Checkbox
                                    checked={this.state.utente.privacyAccettata === 1}
                                    onChange={v => {
                                        const utente = this.state.utente;
                                        utente.privacyAccettata = v ? 1 : 0;
                                        this.setState({utente: utente});
                                    }}/>
                            </SentenceLayout>
                        </PrivacyLayout>
                    </SectionLayout>
                    <Button
                        content={"Registrati"}
                        type={"medium"}
                        disabled={!this._isFormValid()}
                        onClick={() => {this._richiestaNuovoCliente()}}
                        buttonType={"full-normal-login"}
                        alignment={"center"}/>
                </SectionLayout>
            </LRRLayout>
        );
    }
}
