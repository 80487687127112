import {StorageData} from "../../Pages/SitoInterno/Configuratore/Storage/StorageManager";
import React from "react";
import IfContainer from "../../Layout/IfContainer/IfContainer";
import Loader from "../../Core/Loader/Loader";

export interface SingoloSelettoreImmagineProps {
    immagine: StorageData,
    order: number
    escluso?: boolean,
    selezionato?: boolean,
    onClick?: () => void,
    overlaySelettore?: React.ReactElement;
}

export default class SingoloSelettoreImmagine extends React.Component<SingoloSelettoreImmagineProps, {}>{
    public render() {
        return (
            <IfContainer
                condition={!!this.props.immagine.url()}
                elseComponent={
                    <Loader/>
                }>
                <div
                    className={"SingoloSelettoreImmagineContainer"}
                    onClick={() => {this.props.onClick && this.props.onClick()}}
                    style={{order: `${this.props.order}`}}>
                    <img
                        alt={this.props.immagine.name}
                        className={`SingoloSelettoreImmagine ${this.props.escluso && 'escluso'} ${this.props.selezionato && 'selezionato'}`}
                        loading={"lazy"}
                        src={this.props.immagine.url()}/>
                    {this.props.overlaySelettore && this.props.overlaySelettore}
                </div>
            </IfContainer>
        );
    }
}
