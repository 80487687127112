import React from "react";
import {LRRLayout} from "../../PagesLayout/PagesLayout";
import {TextLine} from "../../Widgets/Form/FComponents";
import Button from "../../Core/Buttons/Button";
import SectionLayout from "../../Layout/SectionLayout/SectionLayout";
import TwoSpaceLine from "../../Layout/TwoSpaceLine/TwoSpaceLine";
import Responsive2Col from "../../Layout/Responsive2Col/Responsive2Col";
import RedirectService from "../../Servicies/RedirectService";
import {EmailRegex} from "tici_commons";
import TopLevelComponentStorage from "../../Core/Arch/TopLevelComponentStorage";
import UserService from "../../Servicies/UserService";

export interface RecuperoPageState{
    email: string
}

export default class RecuperoPage extends React.Component<{}, RecuperoPageState>{
    constructor(props: Readonly<{}> | {}) {
        super(props);
        this.state = {
            email: ""
        }
    }

    /**
     * Effettua il recupero della password
     * @private
     */
    private async _recuperaPassword(){
        TopLevelComponentStorage.GetTopLevel('loadingWindow').showLoadingWindow("Recupero", "Stiamo generando una nuova password per il tuo account");
        const response = await UserService.RecuperaPassword(this.state.email);
        TopLevelComponentStorage.GetTopLevel('loadingWindow').hideLoadingWindow();
        TopLevelComponentStorage.GetTopLevel('confirmWindow').showConfirmWindow(
            response ? 'Recupero concluso' : 'Recupero non concluso',
            response ? 'Ti é stata inviata una mail con all\'interno la nuova password per il tuo account' : 'Non é stato possibile recuperare il tuo account',
            'SingleButton',
            () => {
                response && RedirectService.GoToLogin();
            }
        )
    }

    render() {
        return (
            <LRRLayout>
                <SectionLayout size={"smallRelative"} center={true}>
                    <Button
                        content={"Indietro"}
                        type={"medium"}
                        alignment={"right"}
                        buttonType={"simple-light"}
                        onClick={() => RedirectService.GoToLogin()}/>
                    <TextLine
                        label={"Email di recupero"}
                        type={"text"}
                        value={this.state.email}
                        onChange={v => this.setState({email: v})}
                        errorMessage={"Inserisci una mail valida"}
                        showError={!EmailRegex.test(this.state.email)}
                        onlyMobileError={true}/>
                    <Button
                        content={"Recupera"}
                        type={"medium"}
                        disabled={!EmailRegex.test(this.state.email)}
                        buttonType={"full-normal-login"}
                        onClick={() => this._recuperaPassword()}
                        alignment={"center"}/>
                </SectionLayout>

            </LRRLayout>
        );
    }
}
