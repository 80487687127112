import React from "react";


export default abstract class AutoRefreshComponent<T, S> extends React.Component<T, S>{
    private _currentId: number;                             //Id corrente per la gestione del thread
    private _canUpdate = true;                              //Flag di controllo per l'update
    private _delay = 2000;                                  //DelayStandard tra un aggiornamento ed un altro

    constructor(props: Readonly<T> | T) {
        super(props);
    }

    /**
     * Avvia l'intervallo di aggiornamento del componente
     * @private
     */
    private _startInterval(){
        const updateFunction = async () => {
            this._canUpdate = false;

            const current = this;
            const keys = Object.keys(this);
            for(const key of keys){
                if(key.toLowerCase().includes("cycle")) {
                    const fun = current[key as keyof typeof current];
                    if (typeof fun === "function")
                        await fun();
                }

            }

            this._canUpdate = true;
        }
        updateFunction();

        if(this._currentId === undefined){
            this._currentId = window.setInterval(() => {
                if(this._canUpdate)
                    updateFunction();
            }, this._delay)
        }
    }

    /**
     * Pulisce l'intervallo
     * @private
     */
    private _clearInterval(){
        if(this._currentId !== undefined){
            window.clearInterval(this._currentId);
            this._currentId = undefined;
        }
    }

    public componentDidMount() {
        console.log("Avviato l'intervallo");
        this._startInterval();
    }

    public componentWillUnmount() {
        console.log("Stoppato l'intervallo");
        this._clearInterval();
    }

    protected set Delay(delay: number){
        this._clearInterval();
        this._canUpdate = true;
        this._delay = delay;
        this._startInterval();
    }

    protected get Delay(): number{
        return this._delay;
    }
}
