import React from "react";
import ResponsiveLabel from "../../../../../../Core/ResponsiveLabel/ResponsiveLabel";
import {StorageManagerStatus} from "../../../Storage/StorageManager";
import {
    GenerateRiepilogoData,
    GenerateRiepilogoInternalBox,
    RiepilogoBox,
    RiepilogoData,
    RiepilogoInternalBox
} from "../../../Commons/RiepilogoDatoModel";
import SezioneGenerica from "../../SezioneGenerica";
import {FotolibroModel} from "tici_commons";
import CategorySectionLayout from "../../../../../../Layout/CategorySectionLayout/CategorySectionLayout";

export default class SezioneAlbumPocketCopertina extends SezioneGenerica<"copertina", {}, {}> {
    public generaRiepilogo = (fotolibro: FotolibroModel, storage: StorageManagerStatus, modalitaConfiguratore: boolean): RiepilogoInternalBox | RiepilogoData[] | RiepilogoBox | RiepilogoInternalBox[] => {
        return GenerateRiepilogoInternalBox(
            'Copertina album pocket',
            GenerateRiepilogoData(
                `Materiali album pocket analoghi alla copertina dell'album principale`,
                undefined,
                undefined,
                fotolibro.informazioniBase.albumPocketFlag && fotolibro.informazioniBase.albumPocketQuantita > 0
            )
        )
    }

    public render() {
        return (
            <CategorySectionLayout categoryLabel={"Album pocket"} size={"largeRelative"} center={true}>
                <ResponsiveLabel
                    content={"Materiali della copertina dell'album pocket analoghi alla copertina dell'album principale"}
                    type={"medium"}
                    alignment={"right"}/>
            </CategorySectionLayout>
        );
    }
}
