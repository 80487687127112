import {FotolibroModel, PannelliAlufoamModel} from "tici_commons";
import React from "react";
import ResponsiveLabel from "../../../../../../Core/ResponsiveLabel/ResponsiveLabel";
import {TextLine} from "../../../../../../Widgets/Configuratore/CComponents";
import IfContainer from "../../../../../../Layout/IfContainer/IfContainer";
import SezioneFotoTela from "./SezioneFotoTela";
import {StorageDataMapper, StorageManagerStatus} from "../../../Storage/StorageManager";
import {
    GenerateRiepilogoBox, GenerateRiepilogoData, GenerateRiepilogoInternalBox,
    RiepilogoBox,
    RiepilogoData,
    RiepilogoInternalBox
} from "../../../Commons/RiepilogoDatoModel";
import SezionePannelliPlex from "./SezionePannelliPlex";
import CategorySectionLayout from "../../../../../../Layout/CategorySectionLayout/CategorySectionLayout";
import DragAndDropMultifunzionale from "../../../../../../Primary/DragAndDropMultifunzionale/DragAndDropMultifunzionale";
import SezioneAccessoriGenerica from "../SezioneAccessoriGenerica";

export default class SezionePannelliAlufoam extends SezioneAccessoriGenerica<"PannelliAlufoam", {}, {}>{
    public static ErrorMessages = {
        QuantitaErrataError: 'Bisogna selezionare almeno un unità',
        CaricamentiErratiError: 'Il numero di immagini caricate non coincide con il numero di pannelli'
    }

    public generaRiepilogo(accessorio: PannelliAlufoamModel, indexAccessorio: number, fotolibro: FotolibroModel, storage: StorageManagerStatus, modalitaConfiguratore: boolean): RiepilogoBox | RiepilogoBox[] | RiepilogoInternalBox | RiepilogoInternalBox[] | RiepilogoData[] {
        return GenerateRiepilogoBox(
            `Pannelli alufoam ${indexAccessorio + 1}`,
            GenerateRiepilogoInternalBox(
                `Pannelli alufoam ${indexAccessorio + 1}`,
                GenerateRiepilogoData(
                    `Numero pannelli: ${accessorio.quantitaPannelliAlufoam}`,
                    SezionePannelliPlex.ErrorMessages.QuantitaErrataError,
                    accessorio.quantitaPannelliAlufoam === 0,
                ),
                GenerateRiepilogoData(
                    `Formato: ${accessorio.formatoPannelliAlufoam}`,
                    SezioneFotoTela.ErrorMessages.FormatoErratoError,
                    accessorio.formatoPannelliAlufoam.length === 0
                ),
                GenerateRiepilogoData(
                    undefined,
                    SezionePannelliPlex.ErrorMessages.CaricamentiErratiError,
                    true,
                    storage && storage.GetStorageData(`Accessorio-${indexAccessorio}-${StorageDataMapper.PannelliAlufoam}`).length !== accessorio.quantitaPannelliAlufoam
                )
            )
        );
    }

    public render() {
        return (
            <CategorySectionLayout categoryLabel={`Pannelli alufoam ${this.props.indexAccessorio + 1}`} size={"largeRelative"} center={true}>
                <TextLine
                    label={"Numero pannelli"}
                    type={"number"}
                    min={0}
                    max={200}
                    value={String(this.$.quantitaPannelliAlufoam)}
                    onChange={v => this.$.quantitaPannelliAlufoam = isNaN(parseInt(v)) ? 0 : parseInt(v)}
                    showError={this.$.quantitaPannelliAlufoam === 0}
                    errorMessage={SezionePannelliPlex.ErrorMessages.QuantitaErrataError}/>
                <TextLine
                    label={"Formato"}
                    type={"text"}
                    placeHolder={"NxM"}
                    value={this.$.formatoPannelliAlufoam}
                    onChange={v => this.$.formatoPannelliAlufoam = v}
                    errorMessage={SezioneFotoTela.ErrorMessages.FormatoErratoError}
                    showError={!new RegExp(/^[0-9]{1,3}x[0-9]{1,3}$/gi).test(this.$.formatoPannelliAlufoam)}/>
                <IfContainer condition={this.CountImmagini(StorageDataMapper.PannelliAlufoam) !== this.$.quantitaPannelliAlufoam}>
                    <ResponsiveLabel
                        content={SezionePannelliPlex.ErrorMessages.CaricamentiErratiError}
                        labelType={"errorLabel"}
                        type={"small"}
                        alignment={"center"}/>
                </IfContainer>
                <DragAndDropMultifunzionale
                    indicatoreMassimoFogli={this.$.quantitaPannelliAlufoam}
                    onUpload={(file) => this.UplodaImmagine(StorageDataMapper.PannelliAlufoam, file.name, file) }
                    onDelete={(fileName) => this.DeleteImmagine(StorageDataMapper.PannelliAlufoam, fileName)}
                    immagini={this.ImmaginiDaCategoria(StorageDataMapper.PannelliAlufoam)}/>
            </CategorySectionLayout>
        );
    }
}
